import { BaseResponse } from '@/types/service';

export const transformResponse = <Req extends BaseResponse<Req>, Res>(
  data: Req,
) => {
  if (!data.success) {
    return {
      data: null,
      message: data.message,
      apiError: true,
    } as unknown as Res;
  } else {
    return data as unknown as Res;
  }
};
