export const applicationStatus: { [key: string]: string } = {
  APPLICATION_RECEIVED: `APPLICATION_RECEIVED`,
  DOCUMENT_VERIFIED: `DOCUMENT_VERIFIED`,
  APPLICATION_ON_HOLD: `APPLICATION_ON_HOLD`,
  AGGREGATOR_ON_HOLD_RESOLVED: `AGGREGATOR_ON_HOLD_RESOLVED`,
  APPLICATION_IN_ASSESSMENT: `APPLICATION_IN_ASSESSMENT`,
  APPLICATION_SENT_TO_UNIVERSITY: `APPLICATION_SENT_TO_UNIVERSITY`,
  APPLICATION_ON_HOLD_BY_UNIVERSITY: `APPLICATION_ON_HOLD_BY_UNIVERSITY`,
  UNIVERSITY_ON_HOLD_RESOLVED: `UNIVERSITY_ON_HOLD_RESOLVED`,
  UNIVERSITY_COMMUNICATION_PENDING: `UNIVERSITY_COMMUNICATION_PENDING`,
  APPLICATION_DROPPED: `APPLICATION_DROPPED`,
  APPLICATION_REJECTED: `APPLICATION_REJECTED`,
  CONDITIONAL_ADMIT_RECEIVED: `CONDITIONAL_ADMIT_RECEIVED`,
  UNCONDITIONAL_ADMIT_RECEIVED: `UNCONDITIONAL_ADMIT_RECEIVED`,
  ADMIT_ACCEPTED: `ADMIT_ACCEPTED`,
  ADMIT_DECLINED: `ADMIT_DECLINED`,
  OFFER_REVOKED: `OFFER_REVOKED`,
};

export const applicationStatusRank: { [key: string]: number } = {
  APPLICATION_RECEIVED: 1,
  APPLICATION_ON_HOLD: 2,
  AGGREGATOR_ON_HOLD_RESOLVED: 3,
  DOCUMENT_VERIFIED: 4,
  APPLICATION_SENT_TO_UNIVERSITY: 5,
  APPLICATION_IN_ASSESSMENT: 6,
  APPLICATION_ON_HOLD_BY_UNIVERSITY: 7,
  UNIVERSITY_ON_HOLD_RESOLVED: 8,
  UNIVERSITY_COMMUNICATION_PENDING: 9,
  CONDITIONAL_ADMIT_RECEIVED: 10,
  UNCONDITIONAL_ADMIT_RECEIVED: 11,
  APPLICATION_DROPPED: 12,
  APPLICATION_REJECTED: 13,
  ADMIT_ACCEPTED: 14,
  OFFER_REVOKED: 15,
  ADMIT_DECLINED: 16,
};

export const roleStatus: { [key: string]: string } = {
  GB_OPS_MANAGER: `GB_OPS_MANAGER`,
  GB_ADMIN: `GB_ADMIN`,
  GB_ZONAL_MANAGER: `GB_ZONAL_MANAGER`,
  GB_VIEWER: `GB_VIEWER`,
  GB_REPRESENTATIVE: `GB_REPRESENTATIVE`,
  GB_COUNTRY_HEAD: `GB_COUNTRY_HEAD`,
  GB_COUNSELLOR: `GB_COUNSELLOR`,
  GB_HEAD_CUSTOMER_CARE: `GB_HEAD_CUSTOMER_CARE`,
  GB_VISA_TEAM: `GB_VISA_TEAM`,
};

export const uploadStatus: {
  [key: string]: string;
  PENDING: string;
  UPLOADED: string;
  ACCEPTED: string;
  REJECTED: string;
} = {
  PENDING: `PENDING`,
  UPLOADED: `UPLOADED`,
  ACCEPTED: `ACCEPTED`,
  REJECTED: `REJECTED`,
};
export const uploadType: { [key: string]: string } = {
  OFFER_LETTER: `OFFER_LETTER`,
};
export const offerType: { [key: string]: string } = {
  CONDITIONAL_OFFER_LETTER: `CONDITIONAL_OFFER_LETTER`,
  UNCONDITIONAL_OFFER_LETTER: `UNCONDITIONAL_OFFER_LETTER`,
};
export const multiDocsType: { [key: string]: string } = {
  UNCONDITIONAL_OFFER_LETTER: `UNCONDITIONAL_OFFER_LETTER`,
  CONDITIONAL_OFFER_LETTER: `CONDITIONAL_OFFER_LETTER`,
  FINANCIAL_DOCUMENT: `FINANCIAL_DOCUMENT`,
  ACADEMIC_DOCUMENTS: `ACADEMIC_DOCUMENTS`,
  MEDICAL_CERTIFICATE: `MEDICAL_CERTIFICATE`,
  FEE_PAYMENT_RECEIPT: `FEE_PAYMENT_RECEIPT`,
  TT: `TT`,
};
export const multiVisaDocsType: { [key: string]: string } = {
  UNCONDITIONAL_OFFER_LETTER: `UNCONDITIONAL_OFFER_LETTER`,
  FEE_PAYMENT_RECEIPT: `FEE_PAYMENT_RECEIPT`,
};
export const appType: { [key: string]: string } = {
  AGENT_PORTAL: `AGENT_PORTAL`,
  ONLINE_APPLICATION: `ONLINE_APPLICATION`,
  THIRD_PARTY_APPLICATION: `THIRD_PARTY_APPLICATION`,
  PAPER_BASED_APPLICATION: `PAPER_BASED_APPLICATION`,
  EMAIL: `EMAIL`,
  TO_BE_FILED_BY_GEEBEE: `TO_BE_FILED_BY_GEEBEE`,
};
