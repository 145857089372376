import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Form } from 'react-final-form';
import {
  Button,
  FFCheckBoxes,
  FFDropDown,
  FFRadio,
  FFTextInput,
  isFalsy,
  useFinalFormYupFormHelpers,
} from '@leapfinance/frontend-commons';
import { typography } from '@leapfinance/frontend-commons/components/geebee/theme';
import { convertToNormalCase } from '@leapfinance/frontend-commons/helpers';
import { type OPTION_TYPE } from '@leapfinance/frontend-commons/schema/common';
import { Typography } from '@mui/material';
import { type FormApi } from 'final-form';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';

import { selectUserDetails } from '@/app/features/user/userSlice';
import { useAppSelector } from '@/app/hooks';
import useCreateStudent from '@/hooks/vas/useCreateStudent';
import useUniversitiesOptions from '@/hooks/vas/useUniversitiesOptions';
import { useGetApplicationsv2Mutation } from '@/services/applicationApi';
import { useFetchAllCountryDataQuery } from '@/services/studentProfileV1';
import {
  useCreateFlywirePaymentLinkMutation,
  useLazyGetDuplicateFlywireCheckQueryQuery,
} from '@/services/vas';
import { VASCreateFlywirePaymentLinkRequestBodyType } from '@/types/vas/flywire';
import {
  FlywireFormSchema,
  VasStudentCreationRequestBodyType,
  VASStudentCreationSchema,
} from '@/validations/vas.schema';

import FFVASStudentField from './FFVASStudentField';
import {
  FLYWIRE_DETAILS_LOCAL_STORAGE_KEY,
  getAddNewStudentButtonStyles,
  getFlywireDetailsDataOnRedirect,
  getVasRequestFormTitle,
  isStudentExisting,
} from './helpers';
import VASDrawerHeader from './VASDrawerHeader';
import VASSuccessScreen from './VASSuccessScreen';

export type FlywireFormProps = {
  studentId?: number;
  student?: string;
  // TODO:: later replace with actual types
  // initialValues?: Partial<
  //   Record<keyof VASCreateAccomodationRequestBodyType, any>
  // >;
  initialValues?: any;
  cspId?: number;
  cspName?: string;
  onClose: () => void;
  destinationCountry?: string;
};

function VASFlywireFormController({
  student,
  studentId,
  initialValues = {},
  cspId,
  cspName,
  onClose,
  destinationCountry,
}: FlywireFormProps) {
  const user = useAppSelector((state) => selectUserDetails(state));
  const flywireDetailsData = getFlywireDetailsDataOnRedirect();
  const paymentLink = (flywireDetailsData as any)?.paymentLink;
  const showSuccessScreen = (flywireDetailsData as any)?.open;
  const router = useRouter();
  const [screen, setScreen] = useState<number>(0);
  const formRef: React.MutableRefObject<FormApi<any, any> | null> =
    useRef(null);
  const [validate, formNames] = useFinalFormYupFormHelpers({
    schema: FlywireFormSchema,
  });
  const [, studentFormNames] = useFinalFormYupFormHelpers({
    schema: VASStudentCreationSchema,
  });
  const firstScreenFieldNames = [
    formNames.student,
    formNames.countryCode,
    formNames.contactNumber,
    formNames.applicationId,
    formNames.emailId,
    formNames.cspId,
  ];
  const secondScreenFieldNames = [
    formNames.universityName,
    formNames.permissionRequired,
  ];

  const [_, fetchUniversityVendorOptions] = useUniversitiesOptions({
    serviceCategory: `FLYWIRE`,
  });

  const { handleCreateNewStudent, isCreatingStudent } = useCreateStudent();

  const [
    triggerCreateFlywirePaymentLinkRequest,
    { data, isLoading: isCreatingFlywirePaymentLink },
  ] = useCreateFlywirePaymentLinkMutation();
  const [
    getApplicationTrigger,
    { data: applicationsData, isLoading: isApplicationsDataLoading },
  ] = useGetApplicationsv2Mutation();
  const [triggerCheckDuplicateFlywireQuery] =
    useLazyGetDuplicateFlywireCheckQueryQuery();
  const { data: countryList, refetch: refetchCountryList } =
    useFetchAllCountryDataQuery(undefined, {
      skip: screen === 0,
    });
  const countryOptions = (countryList?.data ?? []).map((country) => ({
    label: country,
    value: country,
  }));

  useEffect(() => {
    window.addEventListener(`beforeunload`, clearFlyWireData);
    return () => {
      window.removeEventListener(`beforeunload`, clearFlyWireData);
    };
  }, []);

  const clearFlyWireData = () => {
    localStorage.removeItem(FLYWIRE_DETAILS_LOCAL_STORAGE_KEY);
  };

  const getApplicationOptions = (): OPTION_TYPE[] => {
    return (
      applicationsData?.data?.applications?.map((application) => ({
        label: ``,
        value: `${application.id}`,
        applicationMeta: {
          id: application.id,
          universityName: application.universityName ?? ``,
          intake: {
            title: `Intake`,
            value:
              convertToNormalCase(application.intake ?? ``)
                ?.split(`_`)
                .join(` `) ?? ``,
          },
          courseName: {
            title: `Course Name`,
            value: application.programName ?? ``,
          },
          stage: {
            title: `Application Stage`,
            value: startCase(toLower(application.stage)) ?? ``,
          },
        },
      })) ?? []
    );
  };

  const { enqueueSnackbar } = useSnackbar();
  const initialValuesData = useMemo(() => {
    if (isFalsy(initialValues))
      return {
        createStudentMode: `no`,
      };

    return {
      student: student
        ? {
            label: student,
            value: student,
          }
        : null,
      [formNames.contactNumber]: initialValues.contactNumber,
      [formNames.countryCode]: initialValues.countryCode,
      [formNames.emailId]: initialValues.emailId,
      [formNames.cspId]: {
        label: cspName,
        value: cspId,
      },
      createStudentMode: `no`,
      destinationCountry: destinationCountry ?? null,
    };
  }, [initialValues, destinationCountry]);

  const populateMetaData = (keys: any[], values: any) => {
    return keys.reduce((acc, curr) => {
      acc[curr] = values?.[curr];
      return acc;
    }, {});
  };

  const handleSubmit = async (values: any) => {
    try {
      let newStudentDetails;
      if (values?.createStudentMode === `yes`) {
        newStudentDetails = await handleCreateNewStudent(values);

        // sucessfully student created
        if (newStudentDetails?.value) {
          formRef?.current?.change(
            `studentFirstName`,
            newStudentDetails?.firstName,
          );
          formRef?.current?.change(
            `studentLastName`,
            newStudentDetails?.lastName,
          );
          formRef?.current?.change(
            `selectedStudentId`,
            newStudentDetails?.value,
          );

          formRef.current?.change(`createStudentMode`, `no`);
          formRef.current?.change(studentFormNames.student, {
            label: `${newStudentDetails?.firstName || ``}-${
              newStudentDetails?.lastName || ``
            }`,
            value: newStudentDetails?.value,
          });
          formRef.current?.change(
            formNames.countryCode,
            values?.countryCode?.value,
          );
          formRef.current?.change(
            formNames.contactNumber,
            values?.contactNumber,
          );

          formRef?.current?.change(formNames.emailId, newStudentDetails?.email);

          setScreen(1);
        }
      }
    } catch (err) {
      enqueueSnackbar(`Unable to create student`, {
        variant: `error`,
      });
    }

    if (values?.createStudentMode === `yes`) return;
    try {
      const studentIdValue = studentId ?? values?.student?.value ?? -1;

      const response = await triggerCreateFlywirePaymentLinkRequest({
        studentId: studentIdValue,
        applicationId: values?.applicationId,
        vendorUniversityId: values?.universityName?.vendorUniversityId,
        metadata: populateMetaData(
          values?.universityName?.metadata?.map((item: any) => item.id),
          values,
        ),
        paymentReferenceId: values?.paymentReferenceId,
        country: values?.destinationCountry?.value,
      } as any).unwrap();

      if (response.success) {
        enqueueSnackbar(`Raised Flywire Payment Link request successfully`, {
          variant: `success`,
        });

        if (
          !(
            router.pathname === `/student/[studentId]` &&
            router.query.studentId === studentIdValue &&
            router.query.active === `VAS`
          )
        ) {
          localStorage.setItem(
            FLYWIRE_DETAILS_LOCAL_STORAGE_KEY,
            JSON.stringify({
              paymentLink: response?.data?.paymentLink,
              open: !isFalsy(response.data),
            }),
          );
          router.push(`/student/${studentIdValue}?active=VAS`);
        }
        onClose();
      } else {
        formRef?.current?.change(`universityChanges`, false);
        if (isFalsy(response?.data)) {
          enqueueSnackbar(`Unable to raise Flywire Payment Link request`, {
            variant: `error`,
          });
        }
      }
    } catch (err) {
      enqueueSnackbar(`Unable to raise Flywire Payment Link request`, {
        variant: `error`,
      });
      if (!isFalsy(initialValuesData?.student)) {
        onClose();
      }
    }
  };

  const getMetaDataSum = (values: any) => {
    return (values?.universityName as any)?.metadata?.reduce(
      (acc: any, curr: any) => {
        acc += Number((values as any)?.[curr?.id ?? ``] ?? 0);
        return acc;
      },
      0,
    );
  };

  const disableProceedButton = (errors: any, values: any) => {
    if (values?.createStudentMode === `yes`) {
      const createStudentModeFields = [
        studentFormNames.firstName,
        studentFormNames.lastName,
        formNames.contactNumber,
        formNames.countryCode,
        formNames.emailId,
      ];

      return (
        isStudentExisting(values) ||
        Object.keys(errors).some((value) =>
          createStudentModeFields.includes(value),
        )
      );
    }

    // disable when applications linked to the student is loading
    if (isApplicationsDataLoading) {
      return true;
    }

    // for first screen - student, countryCode, contactNumber, applicationId
    return Object.keys(errors).some((value) =>
      firstScreenFieldNames.includes(value),
    );
  };

  const disableGeneratePaymentLink = (errors: any, values: any) => {
    const hasErrorFields = Object.keys(errors).some((value) =>
      secondScreenFieldNames.includes(value),
    );

    // check for sum of total fields based on metadata
    const hasNonZeroPositiveSum = getMetaDataSum(values) > 0;

    return hasErrorFields || !hasNonZeroPositiveSum;
  };

  const handleUniversityChange = async (
    university: any,
    flywireMetaData: any,
  ) => {
    // formRef?.current?.change(
    //   `destinationCountry`,
    //   (university as any)?.vendorUnivCountry,
    // );
    formRef?.current?.change(`universityChanges`, true);
    // whenever country changes also reset metadata fields
    (flywireMetaData ?? []).forEach((item: any) => {
      formRef?.current?.change(`${item.id}`, undefined);
    });

    const values = formRef?.current?.getState()?.values;
    const flywireCheckResponse: any = await triggerCheckDuplicateFlywireQuery({
      emailId: values?.emailId,
      universityId: values?.universityName?.vendorUniversityId,
    }).unwrap();
    if (flywireCheckResponse?.message) {
      enqueueSnackbar(flywireCheckResponse?.message, {
        variant: `info`,
        style: {
          width: `45%`,
        },
      });
    }
  };

  return (
    <div className="relative h-full w-full">
      <div className="h-full overflow-hidden overflow-y-auto pb-24 w-full">
        <VASDrawerHeader
          title={getVasRequestFormTitle(`flywire`)}
          onClose={() => {
            localStorage.removeItem(FLYWIRE_DETAILS_LOCAL_STORAGE_KEY);
            onClose();
          }}
        />
        <Form<
          VASCreateFlywirePaymentLinkRequestBodyType &
            VasStudentCreationRequestBodyType & {
              applicationExists: boolean;
              selectedStudentId: any;
            }
        >
          initialValues={initialValuesData as any}
          validate={validate as any}
          onSubmit={handleSubmit}
          render={({ form, initialValues, values, errors }) => {
            const shouldDisableStudentField = !isFalsy(
              initialValuesData?.student?.value,
            );

            formRef.current = form;

            form.change(
              `applicationExists`,
              getApplicationOptions().length > 0,
            );

            if (shouldDisableStudentField) {
              const [firstName, lastName] =
                initialValues?.student?.label?.split(` `) ?? [];

              form.change(`studentFirstName` as any, firstName);
              form.change(`studentLastName` as any, lastName);
            }
            if (!isFalsy(showSuccessScreen) && !isFalsy(paymentLink)) {
              return (
                <VASSuccessScreen
                  showPaymentSection
                  showTrackerCard={false}
                  successScreenProps={{
                    title: `Payment Link Generated Successfully!`,
                  }}
                  trackRequestStatusCardProps={{
                    type: `flywire`,
                  }}
                  paymentLink={paymentLink}
                />
              );
            }

            return (
              <>
                <form className="px-4 py-6 w-full">
                  {screen === 0 ? (
                    <>
                      <FFVASStudentField
                        skipDuplicateVasRequestApi
                        onSearchApplications={(params) => {
                          getApplicationTrigger({
                            studentIds: [params.studentIds],
                            showAllAppsOfStudent: true,
                          });
                        }}
                        dobFieldProps={{
                          name: formNames.dob,
                        }}
                        genderFieldProps={{
                          name: formNames.gender,
                        }}
                        maritalStatusFieldProps={{
                          name: formNames.maritalStatus,
                        }}
                        cspFieldProps={{
                          name: formNames.cspId,
                        }}
                        contactNumberFieldProps={{
                          countryCodeFieldProps: {
                            name: formNames.countryCode,
                          },
                          phoneNumberFieldProps: {
                            name: formNames.contactNumber,
                          },
                          countryCodeComponentProps: {
                            options: [],
                            disabled: !isFalsy(values?.student?.value),
                          },
                          phoneNumberComponentProps: {
                            disabled: !isFalsy(values?.student?.value),
                          },
                          formValues: values,
                        }}
                        createOrSearchStudentFieldProps={{
                          studentFieldProps: {
                            name: formNames.student,
                          },
                          firstNameFieldProps: {
                            name: formNames.firstName,
                          },
                          lastNameFieldProps: {
                            name: formNames.lastName,
                          },
                          studentcomponentProps: {
                            disabled: shouldDisableStudentField,
                            options: [],
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            fetchOptionsHandler: () => {},
                          },
                          addStudentLabelButtonProps: {
                            disabled: shouldDisableStudentField,
                            className: getAddNewStudentButtonStyles(
                              user,
                              shouldDisableStudentField,
                            ),
                          },
                          addStudentIconProps: {
                            className: `text-grey-400`,
                          },
                        }}
                        emailFieldProps={{
                          name: formNames.emailId,
                        }}
                        serviceCategory="FLYWIRE"
                        studentId={studentId ?? (values as any)?.student?.value}
                      />
                      <div className="w-full pt-4">
                        <FFTextInput
                          fieldProps={{
                            name: formNames.emailId,
                          }}
                          componentProps={{
                            placeholder: `Please enter student’s email id.`,
                            label: (
                              <Typography
                                variant="subtitle2"
                                className="text-gray-900"
                              >
                                Email Id
                              </Typography>
                            ),
                            disabled: !isFalsy(values?.student?.value),
                          }}
                        />
                      </div>
                      {getApplicationOptions().length > 0 ? (
                        <div className="w-full pt-4 px-4">
                          <Typography
                            variant="subtitle2"
                            className="text-gray-900 pb-3"
                          >
                            Select An Application To Proceed
                          </Typography>
                          <FFRadio
                            fieldProps={{
                              name: `applicationId`,
                            }}
                            componentProps={{
                              type: `application-tab`,
                              options: getApplicationOptions(),
                              className: `w-full`,
                              radioComponentContainerClasses: `w-full`,
                              radioGroupContainerClasses: `w-full gap-y-2`,
                              formControlLabelProps: {
                                sx: {
                                  width: `100% !important`,
                                  '& .MuiTypography-root': {
                                    width: `100% !important`,
                                  },
                                },
                              },
                            }}
                          />
                        </div>
                      ) : null}
                    </>
                  ) : null}
                  {screen === 1 ? (
                    <div className="h-full w-full flex flex-col gap-y-4">
                      <div className="w-full flex items-start gap-x-4">
                        <div className="w-1/2">
                          <FFTextInput
                            fieldProps={{
                              name: `studentFirstName`,
                            }}
                            componentProps={{
                              label: (
                                <Typography
                                  variant="caption1"
                                  className="text-gray-900"
                                >
                                  First Name
                                </Typography>
                              ),
                              disabled: true,
                            }}
                          />
                        </div>
                        <div className="w-1/2">
                          <FFTextInput
                            fieldProps={{
                              name: `studentLastName`,
                            }}
                            componentProps={{
                              label: (
                                <Typography
                                  variant="caption1"
                                  className="text-gray-900"
                                >
                                  Last Name
                                </Typography>
                              ),
                              disabled: true,
                            }}
                          />
                        </div>
                      </div>
                      <div className="w-full flex items-start gap-x-4">
                        <div className="w-1/2">
                          <FFTextInput
                            fieldProps={{
                              name: formNames.emailId,
                            }}
                            componentProps={{
                              label: (
                                <Typography
                                  variant="caption1"
                                  className="text-gray-900"
                                >
                                  Email ID
                                </Typography>
                              ),
                              disabled: true,
                            }}
                          />
                        </div>
                        {values?.applicationExists && values?.applicationId ? (
                          <div className="w-1/2">
                            <FFTextInput
                              fieldProps={{
                                name: formNames.applicationId,
                              }}
                              componentProps={{
                                label: (
                                  <Typography
                                    variant="caption1"
                                    className="text-gray-900"
                                  >
                                    App ID
                                  </Typography>
                                ),
                                disabled: true,
                              }}
                            />
                          </div>
                        ) : values?.selectedStudentId ? (
                          <div className="w-1/2">
                            <FFTextInput
                              fieldProps={{
                                name: `selectedStudentId`,
                              }}
                              componentProps={{
                                label: (
                                  <Typography
                                    variant="caption1"
                                    className="text-gray-900"
                                  >
                                    Student ID
                                  </Typography>
                                ),
                                disabled: true,
                              }}
                            />
                          </div>
                        ) : null}
                      </div>
                      <div className="w-full flex items-start gap-x-4">
                        <div className="w-1/2">
                          <FFDropDown
                            fieldProps={{
                              name: formNames.universityName,
                            }}
                            componentProps={{
                              options: [],
                              async: {
                                // @ts-ignore
                                loadOptions: fetchUniversityVendorOptions,
                              },
                              placeholder: `Please enter university`,
                              disableClearable: false,
                              label: (
                                <Typography
                                  variant="caption1"
                                  className="text-gray-900"
                                >
                                  Select a university
                                </Typography>
                              ),
                              onChange: (_, value) =>
                                handleUniversityChange(
                                  value as OPTION_TYPE,
                                  (values?.universityName as any)?.metadata,
                                ),
                            }}
                          />
                        </div>
                        <div className="w-1/2">
                          <FFDropDown
                            fieldProps={{
                              name: `destinationCountry`,
                            }}
                            componentProps={{
                              options: countryOptions as any,
                              label: (
                                <Typography
                                  variant="caption1"
                                  className="text-gray-900"
                                >
                                  Country
                                </Typography>
                              ),
                              placeholder: `Select Country`,
                            }}
                          />
                        </div>
                      </div>
                      {(values?.universityName as any)?.metadata?.length > 0 ? (
                        <div className="w-full flex flex-col gap-y-4">
                          <div className="w-full grid grid-cols-2 gap-4">
                            {(values?.universityName as any)?.metadata?.map(
                              (item: any, index: number) => (
                                <div key={index} className="w-full">
                                  <FFTextInput
                                    fieldProps={{
                                      name: `${item.id}`,
                                      validate: (value) => {
                                        if (value <= 0) {
                                          return `Cannot be 0 or negative`;
                                        }
                                      },
                                    }}
                                    componentProps={{
                                      type: `number`,
                                      label: (
                                        <Typography
                                          variant="caption1"
                                          className="text-gray-900"
                                        >
                                          {item.title}(
                                          {
                                            (values?.universityName as any)
                                              ?.vendorUnivCurrency
                                          }
                                          )
                                        </Typography>
                                      ),
                                    }}
                                  />
                                </div>
                              ),
                            )}
                          </div>
                          <div
                            className={`px-4 py-3 rounded bg-gray-300 flex items-center ${
                              (values as any)?.universityChanges
                                ? `justify-end`
                                : `justify-between`
                            }`}
                          >
                            {!data?.success &&
                            !(values as any)?.universityChanges ? (
                              <Typography
                                variant="caption1"
                                className="text-error-500"
                              >
                                {data?.message ??
                                  data?.data?.studentIdDigitError}
                              </Typography>
                            ) : null}
                            <Typography
                              variant="subtitle1"
                              className="text-gray-900"
                            >
                              Total:{` `}
                              {
                                (values?.universityName as any)
                                  ?.vendorUnivCurrency
                              }
                              {` `}
                              {getMetaDataSum(values)}
                            </Typography>
                          </div>
                          {!data?.success &&
                            !(values as any)?.universityChanges &&
                            !isFalsy(data?.data?.studentIdDigitError) && (
                              <div className="w-1/2">
                                <FFTextInput
                                  fieldProps={{
                                    name: `paymentReferenceId`,
                                  }}
                                  componentProps={{
                                    label: (
                                      <Typography
                                        variant="caption1"
                                        className="text-gray-900"
                                      >
                                        Enter Valid Id
                                      </Typography>
                                    ),
                                    placeholder: `Enter Valid Id `,
                                  }}
                                />
                              </div>
                            )}
                        </div>
                      ) : null}
                      <div className="w-full self-end">
                        <FFCheckBoxes
                          fieldProps={{
                            name: formNames.permissionRequired,
                            validate: (value) => {
                              if (isFalsy(value)) {
                                return `Please accept the condition`;
                              }
                            },
                          }}
                          componentProps={{
                            options: [
                              {
                                label: `I hereby accept that the payment link is generated and maintained by Flywire Corporation. Geebee Education is not liable for any transaction related issues.`,
                                value: `true`,
                              },
                            ],
                            formControlLabelProps: {
                              componentsProps: {
                                typography: {
                                  fontFamily: typography?.body2?.fontFamily,
                                  fontWeight: typography?.body2?.fontWeight,
                                  lineHeight: typography?.body2?.lineHeight,
                                },
                              },
                              className: `text-grey-700 items-start`,
                            },
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                </form>
                <>
                  {data?.success && data?.data ? null : (
                    <div className="w-full absolute bottom-0 right-0 p-4 bg-white shadow-t-lg">
                      <div
                        className={`flex ${
                          screen === 1 ? `items-center gap-x-2` : `justify-end`
                        }`}
                      >
                        {screen === 1 ? (
                          <>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                setScreen(0);
                              }}
                              className="w-1/2"
                            >
                              <Typography
                                variant="subtitle2"
                                className="normal-case text-primary-500"
                              >
                                Back
                              </Typography>
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                form.submit();
                              }}
                              loading={isCreatingFlywirePaymentLink}
                              className="w-1/2"
                              disabled={disableGeneratePaymentLink(
                                errors,
                                values,
                              )}
                            >
                              <Typography
                                variant="subtitle2"
                                className="text-white normal-case"
                              >
                                Generate Payment link
                              </Typography>
                            </Button>
                          </>
                        ) : null}
                        {screen === 0 ? (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              // if student creation mode then form submit
                              if (values?.createStudentMode === `yes`) {
                                form.submit();
                              } else {
                                setScreen(1);
                              }
                            }}
                            className="w-1/2"
                            disabled={disableProceedButton(errors, values)}
                            loading={
                              isCreatingStudent || isApplicationsDataLoading
                            }
                          >
                            <Typography
                              variant="subtitle2"
                              className="text-white normal-case"
                            >
                              Proceed
                            </Typography>
                          </Button>
                        ) : null}
                      </div>
                    </div>
                  )}
                  {` `}
                </>
              </>
            );
          }}
        />
      </div>
    </div>
  );
}

export default VASFlywireFormController;
