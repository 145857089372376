import { FFMultiselect } from '@leapfinance/frontend-commons';
import geebeeTheme from '@leapfinance/frontend-commons/components/geebee/theme';
import { OPTION_TYPE } from '@leapfinance/frontend-commons/schema/common';
import { ThemeProvider } from '@mui/system';
import { useRouter } from 'next/router';

import { selectGeebeeList } from '@/app/features/geebeeSharedList/geebeeSharedListSlice';
import { selectList } from '@/app/features/sharedList/sharedListSlice';
import { selectUserInfoList } from '@/app/features/userInfoList/userInfoListSlice';
import { useAppSelector } from '@/app/hooks';
import { isFalsy } from '@/components/CF/Dashboard/helpers';
import { selectRole } from '@/features/generalSlice';
import { Roles } from '@/types/global';
import { getSharedListOptions } from '@/utils/helperFunctions';

const MoreFilters = () => {
  const role = useAppSelector((state) => selectRole(state));
  const router = useRouter();
  const geebeeSharedList = useAppSelector((state) => selectGeebeeList(state));
  const userInfoList = useAppSelector((state) => selectUserInfoList(state));
  const sharedList = useAppSelector((state) => selectList(state));
  const countryGroupOptions = isFalsy(
    userInfoList[`userAssociatedCountryGroup`],
  )
    ? getSharedListOptions(`countryGroups`, sharedList ?? {})
    : userInfoList[`userAssociatedCountryGroup`];

  return (
    <ThemeProvider theme={geebeeTheme}>
      <div className="grid grid-cols-2 gap-6">
        {[
          Roles.zonalManager,
          Roles.countryHead,
          Roles.visaTeam,
          Roles.representative,
          Roles.gbInvoicing,
          Roles.qc,
          Roles.viewer,
        ].includes(role as Roles) ? (
          <FFMultiselect
            fieldProps={{ name: `counsellorIds` }}
            componentProps={{
              options:
                (userInfoList[`userAssociatedCounsellors`] as OPTION_TYPE[]) ??
                ([] as OPTION_TYPE[]),
              label: `Counsellor Name`,
              placeholder: `Select Counsellor Name`,
            }}
          />
        ) : null}
        {[
          Roles.countryHead,
          Roles.visaTeam,
          Roles.representative,
          Roles.gbInvoicing,
          Roles.qc,
          Roles.viewer,
        ].includes(role as Roles) ? (
          <FFMultiselect
            fieldProps={{ name: `zonalManagerIds` }}
            componentProps={{
              options:
                (userInfoList[`userAssociatedZms`] as OPTION_TYPE[]) ?? [],
              label: `Zonal Manager`,
              placeholder: `Select Zonal Manager`,
            }}
          />
        ) : null}
        <FFMultiselect
          fieldProps={{ name: `countryGroups` }}
          componentProps={{
            options: (countryGroupOptions ?? []) as OPTION_TYPE[],
            label: `Country Group`,
            placeholder: `Select Country Group`,
            hideSearchOption: true,
          }}
        />
        {router?.pathname?.toString() === `/csp-management` ? (
          <FFMultiselect
            fieldProps={{ name: `dashboardPerformanceCategory` }}
            componentProps={{
              options:
                (geebeeSharedList[
                  `dashboardPerformanceCategory`
                ] as OPTION_TYPE[]) ?? [],
              label: `Performance Category`,
              placeholder: `Select Performance Category`,
            }}
          />
        ) : null}
      </div>
    </ThemeProvider>
  );
};

export default MoreFilters;
