import React from 'react';
import AddTaskIcon from '@mui/icons-material/AddTask';
import { Typography } from '@mui/material';

interface IAddedToShortlistTypoProps {
  smallVersion?: boolean;
}
const AddedToShortlistTypo: React.FC<IAddedToShortlistTypoProps> = ({
  smallVersion,
}) => {
  return (
    <>
      <Typography
        variant="caption2"
        className="uppercase whitespace-nowrap text-[#458B88]"
      >
        <span>
          <AddTaskIcon fontSize="small" />
        </span>
        {smallVersion ? `Shortlist Added` : ` Course added in shortlist`}
      </Typography>
    </>
  );
};

export default AddedToShortlistTypo;
