const fetchUsersData = (payload: any) => ({
  type: "FETCH_USERS_DATA",
  payload,
});

const fetchUsersDataSuccess = (payload: any) => ({
  type: "FETCH_USERS_DATA_SUCCESS",
  payload,
});
const fetchUsersDataFail = (message: string) => ({
  type: "FETCH_USERS_DATA_FAIL",
  payload: message,
});
const fetchCountries = (message:string)=>({
  type:"FETCH_COUNTRIES",
  payload:message,
})
const fetchCountriesSuccess = (message: string) => ({
  type: "FETCH_COUNTRIES_SUCCESS",
  payload: message,
});
const fetchCountriesFail = (message: string) => ({
  type: "FETCH_COUNTRIES_FAIL",
  payload: message,
});
const setDetailType = (message: string) => ({
  type: "SET_DETAIL_TYPE",
  payload: message,
});
const createUser = (message: string) => ({
  type: "CREATE_USER",
  payload: message,
});
const createUserSuccess = (message: string) => ({
  type: "CREATE_USER_SUCCESS",
  payload: message,
});
const createUserFail = (message: string) => ({
  type: "CREATE_USER_FAIL",
  payload: message,
});
const editUser = (message: string) => ({
  type: "EDIT_USER",
  payload: message,
});
const editUserSuccess = (message: string) => ({
  type: "EDIT_USER_SUCCESS",
  payload: message,
});
const editUserFail = (message: string) => ({
  type: "EDIT_USER_FAIL",
  payload: message,
});
const fetchEachUserData = (message: string) => ({
  type: "FETCH_EACH_USER_DATA",
  payload: message,
});
const fetchEachUserDataSuccess = (message: string) => ({
  type: "FETCH_EACH_USER_DATA_SUCCESS",
  payload: message,
});
const fetchEachUserDataFail = (message: string) => ({
  type: "FETCH_EACH_USER_DATA_FAIL",
  payload: message,
});
const setUserManagementPageNo = (message: string) => ({
  type: "SET_USER_MANAGEMENT_PAGE_NO",
  payload: message,
});
const setCreateUserMessage = (message: string) => ({
  type: "SET_CREATE_USER_MESSAGE",
  payload: message,
});
const setEditUserMessage = (message: string) => ({
  type: "SET_EDIT_USER_MESSAGE",
  payload: message,
});
const setEachUserId = (message: string) => ({
  type: "SET_EACH_USER_ID",
  payload: message,
});
const deactivateUser = (message: string) => ({
  type: "DEACTIVATE_USER",
  payload: message,
});
const deactivateUserSuccess = (message: string) => ({
  type: "DEACTIVATE_USER_SUCCESS",
  payload: message,
});
const deactivateUserFail = (message: string) => ({
  type: "DEACTIVATE_USER_FAIL",
  payload: message,
});
const setDeactivateUserMessage = (message: string) => ({
  type: "SET_DEACTIVATE_USER_MESSAGE",
  payload: message,
});
const addLeaves = (message: string) => ({
  type: "ADD_LEAVES",
  payload: message,
});
const addLeavesSuccess = (message: string) => ({
  type: "ADD_LEAVES_SUCCESS",
  payload: message,
});
const addLeavesFail = (message: string) => ({
  type: "ADD_LEAVES_FAIL",
  payload: message,
});
const removeLeaves = (message: string) => ({
  type: "REMOVE_LEAVES",
  payload: message,
});
const removeLeavesSuccess = (message: string) => ({
  type: "REMOVE_LEAVES_SUCCESS",
  payload: message,
});
const removeLeavesFail = (message: string) => ({
  type: "REMOVE_LEAVES_FAIL",
  payload: message,
});
const setAddedLeavesMessage = (message: string) => ({
  type: "SET_ADDED_LEAVES_MESSAGE",
  payload: message,
});
const setRemovedLeavesMessage = (message: string) => ({
  type: "SET_REMOVED_LEAVES_MESSAGE",
  payload: message,
});
const setEachUserDataMessage = (message: string) => ({
  type: "SET_EACH_USER_DATA_MESSAGE",
  payload: message,
});
export const userManagementActions = {
  fetchUsersData,
  fetchUsersDataSuccess,
  fetchUsersDataFail,
  fetchCountries,
  fetchCountriesSuccess,
  fetchCountriesFail,
  setDetailType,
  createUser,
  createUserSuccess,
  createUserFail,
  editUser,
  editUserSuccess,
  editUserFail,
  fetchEachUserData,
  fetchEachUserDataSuccess,
  fetchEachUserDataFail,
  setUserManagementPageNo,
  setCreateUserMessage,
  setEditUserMessage,
  setEachUserId,
  deactivateUser,
  deactivateUserSuccess,
  deactivateUserFail,
  setDeactivateUserMessage,
  addLeaves,
  addLeavesSuccess,
  addLeavesFail,
  removeLeaves,
  removeLeavesSuccess,
  removeLeavesFail,
  setAddedLeavesMessage,
  setRemovedLeavesMessage,
  setEachUserDataMessage,
};