import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';

import SnackbarUtils from '@/utils/snackbar';

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (action.payload?.status === 401) {
      window.location.href = action?.payload?.data?.path;
    }
    if (action.payload && action.payload.apiError) {
      if (action.payload.message && action.payload.message.trim().length > 0) {
        SnackbarUtils.error(action.payload.message);
      } else {
        SnackbarUtils.error(`Something went wrong.Please contact tech team`);
      }
    }
    return next(action);
  };
