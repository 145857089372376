import { AnyAction } from "redux";

const initialVisaState: any = {
  visaData: {},
  isLoading: false,
  visaDataFetchStatus: null
};

const visaManagementReducer = (
  state: any = initialVisaState,
  action: AnyAction
) => {
  switch (action.type) {
    case "UPDATE_VISA_DATA":
      return {
        ...state,
        isLoading: true,
      };
    case "UPDATE_VISA_DATA_SUCCESS":
      return {
        ...state,
        isLoading: false,
        visaData: action.payload,
        visaDataUpdateStatus: "success",
      };
    case "UPDATE_VISA_DATA_FAILURE":
      return {
        ...state,
        isLoading: false,
        visaUpdateErrorMessage: action.payload,
        visaDataUpdateStatus: "failure",
      };
    default:
      return state;
  }
};
export default visaManagementReducer