import { toast } from 'react-toastify';

import { ModalDataType, updateModal } from '@/app/features/modals/modalsSlice';
import { store } from '@/app/store';
import OfferLetterValidate from '@/components/OfferLetter/OfferLetterValidate';
import { getBase64 } from '@/services/cf';
import {
  deleteDocument,
  statusDocument,
  uploadDocument,
} from 'components/Application/ApplicationApis';
import { FormData } from 'components/Application/context/interface';
import { MODAL_TYPES } from 'components/modals/GlobalModal';
export const handleUpload = async (
  payload: any,
  callback: (res: any) => void,
) => {
  const { file, d, leadId, title, eachAppData } = payload;
  console.log(`in upload`, file, title, eachAppData);

  try {
    const res = await uploadDocument({ file, d, leadId, title, eachAppData });

    if (res?.id) {
      toast.success(`Document uploaded Successfully`);
      if (res?.offerLetterInfo) {
        store.dispatch(
          updateModal({
            modalType: MODAL_TYPES.NO_RESULTS,
            modalProps: {
              body: (
                <div className="flex items-center justify-center w-full h-full">
                  <OfferLetterValidate
                    file={file}
                    initialData={res?.offerLetterInfo}
                    fileUrl={res?.url}
                    applicationId={eachAppData?.id as number}
                    documentId={res?.id as number}
                  />
                </div>
              ),
            },
          } as ModalDataType),
        );
      }

      callback(res);
    } else if (res?.message) {
      toast.error(res?.message);
      return null;
    } else {
      throw Error(``);
    }
  } catch (error) {
    toast.error(`Cannot Upload the file.Please contact the tech team`);
  }
};

export const handleDelete = async (d: FormData, callback: () => void) => {
  try {
    const res = await deleteDocument(d);
    if (!res.success) throw Error(``);
    await callback();
    toast.success(`Document deleted Successfully`);
  } catch (error) {
    toast.error(`Cannot delete the file.Please Contact the tech team`);
  }
};

export const handleStatus = async (payload: any, callback: () => void) => {
  const { file, d, leadId, title } = payload;
  let res: any;
  // eslint-disable-next-line prefer-const
  res = await statusDocument({ file, d, leadId, title });

  if (res) {
    await callback();
  } else {
    toast.error(`Something went wrong.Please contact the tech team`);
  }
};
