import { type OPTION_TYPE } from '@leapfinance/frontend-commons/schema/common';
import debounce from 'lodash/debounce';

import { useSearchStudentMutation } from '@/services/student';
function useGetStudentOptions() {
  const [triggerSearchStudent] = useSearchStudentMutation();
  const getStudentSuggestions = (
    searchQuery: string,
    callback: (results?: readonly OPTION_TYPE[] | undefined) => void,
  ) => {
    if (searchQuery?.length > 0) {
      try {
        triggerSearchStudent({
          searchString: searchQuery,
          pageNumber: 0,
          pageSize: 10,
        })
          .unwrap()
          .then((data: any) => {
            const newStudentlist = [] as any;
            const list = data?.data?.students as any;
            for (let i = 0; i < list.length; i++) {
              newStudentlist.push({
                value: list[i]?.id,
                label: list[i]?.result,
              });
            }
            callback(newStudentlist);
          });
      } catch (err: any) {
        console.log(err.message);
      }
    } else {
      callback([]);
    }
  };

  const newGetStudentSuggestions = (
    searchQuery: { input: string },
    callback: (results?: readonly OPTION_TYPE[] | undefined) => void,
  ) => getStudentSuggestions(searchQuery.input, callback);

  return [debounce(newGetStudentSuggestions, 500)];
}
export default useGetStudentOptions;
