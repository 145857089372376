import { isMobile } from 'react-device-detect';

import { store } from '@/app/store';
import { TrackClick, TrackPageProperties } from '@/types/segmentEvents';
import { detectBrowser } from '@/utils/detectBrowser';
import { getPageName } from '@/utils/getPageName';

import { EventNames } from './eventNames';

export const trackPage = (properties: TrackPageProperties) => {
  let prop: TrackPageProperties = {
    browser: detectBrowser(),
    pageUrl: window.location.href || `NA`,
    platform: isMobile ? `MWeb` : `Desktop`,
    ...properties,
  };
  const state = store.getState();
  if (state && state.user.userConfig) {
    const { userId, email } = state.user.userConfig;
    prop = {
      ...properties,
      ...prop,
      userId: userId || `NA`,
      userEmail: email || `NA`,
    };
  }
  window?.analytics?.page(EventNames.PAGE_VIEW, prop);
};

export const trackEvent = (
  event: string,
  properties?: Record<string, unknown> | undefined,
) => {
  properties = {
    pageName: getPageName(), // page names to be added to this method as required
    ...properties,
  };
  const state = store.getState();
  if (state && state.user.userConfig) {
    const { userId, email, firstName, lastName } = state.user.userConfig;
    properties = {
      ...properties,
      userId: userId || `NA`,
      userEmail: email || `NA`,
      userName: `${firstName ?? ``} ${lastName ?? ``}`,
    };
  }
  window?.analytics?.track(event, properties);
};

export const trackClick = (properties: Partial<TrackClick>) => {
  trackEvent(EventNames.CLICK, {
    pageUrl: window.location.href || `NA`,
    ...properties,
  });
};
