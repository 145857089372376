import React, { useEffect } from 'react';
import {
  Field,
  type FieldProps,
  type FieldRenderProps,
  useForm,
} from 'react-final-form';
import {
  FFDropDown,
  FFTextInput,
  isFalsy,
} from '@leapfinance/frontend-commons';
import { type OPTION_TYPE } from '@leapfinance/frontend-commons/schema/common';
import { Typography } from '@mui/material';

import useGetCspOptions from '@/hooks/vas/useGetCspOptions';
import { useLazyGetStudentAssignedCounsellorsQuery } from '@/services/student';
import { useCheckDuplicateVASRequestQuery } from '@/services/vas';
import { ServiceCategoryTypes } from '@/types/vas';
import { genderList, maritalStatusList } from '@/utils/constants';

import ExistingRequestWarning from './ExistingRequestWarning';
import FFVASContactNumberField, {
  FFVASContactNumberFieldProps,
} from './FFVASContactNumberField';
import FFVASCreateOrSearchStudentField, {
  FFVASCreateOrSearchStudentFieldProps,
} from './FFVASCreateOrSearchStudentField';

type SearchApplicationPayload = {
  studentIds: any;
};

type Props = {
  contactNumberFieldProps: FFVASContactNumberFieldProps;
  createOrSearchStudentFieldProps: FFVASCreateOrSearchStudentFieldProps;
  serviceCategory: ServiceCategoryTypes;
  studentId?: number;
  emailFieldProps?: FieldProps<
    any,
    FieldRenderProps<any, HTMLElement, any>,
    HTMLElement,
    any
  >;
  cspFieldProps: FieldProps<
    any,
    FieldRenderProps<any, HTMLElement, any>,
    HTMLElement,
    any
  >;
  recipientEmailIdProps?: FieldProps<
    any,
    FieldRenderProps<any, HTMLElement, any>,
    HTMLElement,
    any
  >;
  maritalStatusFieldProps: FieldProps<
    any,
    FieldRenderProps<any, HTMLElement, any>,
    HTMLElement,
    any
  >;
  genderFieldProps: FieldProps<
    any,
    FieldRenderProps<any, HTMLElement, any>,
    HTMLElement,
    any
  >;
  dobFieldProps: FieldProps<
    any,
    FieldRenderProps<any, HTMLElement, any>,
    HTMLElement,
    any
  >;
  onSearchApplications?: (params: SearchApplicationPayload) => void;
  skipDuplicateVasRequestApi?: boolean;
  onStudentChange?: (student: OPTION_TYPE) => void;
};

function FFVASStudentField({
  contactNumberFieldProps,
  createOrSearchStudentFieldProps,
  serviceCategory,
  studentId,
  emailFieldProps,
  cspFieldProps,
  recipientEmailIdProps,
  maritalStatusFieldProps,
  genderFieldProps,
  dobFieldProps,
  skipDuplicateVasRequestApi,
  onSearchApplications,
  onStudentChange,
}: Props) {
  const [triggerGetStudentAssignedCounsellors] =
    useLazyGetStudentAssignedCounsellorsQuery();
  const [fetchCspOptions] = useGetCspOptions();
  const { getFieldState, change, getState } = useForm();
  const isStudentCreateModeOn =
    getFieldState(`createStudentMode`)?.value === `yes`;

  const isStudentSelected = !isFalsy(
    getFieldState(createOrSearchStudentFieldProps.studentFieldProps.name)?.value
      ?.value,
  );
  const shouldDisableCspFields =
    !isFalsy(getState()?.initialValues?.[cspFieldProps.name]) ||
    !isFalsy(
      getState().values?.[
        createOrSearchStudentFieldProps.studentFieldProps.name
      ]?.value,
    );
  const { data } = useCheckDuplicateVASRequestQuery(
    {
      serviceCategory,
      studentId: isStudentCreateModeOn ? -1 : studentId ?? -1,
    },
    {
      skip: !isStudentSelected || skipDuplicateVasRequestApi,
    },
  );

  useEffect(() => {
    if (serviceCategory === `FLYWIRE`) {
      onSearchApplications?.({
        studentIds: studentId ?? -1,
      });
    }
  }, [studentId, serviceCategory]);

  const clearStudentRelatedFields = () => {
    change(genderFieldProps.name, undefined);
    change(maritalStatusFieldProps.name, undefined);
    change(dobFieldProps.name, undefined);
    change(contactNumberFieldProps.countryCodeFieldProps.name, undefined);
    change(createOrSearchStudentFieldProps.studentFieldProps.name, undefined);
    if (contactNumberFieldProps.phoneNumberFieldProps) {
      change(contactNumberFieldProps.phoneNumberFieldProps.name, undefined);
    }
    if (emailFieldProps?.name) {
      change(emailFieldProps?.name, undefined);
    }
    if (recipientEmailIdProps?.name) {
      change(recipientEmailIdProps.name, undefined);
    }
    change(cspFieldProps.name, undefined);
  };

  const handleStudentChange = async (_: any, student: any) => {
    if (student?.__isNew__) {
      change(`createStudentMode`, `yes`);
      change(
        createOrSearchStudentFieldProps.firstNameFieldProps.name,
        student?.label?.split(` `)[0],
      );
      change(
        createOrSearchStudentFieldProps.lastNameFieldProps.name,
        student?.label?.split(` `)[1],
      );
      clearStudentRelatedFields();
      change(contactNumberFieldProps.countryCodeFieldProps.name, {
        label: `+91` ?? ``,
        value: `+91` ?? ``,
      });
    } else {
      // existing student selected
      change(`createStudentMode`, `no`);
      change(
        createOrSearchStudentFieldProps.firstNameFieldProps.name,
        undefined,
      );
      change(
        createOrSearchStudentFieldProps.lastNameFieldProps.name,
        undefined,
      );
      change(genderFieldProps.name, undefined);
      change(maritalStatusFieldProps.name, undefined);
      change(dobFieldProps.name, undefined);

      if (!isFalsy(student?.country)) {
        change(`destinationCountry`, {
          label: student?.country,
          value: student?.country,
        });
      }

      change(
        contactNumberFieldProps.countryCodeFieldProps.name,
        isFalsy(student)
          ? undefined
          : {
              label: student?.countryCode ?? ``,
              value: student?.countryCode ?? ``,
            } ?? ``,
      );
      if (contactNumberFieldProps.phoneNumberFieldProps) {
        change(
          contactNumberFieldProps.phoneNumberFieldProps.name,
          isFalsy(student) ? undefined : student?.contactNumber ?? ``,
        );
      }
      if (emailFieldProps?.name) {
        change(emailFieldProps?.name, student?.email);
      }

      change(
        cspFieldProps?.name,
        isFalsy(student)
          ? undefined
          : {
              value: student?.cspId,
              label: student?.cspName,
            },
      );

      if (recipientEmailIdProps?.name) {
        change(recipientEmailIdProps?.name, undefined);
      }

      if (recipientEmailIdProps?.name) {
        change(recipientEmailIdProps.name, undefined);
      }
      if (serviceCategory === `FLYWIRE`) {
        change(`selectedStudentId`, student?.value);
      }
      // when student is cleared this retains old values, need to recheck and fix
      if (student?.label && serviceCategory === `FLYWIRE`) {
        // break student into first name and last name
        const [name] = student?.label?.split(`-`);
        const [firstName, lastName] = name?.trim()?.split(` `);
        change(`studentFirstName`, firstName);
        change(`studentLastName`, lastName);
      }
      // make api call here to get applications
      if (serviceCategory === `FLYWIRE`) {
        onSearchApplications?.({
          studentIds: student?.value ?? -1,
        });
      }

      try {
        if (
          student?.value &&
          recipientEmailIdProps?.name &&
          serviceCategory !== `FLYWIRE`
        ) {
          const assignedCounsellors =
            await triggerGetStudentAssignedCounsellors({
              studentId: student?.value,
            }).unwrap();

          if (assignedCounsellors.success && recipientEmailIdProps?.name) {
            change(
              recipientEmailIdProps.name,
              assignedCounsellors?.data?.map(
                (counsellorDetails) => counsellorDetails.email,
              ) ?? [],
            );
          }
        } else {
          if (recipientEmailIdProps?.name) {
            change(recipientEmailIdProps.name, undefined);
          }
        }
      } catch (err) {
        console.log(`error getting assigned counsellors`);
      }

      onStudentChange?.(student);
    }
  };

  return (
    <>
      <Field name="createStudentMode" component="input" type="hidden" />
      <FFVASCreateOrSearchStudentField
        {...createOrSearchStudentFieldProps}
        isStudentCreateModeOn={isStudentCreateModeOn}
        handleStudentChange={handleStudentChange}
        onCreateStudentClick={(createMode) => {
          clearStudentRelatedFields();
          change(`createStudentMode`, createMode);
          change(contactNumberFieldProps.countryCodeFieldProps.name, {
            label: `+91` ?? ``,
            value: `+91` ?? ``,
          });
          createOrSearchStudentFieldProps?.onCreateStudentClick?.(createMode);
        }}
        onDeleteStudent={() => {
          clearStudentRelatedFields();
          change(
            createOrSearchStudentFieldProps.studentFieldProps.name,
            undefined,
          );
          change(
            createOrSearchStudentFieldProps.firstNameFieldProps.name,
            undefined,
          );
          change(
            createOrSearchStudentFieldProps.lastNameFieldProps.name,
            undefined,
          );
        }}
      />
      {isStudentCreateModeOn ? (
        <div className="w-full">
          <div className="w-full flex items-center gap-x-2 py-4">
            <div className="w-1/2">
              <FFTextInput
                fieldProps={{
                  ...dobFieldProps,
                }}
                componentProps={{
                  type: `date`,
                  label: (
                    <Typography
                      variant={`caption1`}
                      className={`lfc-text-gray-700`}
                    >
                      Date of Birth
                    </Typography>
                  ),
                  placeholder: `Date of Birth`,
                }}
              />
            </div>
            <div className="w-1/2">
              <FFDropDown
                fieldProps={genderFieldProps}
                componentProps={{
                  options: genderList,
                  placeholder: `Student Gender`,
                  label: (
                    <Typography
                      variant={`caption1`}
                      className={`lfc-text-gray-700`}
                    >
                      Gender
                    </Typography>
                  ),
                }}
              />
            </div>
          </div>
          <FFDropDown
            fieldProps={maritalStatusFieldProps}
            componentProps={{
              options: maritalStatusList,
              placeholder: `Student Marital Status`,
              label: (
                <Typography
                  variant={`caption1`}
                  className={`${
                    isStudentCreateModeOn
                      ? `lfc-text-gray-700`
                      : `lfc-text-gray-900`
                  }`}
                >
                  Marital Status
                </Typography>
              ),
            }}
          />
        </div>
      ) : null}
      <div className={`w-full ${isStudentCreateModeOn ? `pt-2` : `pt-4`}`}>
        <FFDropDown
          fieldProps={cspFieldProps}
          componentProps={{
            options: [],
            async: {
              // @ts-ignore
              loadOptions: (query, callback) => {
                fetchCspOptions(query, callback);
              },
            },
            placeholder: `Please enter csp`,
            disableClearable: false,
            label: (
              <Typography
                variant={isStudentCreateModeOn ? `caption1` : `subtitle2`}
                className={`${
                  isStudentCreateModeOn
                    ? `lfc-text-gray-700`
                    : `lfc-text-gray-900`
                }`}
              >
                CSP Name
              </Typography>
            ),
            disabled: !isStudentCreateModeOn && shouldDisableCspFields,
          }}
        />
      </div>

      <FFVASContactNumberField
        {...contactNumberFieldProps}
        isStudentCreateModeOn={isStudentCreateModeOn}
        cspFieldProps={cspFieldProps}
        isStudentSelected={isStudentSelected}
      />
      {data?.data && data?.success && isStudentSelected ? (
        <ExistingRequestWarning serviceCategory={serviceCategory} />
      ) : null}
    </>
  );
}

export default FFVASStudentField;
