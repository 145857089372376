import React from 'react';
import { Box, Button, ButtonProps } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export type CustomButtonProps = ButtonProps & {
  text: string;
  loading?: boolean;
};
const CustomButton = ({ className, loading, ...props }: CustomButtonProps) => {
  return (
    <Box
      sx={{
        '.MuiButtonBase-root': {
          color: `white`,
          padding: `0.3rem 0.7rem !important`,
          borderRadius: `0.2rem`,
          backgroundColor: `#443EFF`,
        },
        '.MuiButton-outlined': {
          backgroundColor: `transparent`,
          borderColor: `#443EFF`,
        },
        '.MuiButton-text': {
          backgroundColor: `transparent`,
        },
      }}
    >
      <Button
        variant={props.variant || `contained`}
        sx={{
          backgroundColor: props?.disabled
            ? ``
            : !props.variant
            ? `#443EFF !important`
            : ``,
        }}
        disableElevation={true}
        className={`normal-case	font-sans ${className}`}
        endIcon={
          loading ? (
            <CircularProgress size={24} className="ml-2 text-white" />
          ) : (
            props.endIcon
          )
        }
        {...props}
      >
        {props.text}
      </Button>
    </Box>
  );
};

export default CustomButton;
