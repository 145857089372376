import { GridColDef } from '@mui/x-data-grid';
import cloneDeep from 'lodash/cloneDeep';

import {
  getTableComponentForColumnHeader,
  getTableComponentForUserDetails,
  getTableComponentForUserName,
} from '@/components/SummaryDashboard/UserPerformanceViewDashboard/shared/UserDataTable';
import { OpsManagerListType } from '@/components/SummaryDashboard/UserPerformanceViewDashboard/SummaryDashboardTabs';

export const opsManagerPerformanceColumns: GridColDef[] = [
  {
    field: `userName`,
    headerName: `OPS MANAGER NAME`,
    sortable: false,
    renderCell(params) {
      return getTableComponentForUserName(params?.row?.userName);
    },
    headerAlign: `center`,
    headerClassName: `bg-primary-100`,
    flex: 1,
    renderHeader(params) {
      return getTableComponentForColumnHeader(params?.colDef?.headerName);
    },
  },
  {
    field: `applicationsAssigned`,
    headerName: `APPLICATIONS ASSIGNED`,
    sortable: false,
    renderCell(params) {
      return getTableComponentForUserDetails(
        params?.row?.applicationsAssigned?.type,
        params?.row?.applicationsAssigned?.value,
      );
    },
    headerAlign: `center`,
    headerClassName: `bg-primary-100`,
    renderHeader(params) {
      return getTableComponentForColumnHeader(params?.colDef?.headerName);
    },
    flex: 1,
  },
  {
    field: `firstResponseTakenPercentage`,
    headerName: `FIRST RESPONSE TAKEN (%)`,
    sortable: false,
    renderCell(params) {
      return getTableComponentForUserDetails(
        params?.row?.firstResponseTakenPercentage?.type,
        params?.row?.firstResponseTakenPercentage?.value,
      );
    },
    headerAlign: `center`,
    headerClassName: `bg-primary-100`,
    renderHeader(params) {
      return getTableComponentForColumnHeader(params?.colDef?.headerName);
    },
    flex: 1,
  },
  {
    field: `applicationsSentToInstitutePercentage`,
    headerName: `SENT TO INSTITUTE (%)`,
    sortable: false,
    renderCell(params) {
      return getTableComponentForUserDetails(
        params?.row?.applicationsSentToInstitutePercentage?.type,
        params?.row?.applicationsSentToInstitutePercentage?.value,
      );
    },
    headerAlign: `center`,
    headerClassName: `bg-primary-100`,
    renderHeader(params) {
      return getTableComponentForColumnHeader(params?.colDef?.headerName);
    },
    flex: 1,
  },
  {
    field: `applicationsOnHoldByUniversityPercentage`,
    headerName: `ON HOLD BY UNIVERSITY (%)`,
    sortable: false,
    renderCell(params) {
      return getTableComponentForUserDetails(
        params?.row?.applicationsOnHoldByUniversityPercentage?.type,
        params?.row?.applicationsOnHoldByUniversityPercentage?.value,
      );
    },
    headerAlign: `center`,
    headerClassName: `bg-primary-100`,
    renderHeader(params) {
      return getTableComponentForColumnHeader(params?.colDef?.headerName);
    },
    flex: 1,
  },
  {
    field: `applicationsRejectedPercentage`,
    headerName: `APPLICATION REJECTED (%)`,
    sortable: false,
    renderCell(params) {
      return getTableComponentForUserDetails(
        params?.row?.applicationsRejectedPercentage?.type,
        params?.row?.applicationsRejectedPercentage?.value,
      );
    },
    headerAlign: `center`,
    headerClassName: `bg-primary-100`,
    renderHeader(params) {
      return getTableComponentForColumnHeader(params?.colDef?.headerName);
    },
    flex: 1,
  },
];

export const averageTatPerformanceColumns: GridColDef[] = [
  {
    field: `userName`,
    headerName: `OPS MANAGER NAME`,
    sortable: false,
    renderCell(params) {
      return getTableComponentForUserName(params?.row?.userName);
    },
    headerClassName: `bg-primary-100`,
    headerAlign: `center`,
    flex: 1,
    renderHeader(params) {
      return getTableComponentForColumnHeader(params?.colDef?.headerName);
    },
  },
  {
    field: `firstResponseTime`,
    headerName: `FIRST RESPONSE TIME`,
    sortable: false,
    renderCell(params) {
      return getTableComponentForUserDetails(
        params?.row?.firstResponseTime?.type,
        params?.row?.firstResponseTime?.value,
      );
    },
    headerClassName: `bg-primary-100`,
    headerAlign: `center`,
    flex: 1,
    renderHeader(params) {
      return getTableComponentForColumnHeader(params?.colDef?.headerName);
    },
  },
  {
    field: `firstActionToResolvedTime`,
    headerName: `FIRST RESPONSE ON RESOLVED TIME`,
    sortable: false,
    renderCell(params) {
      return getTableComponentForUserDetails(
        params?.row?.firstActionToResolvedTime?.type,
        params?.row?.firstActionToResolvedTime?.value,
      );
    },
    headerClassName: `bg-primary-100`,
    headerAlign: `center`,
    flex: 1,
    renderHeader(params) {
      return getTableComponentForColumnHeader(params?.colDef?.headerName);
    },
  },
  {
    field: `sentToInstituteTime`,
    headerName: `SENT TO INSTITUTE TIME`,
    sortable: false,
    renderCell(params) {
      return getTableComponentForUserDetails(
        params?.row?.sentToInstituteTime?.type,
        params?.row?.sentToInstituteTime?.value,
      );
    },
    headerClassName: `bg-primary-100`,
    headerAlign: `center`,
    flex: 1,
    renderHeader(params) {
      return getTableComponentForColumnHeader(params?.colDef?.headerName);
    },
  },
  {
    field: `onHoldToResolvedTime`,
    headerName: `ON HOLD TO RESOLUTION TIME`,
    sortable: false,
    renderCell(params) {
      return getTableComponentForUserDetails(
        params?.row?.onHoldToResolvedTime?.type,
        params?.row?.onHoldToResolvedTime?.value,
      );
    },
    headerClassName: `bg-primary-100`,
    headerAlign: `center`,
    flex: 1,
    renderHeader(params) {
      return getTableComponentForColumnHeader(params?.colDef?.headerName);
    },
  },
  {
    field: `firstResponseTimeMissedPercentage`,
    headerName: `FIRST RESPONSE TIME MISSED (%)`,
    sortable: false,
    renderCell(params) {
      return getTableComponentForUserDetails(
        params?.row?.firstResponseTimeMissedPercentage?.type,
        params?.row?.firstResponseTimeMissedPercentage?.value,
      );
    },
    headerClassName: `bg-primary-100`,
    headerAlign: `center`,
    flex: 1,
    renderHeader(params) {
      return getTableComponentForColumnHeader(params?.colDef?.headerName);
    },
  },
  {
    field: `sentToInstituteTimeMissedPercentage`,
    headerName: `SENT TO INSTITUTE MISSED (%)`,
    sortable: false,
    renderCell(params) {
      return getTableComponentForUserDetails(
        params?.row?.sentToInstituteTimeMissedPercentage?.type,
        params?.row?.sentToInstituteTimeMissedPercentage?.value,
      );
    },
    headerClassName: `bg-primary-100`,
    headerAlign: `center`,
    flex: 1,
    renderHeader(params) {
      return getTableComponentForColumnHeader(params?.colDef?.headerName);
    },
  },
];

export const enum SpotlightTypeEnum {
  NEEDS_ATTENTION = `NEEDS_ATTENTION`,
  NEEDS_TO_BE_PRAISED = `NEEDS_TO_BE_PRAISED`,
}

export const enum UserStatsTypeEnum {
  USER_PERFORMANCE = `USER_PERFORMANCE`,
  AVERAGE_TAT_PERFORMANCE = `AVERAGE_TAT_PERFORMANCE`,
}

export const enum PaginationActionTypesEnum {
  NEXT = `NEXT`,
  PREVIOUS = `PREVIOUS`,
}

export const ApplicationDateOptions = [
  { label: `Current Week`, value: `CURRENT_WEEK` },
  { label: `Last Week`, value: `LAST_WEEK` },
  { label: `Current Month`, value: `CURRENT_MONTH` },
  { label: `Last Month`, value: `LAST_MONTH` },
];

export const CountryGroupOptions = [
  { label: `Canada`, value: `CANADA` },
  { label: `USA`, value: `USA` },
  { label: `UKIR`, value: `UKIR` },
  { label: `ASNZO`, value: `ASNZO` },
];

export const enum UserPerformanceTypeEnum {
  BELOW_AVERAGE = `BELOW_AVERAGE`,
  AVERAGE = `AVERAGE`,
  ABOVE_AVERAGE = `ABOVE_AVERAGE`,
}

export const enum DashboardTabTypesEnum {
  MY_TASK = `MY_TASK`,
  PERFORMANCE_TRACKER = `PERFORMANCE_TRACKER`,
  PENDING_TASKS = `PENDING_TASKS`,
}

export const DashboardTabLabelIndexMapper: Record<
  number,
  DashboardTabTypesEnum
> = {
  0: DashboardTabTypesEnum.MY_TASK,
  1: DashboardTabTypesEnum.PERFORMANCE_TRACKER,
};

export const CounsellorDashboardTabLabelIndexMapper: Record<
  number,
  DashboardTabTypesEnum
> = {
  0: DashboardTabTypesEnum.PENDING_TASKS,
  1: DashboardTabTypesEnum.MY_TASK,
  2: DashboardTabTypesEnum.PERFORMANCE_TRACKER,
};

export const mutateFormValuesForSelect = (
  optionsList: OpsManagerListType[],
) => {
  type value = string | number | undefined;
  const valuesList: value[] = [];
  optionsList?.forEach((el) => valuesList.push(el?.value));
  return valuesList;
};

export const mutateFormValues = (values: any) => {
  const mutatedValues = cloneDeep(values);
  mutatedValues.countryGroups = values?.countryGroups
    ? mutateFormValuesForSelect(values?.countryGroups)
    : values?.countryGroups;
  mutatedValues.filterTypes = values?.filterTypes?.value
    ? [values?.filterTypes?.value]
    : [values?.filterTypes];
  mutatedValues.userIds = values?.userIds
    ? mutateFormValuesForSelect(values?.userIds)
    : values?.userIds;
  return mutatedValues;
};
