import { all } from "redux-saga/effects";
import userManagementSaga from "components/UserManagement/redux/UserManagementSaga";
import visaManagementSaga from "components/Application/VisaSection/redux/VisamanagementSaga";
function* rootSaga() {
  yield all([
    userManagementSaga(),
    visaManagementSaga()
  ]);
}

export default rootSaga;
