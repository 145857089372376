import { isFalsy } from '@leapfinance/frontend-commons';
import {
  DocumentUploadButtonProps,
  GBTypography,
} from '@leapfinance/geebee-component-library';

import { Document } from '@/types';
import { definitions } from '@/types/schema';

export const sopAcceptedFileFormats = [
  `CSV`,
  `DOC`,
  `DOCX`,
  `JPEG`,
  `JPG`,
  `PDF`,
  `PNG`,
  `PPT`,
  `PPTX`,
  `SVG`,
  `TXT`,
  `XLS`,
  `XLSX`,
];

export type SopRequestDataType = definitions['SopRequestResponseDto'];

export type SopOwnersResponseDataType = definitions['SopOwnerResponseDto'];

const getUploadedTimeStampInReadableFormat = (timeStamp?: string) => {
  if (!timeStamp) return ``;
  const uploadedTimeStamp = new Date(timeStamp);
  const uploadedDate = uploadedTimeStamp?.toDateString();
  const uploadedTime = `${uploadedTimeStamp?.getHours()}:${uploadedTimeStamp?.getMinutes()}`;
  return `${uploadedDate} ${uploadedTime}`;
};

export const getDocumentChipLabelAndTimeStamp = (
  keyLabel: string,
  fileLabel?: string,
  timeStamp?: string,
) => {
  const uploadedTimeStamp = getUploadedTimeStampInReadableFormat(timeStamp);

  return (
    <div className="flex flex-col gap-1">
      <GBTypography variant="sh3Regular" className="text-grey-900">
        {keyLabel} {fileLabel ? `- ${fileLabel}` : ``}
      </GBTypography>
      {timeStamp ? (
        <GBTypography
          variant="caption2"
          className="text-gbTokens-grey-A900 block"
        >
          Uploaded on{` `}
          {uploadedTimeStamp}
        </GBTypography>
      ) : null}
    </div>
  );
};

export const DraftSharedWithStudentOptions = [
  {
    label: `SOP has been shared with Student`,
    value: `SOP_SHARED_WITH_STUDENT`,
  },
];

export const DraftSharedWithCspOptions = [
  {
    label: `SOP has been shared with CSP`,
    value: `SOP_SHARED_WITH_CSP`,
  },
];

export const getLatestUploadedSopDraft = (
  draftDocuments?: definitions['DocumentDto'][],
  latestUploadedDraftDocId?: number,
) => {
  if (draftDocuments?.length === 1) {
    return draftDocuments?.[0];
  }

  if (isFalsy(draftDocuments)) {
    return null;
  }
  const latestDocument = draftDocuments?.filter(
    (document) => document?.id === latestUploadedDraftDocId,
  );
  if (isFalsy(latestDocument)) {
    return null;
  }

  return latestDocument?.[0];
};

export type IndividualSopPlagiarismCondition =
  definitions['SopQcConditions'] & {
    proofDocumentLocal: Document;
  };

export const plagiarismConditionsToBeEnteredByUser: IndividualSopPlagiarismCondition['conditionKey'][] =
  [`AI_CONTENT`, `WEB_PLAGIARISM_SCORE`];

export const getVerificationButtonDisabledStatus = ({
  condition,
  editable,
}: {
  condition: IndividualSopPlagiarismCondition;
  editable: boolean;
}) => {
  const isProofDocumentMissing = isFalsy(condition?.proofDocument);
  const isProofScoreMissing = isFalsy(condition?.score);
  if (editable) return true;
  if (condition?.verificationStatus === `DONE`) return true;
  if (
    plagiarismConditionsToBeEnteredByUser?.includes(condition?.conditionKey) &&
    (isProofDocumentMissing || isProofScoreMissing)
  ) {
    return true;
  }
  return false;
};

export const SOPQCAcceptedFileFormats: DocumentUploadButtonProps['acceptedFormats'] =
  [`JPEG`, `JPG`, `PDF`, `PNG`, `SVG`];

export const getMaximumAcceptedScoreLimit = (
  conditionKey: IndividualSopPlagiarismCondition['conditionKey'],
) => {
  const acceptedLimitText = `Max Accepted Limit: `;
  switch (conditionKey) {
    case `AI_CONTENT`:
    case `SAME_UNIVERSITY_SCORE`:
      return acceptedLimitText + `50`;
    case `OVERALL_SCORE`:
      return acceptedLimitText + `60`;
    case `WEB_PLAGIARISM_SCORE`:
      return acceptedLimitText + `35`;
  }
};
