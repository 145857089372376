export enum NotificationCategory {
  state = `STATE`,
  document = `DOCUMENT`,
  task = `TASK`,
  remarks = `REMARKS`,
}

export enum NotificationVariant {
  outlined = `outlined`,
  contained = `contained`,
}
