import { OPTION_TYPE } from '@leapfinance/frontend-commons/schema/common';
import debounce from 'lodash/debounce';

import { useSearchUsersinCspMutation } from '@/services/cspManagementApi';

function useGetCspOptions() {
  const [searchCspTrigger] = useSearchUsersinCspMutation();

  const fetchCsp = async (inputValue: string, callback: any) => {
    const options: OPTION_TYPE[] = [];
    const data = await searchCspTrigger({
      pageNumber: 0,
      pageSize: 10,
      cspName: inputValue,
    }).unwrap();
    if (data) {
      const list = data?.data?.accounts || [];
      list.forEach((item) => {
        options.push({
          value: item?.id,
          label: item?.cspName as string,
        });
      });
    }
    callback(options);
  };

  return [debounce(fetchCsp, 500)];
}

export default useGetCspOptions;
