import { CourseFinder } from 'types/courseFinder';
import {
  BaseResponse,
  CmsSpecialisationsRequestType,
  CmsSpecialisationsResponseType,
  GetShortlistSimilarCoursesRequestType,
  GetShortlistSimilarCoursesResponseType,
  ShortListResponse,
  SourceCountriesResponseType,
} from 'types/service';

import { CFCourse } from '@/components/CF/Dashboard/helpers';
import { transformResponse } from '@/utils/common';

import { geebeeRootBaseApiWithTags } from './base/geebeeApi';

export const courseFinderApi = geebeeRootBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getShortlistData: builder.query<ShortListResponse, { studentId: number }>({
      query: ({ studentId }) => `/v1/shortlist/student/${studentId}`,
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as ShortListResponse;
        } else {
          return { data } as unknown as ShortListResponse;
        }
      },
      providesTags: [`ShortlistRequest`],
    }),
    updateShortlist: builder.mutation<
      BaseResponse<any>,
      { studentId: number; shortlistData: CourseFinder }
    >({
      query: ({ studentId, shortlistData }) => ({
        url: `/v1/shortlist/update/student/${studentId}`,
        method: `PUT`,
        body: shortlistData,
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as BaseResponse<any>;
        } else {
          return { data } as unknown as BaseResponse<any>;
        }
      },
      invalidatesTags: [`ShortlistRequest`, `Shortlist`],
    }),
    deleteShortlist: builder.mutation<
      BaseResponse<any>,
      { studentId: number; shortlistId: number }
    >({
      query: ({ studentId, shortlistId }) => ({
        url: `/v1/shortlist/${shortlistId}/student/${studentId}`,
        method: `DELETE`,
      }),
      transformResponse,
      invalidatesTags: [`ShortlistRequest`, `Shortlist`],
    }),
    getSourceCountries: builder.query<SourceCountriesResponseType, void>({
      query: () => `/cf/source-countries`,
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as SourceCountriesResponseType;
        } else {
          return {
            success,
            data,
            message,
          } as unknown as SourceCountriesResponseType;
        }
      },
    }),
    getCmsSpecialisations: builder.query<
      CmsSpecialisationsResponseType,
      CmsSpecialisationsRequestType
    >({
      query: ({ totalPerPage, pageIndex, tags, specialisations }) => ({
        url: `/cf/specialisations`,
        method: `POST`,
        body: {
          totalPerPage,
          pageIndex,
          tags,
          specialisations,
        },
      }),
    }),
    getStudentShortlistedCourses: builder.query<
      BaseResponse<Array<CFCourse>>,
      { studentId: number }
    >({
      query: ({ studentId }) => `/v1/shortlist/student-shortlists/${studentId}`,
      transformResponse: transformResponse,
      providesTags: [`Shortlist`],
    }),
    getShortlistedSimilarCourses: builder.mutation<
      GetShortlistSimilarCoursesResponseType,
      GetShortlistSimilarCoursesRequestType
    >({
      query: (body) => ({
        url: `/v1/shortlist/similar-courses`,
        method: `POST`,
        body,
      }),
    }),
  }),
});

export const {
  useGetShortlistDataQuery,
  useDeleteShortlistMutation,
  useUpdateShortlistMutation,
  useLazyGetShortlistDataQuery,
  useGetSourceCountriesQuery,
  useGetCmsSpecialisationsQuery,
  useGetStudentShortlistedCoursesQuery,
  useLazyGetStudentShortlistedCoursesQuery,
  useGetShortlistedSimilarCoursesMutation,
} = courseFinderApi;
