import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '@/app/store';
import { additionalExams } from '@/components/Student/StudentProfileV1/helpers';
import { configApi } from '@/services/config';
import { Mapping } from '@/types';

const initialState = {} as { mapping: Mapping };

export const mappingSlice = createSlice({
  name: `mapping`,
  initialState,
  reducers: {
    updateConfig: (state, action: PayloadAction<Mapping>) => {
      state.mapping = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      configApi.endpoints.getMapping.matchFulfilled,
      (state, { payload }) => {
        state.mapping = payload.data;
      },
    );
  },
});

export const { updateConfig } = mappingSlice.actions;

export const selectMapping = (state: RootState) => state.mapping.mapping;
export const testExamOptions = (state: RootState) => {
  const mapping = state.mapping.mapping;
  const examOptionKeys = Object.keys(
    mapping?.studentConfig?.documentConfig[`EXAM_INFO`] ?? {},
  );
  const examOptions = examOptionKeys?.map((key) => ({
    label: mapping?.keyMappings[key] ?? ``,
    value: key,
  }));
  const englishExamOptions = examOptions.filter(
    (option) => !additionalExams.includes(option?.value as any),
  );

  const additionalExamOptions = examOptions.filter((option) =>
    additionalExams.includes(option?.value as any),
  );

  return { englishExamOptions, additionalExamOptions };
};

export default mappingSlice.reducer;
