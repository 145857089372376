import * as React from 'react';
import { SVGProps } from 'react';

const TaskNotificationIcon = (
  props: SVGProps<SVGSVGElement> & { active?: boolean },
) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.671 6.608a4.968 4.968 0 0 0-4.965 4.966 4.968 4.968 0 0 0 4.965 4.966 4.968 4.968 0 0 0 4.966-4.966 4.968 4.968 0 0 0-4.966-4.966Zm-1.164 3.502v1.916a.75.75 0 0 0 .753.75l2.108-.008a.75.75 0 0 0-.006-1.5l-1.355.005V10.11a.75.75 0 0 0-1.5 0Z"
      fill={props.active ? `#FF7791` : `#b7b7b7`}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.792 16.98a5.718 5.718 0 0 1-7.002-5.57 5.718 5.718 0 0 1 7.002-5.57V2.126A1.874 1.874 0 0 0 13.917.25H1.944C.908.25.069 1.09.069 2.125v15.75c0 1.035.84 1.875 1.875 1.875h11.973a1.875 1.875 0 0 0 1.875-1.875v-.896ZM2.035 16h6.307a.75.75 0 0 0 0-1.5H2.035a.75.75 0 0 0 0 1.5Zm0-5.25h4.73a.75.75 0 0 0 0-1.5h-4.73a.75.75 0 0 0 0 1.5Zm0-5.25h8.143a.75.75 0 0 0 0-1.5H2.035a.75.75 0 0 0 0 1.5Z"
      fill={props.active ? `#FF7791` : `#b7b7b7`}
    />
  </svg>
);

export default TaskNotificationIcon;
