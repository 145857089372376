import { useSnackbar } from 'notistack';

import { useCreateStudentMutation } from '@/services/student';
import { CreateStudentType } from '@/validations/createStudent.schema';

function useCreateStudent() {
  const [createStudentTrigger, { isLoading }] = useCreateStudentMutation();
  const { enqueueSnackbar } = useSnackbar();

  const handleCreateNewStudent = async (values: any) => {
    try {
      const payload: CreateStudentType = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        contactNumber: values?.contactNumber,
        countryCode: values?.countryCode?.value,
        cspId: values?.cspId?.value,
        email: values?.emailId,
        dob: values?.dob,
        maritalStatus: values?.maritalStatus?.value,
        gender: values?.gender?.value,
      };
      const res = await createStudentTrigger(payload).unwrap();
      if (res?.success && res?.data?.id) {
        values.studentId = res?.data?.id;
        enqueueSnackbar(`Created Student`, {
          variant: `success`,
        });
        return {
          label: `${values?.firstName} ${values?.lastName}`,
          value: res?.data?.id,
          firstName: values?.firstName,
          lastName: values?.lastName,
          ...(values?.createStudentMode === `yes`
            ? {
                email: values?.emailId,
              }
            : {}),
        };
      } else {
        enqueueSnackbar(`Unable to create Student`, {
          variant: `error`,
        });
        return null;
      }
    } catch (err) {
      enqueueSnackbar(`Unable to create Student`, {
        variant: `error`,
      });
      return null;
    }
  };

  return {
    handleCreateNewStudent,
    isCreatingStudent: isLoading,
  };
}

export default useCreateStudent;
