import { useRef } from 'react';
import { Form } from 'react-final-form';
import { Button, FFDropDown, isFalsy } from '@leapfinance/frontend-commons';
import geebeeTheme from '@leapfinance/frontend-commons/components/geebee/theme';
import { OPTION_TYPE } from '@leapfinance/frontend-commons/schema/common';
import CloseRounded from '@material-ui/icons/CloseRounded';
import { Divider, IconButton, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import { useSnackbar } from 'notistack';

import { transformOptionValues } from '@/components/CF/Dashboard/helpers';
import { MODAL_TYPES } from '@/components/modals/GlobalModal';
import useModal from '@/components/modals/useModal';
import { transformCspOptionsLabel } from '@/components/UnisetuDashboard/helpers';
import { mockIntakes } from '@/components/UnisetuDashboard/intakeGenerator';
import { useGetAllCSpsQuery } from '@/services/student';
import {
  useRequestApplicationReportDownloadMutation,
  useRequestPerformanceDashboardReportDownloadMutation,
  useRequestProjectionTableDownloadMutation,
  useRequestStudentReportDownloadMutation,
} from '@/services/unisetuDashboardV2';

export interface CSVExportModalProps {
  initialValues?: Record<string, any>;
  additionalFilters?: Record<string, any>;
  entity?:
    | 'APPLICATION'
    | 'STUDENT'
    | 'UNIVERSITY'
    | 'PROJECTION'
    | 'UNIVERSITY_EMAIL'
    | 'CSP'
    | 'GIC'
    | 'JERRY_USER';
}

const CSVExportModal = (props: CSVExportModalProps) => {
  const { hideModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const formRef = useRef<any>(null);
  const [triggerRequestOverallPerformanceDashboardData] =
    useRequestPerformanceDashboardReportDownloadMutation();
  const [triggerRequestStudentReport] =
    useRequestStudentReportDownloadMutation();
  const [triggerRequestApplicationReport] =
    useRequestApplicationReportDownloadMutation();
  const [triggerRequestProjectionTableReport] =
    useRequestProjectionTableDownloadMutation();
  const { data: cspData } = useGetAllCSpsQuery();

  const cspOptions = transformCspOptionsLabel(cspData?.data?.cspList);

  const handleFileExport = async (formValues: any) => {
    try {
      const payload = transformOptionValues(formValues);
      let response;
      if (props?.entity === `PROJECTION`) {
        response = await triggerRequestProjectionTableReport({
          intakeRange: formValues?.intakes?.projectionIntakeValue,
          ...payload,
        }).unwrap();
      } else if (props?.entity === `APPLICATION`) {
        response = await triggerRequestApplicationReport({
          ...props?.additionalFilters,
          ...payload,
        }).unwrap();
      } else if (props?.entity === `STUDENT`) {
        response = await triggerRequestStudentReport({
          ...props?.additionalFilters,
          ...payload,
        }).unwrap();
      } else {
        response = await triggerRequestOverallPerformanceDashboardData(
          payload,
        ).unwrap();
      }

      if (response.success && response.data) {
        enqueueSnackbar(
          `Download Request Received, you will receive the report in your email attachment`,
          {
            variant: `success`,
          },
        );
        hideModal(MODAL_TYPES.CSV_EXPORT_MODAL);
      }
    } catch (error) {
      console.log(`Error in exporting CSV`, error);
      enqueueSnackbar(`Failed to download data, please try again later`, {
        variant: `error`,
      });
      hideModal(MODAL_TYPES.CSV_EXPORT_MODAL);
    }
  };

  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="bg-white-0 rounded-lg w-[70%] py-4 px-6">
        <ThemeProvider theme={geebeeTheme}>
          <div className="flex flex-col justify-between h-full w-full">
            <div>
              <div className="flex justify-between items-center mb-4">
                <div className="flex items-center gap-x-4">
                  <Typography variant="h3" className="text-[#101010]">
                    Export Data
                  </Typography>
                </div>
                <IconButton
                  className="m-0 p-0"
                  onClick={() => {
                    hideModal(MODAL_TYPES.CSV_EXPORT_MODAL);
                  }}
                >
                  <CloseRounded className="text-[#101010]" />
                </IconButton>
              </div>
              <Divider className="my-4 -mx-6" />
              <Form
                onSubmit={handleFileExport}
                initialValues={{
                  ...props?.initialValues,
                  cspId:
                    props?.initialValues?.cspIds?.length === 1
                      ? props?.initialValues?.cspIds?.[0]
                      : {},
                }}
                render={({ form }) => {
                  formRef.current = form;
                  return (
                    <div className="grid grid-cols-2 gap-6">
                      <FFDropDown
                        fieldProps={{
                          name: `cspId`,
                          validate: (value) => {
                            if (!value) {
                              return `Please choose a CSP to download data`;
                            }
                          },
                        }}
                        componentProps={{
                          options: isFalsy(props?.initialValues?.cspIds)
                            ? cspOptions
                            : props?.initialValues?.cspIds,
                          label: `CSP Name`,
                          placeholder: `Select CSP Name`,
                          helperText: `Please choose a single CSP to export data`,
                        }}
                      />
                      <FFDropDown
                        fieldProps={{ name: `intakes` }}
                        componentProps={{
                          options:
                            (mockIntakes as unknown as OPTION_TYPE[]) ?? [],
                          label: `Intake`,
                          placeholder: `Select Intake`,
                        }}
                      />
                    </div>
                  );
                }}
              />
            </div>
            <div className="flex justify-end">
              <Button
                onClick={() => {
                  console.log(`Submitting`);
                  formRef?.current?.submit();
                }}
              >
                Export
              </Button>
            </div>
          </div>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default CSVExportModal;
