import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const tasksApi = createApi({
  reducerPath: `tasksApi`,
  baseQuery: fetchBaseQuery({ baseUrl: `/api/geebeeRoute?path=/tasks` }),
  endpoints: () => ({}),
});

export const tasksBaseApiWithTags = tasksApi.enhanceEndpoints({
  addTagTypes: [
    `TasksStats`,
    `TaskComments`,
    `TaskDetails`,
    `TaskList`,
    `TaskAuditLogs`,
  ],
});
