import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { AuthToken } from 'lib/auth_token';
import { useRouter } from 'next/router';
import {
  useGetAllUsersQuery,
  useGetAssociatedUsersQuery,
  useGetGeebeeSharedListQuery,
  useGetSharedListQuery,
  useGetUsersByRoleQuery,
} from 'services/generalApi';

import { useAppSelector } from '@/app/hooks';
import { selectRole } from '@/features/generalSlice';
import { selectJoyrideRun } from '@/features/react-joyride-product-tour/reactJoyrideProductTour';
import { useCourseReportModal } from '@/hooks/useCourseReportModal';
import useInternetStatus from '@/hooks/useInternetStatus';
import { useGetMappingQuery } from '@/services/config';
import { Roles } from '@/types/global';

import { useGetCommissionSharedListQuery } from './Commission/services/geebeeCommission';
import NewHeader from './NewHeader';

interface LayoutProps {
  children: ReactElement;
  auth?: AuthToken;
}
const Layout: FunctionComponent<LayoutProps> = (props) => {
  const { children, auth } = props;
  const isProductTourOngoing = useAppSelector((state) =>
    selectJoyrideRun(state as any),
  );
  const [navbarSticky, setNavbarSticky] = useState(true);
  useGetSharedListQuery();
  useGetGeebeeSharedListQuery();
  useGetMappingQuery();
  const router = useRouter();
  useGetAllUsersQuery();
  useGetUsersByRoleQuery();
  useGetAssociatedUsersQuery();
  useInternetStatus();

  const role = auth?.decodedToken?.user_id?.roles[0];
  const currentURL = router.asPath;
  const roleInStore: string | null = useAppSelector((state) =>
    selectRole(state),
  );

  useCourseReportModal(currentURL.includes(`course-details`));
  useGetSharedListQuery();
  useGetGeebeeSharedListQuery();
  useGetCommissionSharedListQuery(undefined, {
    skip: roleInStore !== Roles.gbInvoicing,
  });

  const childrenWithAuth = React.cloneElement(children, { role });

  useEffect(() => {
    if (!router.isReady) return;
    if (
      currentURL.includes(`course-finder`) ||
      currentURL.includes(`course-details`) ||
      currentURL.includes(`university`)
    ) {
      setNavbarSticky(false);
    }
  }, [router]);

  useEffect(() => {
    if (currentURL === `/dashboard/summary`) {
      setNavbarSticky(!isProductTourOngoing);
    }
  }, [isProductTourOngoing]);

  return (
    <div className="w-full">
      <div className={`${navbarSticky ? `sticky top-0 z-10` : ``} bg-white`}>
        {currentURL.includes(`university`) ||
        currentURL?.includes(`course-pdf`) ||
        currentURL?.includes(`course-details`) ||
        currentURL?.includes(`email-content`) ? null : (
          <NewHeader />
        )}
      </div>
      {childrenWithAuth}
    </div>
  );
};

export default Layout;
