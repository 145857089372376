import { useRef } from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { isFalsy } from '@leapfinance/frontend-commons';
import {
  DocumentChip,
  DocumentUploadButton,
  FFTextInput,
  GBModalWrapper,
  GBTypography,
} from '@leapfinance/geebee-component-library';
import classNames from 'classnames';
import { FormApi } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { useSnackbar } from 'notistack';

import { FormData } from '@/components/Application/context/interface';
import { MODAL_TYPES } from '@/components/modals/GlobalModal';
import useModal from '@/components/modals/useModal';
import {
  IndividualSopPlagiarismCondition,
  plagiarismConditionsToBeEnteredByUser,
  SOPQCAcceptedFileFormats,
} from '@/components/SOP/utils';
import useDocumentActions from '@/hooks/useDocumentActions';
import {
  useGetSopDraftPlagiarismDetailsQuery,
  useUpdateSopPlagiarismDetailsForQCMutation,
} from '@/services/plagiarism';
import { definitions } from '@/types/schema';
import { handleDelete, handleUpload } from '@/utils/fileFunctions';

export interface SOPDraftPlagiarismModalProps {
  applicationId: number;
  studentId: number;
  sopForm: FormApi;
  documentKey: definitions['DocumentDto']['keyName'];
  documentId?: number;
  isSopRequestInDraftStage?: boolean;
}

const SOPDraftPlagiarismModal = ({
  applicationId,
  studentId,
  sopForm,
  documentKey,
  documentId,
  isSopRequestInDraftStage,
}: SOPDraftPlagiarismModalProps) => {
  const formRef = useRef<FormApi | null>();
  const { hideModal } = useModal();

  const { enqueueSnackbar } = useSnackbar();
  const { handlePresignedUrlDownload } = useDocumentActions();
  const [triggerUpdateSopPlagiarismDetails] =
    useUpdateSopPlagiarismDetailsForQCMutation();
  const { data: sopPlagiarismData, refetch: refetchSopPlagiarismData } =
    useGetSopDraftPlagiarismDetailsQuery(
      {
        documentId: documentId as number,
        documentKey: documentKey,
      },
      {
        skip: Number.isNaN(Number(documentId)),
        refetchOnMountOrArgChange: true,
      },
    );

  const handleViewDocument = (url: string | undefined) => {
    if (!url) return;
    handlePresignedUrlDownload({ url: url });
  };

  const getHelperText = (
    conditionKey: IndividualSopPlagiarismCondition['conditionKey'],
  ) => {
    if (conditionKey === `WEB_PLAGIARISM_SCORE`) {
      return (
        <GBTypography
          variant="link3"
          className="not-italic text-gbTokens-primary-500 mt-1"
          onClick={() => {
            window?.open(`https://www.grammarly.com/plagiarism-checker`);
          }}
        >
          Check on Grammarly
        </GBTypography>
      );
    }
    if (conditionKey === `AI_CONTENT`) {
      return (
        <GBTypography
          variant="link3"
          className="not-italic text-gbTokens-primary-500 mt-1"
          onClick={() => {
            window?.open(`https://www.zerogpt.com/`);
          }}
        >
          Check on ZeroGPT
        </GBTypography>
      );
    }
  };

  const handleFormSubmit = async (values: any) => {
    if (values?.conditions) {
      try {
        const conditions = values?.conditions;
        const payload = conditions?.map(
          (condition: IndividualSopPlagiarismCondition) => {
            return {
              ...condition,
              proofDocumentId: condition?.proofDocumentLocal
                ? condition?.proofDocumentLocal?.id
                : condition?.proofDocument?.id,
            };
          },
        );
        const response = await triggerUpdateSopPlagiarismDetails({
          applicationId: applicationId,
          updateSopQcDetailsDto: { conditions: payload },
        }).unwrap();
        if (response?.success) {
          enqueueSnackbar(`Details saved successfully`, {
            variant: `success`,
          });
          sopForm.change(`plagiarismScores`, values?.conditions);
          sopForm?.submit();
          hideModal(MODAL_TYPES.SOP_DRAFT_PLAGIARISM_CHECK);
        } else {
          enqueueSnackbar(`Failed to save details`, {
            variant: `error`,
          });
        }
      } catch (error) {
        console.error(`Error in updating SOP details in QC`, error);
        enqueueSnackbar(`Failed to save details`, {
          variant: `error`,
        });
      }
    }
  };

  return (
    <GBModalWrapper
      headerProps={{
        title: `SOP Plagiarism Check`,
        onClose: () => hideModal(MODAL_TYPES.SOP_DRAFT_PLAGIARISM_CHECK),
      }}
      footerProps={{
        primaryCta: {
          title: `Save and Update Status`,
          buttonProps: {
            className: `whitespace-nowrap`,
            onClick: () => formRef?.current?.submit(),
          },
        },
      }}
    >
      <Form
        onSubmit={handleFormSubmit}
        initialValues={{
          ...sopPlagiarismData?.data,
        }}
        mutators={{ ...arrayMutators }}
        render={({ form }) => {
          formRef.current = form;
          return (
            <div className="px-4 py-3 grid gap-3">
              <FieldArray name={`conditions`}>
                {({ fields }) => {
                  return fields.map((name, index) => {
                    const condition: IndividualSopPlagiarismCondition =
                      fields?.value?.[index];

                    return (
                      <div
                        className={classNames(`grid gap-3`, {
                          'grid-cols-1':
                            !plagiarismConditionsToBeEnteredByUser?.includes(
                              condition?.conditionKey,
                            ),
                          'grid-cols-2':
                            plagiarismConditionsToBeEnteredByUser?.includes(
                              condition?.conditionKey,
                            ),
                        })}
                        key={condition?.id}
                      >
                        <FFTextInput
                          fieldProps={{
                            name: `${name}.score`,
                            validate: (value) => {
                              if (
                                isFalsy(value) &&
                                plagiarismConditionsToBeEnteredByUser?.includes(
                                  condition?.conditionKey,
                                )
                              ) {
                                return `Required`;
                              }
                              if (
                                !isFalsy(value) &&
                                value > 100 &&
                                plagiarismConditionsToBeEnteredByUser?.includes(
                                  condition?.conditionKey,
                                )
                              ) {
                                return `Max value: 100`;
                              }
                            },
                          }}
                          componentProps={{
                            label: condition?.conditionName,
                            placeholder: `Enter Score`,
                            type: `number`,
                            size: `small`,
                            helperText: getHelperText(condition?.conditionKey),
                            disabled:
                              !plagiarismConditionsToBeEnteredByUser.includes(
                                condition?.conditionKey,
                              ),
                          }}
                        />
                        <div
                          className={classNames(
                            `flex flex-col`,
                            {
                              'justify-end':
                                !condition?.proofDocument &&
                                !condition?.proofDocumentLocal,
                            },
                            {
                              'justify-center':
                                condition?.proofDocument ||
                                condition?.proofDocumentLocal,
                            },
                          )}
                        >
                          {plagiarismConditionsToBeEnteredByUser?.includes(
                            condition?.conditionKey,
                          ) ? (
                            condition?.proofDocument ? (
                              <DocumentChip
                                documentName={
                                  condition?.proofDocument?.fileName
                                }
                                fullWidth
                                documentMeta={condition?.proofDocument}
                                onView={() => {
                                  handleViewDocument(
                                    condition?.proofDocument?.url,
                                  );
                                }}
                                onDownload={() => {
                                  handleViewDocument(
                                    condition?.proofDocument?.url,
                                  );
                                }}
                                disableDelete={isSopRequestInDraftStage}
                                onDelete={() =>
                                  handleDelete(
                                    {
                                      id: condition?.proofDocument?.id,
                                      keyName:
                                        condition?.proofDocument?.keyName,
                                    } as unknown as FormData,
                                    () => {
                                      refetchSopPlagiarismData();
                                    },
                                  )
                                }
                              />
                            ) : condition?.proofDocumentLocal ? (
                              <DocumentChip
                                documentName={`Proof Document`}
                                fullWidth
                                documentMeta={condition?.proofDocumentLocal}
                                onView={() => {
                                  handleViewDocument(
                                    condition?.proofDocumentLocal?.url,
                                  );
                                }}
                                onDownload={() => {
                                  handleViewDocument(
                                    condition?.proofDocumentLocal?.url,
                                  );
                                }}
                                onDelete={() =>
                                  handleDelete(
                                    {
                                      id: condition?.proofDocumentLocal?.id,
                                      keyName:
                                        condition?.proofDocumentLocal?.keyName,
                                    } as unknown as FormData,
                                    () => {
                                      form.change(
                                        `${name}.proofDocumentLocal`,
                                        undefined,
                                      );
                                    },
                                  )
                                }
                              />
                            ) : (
                              <Field
                                name="document"
                                validate={(value) => {
                                  if (!value) {
                                    return `Required`;
                                  }
                                }}
                                render={({ meta }) => {
                                  const error = meta.error && meta.touched;
                                  return (
                                    <>
                                      <GBTypography
                                        variant="sh3Semibold"
                                        className="mb-1"
                                      >
                                        {condition?.conditionName} Proof
                                      </GBTypography>
                                      <DocumentUploadButton
                                        acceptedFormats={
                                          SOPQCAcceptedFileFormats
                                        }
                                        fullWidth
                                        error={error}
                                        containerClasses="h-[36px]"
                                        helperText={
                                          error
                                            ? `Required`
                                            : `Accepted formats: ${SOPQCAcceptedFileFormats?.join(
                                                `, `,
                                              )}`
                                        }
                                        onDocumentUploadCallback={async (
                                          data,
                                        ) => {
                                          if (
                                            data?.validationMeta?.errorType ===
                                            `INVALID_FILE_FORMAT`
                                          ) {
                                            enqueueSnackbar(
                                              `File format not accepted`,
                                              {
                                                variant: `error`,
                                              },
                                            );
                                            return;
                                          }
                                          if (!data?.file) return;
                                          await handleUpload(
                                            {
                                              file: data?.file,
                                              title:
                                                condition?.conditionKey ===
                                                `WEB_PLAGIARISM_SCORE`
                                                  ? `SOP_QC_WEB_PLAGIARISM_PROOF`
                                                  : `SOP_QC_AI_CONTENT_PROOF`,
                                              eachAppData: {
                                                id: applicationId,
                                                student: { id: studentId },
                                              },
                                            },
                                            (response) => {
                                              form.change(
                                                `${name}.proofDocumentLocal`,
                                                response,
                                              );
                                            },
                                          );
                                        }}
                                      />
                                    </>
                                  );
                                }}
                              />
                            )
                          ) : null}
                        </div>
                      </div>
                    );
                  });
                }}
              </FieldArray>
            </div>
          );
        }}
      />
    </GBModalWrapper>
  );
};

export default SOPDraftPlagiarismModal;
