import { Button, Typography } from '@mui/material';
import { DataGrid, DataGridProps, GridColDef } from '@mui/x-data-grid';

import { UserPerformanceTypeEnum } from '@/components/SummaryDashboard/UserPerformanceViewDashboard/constants';

interface IUserDataTable<T> {
  title?: string;
  downloadTrigger?: () => void;
  tableRowsData: T[];
  tableColumnsData: GridColDef[];
  containerStyles?: string;
  dataGridProps?: Omit<DataGridProps, `columns` | `rows`>;
  disableDownload?: boolean;
}

const getBgColor = (type: UserPerformanceTypeEnum) => {
  switch (type) {
    case UserPerformanceTypeEnum.ABOVE_AVERAGE:
      return `bg-success-200`;
    case UserPerformanceTypeEnum.BELOW_AVERAGE:
      return `bg-error-200`;
    default:
      return `white`;
  }
};

export const getTableComponentForUserName = (name: string) => {
  return (
    <Typography
      className="w-full text-center py-3 whitespace-normal"
      variant="body2"
    >
      {name}
    </Typography>
  );
};

export const getTableComponentForUserDetails = (
  type: UserPerformanceTypeEnum,
  value: string,
) => {
  return (
    <Typography
      variant="subtitle2"
      className={`w-full h-full text-grey-900 flex justify-center items-center ${getBgColor(
        type,
      )}`}
    >
      {value}
    </Typography>
  );
};

export const getTableComponentForColumnHeader = (headerName?: string) => {
  return (
    <Typography
      variant="caption2"
      className="text-grey-800 text-center whitespace-normal"
    >
      <span dangerouslySetInnerHTML={{ __html: headerName as string }} />
    </Typography>
  );
};

const UserDataTableComponent = <T,>(props: IUserDataTable<T>) => {
  const {
    title,
    downloadTrigger,
    tableRowsData,
    tableColumnsData,
    containerStyles,
    dataGridProps,
    disableDownload,
  } = props;

  const getTableStyles = () => {
    if (tableRowsData?.length > 5) {
      return {
        border: `0px`,
        height: `${5 * 4}rem`,
      };
    }
    return {
      border: `0px`,
    };
  };

  const getTableAutoHeight = () => {
    if (tableRowsData?.length > 5) {
      return false;
    }
    return true;
  };

  return (
    <div className={containerStyles ?? ``}>
      <div className="flex justify-between items-center px-4 mb-4 pt-6">
        <Typography variant="h2">{title}</Typography>
        {tableRowsData?.length > 0 && !disableDownload ? (
          <Button
            className={`normal-case font-sans text-white font-semibold px-7 py-3 text-sm ${
              tableRowsData?.length === 0 ? `bg-grey-600` : `bg-primary-500`
            }`}
            variant="contained"
            color="primary"
            onClick={() => downloadTrigger?.()}
          >
            Download Report
          </Button>
        ) : null}
      </div>
      <DataGrid
        className="font-sans rounded-lg px-4 pt-2"
        style={getTableStyles()}
        sx={{
          '.MuiDataGrid-columnSeparator': {
            display: `none`,
          },
        }}
        autoHeight={getTableAutoHeight()}
        hideFooter={true}
        columns={tableColumnsData}
        disableColumnFilter={true}
        disableColumnMenu={true}
        disableColumnSelector={true}
        hideFooterPagination={true}
        hideFooterSelectedRowCount={true}
        disableDensitySelector={true}
        rows={tableRowsData}
        disableSelectionOnClick={true}
        disableVirtualization={true}
        {...dataGridProps}
      />
    </div>
  );
};

export default UserDataTableComponent;
