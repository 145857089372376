import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const seoRootBaseApi = createApi({
  reducerPath: `seoRootBaseApi`,
  baseQuery: fetchBaseQuery({ baseUrl: `/api/seoRoute?path=` }),
  endpoints: () => ({}),
});

export const seoRootBaseApiWithTags = seoRootBaseApi.enhanceEndpoints({
  addTagTypes: [`university`, `course`],
});
