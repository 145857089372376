import { store } from '@/app/store';
import { quackerApi } from '@/services/quackerApi';
import yup from '@/yup/yupInstance';

const MOBILE_NUMBER_ALREADY_EXIST_ERROR_MESSAGE = `CSP already exists in your contact.`;
const MOBILE_NUMBER_REQUIRED_ERROR_MESSAGE = `Mobile number is required`;

const addQuackerContactSchema = yup.object().shape({
  name: yup.string().required(`Contact name is required`),
  cspTeamName: yup.mixed<{ label: string; value: string }>(),

  phoneNumber: yup.mixed().test(async function (value) {
    const mobileNumber = value?.number;
    const countryCode = value?.countryCode;
    if (!mobileNumber) {
      return this.createError({
        message: `${MOBILE_NUMBER_REQUIRED_ERROR_MESSAGE}`,
      });
    }

    if (countryCode === `+91` && value?.number?.length !== 10) {
      return this.createError({
        message: `Please enter a valid 10 digit mobile number`,
      });
    }

    if (mobileNumber?.length > 7) {
      // const checkDuplicateCache =
      //   quackerApi.endpoints.checkDuplicateQuackerContact.select({
      //     contactPhoneNumber: encodeURIComponent(
      //       `${value?.countryCode}${mobileNumber}`,
      //     ),
      //   })(store.getState() as any);

      const promise = store.dispatch(
        quackerApi.endpoints.checkDuplicateQuackerContact.initiate({
          contactPhoneNumber: encodeURIComponent(
            `${value?.countryCode}${mobileNumber}`,
          ),
        }),
      );
      const response = await promise.unwrap();

      // const response = await promise.unwrap();
      if (response?.success && response?.data) {
        return this.createError({
          message: `${MOBILE_NUMBER_ALREADY_EXIST_ERROR_MESSAGE}`,
        });
      }
      promise.unsubscribe();
    }
    return true;
  }),
  label: yup.mixed().nullable().required(`CSP/Internal Team Name is required`),
  type: yup.mixed().nullable().required(`Location/Additional Flag is required`),
});

export {
  addQuackerContactSchema,
  MOBILE_NUMBER_ALREADY_EXIST_ERROR_MESSAGE,
  MOBILE_NUMBER_REQUIRED_ERROR_MESSAGE,
};
