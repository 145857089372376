import * as yup from 'yup';

import {
  decimal,
  decimalPoints,
  englishTestScore,
  wholeNumber,
} from './methods/number.methods';
import { validGradeCheck } from './methods/string.methods';

yup.addMethod<yup.StringSchema>(yup.string, `validGradeCheck`, validGradeCheck);
yup.addMethod<yup.NumberSchema>(yup.number, `decimal`, decimal);
yup.addMethod<yup.NumberSchema>(yup.number, `wholeNumber`, wholeNumber);
yup.addMethod<yup.NumberSchema>(
  yup.number,
  `englishTestScore`,
  englishTestScore,
);
yup.addMethod<yup.NumberSchema>(yup.number, `decimalPoints`, decimalPoints);
yup.addMethod<yup.StringSchema>(yup.string, `decimalPoints`, decimalPoints);

export default yup;
