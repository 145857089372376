import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const metabaseApi = createApi({
  reducerPath: `metabaseApi`,
  baseQuery: fetchBaseQuery({ baseUrl: `/api/metabaseAnalytics?path=` }),
  endpoints: () => ({}),
});

export const metabaseApiWithTags = metabaseApi.enhanceEndpoints({
  addTagTypes: [`MetabaseData`, `MetabaseParameters`],
});
