import {
  GBModalWrapper,
  GBTypography,
} from '@leapfinance/geebee-component-library';
import { Close } from '@mui/icons-material';
import ReportIcon from '@mui/icons-material/Report';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { close, CONFIRM_MODAL_TYPES } from '../features/confirmDialogSlice';

import CustomButton from './CustomMUIComponents/CustomButton';
import CommissionWarningModal from './dialogs/CommissionWarningModal';

const ConfirmDialog = () => {
  const { onSubmit, message, title, type, onCancel, options } = useAppSelector(
    (state) => state.confirmDialog,
  );
  const dispatch = useAppDispatch();

  if (type === CONFIRM_MODAL_TYPES.COMMISSION_WARNING) {
    return <CommissionWarningModal />;
  }

  return (
    <Dialog open={Boolean(onSubmit)} maxWidth="sm">
      <GBModalWrapper
        variant="small"
        headerProps={{
          title,
          onClose: () => {
            if (onCancel) {
              onCancel();
            }
            dispatch(close());
          },
        }}
        footerProps={{
          primaryCta: {
            title: options?.proceedButtonText ?? `Confirm`,
            buttonProps: {
              onClick: () => {
                if (onSubmit) {
                  onSubmit();
                }
                dispatch(close());
              },
            },
          },
          secondaryCta: {
            title: options?.cancelButtontext || `Cancel`,
            buttonProps: {
              onClick: () => {
                if (onCancel) {
                  onCancel();
                }
                dispatch(close());
              },
            },
          },
        }}
      >
        <div className="flex flex-col justify-center items-center p-4 gap-2 text-center">
          <ReportIcon className={`w-10 h-10 text-gbTokens-warning-500`} />
          {/* <GBTypography className="font-sans font-bold">{title}</DialogTitle> */}
          <GBTypography variant="body4">{message}</GBTypography>
        </div>
      </GBModalWrapper>
      {/* <div className="flex items-end justify-end w-full">
        <IconButton className="h-10 w-10" onClick={() => dispatch(close())}>
          <Close />
        </IconButton>
      </div>
      <DialogContent className={`flex flex-col items-center justify-center`}>
        <ReportIcon className={`w-20 h-20 text-amber-400`} />
        <DialogTitle className="font-sans font-bold">{title}</DialogTitle>
        <Typography sx={{ fontFamily: `Montserrat` }}>{message}</Typography>
      </DialogContent>
      <DialogActions className={`flex  items-center justify-center pb-10`}>
        <CustomButton
          text={options?.cancelButtontext || `Cancel`}
          variant={`outlined`}
          onClick={() => {
            if (onCancel) {
              onCancel();
            }
            dispatch(close());
          }}
          className={`text-primary-500 border-primary mx-2`}
        />
        <CustomButton
          text={options?.proceedButtonText || `Confirm`}
          className="hover:opacity-80 mx-2"
          onClick={() => {
            if (onSubmit) {
              onSubmit();
            }
            dispatch(close());
          }}
        />
      </DialogActions> */}
    </Dialog>
  );
};

export default ConfirmDialog;
