import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';
import { tasksApi } from '@/services/tasks';
import { definitions } from '@/types/schema';
import { TaskDetailsResponse } from '@/types/Services';

const initialState = {} as TaskDetailsResponse & {
  currentTaskIdInView?: number;
};
//[Todo]: Move to context or window obj
export const taskSlice = createSlice({
  name: `taskSlice`,
  initialState,
  reducers: {
    updateTasksList: (
      state,
      action: PayloadAction<{
        taskId: number;
        status: string;
        statusUpdatedInBulkBy: {
          userName?: string | null;
        };
      }>,
    ) => {
      return {
        ...state,
        data: {
          ...(state?.data as any),
          tasks:
            state.data?.tasks?.map((item) => {
              if (item.id === action.payload.taskId) {
                return {
                  ...item,
                  status: action.payload.status,
                  statusUpdatedInBulkBy: {
                    userName: action.payload.statusUpdatedInBulkBy?.userName,
                  },
                };
              }
              return item;
            }) || [],
        },
      };
    },
    setCurrentTaskIdInView: (
      state,
      action: PayloadAction<{
        currentTaskIdInView?: number;
      }>,
    ) => {
      return {
        ...state,
        currentTaskIdInView: action.payload.currentTaskIdInView,
      };
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      tasksApi.endpoints.getAllTasks.matchFulfilled,
      (state, { payload }) => {
        // this.reducers.setCurrentTaskIdInView(state, {
        //   payload: {
        //     currentTaskIdInView: payload.data?.tasks?.[0]?.id,
        //   },
        //   type: 'taskSlice/setCurrentTaskIdInView',
        // });
        return payload;
      },
    );
  },
});

export const { updateTasksList, setCurrentTaskIdInView } = taskSlice.actions;

export const selectTasksData = (state: RootState) => state.tasksData;

export const selectCurrentTaskIdInView = (state: RootState) =>
  state.tasksData.currentTaskIdInView;

export default taskSlice.reducer;
