import { Roles } from 'types/global';

enum PagePermissionsList {
  viewAllTaskPage,
  userManagementPage,
  cspManagementPage,
  studentDashBaord,
}

const pageRolesPermissionMapping: { [key in Roles]: PagePermissionsList[] } = {
  [Roles.zonalManager]: [
    PagePermissionsList.userManagementPage,
    PagePermissionsList.viewAllTaskPage,
    PagePermissionsList.studentDashBaord,
  ],
  [Roles.visaTeam]: [
    PagePermissionsList.userManagementPage,
    PagePermissionsList.viewAllTaskPage,
    PagePermissionsList.studentDashBaord,
  ],
  [Roles.countryHead]: [
    PagePermissionsList.userManagementPage,
    PagePermissionsList.viewAllTaskPage,
    PagePermissionsList.studentDashBaord,
  ],
  [Roles.gbInvoicing]: [
    PagePermissionsList.userManagementPage,
    PagePermissionsList.viewAllTaskPage,
    PagePermissionsList.studentDashBaord,
  ],
  [Roles.representative]: [
    PagePermissionsList.userManagementPage,
    PagePermissionsList.cspManagementPage,
    PagePermissionsList.viewAllTaskPage,
    PagePermissionsList.studentDashBaord,
  ],
  [Roles.counsellor]: [
    PagePermissionsList.userManagementPage,
    PagePermissionsList.viewAllTaskPage,
    PagePermissionsList.studentDashBaord,
  ],
  [Roles.admin]: [
    PagePermissionsList.viewAllTaskPage,
    PagePermissionsList.studentDashBaord,
  ],
  [Roles.viewer]: [
    PagePermissionsList.viewAllTaskPage,
    PagePermissionsList.studentDashBaord,
  ],
  [Roles.headCustomerCare]: [
    PagePermissionsList.viewAllTaskPage,
    PagePermissionsList.studentDashBaord,
  ],
  [Roles.opsManager]: [
    PagePermissionsList.viewAllTaskPage,
    PagePermissionsList.studentDashBaord,
  ],
  [Roles.qc]: [
    PagePermissionsList.viewAllTaskPage,
    PagePermissionsList.studentDashBaord,
  ],
};

export { pageRolesPermissionMapping, PagePermissionsList, Roles };
