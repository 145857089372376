const updateVisaData = (payload: any) => ({
  type: `UPDATE_VISA_DATA`,
  payload,
});
const updateVisaDataSuccess = (payload: any) => ({
  type: `UPDATE_VISA_DATA_SUCCESS`,
  payload,
});
const updateVisaDataFailure = (payload: any) => ({
  type: `UPDATE_VISA_DATA_FAILURE`,
  payload,
});

export const visaManagementActions = {
  updateVisaData,
  updateVisaDataSuccess,
  updateVisaDataFailure,
};
