import React from 'react';
import { type DocumentChipProps } from '@leapfinance/frontend-commons/components/geebee/DocumentChip';
import { DOCUMENT_ACTION_TYPES } from '@leapfinance/frontend-commons/constants/common';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import DocumentChipWrapper from '../Application/QCForm/DocumentChipWrapper';

function GicDocumentChipWrapper(
  props: DocumentChipProps & { hideDelete?: boolean },
) {
  return (
    <div className="[&>.lfc-document-chip-container]:justify-between [&>.lfc-document-chip-container]:border-grey-500 [&>.lfc-document-chip-container]:m-0 [&>.lfc-document-chip-container]:rounded-lg [&>.lfc-document-chip-container>p]:py-2 [&>.lfc-document-chip-container]:px-3 w-full">
      <DocumentChipWrapper
        {...props}
        displayActions={[
          ...(props?.hideDelete ? [] : [DOCUMENT_ACTION_TYPES.DELETE]),
          ...(props?.documentMeta?.url
            ? [DOCUMENT_ACTION_TYPES.DOWNLOAD, DOCUMENT_ACTION_TYPES.VIEW]
            : []),
        ]}
        noUnderline
        alwaysVisible
        downloadIcon={
          <FileDownloadOutlinedIcon className="text-grey-900 font-light hover:translate-y-[-3px] transition-all cursor-pointer" />
        }
        deleteIcon={
          <DeleteOutlineOutlinedIcon className="text-grey-900 font-light hover:translate-y-[-3px] transition-all cursor-pointer" />
        }
        viewIcon={
          <VisibilityOutlinedIcon className="text-grey-900 font-light hover:translate-y-[-3px] transition-all cursor-pointer" />
        }
      />
    </div>
  );
}

export default GicDocumentChipWrapper;
