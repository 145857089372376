export enum Roles {
  opsManager = `GB_OPS_MANAGER`,
  zonalManager = `GB_ZONAL_MANAGER`,
  admin = `GB_ADMIN`,
  counsellor = `GB_COUNSELLOR`,
  countryHead = `GB_COUNTRY_HEAD`,
  headCustomerCare = `GB_HEAD_CUSTOMER_CARE`,
  representative = `GB_REPRESENTATIVE`,
  viewer = `GB_VIEWER`,
  qc = `GB_QC_TEAM`,
  visaTeam = `GB_VISA_TEAM`,
  gbInvoicing = `GB_INVOICING`,
}
