import React, { useMemo, useRef } from 'react';
import { Form } from 'react-final-form';
import {
  isFalsy,
  useFinalFormYupFormHelpers,
} from '@leapfinance/frontend-commons';
import {
  Button,
  FFAsyncDropdown,
  FFCheckboxes,
  FFDropdown,
  FFRadioGroup,
  FFTextInput,
  GBTypography,
} from '@leapfinance/geebee-component-library';
import { type FormApi } from 'final-form';
import omit from 'lodash/omit';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';

import { selectList } from '@/app/features/sharedList/sharedListSlice';
import { selectUserDetails } from '@/app/features/user/userSlice';
import { useAppSelector } from '@/app/hooks';
import useCreateStudent from '@/hooks/vas/useCreateStudent';
import useUniversitiesOptions from '@/hooks/vas/useUniversitiesOptions';
import { useCreateGBLoansRequestMutation } from '@/services/vas';
import { VASCreateLoansRequestBodyType } from '@/types/vas/loan';
import {
  LoansFormSchema,
  VASStudentCreationSchema,
} from '@/validations/vas.schema';

import { useGetSourceCountriesQuery } from '../CF/Dashboard/services/geebeeCf';
import { transformOptionValues } from '../Student/StudentProfileV1/helpers';

import FFVASStudentField from './FFVASStudentField';
import {
  getAddNewStudentButtonStyles,
  getVasRequestFormTitle,
} from './helpers';
import VASDrawerHeader from './VASDrawerHeader';
import VASSuccessScreen from './VASSuccessScreen';

// import { useGetSourceCountriesQuery } from '../../CF/Dashbaord/services/geebeeCf';
// import { transformOptionValues } from '../../StudentProfileSection/helpers';
// import { getVasRequestFormTitle } from '../helpers';
// import FFVASStudentField from '../sharedComponents/FFVASStudentField';
// import VASDrawerHeader from '../sharedComponents/VASDrawerHeader';
// import VASSuccessScreen from '../sharedComponents/VASSuccessScreen';

export type LoansFormProps = {
  studentId?: number;
  student?: string;
  initialValues?: Partial<Record<keyof VASCreateLoansRequestBodyType, any>>;
  cspId?: number;
  cspName?: string;
  onClose: () => void;
};

function VASLoansFormController({
  student,
  studentId,
  initialValues = {},
  cspId,
  cspName,
  onClose,
}: LoansFormProps) {
  const router = useRouter();
  const user = useAppSelector((state) => selectUserDetails(state));
  const formRef: React.MutableRefObject<FormApi<any, any> | null> =
    useRef(null);
  const [validate, formNames] = useFinalFormYupFormHelpers({
    schema: LoansFormSchema.concat(VASStudentCreationSchema),
  });

  const { loanType } = useAppSelector((state) => selectList(state));
  const [fetchUniversities] = useUniversitiesOptions({
    serviceCategory: `LOAN`,
  });
  const { handleCreateNewStudent } = useCreateStudent();
  const { data: sourceCountryData } = useGetSourceCountriesQuery();
  const sourceCountries =
    sourceCountryData?.data?.map((country) => {
      return {
        label: country?.displayName ?? ``,
        value: country?.displayName ?? ``,
      };
    }) ?? [];
  const [triggerCreateLoanRequest, { data, isLoading: isCreatingLoanRequest }] =
    useCreateGBLoansRequestMutation();
  const { enqueueSnackbar } = useSnackbar();
  const initialValuesData = useMemo(() => {
    if (isFalsy(initialValues))
      return {
        createStudentMode: `no`,
      };

    return {
      student: student
        ? {
            label: student,
            value: student,
          }
        : null,
      [formNames.contactNumber]: initialValues.contactNumber,
      [formNames.countryCode]: {
        label: initialValues.countryCode,
        value: initialValues.countryCode,
      },
      [formNames.emailId]: initialValues.emailId,
      [formNames.cspId]: {
        label: cspName,
        value: cspId,
      },
      createStudentMode: `no`,
    };
  }, [initialValues]);

  const handleSubmit = async (values: any) => {
    try {
      let newStudentDetails;
      if (values?.createStudentMode === `yes`) {
        newStudentDetails = await handleCreateNewStudent(values);
      }

      const studentIdValue =
        values?.createStudentMode === `yes`
          ? newStudentDetails?.value ?? -1
          : studentId ?? values?.student?.value ?? -1;

      const response = await triggerCreateLoanRequest({
        studentId:
          values?.createStudentMode === `yes`
            ? newStudentDetails?.value ?? -1
            : studentId ?? values?.student?.value ?? -1,
        ...transformOptionValues(
          omit(values, [`student`, `permissionRequired`]),
        ),
      }).unwrap();

      if (response.success) {
        enqueueSnackbar(`Raised loan request successfully`, {
          variant: `success`,
        });
        if (
          !(
            router.pathname === `/student/[studentId]` &&
            router.query.studentId === studentIdValue &&
            router.query.active === `VAS`
          )
        ) {
          router.push(`/student/${studentIdValue}?active=VAS`);
        }
      } else {
        enqueueSnackbar(`Unable to raise loan request`, {
          variant: `error`,
        });
      }
    } catch (err) {
      enqueueSnackbar(`Unable to raise loan request`, {
        variant: `error`,
      });
    } finally {
      if (!isFalsy(initialValuesData?.student)) {
        onClose();
      }
    }
  };

  const getInitialBottomMargin = (touched: any, errors: any) => {
    // if any field is not touched and there are errors then mb-28 else mb-16
    const fields = Object.keys(touched);
    const hasErrors = Object.keys(errors).length > 0;
    if (fields.some((field) => touched[field]) && hasErrors) {
      return `mb-28`;
    }
    return `mb-16`;
  };

  return (
    <div className="relative h-full">
      <div className="h-full overflow-hidden overflow-y-auto pb-24">
        <VASDrawerHeader
          title={getVasRequestFormTitle(`loan`)}
          onClose={onClose}
        />
        <Form<
          VASCreateLoansRequestBodyType & {
            createStudentMode: 'yes' | 'no';
            firstName: string;
            lastName: string;
            student: {
              label: string;
              value: string | number;
              countryCode: string;
              contactNumber: string;
              email: string;
            };
          }
        >
          initialValues={initialValuesData as any}
          validate={validate as any}
          onSubmit={handleSubmit}
          render={({ form, initialValues, values, touched, errors }) => {
            const shouldDisableStudentField = !isFalsy(
              initialValues?.student?.value,
            );

            formRef.current = form;
            return data?.success && data?.data ? (
              <VASSuccessScreen
                successScreenProps={{
                  title: `Loan request received!`,
                }}
                trackRequestStatusCardProps={{
                  type: `loan`,
                  onClick: () => {
                    window.open(`/vas/dashboard`, `_blank`);
                  },
                }}
              />
            ) : (
              <form
                className={`w-[40rem] px-4 pt-6 ${getInitialBottomMargin(
                  touched,
                  errors,
                )} relative h-full`}
              >
                <FFVASStudentField
                  dobFieldProps={{
                    name: formNames.dob,
                  }}
                  genderFieldProps={{
                    name: formNames.gender,
                  }}
                  maritalStatusFieldProps={{
                    name: formNames.maritalStatus,
                  }}
                  cspFieldProps={{
                    name: formNames.cspId,
                  }}
                  contactNumberFieldProps={{
                    countryCodeFieldProps: {
                      name: formNames.countryCode,
                    },
                    phoneNumberFieldProps: {
                      name: formNames.contactNumber,
                    },
                    countryCodeComponentProps: {
                      // disabled: shouldDisableStudentField,
                      options: [],
                    },
                    phoneNumberComponentProps: {
                      // disabled: shouldDisableCountryCodeField,
                    },
                    formValues: values,
                  }}
                  createOrSearchStudentFieldProps={{
                    studentFieldProps: {
                      name: formNames.student,
                    },
                    firstNameFieldProps: {
                      name: formNames.firstName,
                    },
                    lastNameFieldProps: {
                      name: formNames.lastName,
                    },
                    studentcomponentProps: {
                      disabled: shouldDisableStudentField,
                      options: [],
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      fetchOptionsHandler: () => {},
                    },
                    addStudentLabelButtonProps: {
                      disabled: shouldDisableStudentField,
                      className: getAddNewStudentButtonStyles(
                        user,
                        shouldDisableStudentField,
                      ),
                    },
                    addStudentIconProps: {
                      className: `text-grey-400`,
                    },
                  }}
                  emailFieldProps={{
                    name: formNames.emailId,
                  }}
                  serviceCategory="LOAN"
                  studentId={studentId ?? (values as any)?.student?.value}
                />
                <div className="w-full pt-4">
                  <FFTextInput
                    fieldProps={{
                      name: formNames.emailId,
                    }}
                    componentProps={{
                      placeholder: `Please enter student’s email id.`,
                      label: `Email Id`,
                    }}
                  />
                </div>
                <div className="w-full pt-4">
                  <FFDropdown
                    fieldProps={{
                      name: formNames.destinationCountry,
                    }}
                    componentProps={{
                      options: sourceCountries,
                      textInputProps: {
                        label: `Destination Country`,
                        placeholder: `Please enter student's destination country`,
                      },
                    }}
                  />
                </div>
                <div className="w-full pt-4">
                  <FFAsyncDropdown
                    fieldProps={{
                      name: formNames.universityName,
                    }}
                    componentProps={{
                      options: [],
                      // @ts-ignore
                      fetchOptionsHandler: (
                        request: {
                          input: string;
                        },
                        callback: any,
                      ) => {
                        fetchUniversities(request.input, callback);
                      },
                      textInputProps: {
                        label: `Select a University`,
                        placeholder: `Please enter university`,
                      },
                      disableClearable: false,
                      creatable: true,
                    }}
                  />
                </div>
                <div className="w-full pt-4">
                  <FFRadioGroup
                    fieldProps={{
                      name: formNames.type,
                    }}
                    componentProps={{
                      label: (
                        <GBTypography variant="sh2Semibold" className="pb-2">
                          Type of Loan
                        </GBTypography>
                      ),
                      row: false,
                      variant: `radio-tab`,
                      options:
                        loanType?.map((type) => ({
                          label: type?.labelValue ?? ``,
                          value: type?.labelKey ?? ``,
                        })) ?? [],
                    }}
                  />
                </div>
                <div className="w-full self-end pt-6">
                  <FFCheckboxes
                    fieldProps={{
                      name: formNames.permissionRequired,
                      validate: (value) => {
                        if (isFalsy(value)) {
                          return `Please accept the condition`;
                        }
                      },
                    }}
                    componentProps={{
                      options: [
                        {
                          label: `I hereby give my consent to share the provided details and necessary documents with the bank. I also agree that GB can contact the student for further discussions regarding my loan application.`,
                          value: `true`,
                        },
                      ],
                    }}
                  />
                </div>
              </form>
            );
          }}
        />
      </div>
      {data?.success && data?.data ? (
        <></>
      ) : (
        <div className="w-full absolute bottom-0 right-0 p-2 bg-white shadow-t-lg z-50">
          <div className="flex justify-center">
            <Button
              onClick={() => {
                formRef.current?.submit();
              }}
              loading={isCreatingLoanRequest}
              className="w-1/3 py-2"
            >
              Raise Request
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default VASLoansFormController;
