import { createStore, applyMiddleware } from "redux";
import { MakeStore, createWrapper, Context } from "next-redux-wrapper";
import createSagaMiddleware from "redux-saga";
import { rootReducer } from "redux/rootReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import rootSaga from "redux/rootSaga";
import { sagaMiddleware, store } from "app/store";

export const makeStore: MakeStore<any> = (context: Context) => {
  (store as any).sagaTask = sagaMiddleware.run(rootSaga);

  // 4: now return the store:
  return store;
};

// export an assembled wrapper
export const wrapper = createWrapper<any>(makeStore, { debug: false });
