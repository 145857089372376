import React from 'react';
import DocumentChip, {
  type DocumentChipProps,
} from '@leapfinance/frontend-commons/components/geebee/DocumentChip';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

const DocumentChipWrapper: React.FC<DocumentChipProps> = (props) => {
  return (
    <DocumentChip
      className={`rounded-md border-gray-500  ${props?.className ?? ``}`}
      downloadIcon={
        <FileDownloadOutlinedIcon className="text-primary-500 hover:translate-y-[-3px] transition-all cursor-pointer" />
      }
      deleteIcon={
        <DeleteOutlineOutlinedIcon className="text-primary-500 hover:translate-y-[-3px] transition-all cursor-pointer" />
      }
      viewIcon={
        <VisibilityOutlinedIcon className="text-primary-500 hover:translate-y-[-3px] transition-all cursor-pointer" />
      }
      {...props}
    />
  );
};

export default DocumentChipWrapper;
