import { Roles } from 'types/global';

/**
 * All the permissions related to applicationDashboard Resource.
 */
enum ApplicationDashboardPermissionsList {
  downloadCsv,
}

/**
 * Add permissions to roles respectivly
 */
const ApplicationDashboardRolesPermissionMapping: {
  [key in Roles]: ApplicationDashboardPermissionsList[];
} = {
  [Roles.zonalManager]: [ApplicationDashboardPermissionsList.downloadCsv],
  [Roles.visaTeam]: [ApplicationDashboardPermissionsList.downloadCsv],
  [Roles.countryHead]: [ApplicationDashboardPermissionsList.downloadCsv],
  [Roles.representative]: [ApplicationDashboardPermissionsList.downloadCsv],
  [Roles.counsellor]: [ApplicationDashboardPermissionsList.downloadCsv],
  [Roles.admin]: [],
  [Roles.viewer]: [ApplicationDashboardPermissionsList.downloadCsv],
  [Roles.headCustomerCare]: [ApplicationDashboardPermissionsList.downloadCsv],
  [Roles.opsManager]: [],
  [Roles.qc]: [],
  [Roles.gbInvoicing]: [ApplicationDashboardPermissionsList.downloadCsv],
};

export {
  ApplicationDashboardRolesPermissionMapping,
  ApplicationDashboardPermissionsList,
};
