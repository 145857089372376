import { useEffect } from 'react';
import { useSnackbar } from 'notistack';

const useInternetStatus = () => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const handleOnline = () => {
        enqueueSnackbar(`You are online`, {
          variant: `success`,
        });
      };
      const handleOffline = () => {
        enqueueSnackbar(
          `You are currently offline. Please check your internet connection`,
          {
            variant: `error`,
          },
        );
      };

      window.addEventListener(`online`, handleOnline);
      window.addEventListener(`offline`, handleOffline);

      return () => {
        window.removeEventListener(`online`, handleOnline);
        window.removeEventListener(`offline`, handleOffline);
      };
    }
  }, []);
};

export default useInternetStatus;
