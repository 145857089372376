import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import Link from 'next/link';

type Props = {
  paymentLink?: string;
};

function VASFlywireSuccessScreen({ paymentLink }: Props) {
  const [copied, setCopied] = useState(false);

  const handleCopyPaymentLink = async () => {
    setCopied(true);
    if (navigator && paymentLink) {
      await navigator.clipboard.writeText(paymentLink);
    }
    const id = setTimeout(() => {
      setCopied(false);
      clearTimeout(id);
    }, 1500);
  };
  return (
    <div className="border-[0.5px] border-primary-100 rounded-lg shadow-card-x1 py-3 px-4 flex flex-col gap-y-4 mx-4">
      <Typography variant="body2" className="text-gray-800">
        Your payment link has been generated successfully. You can copy the link
        and share with the student for making the payment
      </Typography>
      {paymentLink ? (
        <Link href={paymentLink ?? ``}>
          <a target="_blank">
            <Typography variant="body2" className="text-gray-800 underline">
              Payment Link
            </Typography>
          </a>
        </Link>
      ) : null}
      <Button
        variant={copied ? `contained` : `outlined`}
        color={copied ? undefined : `primary`}
        startIcon={
          <i
            className={`fi fi-rr-clone ${
              copied ? `text-white` : `text-primary-500`
            }`}
          ></i>
        }
        className={copied ? `bg-[#6DA559] p-2 w-1/2` : `rounded w-1/2`}
        onClick={handleCopyPaymentLink}
      >
        <Typography
          variant="subtitle2"
          className={`${
            copied ? `text-white whitespace-nowrap` : `text-primary-500`
          } normal-case`}
        >
          {copied ? `Copied payment link!!` : `Copy Payment Link`}
        </Typography>
      </Button>
    </div>
  );
}

export default VASFlywireSuccessScreen;
