import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';

const initialState: {
  documentsAttachedWithInstallment: Array<{
    transactionId: any;
    document: any;
  }>;
} = {
  documentsAttachedWithInstallment: [],
};

export const miscDocSlice = createSlice({
  name: `miscDocuments`,
  initialState,
  reducers: {
    initializeAttachedDocuments: (
      state,
      action: PayloadAction<
        Array<{
          transactionId: any;
          document: any;
        }>
      >,
    ) => {
      state.documentsAttachedWithInstallment = action.payload;
    },
    attachDocumentWithInstallment: (
      state,
      action: PayloadAction<{
        transactionId: any;
        document: any;
      }>,
    ) => {
      state.documentsAttachedWithInstallment = [
        ...state.documentsAttachedWithInstallment,
        action.payload,
      ];

      // if some document already exists and we attach it
      if (
        state.documentsAttachedWithInstallment.some(
          (obj) => obj[`transactionId`] === action.payload.transactionId,
        )
      ) {
        const installmentWithoutRecentlyAttachedDoc =
          state.documentsAttachedWithInstallment.filter(
            (item) => item.transactionId !== action.payload.transactionId,
          );
        state.documentsAttachedWithInstallment = [
          ...installmentWithoutRecentlyAttachedDoc,
          action.payload,
        ];
      } else {
        state.documentsAttachedWithInstallment = [
          ...state.documentsAttachedWithInstallment,
          action.payload,
        ];
      }
    },
    detachDocumentWithInstallment: (
      state,
      action: PayloadAction<{
        transactionId: any;
        document: any;
      }>,
    ) => {
      state.documentsAttachedWithInstallment =
        state.documentsAttachedWithInstallment.filter(
          (item) => item.transactionId !== action.payload.transactionId,
        );
    },
  },
});

export const {
  attachDocumentWithInstallment,
  detachDocumentWithInstallment,
  initializeAttachedDocuments,
} = miscDocSlice.actions;

export const selectAttachedDocuments = (state: RootState) =>
  state.miscDocuments.documentsAttachedWithInstallment;

export default miscDocSlice.reducer;
