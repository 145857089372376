export const downloadFileFromPresignedUrl = (
  props: Pick<HTMLAnchorElement, `href`> &
    Partial<Pick<HTMLAnchorElement, `download` | `target`>>,
) => {
  const { href, download, target } = props;
  const anchorTag = document.createElement(`a`);
  anchorTag.href = href;
  anchorTag.download = download || `my-file`;
  anchorTag.target = target || `_blank`;

  document.body.appendChild(anchorTag);
  anchorTag.click();
};
