import { useDispatch } from 'react-redux';
import { Animate } from '@leapfinance/frontend-commons';
import {
  Button,
  GBModalWrapper,
  GBTypography,
} from '@leapfinance/geebee-component-library';
import Image from 'next/image';
import { useSnackbar } from 'notistack';

import {
  setCurrentTaskIdInView,
  updateTasksList,
} from '@/app/features/tasks/taskSlice';
import { TaskStatus } from '@/components/task/task-manager/enums/task.enum';
import { Task } from '@/components/task/task-manager/types/task';
import { useUpdateTaskDetailsMutation } from '@/services/tasks';
export interface SuccessfulUpdateModalProps {
  onClose: () => void;
  toStatus: string;
  handleMarkAsDone: () => void;
  task?: Task;
}

const SuccessfulUpdateModal = ({
  onClose,
  toStatus,
  handleMarkAsDone,
  task,
}: SuccessfulUpdateModalProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [triggerUpdateTaskDetails] = useUpdateTaskDetailsMutation();
  const handleStatusChange =
    (task: Task) =>
    async (
      callback: () => void,
      status: TaskStatus,
      resolutionDate?: string,
    ) => {
      try {
        const response = await triggerUpdateTaskDetails({
          status,
          expectedResolutionDate: resolutionDate,
          taskType: task?.taskType as any,
          id: task?.id,
          entity: task.entity,
          entityId: task.entityId,
        }).unwrap();

        if (!response.success) {
          throw new Error(response.message);
        }
        dispatch(
          updateTasksList({
            taskId: task?.id,
            status,
            statusUpdatedInBulkBy: {
              userName: null,
            },
          }),
        );
        // this hack will figure out and refactor later
        setTimeout(() => {
          dispatch(
            setCurrentTaskIdInView({
              currentTaskIdInView: task?.id,
            }),
          );
        }, 200);
        enqueueSnackbar(`Status Changed`, {
          autoHideDuration: 2000,
          variant: `success`,
        });
        handleMarkAsDone();
        callback();
      } catch (error: any) {
        enqueueSnackbar(error.message, { variant: `error` });
      }
    };

  return (
    <GBModalWrapper
      headerProps={{
        title: ``,
        onClose: onClose,
      }}
    >
      <div className="p-16">
        <div className="flex flex-col gap-6 items-center">
          <Animate
            animationProps={{
              renderer: `svg`,
              path: `/assets/lottie/resolved.json`,
              loop: true,
            }}
            containerClasses={`h-[6.25rem] w-[6.25rem]`}
          />
          <GBTypography variant="sh1Semibold" className="text-success-600">
            Successfully Updated!
          </GBTypography>
          <GBTypography variant="body4">
            Application status has been successfully updated to {toStatus} and
            the CSP is notified.
          </GBTypography>
          <Button
            variant="primary"
            onClick={() => {
              if (task)
                handleStatusChange(task)(
                  () => {
                    onClose();
                  },
                  TaskStatus.DONE,
                  Date.now().toString(),
                );
            }}
          >
            Mark task as Done
          </Button>
        </div>
      </div>
    </GBModalWrapper>
  );
};

export default SuccessfulUpdateModal;
