import { UserTypes } from '../types/userManagment';

import { Roles } from './permissions/pagePermissions';

enum PermissionsList {
  userListPage,
  editPage,
  addPage,
  listOfReportees,
  leaveMangement,
  assignedCspsLsit,
  countryChange,
  reportingManagerView,
  addUserButton,
  deleteUser,
  editBasicDetails,
}

const authRoleAndUserTypeMapping: any = {
  [Roles.countryHead]: [
    UserTypes.zonalManager,
    UserTypes.counsellor,
    UserTypes.opsManager,
  ],
  [Roles.gbInvoicing]: [
    UserTypes.zonalManager,
    UserTypes.counsellor,
    UserTypes.opsManager,
  ],
  [Roles.zonalManager]: [UserTypes.counsellor, UserTypes.opsManager],
  [Roles.visaTeam]: [],
};

const rolesPermissionMapping: { [key in Roles]: PermissionsList[] } = {
  [Roles.zonalManager]: [
    PermissionsList.userListPage,
    PermissionsList.editPage,
    PermissionsList.assignedCspsLsit,
    PermissionsList.reportingManagerView,
    PermissionsList.editBasicDetails,
  ],
  [Roles.visaTeam]: [
    PermissionsList.userListPage,
    PermissionsList.editPage,
    PermissionsList.assignedCspsLsit,
    PermissionsList.reportingManagerView,
    PermissionsList.editBasicDetails,
  ],
  [Roles.countryHead]: [
    PermissionsList.userListPage,
    PermissionsList.editPage,
    PermissionsList.reportingManagerView,
    PermissionsList.editBasicDetails,
  ],
  [Roles.gbInvoicing]: [
    PermissionsList.userListPage,
    PermissionsList.editPage,
    PermissionsList.reportingManagerView,
    PermissionsList.editBasicDetails,
  ],
  [Roles.representative]: [
    PermissionsList.userListPage,
    PermissionsList.addPage,
    PermissionsList.editPage,
    PermissionsList.countryChange,
    PermissionsList.reportingManagerView,
    PermissionsList.addUserButton,
    PermissionsList.deleteUser,
    PermissionsList.editBasicDetails,
  ],
  [Roles.counsellor]: [
    PermissionsList.userListPage,
    PermissionsList.editPage,
    PermissionsList.listOfReportees,
    PermissionsList.assignedCspsLsit,
  ],
  [Roles.admin]: [],
  [Roles.viewer]: [],
  [Roles.headCustomerCare]: [],
  [Roles.opsManager]: [],
  [Roles.qc]: [],
};

const hasPermission = (permissions: PermissionsList[]): boolean =>
  permissions.some((r) => permissions.includes(r));

export {
  rolesPermissionMapping,
  PermissionsList,
  Roles,
  authRoleAndUserTypeMapping,
  hasPermission,
};
