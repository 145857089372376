import { isFalsy } from '@leapfinance/frontend-commons';
import { FetchArgs } from '@reduxjs/toolkit/query';

import { geebeeRootBaseApiWithTags } from '@/services/base/geebeeApi';
import { Document } from '@/types/documentSection';
import {
  BaseResponse,
  CreateGBLoansRequestType,
  CreateGBLoansResponseType,
  GetGBLoansRequestType,
  GetGBLoansResponseType,
  UpdateGBLoansRequestType,
  UpdateGBLoansResponseType,
  VASGetGICRequestRaisedRequestType,
  VASGetGICRequestRaisedResponseType,
  VASUpdateGICRequestRaisedRequestType,
  VASUpdateGICRequestRaisedResponseType,
  VASUploadGICDocumentsRequestType,
  VASUploadGICDocumentsResponseType,
} from '@/types/Services';
import {
  ServiceCategoryTypes,
  VASRequestDashboardRequestBodyType,
  VASRequestDashboardResponseType,
} from '@/types/vas';
import {
  BooleanResponseType,
  VASCreateAccomodationRequestBodyType,
  VASGetAccomodationResponseType,
  VASUpdateAccomodationRequestBodyType,
} from '@/types/vas/accomodation';
import {
  VASCreateFlywirePaymentLinkAPIRequestBodyType,
  VASCreateFlywirePaymentLinkAPIResponseBodyType,
  VASFlywireDuplicateRequestCheckRequestType,
  VASFlywireDuplicateRequestCheckResponseType,
  VASGetPaymentRequestResponseType,
} from '@/types/vas/flywire';
import {
  VASCreateGICRequestBodyType,
  VASGetGICBankNamesResponseType,
  VASGetGICReferralCodeType,
  VASGetGICResponseType,
  VASUpdateGICRequestBodyType,
} from '@/types/vas/gic';
import { transformResponse } from '@/utils/common';

import { documentUploadBaseApi } from './base/documentUpload';

export const vasApi = geebeeRootBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    createAccomodationRequest: builder.mutation<
      BooleanResponseType,
      {
        body: VASCreateAccomodationRequestBodyType;
        studentId: number;
      }
    >({
      query: ({ studentId, body }) => ({
        url: `/accommodation-request/${studentId}`,
        method: `POST`,
        body,
      }),
      invalidatesTags: [`AccomodationRequest`],
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as BooleanResponseType;
        } else {
          return {
            data,
            success: true,
            message: null,
          } as unknown as BooleanResponseType;
        }
      },
    }),

    getAccomodationRequestInfo: builder.query<
      VASGetAccomodationResponseType,
      {
        studentId: number;
      }
    >({
      query: ({ studentId }) => ({
        url: `/accommodation-request/${studentId}`,
        method: `GET`,
      }),
      providesTags: [`AccomodationRequest`],
    }),

    updateAccomodationRequest: builder.mutation<
      BooleanResponseType,
      {
        body: VASUpdateAccomodationRequestBodyType;
        studentId: number;
      }
    >({
      query: ({ studentId, body }) => ({
        url: `/accommodation-request/${studentId}`,
        method: `PUT`,
        body,
      }),
      invalidatesTags: [`AccomodationRequest`],
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as BooleanResponseType;
        } else {
          return {
            data,
            success: true,
            message: null,
          } as unknown as BooleanResponseType;
        }
      },
    }),
    getVASRequests: builder.mutation<
      VASRequestDashboardResponseType,
      VASRequestDashboardRequestBodyType
    >({
      query: (body) => ({
        url: `/vas-request/search`,
        method: `POST`,
        body: {
          pageNumber: 0,
          pageSize: 10,
          ...body,
        },
      }),
      invalidatesTags: [`AccomodationRequest`, `GICRequest`, `LoansRequest`],
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as VASRequestDashboardResponseType;
        } else {
          return {
            data,
            success: true,
            message: null,
          } as unknown as VASRequestDashboardResponseType;
        }
      },
    }),
    markCallbackAsDone: builder.mutation<
      BooleanResponseType,
      {
        serviceCategory: ServiceCategoryTypes;
        studentId: number;
        requestId: string;
      }
    >({
      query: ({ studentId, serviceCategory, requestId }) => ({
        url: `/vas-request/${studentId}/service-category/${serviceCategory}/mark-call-back-request-as-done?requestId=${requestId}`,
        method: `PUT`,
      }),
      invalidatesTags: [`AccomodationRequest`, `GICRequest`, `LoansRequest`],
    }),
    checkDuplicateVASRequest: builder.query<
      BooleanResponseType,
      {
        studentId: number;
        serviceCategory: ServiceCategoryTypes;
      }
    >({
      query: ({ studentId, serviceCategory }) => ({
        url: `/vas-request/${studentId}/service-category/${serviceCategory}/check-request-status`,
        method: `GET`,
      }),
      providesTags: [`AccomodationRequest`, `GICRequest`, `LoansRequest`],
    }),
    createGICRequest: builder.mutation<
      BooleanResponseType,
      {
        body: VASCreateGICRequestBodyType;
        studentId: number;
      }
    >({
      query: ({ studentId, body }) => ({
        url: `/gic-request/${studentId}`,
        method: `POST`,
        body,
      }),
      invalidatesTags: [`GICRequest`],
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as BooleanResponseType;
        } else {
          return {
            data,
            success: true,
            message: null,
          } as unknown as BooleanResponseType;
        }
      },
    }),
    getGICBankNames: builder.query<VASGetGICBankNamesResponseType, void>({
      query: () => ({
        url: `/gic-request/bank-details`,
        method: `GET`,
      }),
    }),

    getGICRequestInfo: builder.query<
      VASGetGICResponseType,
      {
        studentId: number;
      }
    >({
      query: ({ studentId }) => ({
        url: `/gic-request/${studentId}`,
        method: `GET`,
      }),
      providesTags: [`GICRequest`],
    }),

    updateGICRequest: builder.mutation<
      BooleanResponseType,
      {
        body: VASUpdateGICRequestBodyType;
        studentId: number;
      }
    >({
      query: ({ studentId, body }) => ({
        url: `/gic-request/${studentId}`,
        method: `PUT`,
        body,
      }),
      invalidatesTags: [`GICRequest`],
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as BooleanResponseType;
        } else {
          return {
            data,
            success: true,
            message: null,
          } as unknown as BooleanResponseType;
        }
      },
    }),
    getGICRequestReferralCode: builder.query<VASGetGICReferralCodeType, void>({
      query: () => ({
        url: `/gic-request/referral-code`,
        method: `GET`,
      }),
    }),
    createFlywirePaymentLink: builder.mutation<
      VASCreateFlywirePaymentLinkAPIResponseBodyType,
      VASCreateFlywirePaymentLinkAPIRequestBodyType
    >({
      query: (body) => ({
        url: `/payment-request/create`,
        method: `POST`,
        body,
      }),
      invalidatesTags: [`FlywireRequest`],
      // transformResponse: ({ success, data, message }) => {
      //   if (!success) {
      //     return {
      //       data: null,
      //       message,
      //       apiError: true,
      //     } as unknown as VASCreateFlywirePaymentLinkAPIResponseBodyType;
      //   } else {
      //     return {
      //       data,
      //       success: true,
      //       message: null,
      //     } as unknown as VASCreateFlywirePaymentLinkAPIResponseBodyType;
      //   }
      // },
    }),
    getFlywirePaymentRequest: builder.query<
      VASGetPaymentRequestResponseType,
      {
        studentId: number;
        applicationId?: number;
      }
    >({
      query: ({ studentId, applicationId }) => ({
        url: `/payment-request/${studentId}${
          !isFalsy(applicationId) ? `?applicationId=${applicationId}` : ``
        }`,
        method: `GET`,
      }),
      providesTags: [`FlywireRequest`],
    }),
    archiveFlywirePaymentRequest: builder.mutation<
      BooleanResponseType,
      {
        paymentId: string;
      }
    >({
      query: ({ paymentId }) => ({
        url: `/payment-request/archive/${paymentId}`,
        method: `PUT`,
      }),
      invalidatesTags: [`FlywireRequest`],
    }),
    getDuplicateFlywireCheckQuery: builder.query<
      VASFlywireDuplicateRequestCheckResponseType,
      VASFlywireDuplicateRequestCheckRequestType
    >({
      query: ({ emailId, universityId }) => ({
        url: `/payment-request/search/${emailId}/university/${universityId}`,
      }),
    }),

    getStudentsGicRequiredDocuments: builder.query<
      BaseResponse<Document[]>,
      { studentId: number }
    >({
      query: ({ studentId }) => ({
        url: `/gic-request/${studentId}/required-documents`,
      }),
      transformResponse: transformResponse,
    }),
    updateGICRequestRaisedStatus: builder.mutation<
      VASUpdateGICRequestRaisedResponseType,
      VASUpdateGICRequestRaisedRequestType
    >({
      query: (body) => ({
        url: `/gic-request/gic-raised`,
        method: `POST`,
        body,
      }),
      invalidatesTags: [`GICRequestRaised`],
    }),
    getGICRequestRaisedStatus: builder.query<
      VASGetGICRequestRaisedResponseType,
      VASGetGICRequestRaisedRequestType
    >({
      query: ({ studentId }) => ({
        url: `/gic-request/${studentId}/gic-raised`,
      }),
      transformResponse: transformResponse,
      providesTags: [`GICRequestRaised`],
    }),
    createGBLoansRequest: builder.mutation<
      CreateGBLoansResponseType,
      CreateGBLoansRequestType
    >({
      query: ({ studentId, ...body }) => ({
        url: `/loan-request/${studentId}`,
        method: `POST`,
        body,
      }),
      invalidatesTags: [`LoansRequest`],
      transformResponse: transformResponse,
    }),
    getGBLoansRequest: builder.query<
      GetGBLoansResponseType,
      GetGBLoansRequestType
    >({
      query: ({ studentId }) => ({
        url: `/loan-request/${studentId}`,
      }),
      providesTags: [`LoansRequest`],
      transformResponse: transformResponse,
    }),
    // update gb loans request
    updateGBLoansRequest: builder.mutation<
      UpdateGBLoansResponseType,
      UpdateGBLoansRequestType
    >({
      query: ({ studentId, ...body }) => ({
        url: `/loan-request/${studentId}`,
        method: `PUT`,
        body,
      }),
      invalidatesTags: [`LoansRequest`],
      transformResponse: transformResponse,
    }),
  }),
});

export const vasDocumentApi = documentUploadBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadGicLoaDocument: builder.mutation<
      BaseResponse<any>,
      {
        file: File;
        studentId: number;
      }
    >({
      query: ({ file, studentId }) => {
        const formData = new FormData();
        formData.append(`file`, file);
        return {
          url: `/gic-request/${studentId}/upload-loa-document`,
          body: formData,
          method: `POST`,
        } as FetchArgs;
      },
      transformResponse: transformResponse,
    }),
    uploadGICGenericDocuments: builder.mutation<
      VASUploadGICDocumentsResponseType,
      VASUploadGICDocumentsRequestType
    >({
      query: ({ file, documentType, vasRequestId }) => {
        const formData = new FormData();
        formData.append(`file`, file as any);
        return {
          url: `/gic-request/${vasRequestId}/upload-gic-documents/${documentType}`,
          body: formData,
          method: `POST`,
        } as FetchArgs;
      },
      transformResponse: transformResponse,
    }),
  }),
});

export const {
  useCreateAccomodationRequestMutation,
  useGetAccomodationRequestInfoQuery,
  useUpdateAccomodationRequestMutation,
  useGetVASRequestsMutation,
  useMarkCallbackAsDoneMutation,
  useCheckDuplicateVASRequestQuery,
  useCreateGICRequestMutation,
  useGetGICBankNamesQuery,
  useGetGICRequestInfoQuery,
  useUpdateGICRequestMutation,
  useGetGICRequestReferralCodeQuery,
  // useUploadFundingInstructionDocumentMutation
  useCreateFlywirePaymentLinkMutation,
  useGetFlywirePaymentRequestQuery,
  useArchiveFlywirePaymentRequestMutation,
  useGetDuplicateFlywireCheckQueryQuery,
  useLazyGetDuplicateFlywireCheckQueryQuery,
  useLazyGetStudentsGicRequiredDocumentsQuery,
  useGetGICRequestRaisedStatusQuery,
  useUpdateGICRequestRaisedStatusMutation,
  useCreateGBLoansRequestMutation,
  useGetGBLoansRequestQuery,
  useUpdateGBLoansRequestMutation,
} = vasApi;

export const {
  useUploadGicLoaDocumentMutation,
  useUploadGICGenericDocumentsMutation,
} = vasDocumentApi;
