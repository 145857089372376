import { OPTION_TYPE } from '@leapfinance/frontend-commons/schema/common';
import { format } from 'date-fns';

import { transformOptionValues } from '@/components/CF/Dashboard/helpers';
import { GroupedIntakes } from '@/types/formUtilities';
import { definitions } from '@/types/schema';
import { GetSummaryDashboardResponseType } from '@/types/Services';
import { LocalStorageKeysEnum, QueryParams } from '@/utils/constants';
import { getFutureIntakeOptionsFromNow, monthArr } from '@/utils/formHelpers';

import { ActionLabel } from './types';

export enum TaskMenu {
  FIVE_DAYS = `fiveDays`,
  TWENTY_DAYS = `twentyDays`,
  DO_NOT_SHOW = `doNotShow`,
}

export type Identifier =
  | Exclude<
      GetSummaryDashboardResponseType[`data`],
      undefined
    >[`summaryDashboard`][number][`name`]
  | Exclude<
      GetSummaryDashboardResponseType[`data`],
      undefined
    >[`summaryDashboard`][number][`subBuckets`][number][`name`];

export const convertFiltersForRedirection = (
  filterValues: Record<string, any>,
) => {
  const transformedValues = transformOptionValues(filterValues);
  const arrayTypeFilters: any = [];
  Object?.keys(transformedValues)?.forEach((key) => {
    arrayTypeFilters?.push({
      key: key,
      value: transformedValues[key],
    });
  });
  return arrayTypeFilters;
};

export const getStageCardUrl = (
  entity?: ActionLabel[`entity`],
  filters?: Record<string, any>[],
) => {
  if (!entity) return `/`;
  let baseUrl;
  switch (entity) {
    case `GIC`:
    case `APPLICATION`:
      baseUrl = `/dashboard/applications`;
      break;
    case `STUDENT`:
      baseUrl = `/dashboard/students`;
      break;
    default:
      break;
  }
  if (!baseUrl) return `/dashboard/applications`;

  if (filters) {
    localStorage.setItem(
      LocalStorageKeysEnum.DASHBOARD_NAVIGATION_FILTERS,
      JSON.stringify(filters),
    );
    baseUrl += `?${QueryParams.APPLY_DASHBOARD_FILTERS}=${true}`;
  }

  return baseUrl;
};

export const getFiltersInDashboardFormat = (
  savedFilterValues: Record<string, any>,
) => {
  return Object.keys(savedFilterValues).map((key) => ({
    key,
    value: (savedFilterValues as any)?.[key] ?? [],
  }));
};

export const snoozeMenuitems = [
  {
    label: `Show after 5 Days`,
    value: TaskMenu.FIVE_DAYS,
  },
  {
    label: `Show after 20 days`,
    value: TaskMenu.TWENTY_DAYS,
  },
  { label: `Do not Show`, value: TaskMenu.DO_NOT_SHOW },
];

export const transformIntakes = (intakeCount: number) => {
  return getFutureIntakeOptionsFromNow(intakeCount).map((item) => {
    try {
      return {
        label: item.label,
        value: JSON.stringify(item.children.map((intake) => intake.value)),
      };
    } catch (err) {
      return {
        label: ``,
        value: ``,
      };
    }
  });
};

export const transformIntakesForApiPayload = (
  intakes: Array<{ label: string; value: string }>,
) => {
  return intakes
    .map((item) => {
      try {
        return JSON.parse(item.value ?? ``);
      } catch (err) {
        return [];
      }
    })
    .flat();
};

interface IgetIntakeOptions {
  fromCurrentMonth?: boolean;
}

const getIntakeOptions = (props?: IgetIntakeOptions): OPTION_TYPE[] => {
  const currentYear = Number(format(new Date(), `yyyy`));
  const intakeOptions: OPTION_TYPE[] = [];
  for (let year = currentYear - 2; year < currentYear + 5; year++) {
    const options = [];
    for (const month of monthArr) {
      options.push({
        label: `${month} ${year}`,
        value: `${month.toUpperCase()}_${year}`,
      });
    }
    intakeOptions.push(...[...options]);
  }
  if (props && props.fromCurrentMonth) {
    const currentMonth = Number(new Date().getMonth());
    return intakeOptions.slice(24 + currentMonth);
  }
  return intakeOptions;
};

export const getGroupedIntakesForDashboard = (): {
  intakes: OPTION_TYPE[];
  groupedIntakes: GroupedIntakes[];
} => {
  let startYear: number = Number(format(new Date(), `yy`)) - 1;
  const intakes: OPTION_TYPE[] = getIntakeOptions().slice(11);
  const groupedIntakes: GroupedIntakes[] = [];
  let div = 0;
  for (let i = 1; i < intakes.length; i += 12) {
    const group: any = {};
    group[`title`] = `${startYear} Intakes`;
    group[`intakeGroups`] = [
      {
        title: `Dec ${startYear - 1} - Mar ${startYear}`,
        months: intakes.slice(div, (div += 4)),
      },
      {
        title: `Apr ${startYear} - July ${startYear}`,
        months: intakes.slice(div, (div += 4)),
      },
      {
        title: `Aug ${startYear} - Nov ${startYear}`,
        months: intakes.slice(div, (div += 4)),
      },
    ];
    startYear++;
    groupedIntakes.push(group);
  }

  return { intakes, groupedIntakes };
};

export const transformCspOptionsLabel = (
  cspList?: definitions['CspAccountInfo'][],
) => {
  return (cspList ?? [])?.map((cspInfo) => {
    return {
      label: `${cspInfo?.centreId ? cspInfo?.centreId + `-` : ``}${
        cspInfo?.subZone
      }-${cspInfo?.cspName}`,
      value: cspInfo?.id,
    };
  });
};
