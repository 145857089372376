import React, { useState } from 'react';
import GeebeeTheme from '@leapfinance/frontend-commons/components/geebee/theme';
import {
  Avatar,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
} from '@mui/material';
import { ThemeProvider } from '@mui/system';
import { selectUserDetails } from 'app/features/user/userSlice';
import { useAppSelector } from 'app/hooks';
import { selectRole } from 'features/generalSlice';
import { useRouter } from 'next/router';
import { UserConfig } from 'types/user';
import { hasPermissionTo } from 'utils/permissions/base';

import { getSpeedupProfileMenuOptions } from '@/components/NewHeader/constants';

import useAddJerryContact from '../AddQuackerContact/useAddQuackerContact';

const ProfileDropDown = () => {
  const router = useRouter();
  const [profileData, setProfileData] = useState<UserConfig | null>(null);
  const data = useAppSelector((state) => selectUserDetails(state));
  const role: string | null = useAppSelector((state) => selectRole(state));

  const viewAllTaskPageVisibility = hasPermissionTo(`pages`, [
    `viewAllTaskPage`,
  ]);
  const userManagementVisibility = hasPermissionTo(`pages`, [
    `userManagementPage`,
  ]);
  const { handleShowAddModal } = useAddJerryContact();

  router?.events?.on(`routeChangeStart`, () => setAnchorEl(null));

  if (data && !profileData) {
    setProfileData(data);
  }
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <ThemeProvider theme={GeebeeTheme}>
      <div>
        <IconButton onClick={handleClick} className="relative">
          <Avatar className="text-[#2F9ED5] bg-[#bddeee] h-10 w-10 text-lg">
            {(profileData?.firstName || ` `).substring(0, 1).toUpperCase() +
              `` +
              (profileData?.lastName || ` `).substring(0, 1).toUpperCase()}
          </Avatar>
        </IconButton>
      </div>
      <Menu
        id={`avatar-dropdown-menu-updated`}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: `bottom`,
          horizontal: `right`,
        }}
        sx={{
          width: `300px`,
        }}
      >
        <div>
          <MenuList className="font-sans w-full">
            {getSpeedupProfileMenuOptions(
              router,
              profileData,
              role,
              handleShowAddModal,
              userManagementVisibility,
              viewAllTaskPageVisibility,
            )?.map((menu, index) => {
              if (!menu) return;
              return (
                <>
                  <MenuItem
                    key={`${menu?.title}-${index}`}
                    title={menu?.title}
                    className={menu?.menuClasses}
                    onClick={menu?.menuAction}
                  >
                    {menu?.children}
                  </MenuItem>
                  {menu?.addDivider ? <Divider /> : null}
                </>
              );
            })}
          </MenuList>
        </div>
      </Menu>
    </ThemeProvider>
  );
};

export default ProfileDropDown;
