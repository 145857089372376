import React, { useRef } from 'react';
import { Button, isFalsy } from '@leapfinance/frontend-commons';
import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import useFileUpload from '@/hooks/useFileUpload';
import { Document } from '@/types/documentSection';

import { gicAcceptedFormats } from './GICRequiredDocuments';

interface IDocumentUploadCardProps {
  cardTitle: string;
  cardDescription: string;
  keyName: Document[`keyName`];
  studentId?: number;
  applicationId?: number;
  onDocumentUpload?: (document?: {
    file: File;
    keyName: Document[`keyName`];
  }) => void;
  errorMessage?: string;
}

const DocumentUploadCard = (props: IDocumentUploadCardProps) => {
  const { validateFiles } = useFileUpload({
    accept: gicAcceptedFormats.map((key) => `.${key}`).join(`,`),
  });
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleFiles = (files: FileList | null) => {
    const acceptedFormats = [`jpg`, `png`, `pdf`, `jpeg`];
    const file = files?.[0];
    if (!file) return;
    const validationResult = validateFiles({
      files: [file],
      acceptedFormats,
    });

    if (validationResult.isValid) {
      props?.onDocumentUpload?.({ file, keyName: props.keyName });
    } else {
      enqueueSnackbar(validationResult.errorMessage, { variant: `error` });
    }
  };

  return (
    <div>
      <div
        className={`py-[10px] px-4 flex items-center gap-4 border rounded-lg w-fit ${
          !isFalsy(props?.errorMessage)
            ? `border-error-400`
            : `border-primary-400`
        }`}
      >
        <div className="gap-1">
          <Typography variant="subtitle2">{props.cardTitle}</Typography>
          <Typography variant="caption1" className="text-grey-600">
            {props.cardDescription}
          </Typography>
        </div>
        <div>
          <Button onClick={() => inputRef?.current?.click?.()}>Upload</Button>
          <input
            ref={inputRef}
            hidden
            className="hidden"
            type={`file`}
            accept={gicAcceptedFormats.map((key) => `.${key}`).join(`,`)}
            onChange={(event) => handleFiles(event.target.files)}
          />
        </div>
      </div>
      {!isFalsy(props?.errorMessage) && (
        <Typography variant="caption1" className="text-error-500">
          {props?.errorMessage}
        </Typography>
      )}
    </div>
  );
};

export default DocumentUploadCard;
