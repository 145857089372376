import React from 'react';
import { Typography } from '@mui/material';

import { ServiceCategoryTypes } from '@/types/vas';

import VASWarningIcon from '../Icons/vas/VASWarningIcon';

type Props = {
  serviceCategory: ServiceCategoryTypes;
};

const ServiceCategoryValueLabelMap: Record<ServiceCategoryTypes, string> = {
  ACCOMMODATION: `accommodation`,
  GIC: `gic`,
  FLYWIRE: ``,
  PAYMENT: ``,
  LOAN: `loan`,
};

function ExistingRequestWarning({ serviceCategory }: Props) {
  return (
    <div className="bg-error-100 rounded p-2 flex items-center gap-x-2 my-4">
      <VASWarningIcon className="w-8 h-8" />
      <Typography variant="body2" className="text-grey-900">
        A {ServiceCategoryValueLabelMap[serviceCategory]} request has already
        been raised for this student! Delete the existing{` `}
        {ServiceCategoryValueLabelMap[serviceCategory]} request to raise another
        request.
      </Typography>
    </div>
  );
}

export default ExistingRequestWarning;
