import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width={24}
    height={23}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.555 2.59a4.837 4.837 0 0 0-6.986 0L8.555 9.814l-3.495 3.6a2.972 2.972 0 0 0 0 4.11 2.763 2.763 0 0 0 3.99 0l9.821-10.115a.74.74 0 0 0 0-1.024.689.689 0 0 0-.994 0L8.057 16.499a1.386 1.386 0 0 1-2.002 0 1.47 1.47 0 0 1-.414-1.031c0-.39.147-.756.414-1.03l3.496-3.601 7.011-7.221a3.46 3.46 0 0 1 4.999 0c1.377 1.418 1.378 3.727 0 5.146L11.053 19.585a5.544 5.544 0 0 1-7.994 0c-2.204-2.27-2.204-5.963 0-8.233l9.82-10.115a.74.74 0 0 0 0-1.024.689.689 0 0 0-.994 0l-9.82 10.115c-2.753 2.835-2.753 7.447 0 10.282 1.333 1.373 3.105 2.13 4.99 2.13 1.887 0 3.66-.757 4.993-2.13L22.555 9.788v-.001c1.927-1.984 1.927-5.212 0-7.196Z"
      fill={props.fill || `#59526D`}
    />
  </svg>
);

export default SvgComponent;
