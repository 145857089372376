import { isOptionTypeArray } from '@leapfinance/frontend-commons/components/geebee/inputs/DropDown';
import { OPTION_TYPE } from '@leapfinance/frontend-commons/schema/common';
import { format, isValid } from 'date-fns';
import { PartialObject } from 'lodash';

import { components } from '@/types/commissionSchema';

import { ApplyPayload } from './StudentListPage/StudentListFilters';

export const isOptionType = (option: any): option is OPTION_TYPE => {
  return (
    (option as OPTION_TYPE)?.value !== undefined &&
    (option as OPTION_TYPE)?.label !== undefined
  );
};

export const transformOptionValues = (obj: Record<string, any>) => {
  if (isFalsy(obj)) return obj;
  if (isOptionType(obj)) return obj.value;
  if (
    typeof obj === `number` ||
    typeof obj === `string` ||
    typeof obj === `boolean`
  ) {
    if (obj === `true`) return true;
    if (obj === `false`) return false;
    return obj;
  }
  const transformedValues: any = {};
  Object.entries(obj).forEach(([key, value]: [key: string, value: any]) => {
    if (isOptionType(value)) {
      transformedValues[key] = value.value;
    } else if (Array.isArray(value)) {
      transformedValues[key] = value.map((data) => transformOptionValues(data));
    } else if (typeof value === `string`) {
      transformedValues[key] =
        value.trim().length > 0 ? value.trim() : undefined;
    } else if (typeof value === `object`) {
      transformedValues[key] = transformOptionValues(value);
    } else {
      transformedValues[key] = value;
    }
  });

  if (obj?.showOverdueTasks) {
    transformedValues[`showOverdueTasks`] = obj?.showOverdueTasks?.[0]?.value;
  }

  return transformedValues;
};
export const displayStringValues = (
  value: string | null | undefined | number,
) => {
  if (typeof value === `number`) return value;
  if (!value || !value?.trim()) return `-`;
  return value?.trim();
};

export const displayOptionValues = (value: OPTION_TYPE | null | undefined) => {
  if (isOptionType(value)) return value?.label;
  if (!value) return `-`;
  return value;
};

/**
 * Replaces all occurrences of hyphens with slashes in a date string.
 *
 * @param {string} dateString - The date string to be fixed.
 * @returns {string} The fixed date string with hyphens replaced by slashes.
 */
export const fixDateForAllBrowsers = (dateString: string) =>
  dateString.replace(/-/g, `/`);

export const displayDateValues = (value: string) => {
  try {
    if (!value) return `-`;
    const stableDate = fixDateForAllBrowsers(value);
    if (!isValid(new Date(stableDate))) return `-`;
    const date = format(new Date(stableDate), `dd/MM/yyyy`);
    return date;
  } catch (error) {
    console.error(`Failed to get valid date`, error);
    return `-`;
  }
};

/**
 * Returns an array of indexes at which elements in the input array satisfy the provided callback condition.
 *
 * @template T
 * @param {T[]} array - The input array to search for indexes in.
 * @param {(item: T) => boolean} callbackfn - A callback function that tests each element in the array. It should return `true` for elements that should be included in the result.
 * @returns {number[]} An array of indexes where elements in the input array satisfy the callback condition. If no elements satisfy the condition, an empty array is returned.
 *
 * @example
 * // Finding indexes of even numbers in an array
 * const numbers = [1, 2, 3, 4, 5, 6];
 * const evenIndexes = getAllIndexes(numbers, (num) => num % 2 === 0);
 * // evenIndexes will be [1, 3, 5] since the even numbers (2, 4, 6) are at those indexes.
 */
export function getAllIndexes<T>(
  array: T[],
  callbackfn: (item: T) => boolean,
): number[] {
  return array.reduce((acc: any, curr: any, index: number) => {
    if (callbackfn(curr)) {
      acc.push(index);
      return acc;
    }
    return acc;
  }, []);
}

export const isFalsy = (value: any) => {
  return (
    value === `` ||
    value === null ||
    value === undefined ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === `object` && Object.keys(value).length === 0)
  );
};

export const getFilterCount = (
  filterValues: ApplyPayload,
  filterKeysToShow: Array<keyof ApplyPayload>,
) => {
  const filters = filterKeysToShow.reduce((acc, key) => {
    const data = filterValues?.[key];
    if (isOptionType(data)) {
      acc.push({ title: data?.label, key, value: data?.value });
    } else if (isOptionTypeArray(data) || Array.isArray(data)) {
      const values = data.map((option) => ({
        title: (option as any)?.label ?? option,
        key,
        value: (option as any)?.value ?? option,
      }));
      acc.push(...values);
    } else if (typeof data === `string`) {
      acc.push({ title: data, key, value: data });
    }
    return acc;
  }, [] as any[]);

  return filters.length ?? 0;
};

export const getPartsData = (
  parts: PartialObject<
    components['schemas']['NextReceivablePartResponseDto']
  >[],
  originalParts: components['schemas']['CommissionInvoiceResponseDto'][`nextReceivableParts`],
) => {
  const updatedParts =
    parts.map((part) => {
      const partNumberPresentInData = (originalParts ?? []).findIndex(
        (partData) =>
          partData.receivablePartNumber === part.receivablePartNumber,
      );
      if (partNumberPresentInData === -1) return part;
      return originalParts?.[partNumberPresentInData];
    }) ?? null;

  return updatedParts;
};

export enum SectionNameEnum {
  BILLING_DETAILS = `Billing Details`,
  INVOICE_DETAILS = `Invoice Details`,
  RATE = `Rate`,
  DESCRIPTION = `Description`,
  RULES = `Rules`,
}

export const transformSharedListOptionsWithNullValues = (
  listOptions: components['schemas']['ListResponseDto'][],
) => {
  const transformed = (listOptions ?? [])?.map((option) => {
    if (option?.value === null) {
      return { ...option, value: String(null) };
    } else {
      return option;
    }
  });
  return transformed;
};

export const transformPayloadToRemoveNullValues = (values: any) => {
  const payload: any = { ...values };
  Object?.keys(payload)?.forEach((key) => {
    if (payload[key] === `null`) {
      payload[key] = null;
    }
    if (Array.isArray(payload[key])) {
      const temp = payload[key];
      const mutated = temp?.map((value: string) => {
        if (value === `null`) {
          return null;
        }
        return value;
      });
      payload[key] = mutated;
    }
  });
  return payload;
};
