import debounce from 'lodash/debounce';

import { useLazyFetchAllCitiesQuery } from '@/services/student';

function usePreferredCityOptions() {
  const [triggerGetCities, { isLoading: fetchingCities }] =
    useLazyFetchAllCitiesQuery();

  const fetchCities = async (
    inputValue: string,
    callback: any,
    country: string,
  ) => {
    const options = [];
    const data = await triggerGetCities({
      city: inputValue,
      country,
    }).unwrap();
    if (data.success) {
      const list = data.data || [];
      for (let i = 0; i < list.length; i++) {
        options.push({
          value: list[i],
          label: list[i],
        });
      }
    }
    callback(options);
  };

  return [debounce(fetchCities, 500)];
}

export default usePreferredCityOptions;
