export enum UserStatus {
  RESIGNED = `RESIGNED`,
  ACTIVE = `ACTIVE`,
  ABSENT = `ABSENT`,
  DEACTIVATED = `DEACTIVATED`,
}

export enum UserTypes {
  opsManager = `OPS_MANAGER`,
  zonalManager = `ZONAL_MANAGER`,
  admin = `ADMIN`,
  counsellor = `COUNSELLOR`,
  countryHead = `COUNTRY_HEAD`,
  headCustomerCare = `HEAD_CUSTOMER_CARE`,
  viewer = `VIEWER`,
  qc = `QC_TEAM`,
  visaTeam = `VISA_TEAM`,
  invoicing = `INVOICING`,
}

export type User = {
  createdAt: string;
  id: number;
  name: string;
  status: UserStatus;
  updatedAt: string;
  userType: 'OPS_MANAGER';
};

export interface AssignedCSP {
  id?: number;
  name?: string;
}

export interface ReportingManager {
  id: number;
  firstName: string;
  lastName: string;
}

export interface Reportee {
  id: number;
  name: string;
}

export interface UserInfo {
  assignedCsps: AssignedCSP[] | null;
  countryGroups: string[];
  department: string | null;
  designation: string | null;
  email: string | null;
  firstName: string | null;
  id: number;
  lastName: string | null;
  leaveEndDate: Date | null;
  leaveStartDate: Date | null;
  middleName: string | null;
  mobile: string | null;
  reportees: Reportee[] | null;
  reportingManagerId: number | null;
  status: UserStatus;
  userType: UserTypes | null;
  countryCode?: string | null;
  callingContact?: string | null;
  callingContactNumber?: string | null;
}
