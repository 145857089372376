import React from 'react';
import {
  SuccessScreen,
  TrackRequestStatusCard,
} from '@leapfinance/frontend-commons';
import { type SuccessScreenProps } from '@leapfinance/frontend-commons/components/geebee/vas/RequestInitiationWorkflow/SuccessScreen';
import { type TrackRequestStatusCardProps } from '@leapfinance/frontend-commons/components/geebee/vas/RequestInitiationWorkflow/TrackRequestStatusCard';

import VASFlywireSuccessScreen from './VASFlywireSuccessScreen';

type Props = {
  showPaymentSection?: boolean;
  paymentLink?: string;
  showTrackerCard?: boolean;
  successScreenProps: SuccessScreenProps;
  trackRequestStatusCardProps: TrackRequestStatusCardProps;
};

function VASSuccessScreen({
  showPaymentSection,
  showTrackerCard = true,
  successScreenProps,
  trackRequestStatusCardProps,
  paymentLink,
}: Props) {
  return (
    <div
      className={` ${
        showPaymentSection ? `` : ` justify-between items-center`
      } flex flex-col h-full`}
    >
      <SuccessScreen {...successScreenProps} />
      {showTrackerCard ? (
        <TrackRequestStatusCard {...trackRequestStatusCardProps} />
      ) : null}
      {showPaymentSection ? (
        <VASFlywireSuccessScreen paymentLink={paymentLink} />
      ) : null}
    </div>
  );
}

export default VASSuccessScreen;
