import { UserTypes } from '../types/userManagment';

export interface OptionType {
  label: string;
  value: string | number | null | boolean | undefined;
  isDisabled?: boolean;
}
export interface BooleanType {
  label: string;
  value: boolean;
}

export interface StateOptionType {
  label: string;
  value: string | number;
  code: string | number;
}

export interface CheckBoxOptionType {
  id: number;
  label: string;
}

export interface ValueOptionType {
  id: number;
  label: string;
  value: Array<number>;
}

export const countryList: Array<OptionType> = [
  {
    label: `USA`,
    value: `USA`,
  },
  {
    label: `Canada`,
    value: `Canada`,
  },
  {
    label: `UK`,
    value: `UK`,
  },
  {
    label: `Ireland`,
    value: `Ireland`,
  },
  {
    label: `Australia`,
    value: `Australia`,
  },
];

export const onHoldReasonOptions: Array<OptionType> = [
  {
    label: `Exams score Insufficient`,
    value: `Exams score Insufficient`,
  },
  {
    label: `Academic score Insufficient`,
    value: `Academic score Insufficient`,
  },
  {
    label: `Too many backlogs`,
    value: `Too many backlogs`,
  },
  {
    label: `Academic background not matching`,
    value: `Academic background not matching`,
  },
  {
    label: `Insufficient work experience`,
    value: `Insufficient work experience`,
  },
  {
    label: `Too much gap after last studies`,
    value: `Too much gap after last studies`,
  },
  {
    label: `Additional docs required`,
    value: `Additional docs required`,
    isDisabled: true,
  },
  {
    label: `Incorrect documents`,
    value: `Incorrect documents`,
  },
  {
    label: `Missing documents`,
    value: `Missing documents`,
  },
  {
    label: `Application fee pending`,
    value: `Application fee pending`,
  },
  {
    label: `Intake yet to open`,
    value: `Intake yet to open`,
  },
  {
    label: `Program closed`,
    value: `Program closed`,
  },
  {
    label: `Program not available`,
    value: `Program not available`,
  },
  {
    label: `Student not eligible`,
    value: `Student not eligible`,
  },
  {
    label: `Payment Pending`,
    value: `Payment Pending`,
  },
  {
    label: `Application Waitlisted`,
    value: `Application Waitlisted`,
  },
  {
    label: `Other reasons`,
    value: `Other reasons`,
  },
  {
    label: `Exam Score (IELTS/TOEFL/GRE/GMAT)`,
    value: `Exam Score (IELTS/TOEFL/GRE/GMAT)`,
  },
  {
    label: `Internal Requirement`,
    value: `Internal Requirement`,
  },
  {
    label: `Essay Revision`,
    value: `Essay Revision`,
  },
  {
    label: `Referee Not Responding`,
    value: `Referee Not Responding`,
  },
  {
    label: `WES Reporting`,
    value: `WES Reporting`,
  },
  {
    label: `Financial Proof Required`,
    value: `Financial Proof Required`,
  },
  {
    label: `Official Score Reporting`,
    value: `Official Score Reporting`,
  },
  {
    label: `Program Closed - Alternate Intake/Course Offered`,
    value: `Program Closed - Alternate Intake/Course Offered`,
  },
  {
    label: `Login Id Required`,
    value: `Login Id Required`,
  },
  {
    label: `University Portal Update Required`,
    value: `University Portal Update Required`,
  },
  {
    label: `Math Evaluation`,
    value: `Math Evaluation`,
  },
];

export const onDropReasonOptions: Array<OptionType> = [
  {
    label: `Student not eligible`,
    value: `Student not eligible`,
  },
  {
    label: `Student received another offer`,
    value: `Student received another offer`,
  },
  {
    label: `Student not interested`,
    value: `Student not interested`,
  },
  {
    label: `Student not responding`,
    value: `Student not responding`,
  },
  {
    label: `Program closed`,
    value: `Program closed`,
  },
  {
    label: `Dropped by Partner`,
    value: `Dropped by Partner`,
  },
  {
    label: `Other reasons`,
    value: `Other reasons`,
  },
];

export const onRejectionReasonOptions: Array<OptionType> = [
  {
    label: `Exams score Insufficient`,
    value: `Exams score Insufficient`,
  },
  {
    label: `Academic score Insufficient`,
    value: `Academic score Insufficient`,
  },
  {
    label: `Too many backlogs`,
    value: `Too many backlogs`,
  },
  {
    label: `Academic background not matching`,
    value: `Academic background not matching`,
  },
  {
    label: `Insufficient work experience`,
    value: `Insufficient work experience`,
  },
  {
    label: `Too much gap after last studies`,
    value: `Too much gap after last studies`,
  },
  {
    label: `Student not eligible`,
    value: `Student not eligible`,
  },
  {
    label: `Additional docs required`,
    value: `Additional docs required`,
  },
  {
    label: `Program closed`,
    value: `Program closed`,
  },
  {
    label: `Program not available`,
    value: `Program not available`,
  },
  {
    label: `Other reasons`,
    value: `Other reasons`,
  },
  {
    label: `Document not provided`,
    value: `Document not provided`,
  },
  {
    label: `IELTS marks not provided`,
    value: `IELTS marks not provided`,
  },
];
export const applicationStages: Array<OptionType> = [
  {
    label: `APPLICATION RECEIVED`,
    value: `APPLICATION_RECEIVED`,
  },
  {
    label: `APPLICATION ON HOLD`,
    value: `APPLICATION_ON_HOLD`,
  },
  {
    label: `AGGREGATOR ON HOLD RESOLVED`,
    value: `AGGREGATOR_ON_HOLD_RESOLVED`,
  },
  {
    label: `DOCUMENT VERIFIED`,
    value: `DOCUMENT_VERIFIED`,
  },

  {
    label: `APPLICATION SENT TO UNIVERSITY`,
    value: `APPLICATION_SENT_TO_UNIVERSITY`,
  },
  {
    label: `APPLICATION IN ASSESSMENT`,
    value: `APPLICATION_IN_ASSESSMENT`,
  },
  {
    label: `APPLICATION ON HOLD BY UNIVERSITY`,
    value: `APPLICATION_ON_HOLD_BY_UNIVERSITY`,
  },
  {
    label: `UNIVERSITY ON HOLD RESOLVED`,
    value: `UNIVERSITY_ON_HOLD_RESOLVED`,
  },
  {
    label: `UNIVERSITY COMMUNICATION PENDING`,
    value: `UNIVERSITY_COMMUNICATION_PENDING`,
  },
  {
    label: `CONDITIONAL ADMIT RECEIVED`,
    value: `CONDITIONAL_ADMIT_RECEIVED`,
  },
  {
    label: `UNCONDITIONAL ADMIT RECEIVED`,
    value: `UNCONDITIONAL_ADMIT_RECEIVED`,
  },
  {
    label: `APPLICATION DROPPED`,
    value: `APPLICATION_DROPPED`,
  },
  {
    label: `APPLICATION REJECTED`,
    value: `APPLICATION_REJECTED`,
  },

  {
    label: `ADMIT ACCEPTED`,
    value: `ADMIT_ACCEPTED`,
  },
  {
    label: `OFFER REVOKED`,
    value: `OFFER_REVOKED`,
  },
  {
    label: `ADMIT DECLINED`,
    value: `ADMIT_DECLINED`,
  },
];
export const intakeOptions: Array<OptionType> = [
  { label: `January 2022`, value: `JANUARY_2022` },
  { label: `February 2022`, value: `FEBRUARY_2022` },
  { label: `March 2022`, value: `MARCH_2022` },
  { label: `April 2022`, value: `APRIL_2022` },
  { label: `May 2022`, value: `MAY_2022` },
  { label: `June 2022`, value: `JUNE_2022` },
  { label: `July 2022`, value: `JULY_2022` },
  { label: `August 2022`, value: `AUGUST_2022` },
  { label: `September 2022`, value: `SEPTEMBER_2022` },
  { label: `October 2022`, value: `OCTOBER_2022` },
  { label: `November 2022`, value: `NOVEMBER_2022` },
  { label: `December 2022`, value: `DECEMBER_2022` },
  { label: `January 2023`, value: `JANUARY_2023` },
  { label: `February 2023`, value: `FEBRUARY_2023` },
  { label: `March 2023`, value: `MARCH_2023` },
  { label: `April 2023`, value: `APRIL_2023` },
  { label: `May 2023`, value: `MAY_2023` },
  { label: `June 2023`, value: `JUNE_2023` },
  { label: `July 2023`, value: `JULY_2023` },
  { label: `August 2023`, value: `AUGUST_2023` },
  { label: `September 2023`, value: `SEPTEMBER_2023` },
  { label: `October 2023`, value: `OCTOBER_2023` },
  { label: `November 2023`, value: `NOVEMBER_2023` },
  { label: `December 2023`, value: `DECEMBER_2023` },
  { label: `January 2024`, value: `JANUARY_2024` },
  { label: `February 2024`, value: `FEBRUARY_2024` },
  { label: `March 2024`, value: `MARCH_2024` },
  { label: `April 2024`, value: `APRIL_2024` },
  { label: `May 2024`, value: `MAY_2024` },
  { label: `June 2024`, value: `JUNE_2024` },
  { label: `July 2024`, value: `JULY_2024` },
  { label: `August 2024`, value: `AUGUST_2024` },
  { label: `September 2024`, value: `SEPTEMBER_2024` },
  { label: `October 2024`, value: `OCTOBER_2024` },
  { label: `November 2024`, value: `NOVEMBER_2024` },
  { label: `December 2024`, value: `DECEMBER_2024` },
  { label: `January 2025`, value: `JANUARY_2025` },
  { label: `February 2025`, value: `FEBRUARY_2025` },
  { label: `March 2025`, value: `MARCH_2025` },
  { label: `April 2025`, value: `APRIL_2025` },
  { label: `May 2025`, value: `MAY_2025` },
  { label: `June 2025`, value: `JUNE_2025` },
  { label: `July 2025`, value: `JULY_2025` },
  { label: `August 2025`, value: `AUGUST_2025` },
  { label: `September 2025`, value: `SEPTEMBER_2025` },
  { label: `October 2025`, value: `OCTOBER_2025` },
  { label: `November 2025`, value: `NOVEMBER_2025` },
  { label: `December 2025`, value: `DECEMBER_2025` },
  { label: `January 2026`, value: `JANUARY_2026` },
  { label: `February 2026`, value: `FEBRUARY_2026` },
  { label: `March 2026`, value: `MARCH_2026` },
  { label: `April 2026`, value: `APRIL_2026` },
  { label: `May 2026`, value: `MAY_2026` },
  { label: `June 2026`, value: `JUNE_2026` },
  { label: `July 2026`, value: `JULY_2026` },
  { label: `August 2026`, value: `AUGUST_2026` },
  { label: `September 2026`, value: `SEPTEMBER_2026` },
  { label: `October 2026`, value: `OCTOBER_2026` },
  { label: `November 2026`, value: `NOVEMBER_2026` },
  { label: `December 2026`, value: `DECEMBER_2026` },
];

export const sortingFieldOptions: Array<OptionType> = [
  {
    label: `Application No`,
    value: `number`,
  },
  {
    label: `Application Date`,
    value: `applicationDate`,
  },
  {
    label: `Student Name`,
    value: `studentName`,
  },
  {
    label: `University Name`,
    value: `universityName`,
  },
  {
    label: `Program Name`,
    value: `programName`,
  },
  {
    label: `Due Date`,
    value: `dueDateOffer`,
  },
  {
    label: `Country`,
    value: `country`,
  },
  {
    label: `Stage`,
    value: `stage`,
  },
  {
    label: `Ops Manager`,
    value: `assignedToName`,
  },
];

export const documentTypeOptions: Array<OptionType> = [
  {
    label: `TT`,
    value: `TT`,
  },
  {
    label: `Flywire`,
    value: `FLYWIRE`,
  },
  {
    label: `Bank Draft`,
    value: `BANK_DRAFT`,
  },
  {
    label: `Credit Card Receipt`,
    value: `CREDIT_CARD_RECEIPT`,
  },
  {
    label: `Direct Fee Receipt`,
    value: `DIRECT_FEE_RECEIPT`,
  },
  {
    label: `CAS`,
    value: `CAS_LETTER`,
  },
  {
    label: `eCOE`,
    value: `ECOE`,
  },
  {
    label: `Fee Receipt`,
    value: `FEE_PAYMENT_RECEIPT`,
  },
  {
    label: `I-20`,
    value: `I20`,
  },
  {
    label: `LOA`,
    value: `LOA`,
  },
  {
    label: `Unconditional Offer Letter`,
    value: `UNCONDITIONAL_OFFER_LETTER`,
  },
  {
    label: `Conditional Offer Letter`,
    value: `CONDITIONAL_OFFER_LETTER`,
  },
  {
    label: `GIC`,
    value: `GIC_CERTIFICATE`,
  },
  {
    label: `FTS`,
    value: `FTS`,
  },
  {
    label: `Bank Loan Letter`,
    value: `EDUCATION_LOAN_LETTER`,
  },
  {
    label: `Financial Documents`,
    value: `FINANCIAL_DOCUMENT`,
  },
  {
    label: `Academic Documents`,
    value: `ACADEMIC_DOCUMENTS`,
  },
  {
    label: `Medical`,
    value: `MEDICAL_CERTIFICATE`,
  },
  {
    label: `Visa Application Form whatever has been ticked`,
    value: `VISA_APPLICATION_FORM_MAIN`,
  },
  {
    label: `AIP Letter`,
    value: `AIP_LETTER`,
  },
  {
    label: `PPR Letter`,
    value: `PPR_LETTER`,
  },
  {
    label: `Visa Copy`,
    value: `VISA`,
  },
  {
    label: `Submission Acknowledgement`,
    value: `SUBMISSION_ACKNOWLEDGEMENT`,
  },
  {
    label: `Consent Form`,
    value: `CONSENT_FORM`,
  },
  {
    label: `Authority Letter`,
    value: `AUTHORITY_LETTER`,
  },
  {
    label: `Login Credentials`,
    value: `LOGIN_CREDENTIALS`,
  },
  {
    label: `I20 Request Form`,
    value: `I20_REQUEST_FORM`,
  },
];
export const paymentStatusOptions: Array<OptionType> = [
  {
    label: `DEPOSIT PAID`,
    value: `DEPOSIT_PAID`,
  },
  {
    label: `TUITION FEE PAID`,
    value: `TUITION_FEE_PAID`,
  },
  {
    label: `Refunded`,
    value: `REFUNDED`,
  },
  {
    label: `Not Paid`,
    value: `NOT_PAID`,
  },
];
export const paymentStatusFilterOptions: Array<OptionType> = [
  {
    label: `DEPOSIT PAID`,
    value: `DEPOSIT_PAID`,
  },
  {
    label: `TUITION FEE PAID`,
    value: `TUITION_FEE_PAID`,
  },
  {
    label: `NA`,
    value: null,
  },
  {
    label: `Refunded`,
    value: `REFUNDED`,
  },
  {
    label: `Not Paid`,
    value: `NOT_PAID`,
  },
];
export const conditionStatusFilterOptions: Array<OptionType> = [
  { label: `Condition Met`, value: `CONDITION_MET` },
  { label: `Condition Not Met`, value: `CONDITION_NOT_MET` },
  {
    label: `Conditions Verified`,
    value: `CONDITION_VERIFIED`,
  },
  {
    label: `Follow Up Done`,
    value: `FOLLOW_UP_DONE`,
  },
];

export const feesWithOptions: Array<OptionType> = [
  {
    label: `Instituion`,
    value: `INSTITUTION`,
  },
  {
    label: `Geebee`,
    value: `GEEBEE`,
  },
  {
    label: `Pending`,
    value: `PENDING`,
  },
  {
    label: `Refunded`,
    value: `REFUNDED`,
  },
];
export const paymentTypeOptions: Array<OptionType> = [
  {
    label: `TT`,
    value: `TT`,
  },
  {
    label: `Flywire`,
    value: `FLYWIRE`,
  },
  {
    label: `Bank Draft`,
    value: `BANK_DRAFT`,
  },
  {
    label: `Credit Card`,
    value: `CREDIT_CARD`,
  },
  {
    label: `Direct Fee Receipt`,
    value: `DIRECT_FEE_RECEIPT`,
  },
  {
    label: `Waiver Letter`,
    value: `WAIVER_LETTER`,
  },
  {
    label: `Western Union`,
    value: `WESTERN_UNION`,
  },
  {
    label: `Debit Card`,
    value: `DEBIT_CARD`,
  },
  {
    label: `Others`,
    value: `OTHERS`,
  },
];
export const applicationTypeOptions: Array<OptionType> = [
  {
    label: `Agent Portal`,
    value: `AGENT_PORTAL`,
  },
  {
    label: `Online Application`,
    value: `ONLINE_APPLICATION`,
  },
  {
    label: `Third Party Application`,
    value: `THIRD_PARTY_APPLICATION`,
  },
  {
    label: `Paper Based Application`,
    value: `PAPER_BASED_APPLICATION`,
  },
  {
    label: `Email`,
    value: `EMAIL`,
  },
  {
    label: `To be filed by Geebee`,
    value: `TO_BE_FILED_BY_GEEBEE`,
  },
];

export const currencyTypeOptions: Array<OptionType> = [
  {
    label: `USD`,
    value: `USD`,
  },
  {
    label: `GBP`,
    value: `GBP`,
  },
  {
    label: `AUD`,
    value: `AUD`,
  },
  {
    label: `NZD`,
    value: `NZD`,
  },
  {
    label: `CAD`,
    value: `CAD`,
  },
  {
    label: `INR`,
    value: `INR`,
  },
  {
    label: `EURO`,
    value: `EURO`,
  },
  {
    label: `SGD`,
    value: `SGD`,
  },
];

export const userManagementRoles: Array<OptionType> = [
  {
    label: `Ops Manager`,
    value: UserTypes.opsManager,
  },
  {
    label: `Zonal Manager`,
    value: UserTypes.zonalManager,
  },
  {
    label: `Admin`,
    value: UserTypes.admin,
  },
  {
    label: `Counsellor`,
    value: UserTypes.counsellor,
  },
  {
    label: `Country Head`,
    value: UserTypes.countryHead,
  },
  {
    label: `Head Customer Care`,
    value: UserTypes.headCustomerCare,
  },
  {
    label: `QC`,
    value: UserTypes.qc,
  },
  {
    label: `Viewer`,
    value: UserTypes.viewer,
  },
  {
    label: `Visa Team`,
    value: UserTypes.visaTeam,
  },
  {
    label: `Invoicing Team`,
    value: UserTypes.invoicing,
  },
];

export const applicationType: Array<OptionType> = [
  {
    label: `Agent Portal`,
    value: `AGENT_PORTAL`,
  },
  {
    label: `Online Application`,
    value: `ONLINE_APPLICATION`,
  },
  {
    label: `Third Party Application`,
    value: `THIRD_PARTY_APPLICATION`,
  },
  {
    label: `Paper Based Application`,
    value: `PAPER_BASED_APPLICATION`,
  },
  {
    label: `Email`,
    value: `EMAIL`,
  },
  {
    label: `To be filed by Geebee`,
    value: `TO_BE_FILED_BY_GEEBEE`,
  },
];
export const visaDocumentTypeOptions: Array<OptionType> = [
  {
    label: `CAS`,
    value: `CAS_LETTER`,
  },
  {
    label: `eCOE`,
    value: `ECOE`,
  },
  {
    label: `Fee Receipt`,
    value: `FEE_PAYMENT_RECEIPT`,
  },
  {
    label: `Flywire`,
    value: `FLYWIRE`,
  },
  {
    label: `I-20`,
    value: `I20`,
  },
  {
    label: `LOA`,
    value: `LOA`,
  },
  {
    label: `Unconditional Offer Letter`,
    value: `UNCONDITIONAL_OFFER_LETTER`,
  },
];
export const visaStatusOptions: Array<OptionType> = [
  {
    label: `Preparing Documents`,
    value: `PREPARING_DOCUMENTS`,
  },
  {
    label: `Visa Applied`,
    value: `VISA_APPLIED`,
  },
  {
    label: `Visa Interview`,
    value: `VISA_INTERVIEW`,
  },
  {
    label: `Visa Approved`,
    value: `VISA_APPROVED`,
  },
  {
    label: `Visa Rejected`,
    value: `VISA_REJECTED`,
  },
  {
    label: `Withdrawn`,
    value: `WITHDRAWN`,
  },
  {
    label: `Dropout`,
    value: `DROP_OUT`,
  },
  {
    label: `Defered`,
    value: `DEFERRED`,
  },
];

export const OnHoldMissingDocuments = `MISSING_DOCUMENTS`;
export const OnHoldIncorrectDocuments = `INCORRECT_DOCUMENTS`;
export const OnHoldUniversityMissingDocuments = `MISSING_DOCUMENTS`;
export const OnHoldUniversityIncorrectDocuments = `INCORRECT_DOCUMENTS`;
export const AcademicTitleMapper: { [key: string]: string } = {
  TENTH: `Std. 10th`,
  TWELFTH: `Std. 12th`,
  DIPLOMA: `Diploma`,
  PG_DIPLOMA: `PG Diploma`,
  BACHELORS_DEGREE: `Bachelors Degree`,
  MASTERS_DEGREE: `Masters Degree`,
  PHD: `PHD`,
};

export const documentStatusOptions: Array<OptionType> = [
  {
    label: `Request Received`,
    value: `REQUEST_RECEIVED`,
  },
  {
    label: `Process On Hold`,
    value: `PROCESS_ON_HOLD`,
  },
  {
    label: `Document Uploaded`,
    value: `DOCUMENT_UPLOADED`,
  },
  {
    label: `Follow Up Done`,
    value: `FOLLOW_UP_DONE`,
  },
];

export const visaApplicationDocumentTypeOptions: Array<OptionType> = [
  {
    label: `CAS`,
    value: `CAS_LETTER`,
  },
  {
    label: `I20`,
    value: `I20`,
  },
  {
    label: `ECOE`,
    value: `ECOE`,
  },
];

export const conditionalAdmitReasonListOptions: Array<OptionType> = [
  {
    label: `IELTS Score Card`,
    value: `IELTS_SCORE_CARD`,
  },
  {
    value: `TWELFTH_MARK_SHEET`,
    label: `Twelfth Marksheet`,
  },
  {
    value: `UG_CONSOLIDATED_MARK_SHEET`,
    label: `UG Consolidated Marksheet`,
  },
  {
    value: `PROVISIONAL_DEGREE_CERTIFICATE`,
    label: `Provisional Degree Certificate`,
  },
  {
    value: `SOP`,
    label: `SOP`,
  },
  {
    value: `LOR`,
    label: `LOR`,
  },
  {
    value: `INTERVIEW`,
    label: `Credibility Interview`,
  },
  {
    value: `BACKLOG_LETTER`,
    label: `Backlog Letter`,
  },
  {
    value: `OTHER`,
    label: `Other`,
  },
  {
    value: `FINANCIAL_DOCUMENT`,
    label: `Financial Document`,
  },
  {
    value: `FINAL_DEGREE_CERTIFICATE`,
    label: `Final Degree Certificate`,
  },
  { value: `DEPOSIT_PAYMENT`, label: `Deposit Payment` },
  { value: `I20_REQUEST_FORM`, label: `I20 Request Form` },
  { value: `HOUSING_PREFERENCE_FORM`, label: `Housing Preference Form` },
  {
    value: `STATEMENT_OF_UNDERSTANDING_FORM`,
    label: `Statement of Understanding Form`,
  },
  { value: `FINANCIAL_DECLARATION_FORM`, label: `Financial Declaration Form` },
  { value: `VISA_HISTORY`, label: `Visa History` },
  { value: `ATAS_CERTIFICATE`, label: `Atas Certificate` },
  { value: `STUDIED_IN_UK_QUERY`, label: `Studied in UK Query` },
];

export const visaStatusMappingIpToGeebee = [
  `VISA_REQUEST_RAISED`,
  `VISA_PROCESS_ON_HOLD`,
  `VISA_FILING_IN_PROCESS`,
];
export const PreparingDocs = `PREPARING_DOCUMENTS`;

export const userManagementCountries = [
  {
    label: `Canada`,
    value: `CANADA`,
  },
  {
    label: `USA`,
    value: `USA`,
  },
  {
    label: `UK & Ireland`,
    value: `UKIR`,
  },
  {
    label: `AusNZ & Others`,
    value: `ASNZO`,
  },
];
export const conditionStatusOptions: Array<OptionType> = [
  {
    label: `Accepted`,
    value: `ACCEPTED`,
  },
  {
    label: `Rejected`,
    value: `REJECTED`,
  },
];
export const rejectReasonOptions: Array<OptionType> = [
  {
    label: `Document Incorrect`,
    value: `DOCUMENT_INCORRECT`,
  },
  {
    label: `Document not visible`,
    value: `DOCUMENT_NOT_VISIBLE`,
  },
  {
    label: `Score not fulfilled`,
    value: `SCORE_NOT_FULFILLED`,
  },
  {
    label: `Duplicate Document`,
    value: `DUPLICATE_DOCUMENT`,
  },
  {
    label: `Document not uploaded on Portal`,
    value: `DOCUMENT_NOT_UPLOADED_ON_PORTAL`,
  },
];
export const AdmitconditionStatusOptions: Array<OptionType> = [
  { label: `Condition Met`, value: `CONDITION_MET`, isDisabled: true },
  { label: `Condition Not Met`, value: `CONDITION_NOT_MET`, isDisabled: true },
  {
    label: `Conditions Verified`,
    value: `CONDITION_VERIFIED`,
    isDisabled: true,
  },
  {
    label: `Follow Up Done`,
    value: `FOLLOW_UP_DONE`,
    isDisabled: true,
  },
  {
    label: `Condition On Hold`,
    value: `CONDITION_ON_HOLD`,
    isDisabled: true,
  },
];

export const IntakeDropdownV2: Array<OptionType> = [
  {
    label: `JAN`,
    value: `JAN`,
  },
  {
    label: `FEB`,
    value: `FEB`,
  },
  {
    label: `MAR`,
    value: `MAR`,
  },
  {
    label: `APR`,
    value: `APR`,
  },
  {
    label: `MAY`,
    value: `MAY`,
  },
  {
    label: `JUN`,
    value: `JUN`,
  },
  {
    label: `JUL`,
    value: `JUL`,
  },
  {
    label: `AUG`,
    value: `AUG`,
  },
  {
    label: `SEP`,
    value: `SEP`,
  },
  {
    label: `OCT`,
    value: `OCT`,
  },
  {
    label: `NOV`,
    value: `NOV`,
  },
  {
    label: `DEC`,
    value: `DEC`,
  },
];

export const desireIntake: Array<OptionType> = [
  {
    label: `SEP`,
    value: `SEP`,
  },
  {
    label: `MAY`,
    value: `MAY`,
  },
  {
    label: `JAN`,
    value: `JAN`,
  },
  {
    label: `NOV`,
    value: `NOV`,
  },

  {
    label: `JUN`,
    value: `JUN`,
  },
];

export const courseDuration: Array<ValueOptionType> = [
  { id: 1, label: `Below 1 year`, value: [0, 11] },
  { id: 2, label: `1 year - 2 years`, value: [12, 24] },
  { id: 3, label: `2 years - 3 years`, value: [24, 36] },
  { id: 4, label: `Above 3 years`, value: [36, 72] },
];

export const tutionFee: Array<ValueOptionType> = [
  { id: 1, label: `Below 5 Lakhs`, value: [0, 500000] },
  { id: 2, label: `5 Lakhs - 10 Lakhs`, value: [500000, 1000000] },
  { id: 3, label: `10 Lakhs - 20 Lakhs`, value: [1000000, 2000000] },
  { id: 4, label: `20 Lakhs - 30 Lakhs`, value: [2000000, 3000000] },
  { id: 5, label: `Above 30 Lakhs`, value: [3000000, 20000000] },
];

const getYear = (): any => {
  let i;
  const yearArr = [];
  for (i = 2023; i >= 2000; i--) {
    const yearObj = {
      label: i,
      value: i,
    };
    yearArr.push(yearObj);
  }
  return yearArr;
};

export const getYearRange = (backYearsRange = 10) => {
  const currentYear = new Date().getFullYear();
  const yearRange: Array<{ label: string; value: number }> = [];
  const range = (start: number, stop: number, step: number) =>
    Array.from({ length: (stop - start) / step + 1 }, (_, i) => {
      yearRange.push({
        label: (start + i * step).toString(),
        value: start + i * step,
      });
    });

  range(currentYear + 1, currentYear - backYearsRange, -1);
  return yearRange;
};

export const orderedPopularCourses: Array<CheckBoxOptionType> = [
  {
    id: 28,
    label: `Business Anlaytics & Management, International Business & Administration, Entrepreneurship, Innovation, Operations, Project Management, Event Management, Organizational Management, Health Management`,
  },
  {
    id: 32,
    label: `Computing,Computer science & technology,Software development & Testing, Information systems`,
  },
  {
    id: 37,
    label: `Data Science and Analytics`,
  },
  {
    id: 26,
    label: `Banking and finance, Commerce, Insurance, Risk, Accounts, Actuarial science, Economics`,
  },
];

export const orderedDegree: Array<CheckBoxOptionType> = [
  {
    id: 36,
    label: `Postgraduate`,
  },
  {
    id: 32,
    label: `PG Diploma /Certificate`,
  },
  {
    id: 33,
    label: `Undergraduate`,
  },
  {
    id: 35,
    label: `UG+PG (Accelerated) Degree`,
  },
  {
    id: 3,
    label: `Ph.D.`,
  },
];

export const highestEducationLevel: Array<OptionType> = [
  {
    label: `Postgraduate`,
    value: `Postgraduate`,
  },
  {
    label: `Graduate`,
    value: `Graduate`,
  },
  {
    label: `Grade 12 or equivalent`,
    value: `Grade 12 or equivalent`,
  },
];

export const gradScoreSystem: Array<OptionType> = [
  {
    label: `Out of 4`,
    value: `Out of 4`,
  },
  {
    label: `Out of 10`,
    value: `Out of 10`,
  },
  {
    label: `Out of 100`,
    value: `Out of 100`,
  },
];

export const twelfthScoreSystem: Array<OptionType> = [
  {
    label: `Out of 10`,
    value: `Out of 10`,
  },
  {
    label: `Out of 100`,
    value: `Out of 100`,
  },
];

export const additionalExamOptions: Array<OptionType> = [
  {
    label: `GRE`,
    value: `GRE`,
  },
  {
    label: `GMAT`,
    value: `GMAT`,
  },
];

export const typeOfProgram: Array<OptionType> = [
  {
    label: `PGD`,
    value: `PGD`,
  },
  {
    label: `Masters`,
    value: `Masters`,
  },
  {
    label: `PhD`,
    value: `PhD`,
  },
  {
    label: `MBA`,
    value: `MBA`,
  },
  {
    label: `UG`,
    value: `UG`,
  },
];

export const countryOptionsV2: Array<OptionType> = [
  {
    label: `Canada`,
    value: `Canada`,
  },
  {
    label: `USA`,
    value: `USA`,
  },
  {
    label: `United Kingdom`,
    value: `United Kingdom`,
  },
  {
    label: `Germany`,
    value: `Germany`,
  },
  {
    label: `Australia`,
    value: `Australia`,
  },
  {
    label: `NewZealand`,
    value: `NewZealand`,
  },
  {
    label: `Singapore`,
    value: `Singapore`,
  },
  {
    label: `Ireland`,
    value: `Ireland`,
  },
  {
    label: `Netherlands`,
    value: `Netherlands`,
  },
  {
    label: `China`,
    value: `China`,
  },
];

export const orderedPopularCountries: Array<CheckBoxOptionType> = [
  { id: 1, label: `Australia` },
  { id: 2, label: `UK` },
  { id: 3, label: `Ireland` },
  { id: 4, label: `Canada` },
  { id: 5, label: `USA` },
  { id: 6, label: `NewZealand` },
];

export const englishExamType: Array<OptionType> = [
  {
    label: `IELTS`,
    value: `IELTS`,
  },
  {
    label: `TOEFL`,
    value: `TOEFL`,
  },
  {
    label: `DUOLINGO`,
    value: `DUOLINGO`,
  },
  {
    label: `PTE`,
    value: `PTE`,
  },
];

export const AUTH_FAILURE_MESSAGE = `AUTH_FAILUTE`;

export const EDIT_APP_DETAILS_ON_STAGES = [
  `APPLICATION_RECEIVED`,
  `APPLICATION_ON_HOLD`,
  `AGGREGATOR_ON_HOLD_RESOLVED`,
];

export const TASK_FILTERS_CLIENT_STORAGE_KEY = `task-filters`;

export enum ApplicationStages {
  APPLICATION_RECEIVED = `APPLICATION_RECEIVED`,
  APPLICATION_ON_HOLD = `APPLICATION_ON_HOLD`,
  AGGREGATOR_ON_HOLD_RESOLVED = `AGGREGATOR_ON_HOLD_RESOLVED`,
  DOCUMENT_VERIFIED = `DOCUMENT_VERIFIED`,
  APPLICATION_SENT_TO_UNIVERSITY = `APPLICATION_SENT_TO_UNIVERSITY`,
  APPLICATION_IN_ASSESSMENT = `APPLICATION_IN_ASSESSMENT`,
  APPLICATION_ON_HOLD_BY_UNIVERSITY = `APPLICATION_ON_HOLD_BY_UNIVERSITY`,
  UNIVERSITY_ON_HOLD_RESOLVED = `UNIVERSITY_ON_HOLD_RESOLVED`,
  UNIVERSITY_COMMUNICATION_PENDING = `UNIVERSITY_COMMUNICATION_PENDING`,
  CONDITIONAL_ADMIT_RECEIVED = `CONDITIONAL_ADMIT_RECEIVED`,
  UNCONDITIONAL_ADMIT_RECEIVED = `UNCONDITIONAL_ADMIT_RECEIVED`,
  APPLICATION_DROPPED = `APPLICATION_DROPPED`,
  APPLICATION_REJECTED = `APPLICATION_REJECTED`,
  ADMIT_ACCEPTED = `ADMIT_ACCEPTED`,
  OFFER_REVOKED = `OFFER_REVOKED`,
  ADMIT_DECLINED = `ADMIT_DECLINED`,
}

export const shortlistRequestDocumentDetails = [
  { label: `Maths Evaluation`, value: `MATHS_EVALUATION` },
  { label: `GAP`, value: `GAP` },
  { label: `MOI`, value: `MOI` },
  { label: `Work Experience Certificate`, value: `WORK_EX` },
  { label: `Work Appointment Letter`, value: `WORK_APPOINTMENT_LETTER` },
  { label: `Pay Slip`, value: `PAY_SLIP` },
  { label: `Internship Certificate`, value: `INTERNSHIP_CERTIFICATE` },
  { label: `Ielts Score Card`, value: `IELTS_SCORE_CARD` },
  { label: `Toefl Score Card`, value: `TOEFL_SCORE_CARD` },
  { label: `Duolingo Score Card`, value: `DUOLINGO_SCORE_CARD` },
  { label: `GRE Score Card`, value: `GRE_SCORE_CARD` },
  { label: `GMAT Score Card`, value: `GMAT_SCORE_CARD` },
  { label: `PTE Score Card`, value: `PTE_SCORE_CARD` },
  { label: `Tenth Mark Sheet`, value: `TENTH_MARK_SHEET` },
  { label: `Tenth Certificate`, value: `TENTH_CERTIFICATE` },
  { label: `Twelfth Mark Sheet`, value: `TWELFTH_MARK_SHEET` },
  { label: `Twelfth Certificate`, value: `TWELFTH_CERTIFICATE` },
  { label: `Diploma Mark Sheet`, value: `DIPLOMA_MARK_SHEET` },
  { label: `Diploma Certificate`, value: `DIPLOMA_CERTIFICATE` },
  { label: `PGD Mark Sheet`, value: `PGD_MARK_SHEET` },
  { label: `PGD Certificate`, value: `PGD_CERTIFICATE` },
  { label: `UG DMC`, value: `UG_DMC` },
  {
    label: `UG Consolidated Mark Sheet`,
    value: `UG_CONSOLIDATED_MARK_SHEET`,
  },
  { label: `UG Passing Certificate`, value: `UG_PASSING_CERT` },
  { label: `UG First Sem Mark Sheet`, value: `UG_FIRST_SEM_MARK_SHEET` },
  { label: `UG Second Sem Mark Sheet`, value: `UG_SECOND_SEM_MARK_SHEET` },
  { label: `UG Third Sem Mark Sheet`, value: `UG_THIRD_SEM_MARK_SHEET` },
  { label: `UG Fourth Sem Mark Sheet`, value: `UG_FOURTH_SEM_MARK_SHEET` },
  { label: `UG Fifth Sem Mark Sheet`, value: `UG_FIFTH_SEM_MARK_SHEET` },
  { label: `UG Six Sem Mark Sheet`, value: `UG_SIX_SEM_MARK_SHEET` },
  { label: `UG Seven Sem Mark Sheet`, value: `UG_SEVEN_SEM_MARK_SHEET` },
  { label: `UG Eight Sem Mark Sheet`, value: `UG_EIGHT_SEM_MARK_SHEET` },
  { label: `UG Ninth Sem Mark Sheet`, value: `UG_NINTH_SEM_MARK_SHEET` },
  { label: `UG Tenth Sem Mark Sheet`, value: `UG_TENTH_SEM_MARK_SHEET` },
  {
    label: `UG Credit Requirement and Grading System`,
    value: `UG_CREDIT_AND_GRADING_SYSTEM`,
  },
  { label: `UG Backlog Certificate`, value: `UG_BACKLOG_CERTIFICATE` },
  {
    label: `Provisional Degree Certificate`,
    value: `PROVISIONAL_DEGREE_CERTIFICATE`,
  },
  { label: `Final Degree Certificate`, value: `FINAL_DEGREE_CERTIFICATE` },
  { label: `Backlog Letter`, value: `BACKLOG_LETTER` },
  { label: `Transcript Graduation`, value: `TRANSCRIPT_GRADUATION` },
  { label: `PG DMC`, value: `PG_DMC` },
  {
    label: `PG Consolidated Mark Sheet`,
    value: `PG_CONSOLIDATED_MARK_SHEET`,
  },
  { label: `PG Passing Cert`, value: `PG_PASSING_CERT` },
  { label: `PG First Sem Mark Sheet`, value: `PG_FIRST_SEM_MARK_SHEET` },
  { label: `PG Second Sem Mark Sheet`, value: `PG_SECOND_SEM_MARK_SHEET` },
  { label: `PG Third Sem Mark Sheet`, value: `PG_THIRD_SEM_MARK_SHEET` },
  { label: `PG Fourth Sem Mark Sheet`, value: `PG_FOURTH_SEM_MARK_SHEET` },
  { label: `PG Fifth Sem Mark Sheet`, value: `PG_FIFTH_SEM_MARK_SHEET` },
  { label: `PG Six Sem Mark Sheet`, value: `PG_SIX_SEM_MARK_SHEET` },
  {
    label: `PG Credit Requirement and Grading System`,
    value: `PG_CREDIT_AND_GRADING_SYSTEM`,
  },
  { label: `PG Backlog Certificate`, value: `PG_BACKLOG_CERTIFICATE` },
  {
    label: `Transcript Higher Education`,
    value: `TRANSCRIPT_HIGHER_EDUCATION`,
  },
  { label: `PHD Mark Sheet`, value: `PHD_MARK_SHEET` },
  { label: `PHD Certificate`, value: `PHD_CERTIFICATE` },
  { label: `Resume`, value: `RESUME` },
  { label: `Passport Front`, value: `PASSPORT_FRONT` },
  { label: `Passport Back`, value: `PASSPORT_BACK` },
  { label: `Consolidated Passport`, value: `CONSOLIDATED_PASSPORT` },
  { label: `Counseling Other`, value: `COUNSELING_OTHER` },
];
