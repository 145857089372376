import * as React from 'react';
import { SVGProps } from 'react';

const DocumentNotificationIcon = (
  props: SVGProps<SVGSVGElement> & { active?: boolean },
) => (
  <svg
    width={20}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18 2h-8L8.59.59C8.21.21 7.7 0 7.17 0H2C.9 0 .01.9.01 2L0 14c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2Zm-5 3c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2Zm4 8H9v-1c0-1.33 2.67-2 4-2s4 .67 4 2v1Z"
      fill={props.active ? `#F2A156` : `#b7b7b7`}
    />
  </svg>
);

export default DocumentNotificationIcon;
