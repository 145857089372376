import * as React from 'react';
import { SVGProps } from 'react';

const CommissionWarningIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.2908 3.85923L1.82075 17.9992C1.64612 18.3017 1.55372 18.6445 1.55274 18.9937C1.55176 19.343 1.64224 19.6864 1.81518 19.9898C1.98812 20.2931 2.23748 20.546 2.53846 20.7231C2.83944 20.9002 3.18155 20.9954 3.53075 20.9992H20.4708C20.82 20.9954 21.1621 20.9002 21.463 20.7231C21.764 20.546 22.0134 20.2931 22.1863 19.9898C22.3593 19.6864 22.4497 19.343 22.4488 18.9937C22.4478 18.6445 22.3554 18.3017 22.1808 17.9992L13.7108 3.85923C13.5325 3.56533 13.2815 3.32235 12.9819 3.15371C12.6824 2.98508 12.3445 2.89648 12.0008 2.89648C11.657 2.89648 11.3191 2.98508 11.0196 3.15371C10.72 3.32235 10.469 3.56533 10.2908 3.85923Z"
      stroke="#BA3B3B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 8.99902V12.999"
      stroke="#BA3B3B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 17H12.01"
      stroke="#BA3B3B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CommissionWarningIcon;
