import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import { generalApi } from 'services/generalApi';
import { definitions } from 'types/schema';

export type SharedLists = definitions['FilterListDto'];

const initialState = {} as SharedLists;

export const sharedSlice = createSlice({
  name: `sharedLists`,
  initialState,
  reducers: {
    updateList: (_state, action: PayloadAction<SharedLists>) => {
      return action.payload;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      generalApi.endpoints.getSharedList.matchFulfilled,
      (state, { payload }) => {
        return payload;
      },
    );
  },
});

export const { updateList } = sharedSlice.actions;

export const selectList = (state: RootState) => {
  return state.sharedList || [];
};

export default sharedSlice.reducer;
