import { geebeeRootBaseApiWithTags } from '@/services/base/geebeeApi';
import {
  GetSopPlagiarismDetailsRequestType,
  GetSopPlagiarismDetailsResponseType,
  SopDraftPlagiarismVerificationRequestType,
  SopDraftPlagiarismVerificationResponseType,
  SopQcDetailsVerificationRequestType,
  SopQcDetailsVerificationResponseType,
  UpdateSopPlagiarismDetailsRequestType,
  UpdateSopPlagiarismDetailsResponseType,
} from '@/types/Services';
import { transformResponse } from '@/utils/common';

export const plagiarismApi = geebeeRootBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getSopPlagiarismDetailsForQC: builder.query<
      GetSopPlagiarismDetailsResponseType,
      GetSopPlagiarismDetailsRequestType
    >({
      query: (payload) => `/plagiarism/sop-qc/${payload?.applicationId}`,
      transformResponse,
      providesTags: [`GetSopQcPlagiarismDetails`],
    }),
    updateSopPlagiarismDetailsForQC: builder.mutation<
      UpdateSopPlagiarismDetailsResponseType,
      UpdateSopPlagiarismDetailsRequestType
    >({
      query: (payload) => ({
        url: `/plagiarism/sop-qc/${payload?.applicationId}`,
        method: `PUT`,
        body: payload?.updateSopQcDetailsDto,
      }),
      transformResponse,
      invalidatesTags: [`GetSopQcPlagiarismDetails`],
    }),
    updateVerificationStatusForSopQcDetails: builder.mutation<
      SopQcDetailsVerificationResponseType,
      SopQcDetailsVerificationRequestType
    >({
      query: (payload) => ({
        url: `/plagiarism/sop-qc/${payload?.id}/verification-status/${payload?.verificationStatus}`,
        method: `PUT`,
      }),
      transformResponse,
      invalidatesTags: [`GetSopQcPlagiarismDetails`],
    }),
    getSopDraftPlagiarismDetails: builder.query<
      SopDraftPlagiarismVerificationResponseType,
      SopDraftPlagiarismVerificationRequestType
    >({
      query: (payload) =>
        `/plagiarism/document-qc/${payload?.documentKey}/${payload?.documentId}`,
      transformResponse,
    }),
  }),
});

export const {
  useGetSopPlagiarismDetailsForQCQuery,
  useUpdateSopPlagiarismDetailsForQCMutation,
  useUpdateVerificationStatusForSopQcDetailsMutation,
  useGetSopDraftPlagiarismDetailsQuery,
} = plagiarismApi;
