import { OPTION_TYPE } from '@leapfinance/geebee-component-library';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';
import { CourseType } from '@/components/CF/Dashboard/CfCoursesDashboard/CourseCardWrapper/SimilarCoursesCarousel';
import { DegreeIntakeObj } from '@/components/CF/Dashboard/DegreeCountryUtil';
import { GetCFSuggestionsUtil } from '@/components/CF/Dashboard/GetCFSuggestionsUtil';
import {
  CFCourse,
  CfUniversitiesType,
} from '@/components/CF/Dashboard/helpers';
import { courseFinderApi } from '@/services/courseFinder';
import { definitions } from '@/types/schema';
import { NoResultInfoResponseType } from '@/types/Services';
import SnackbarUtils from '@/utils/snackbar';

export enum CoursesType {
  COURSES,
  ELIGIBLE_COURSES,
  NON_ELIGIBLE_COURSES,
}
export enum CourseSection {
  COURSES,
  ELIGIBILITY,
}
export enum EligiblityTabs {
  ELIGIBLE,
  NON_ELIGIBLE,
}

export enum ResultSection {
  COURSE,
  UNIVERSITY,
}

export type Courses = definitions[`CourseFinderCourseCardDto`][];
type NoResultDataType = NoResultInfoResponseType[`data`];
type CoursesMap = Record<number, definitions[`CourseFinderCourseCardDto`][]>;
type UniversitesMap = Record<number, CfUniversitiesType>;

type Aggregation = definitions['CfAggregationResponseDto'];

interface CourseData {
  courses: CoursesMap;
  page: number;
  totalCourses: number;
  specializationCourses?: Courses;
  subStreamCourses?: Courses;
  aggregations: Aggregation[];
  totalCoursesMoreThanLimit?: boolean;
  usps: definitions['UniversityUspResponseDto'][];
  specializationUsps: definitions['UniversityUspResponseDto'][];
  subStreamUsps: definitions['UniversityUspResponseDto'][];
  uspAdditionalCourses: Courses;
  europeanCourses: Courses;
  hasOpenIntakes?: boolean;
}

export interface EligiblityCoursesData extends CourseData {
  selectedCategoryTabCriteria?: definitions['SelectedCategoryTabCriteria'];
}
export interface EligibilityCourseData {
  eligibleCourseData: EligiblityCoursesData;
  nonEligibleCourseData: EligiblityCoursesData;
  aggregations: Aggregation[];
  activeEligiblityTab?: EligiblityTabs;
  isEligibilityFactorsCardVisible?: boolean;
}

interface UniversityData {
  universities: UniversitesMap;
  specialisationUniversities: CfUniversitiesType;
  subStreamUniversities: CfUniversitiesType;
  page: number;
  totalUniversities: number;
}

interface CourseFinderSlice {
  activeCourseSection: CourseSection;
  coursesData: CourseData;
  eligiblityCourseData: EligibilityCourseData;
  studentInfo: {
    shortlistCount: number;
    studentId?: number | null;
    studentName?: string | null;
    shortlistedCourses?: CFCourse[];
  };
  selectedCourses: CFCourse[];
  universityData: UniversityData;
  noResultsInfo?: NoResultDataType;
  degreeIntakeMapping: DegreeIntakeObj;
  activeResultSection: ResultSection;
  similarCoursesforCourseId: Record<number, CourseType[]> | null;
  filterState: {
    expanded: boolean;
  };
}

const initalCourseData: CourseData = {
  courses: [],
  page: 0,
  aggregations: [],
  totalCourses: 0,
  specializationCourses: [],
  specializationUsps: [],
  subStreamCourses: [],
  subStreamUsps: [],
  usps: [],
  europeanCourses: [],
  uspAdditionalCourses: [],
};
const initialEligiblityCourseData: EligibilityCourseData = {
  eligibleCourseData: {
    ...initalCourseData,
    selectedCategoryTabCriteria: {
      consideredCriteria: [],
      notConsideredCriteria: [],
    },
  },
  nonEligibleCourseData: {
    ...initalCourseData,
    selectedCategoryTabCriteria: {
      consideredCriteria: [],
      notConsideredCriteria: [],
    },
  },
  activeEligiblityTab: EligiblityTabs.ELIGIBLE,
  aggregations: [],
};

const initialState = {
  activeCourseSection: CourseSection.COURSES,
  coursesData: initalCourseData,
  eligiblityCourseData: initialEligiblityCourseData,
  universityData: {
    universities: [],
    page: 1,
    totalUniversities: 0,
    specialisationUniversities: [],
    subStreamUniversities: [],
  },
  degreeIntakeMapping: {
    disabledDegrees: [],
    popularIntakes: [],
  },
  activeResultSection: ResultSection.COURSE,
  studentInfo: {
    shortlistCount: 0,
    shortlistedCourses: [],
  },
  selectedCourses: [],
  filterState: {
    expanded: false,
  },
  similarCoursesforCourseId: null,
} as CourseFinderSlice;

export const cfStateSlice = createSlice({
  name: `cfStateSlice`,
  initialState,
  reducers: {
    setActiveCourseSection: (state, action: PayloadAction<CourseSection>) => {
      state.activeCourseSection = action.payload;
      if (action.payload === CourseSection.COURSES) {
        state.eligiblityCourseData.isEligibilityFactorsCardVisible = false; // hide on section change
      }
    },
    setCourseData: (state, action: PayloadAction<CourseData>) => {
      state.coursesData = action.payload;
    },
    setEligiblityCourseData: (
      state,
      action: PayloadAction<EligibilityCourseData>,
    ) => {
      state.eligiblityCourseData.aggregations = action.payload.aggregations;
      state.eligiblityCourseData.eligibleCourseData =
        action.payload.eligibleCourseData;
      state.eligiblityCourseData.nonEligibleCourseData =
        action.payload.nonEligibleCourseData;
      state.eligiblityCourseData.activeEligiblityTab =
        state.eligiblityCourseData.activeEligiblityTab ??
        EligiblityTabs.ELIGIBLE;
      state.eligiblityCourseData.eligibleCourseData.aggregations =
        action.payload.eligibleCourseData.aggregations;
      state.eligiblityCourseData.nonEligibleCourseData.aggregations =
        action.payload.nonEligibleCourseData.aggregations;
    },
    appendCoursesToPage: (
      state,
      action: PayloadAction<{
        courseType: CoursesType;
        courses: Courses;
        pageNumber: number;
        resetOtherPages?: boolean;
      }>,
    ) => {
      switch (action.payload.courseType) {
        case CoursesType.COURSES:
          if (action.payload.resetOtherPages) {
            state.coursesData.courses = {
              [action.payload.pageNumber]: action.payload.courses ?? [],
            };
          } else {
            state.coursesData.courses[action.payload.pageNumber] =
              action.payload.courses;
          }
          state.coursesData.page = action.payload.pageNumber;
          break;
        case CoursesType.ELIGIBLE_COURSES:
          if (action.payload.resetOtherPages) {
            state.eligiblityCourseData.eligibleCourseData.courses = {
              [action.payload.pageNumber]: action.payload.courses ?? [],
            };
          } else {
            state.eligiblityCourseData.eligibleCourseData.courses[
              action.payload.pageNumber
            ] = action.payload.courses;
          }

          state.eligiblityCourseData.eligibleCourseData.page =
            action.payload.pageNumber;
          break;
        case CoursesType.NON_ELIGIBLE_COURSES:
          if (action.payload.resetOtherPages) {
            state.eligiblityCourseData.nonEligibleCourseData.courses = {
              [action.payload.pageNumber]: action.payload.courses ?? [],
            };
          } else {
            state.eligiblityCourseData.nonEligibleCourseData.courses[
              action.payload.pageNumber
            ] = action.payload.courses;
          }

          state.eligiblityCourseData.nonEligibleCourseData.page =
            action.payload.pageNumber;
          break;
        default:
          break;
      }
    },
    setCourseSectionPage: (
      state,
      action: PayloadAction<{
        courseType: CoursesType;
        pageNumber: number;
      }>,
    ) => {
      switch (action.payload.courseType) {
        case CoursesType.COURSES:
          state.coursesData.page = action.payload.pageNumber;
          break;
        case CoursesType.ELIGIBLE_COURSES:
          state.eligiblityCourseData.eligibleCourseData.page =
            action.payload.pageNumber;
          break;
        case CoursesType.NON_ELIGIBLE_COURSES:
          state.eligiblityCourseData.nonEligibleCourseData.page =
            action.payload.pageNumber;
          break;
        default:
          break;
      }
    },
    updateStudentShortlistCount: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        studentInfo: { ...state?.studentInfo, shortlistCount: action.payload },
      };
    },
    setActiveEligiblityTab: (state, action: PayloadAction<EligiblityTabs>) => {
      state.eligiblityCourseData.activeEligiblityTab = action.payload;
    },
    setSpecialisationCourses: (
      state,
      action: PayloadAction<{
        courseType: CoursesType;
        usps: definitions['UniversityUspResponseDto'][];
        courses: Courses;
      }>,
    ) => {
      switch (action.payload.courseType) {
        case CoursesType.COURSES:
          state.coursesData.specializationCourses = action.payload.courses;
          state.coursesData.specializationUsps = action.payload.usps;
          break;
        case CoursesType.ELIGIBLE_COURSES:
          state.eligiblityCourseData.eligibleCourseData.specializationCourses =
            action.payload.courses;
          state.eligiblityCourseData.eligibleCourseData.specializationUsps =
            action.payload.usps;
          break;
        case CoursesType.NON_ELIGIBLE_COURSES:
          state.eligiblityCourseData.nonEligibleCourseData.specializationCourses =
            action.payload.courses;
          state.eligiblityCourseData.nonEligibleCourseData.specializationUsps =
            action.payload.usps;
          break;

        default:
          break;
      }
    },
    setSubStreamCourses: (
      state,
      action: PayloadAction<{
        courseType: CoursesType;
        usps: definitions['UniversityUspResponseDto'][];
        courses: Courses;
      }>,
    ) => {
      switch (action.payload.courseType) {
        case CoursesType.COURSES:
          state.coursesData.subStreamCourses = action.payload.courses;
          state.coursesData.subStreamUsps = action.payload.usps;
          break;
        case CoursesType.ELIGIBLE_COURSES:
          state.eligiblityCourseData.eligibleCourseData.subStreamCourses =
            action.payload.courses;
          state.eligiblityCourseData.eligibleCourseData.subStreamUsps =
            action.payload.usps;
          break;
        case CoursesType.NON_ELIGIBLE_COURSES:
          state.eligiblityCourseData.nonEligibleCourseData.subStreamCourses =
            action.payload.courses;
          state.eligiblityCourseData.nonEligibleCourseData.subStreamUsps =
            action.payload.usps;
          break;

        default:
          break;
      }
    },
    appendAggregations: (
      state,
      action: PayloadAction<{
        aggregations: Aggregation[];
        activeCourseSection: CourseSection;
      }>,
    ) => {
      switch (action.payload.activeCourseSection) {
        case CourseSection.COURSES:
          state.coursesData.aggregations =
            GetCFSuggestionsUtil.mergeAggregations(
              state.coursesData.aggregations,
              action.payload.aggregations,
            );

          break;
        case CourseSection.ELIGIBILITY:
          state.eligiblityCourseData.aggregations =
            GetCFSuggestionsUtil.mergeAggregations(
              state.eligiblityCourseData.aggregations,
              action.payload.aggregations,
            );
          break;
        default:
          break;
      }
    },
    setUniversityData: (state, action: PayloadAction<UniversityData>) => {
      state.universityData = action.payload;
    },
    setUniversityPage: (state, action: PayloadAction<number>) => {
      state.universityData.page = action.payload;
    },
    appendUniversitiesToPage: (
      state,
      action: PayloadAction<{
        universities: CfUniversitiesType;
        pageNumber: number;
        resetOtherPages?: boolean;
      }>,
    ) => {
      if (action.payload.resetOtherPages) {
        state.universityData.universities = {
          [action.payload.pageNumber]: action.payload.universities ?? [],
        };
      } else {
        state.universityData.universities[action.payload.pageNumber] =
          action.payload.universities;
      }
      state.universityData.page = action.payload.pageNumber;
    },
    setSpecialisationUniversities: (
      state,
      action: PayloadAction<{
        universities: CfUniversitiesType;
      }>,
    ) => {
      state.universityData.specialisationUniversities =
        action.payload.universities;
    },
    setSubStreamUniversities: (
      state,
      action: PayloadAction<{
        universities: CfUniversitiesType;
      }>,
    ) => {
      state.universityData.subStreamUniversities = action.payload.universities;
    },
    setDegreeIntakeMap: (state, action: PayloadAction<DegreeIntakeObj>) => {
      state.degreeIntakeMapping = { ...action.payload };
    },
    setNoResultData: (state, action: PayloadAction<NoResultDataType>) => {
      state.noResultsInfo = action.payload;
    },
    setEuropeanCourses: (
      state,
      action: PayloadAction<{
        courses: Courses;
        courseType: CoursesType;
      }>,
    ) => {
      switch (action.payload.courseType) {
        case CoursesType.COURSES:
          state.coursesData.europeanCourses = action.payload.courses;
          break;
        case CoursesType.ELIGIBLE_COURSES:
          state.eligiblityCourseData.eligibleCourseData.europeanCourses =
            action.payload.courses;
          break;
        case CoursesType.NON_ELIGIBLE_COURSES:
          state.eligiblityCourseData.nonEligibleCourseData.europeanCourses =
            action.payload.courses;
          break;
        default:
          break;
      }
    },
    setUspAdditionalCourses: (
      state,
      action: PayloadAction<{
        courseType: CoursesType;
        usps: definitions['UniversityUspResponseDto'][];
        courses: Courses;
      }>,
    ) => {
      switch (action.payload.courseType) {
        case CoursesType.COURSES:
          state.coursesData.uspAdditionalCourses = action.payload.courses;
          break;
        case CoursesType.ELIGIBLE_COURSES:
          state.eligiblityCourseData.eligibleCourseData.uspAdditionalCourses =
            action.payload.courses;
          break;
        case CoursesType.NON_ELIGIBLE_COURSES:
          state.eligiblityCourseData.nonEligibleCourseData.uspAdditionalCourses =
            action.payload.courses;
          break;
        default:
          break;
      }
    },

    setActiveResultSection: (state, action: PayloadAction<ResultSection>) => {
      state.activeResultSection = action.payload;
    },

    setIsEligibilityFactorsCardVisible: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.eligiblityCourseData.isEligibilityFactorsCardVisible =
        action.payload;
    },
    clearAllShortlistedCourses: (state) => {
      state.studentInfo.shortlistedCourses = [];
    },
    clearAllSelectedCourses: (state) => {
      state.selectedCourses = [];
    },
    addOrRemoveSelectedCourses: (state, action: PayloadAction<CFCourse>) => {
      const currentSelectedCourses = state.selectedCourses ?? [];
      const courseIndex = currentSelectedCourses.findIndex(
        (selectedCourse) =>
          selectedCourse?.courseId === action.payload?.courseId,
      );
      if (courseIndex < 0) {
        if (currentSelectedCourses.length === 15) {
          SnackbarUtils.warning(`Max only 15 courses can be selected`);
          return;
        }
        state.selectedCourses = [...currentSelectedCourses, action.payload];
      } else {
        const tempCourses = [...currentSelectedCourses];
        tempCourses.splice(courseIndex, 1);
        state.selectedCourses = tempCourses;
      }
    },
    updateSelectedCourses: (state, action: PayloadAction<Array<CFCourse>>) => {
      state.selectedCourses = action.payload ?? [];
    },
    updateStudent: (store, action: PayloadAction<OPTION_TYPE | null>) => {
      if (!action.payload) {
        store.studentInfo.studentId = null;
        store.studentInfo.studentName = null;
        return;
      }
      if (!action.payload.value) return;
      store.studentInfo.studentId = Number(action.payload.value);
      store.studentInfo.studentName = action.payload.label;
    },
    updateSimilarCoursesForCourseId: (
      store,
      action: PayloadAction<{ courseId: number; courses: CourseType[] }>,
    ) => {
      if (!action.payload) {
        store.similarCoursesforCourseId = null;
        return;
      }
      store.similarCoursesforCourseId = {
        ...store.similarCoursesforCourseId,
        [action.payload.courseId]: action.payload.courses,
      };
    },
    setFilterExpandedState: (
      store,
      action: PayloadAction<{ expanded: boolean }>,
    ) => {
      store.filterState.expanded = action.payload.expanded;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      courseFinderApi.endpoints.getStudentShortlistedCourses.matchFulfilled,
      (state, { payload }) => {
        state.studentInfo.shortlistedCourses = payload.data;
      },
    );
  },
});

export const selectStudentShortlistCount = (state: RootState) =>
  state.cfStateSlice.studentInfo?.shortlistCount;

export const selectCourseData = (state: RootState, courseType: CoursesType) => {
  switch (courseType) {
    case CoursesType.COURSES:
      return state.cfStateSlice.coursesData;
    case CoursesType.ELIGIBLE_COURSES:
      return state.cfStateSlice.eligiblityCourseData.eligibleCourseData;

    case CoursesType.NON_ELIGIBLE_COURSES:
      return state.cfStateSlice.eligiblityCourseData.nonEligibleCourseData;

    default:
      return initalCourseData;
  }
};

export const selectActiveCourseSection = (state: RootState) =>
  state.cfStateSlice.activeCourseSection;

export const selectCoursesToDisplay = (state: RootState) => {
  const cfSliceData = state.cfStateSlice;
  if (cfSliceData.activeCourseSection === CourseSection.COURSES) {
    const currentPage = cfSliceData.coursesData.page;
    return cfSliceData.coursesData.courses?.[currentPage] ?? [];
  } else if (cfSliceData.activeCourseSection === CourseSection.ELIGIBILITY) {
    const activeTab = cfSliceData.eligiblityCourseData.activeEligiblityTab;
    if (activeTab === EligiblityTabs.ELIGIBLE) {
      const currentPage =
        cfSliceData.eligiblityCourseData.eligibleCourseData.page;
      return (
        cfSliceData.eligiblityCourseData.eligibleCourseData.courses?.[
          currentPage
        ] ?? []
      );
    }
    const currentPage =
      cfSliceData.eligiblityCourseData.nonEligibleCourseData.page;
    return (
      cfSliceData.eligiblityCourseData.nonEligibleCourseData.courses?.[
        currentPage
      ] ?? []
    );
  } else {
    return [];
  }
};

export const selectActiveCoursesType = (state: RootState) => {
  const cfSliceData = state.cfStateSlice;
  if (cfSliceData.activeCourseSection === CourseSection.COURSES) {
    return CoursesType.COURSES;
  } else if (cfSliceData.activeCourseSection === CourseSection.ELIGIBILITY) {
    const activeTab = cfSliceData.eligiblityCourseData.activeEligiblityTab;
    if (activeTab === EligiblityTabs.ELIGIBLE) {
      return CoursesType.ELIGIBLE_COURSES;
    }
    return CoursesType.NON_ELIGIBLE_COURSES;
  } else {
    return CoursesType.COURSES;
  }
};

export const hasOpenIntakes = (state: RootState) => {
  const cfSliceData = state.cfStateSlice;
  if (cfSliceData.activeCourseSection === CourseSection.COURSES) {
    return state.cfStateSlice.coursesData?.hasOpenIntakes;
  } else if (cfSliceData.activeCourseSection === CourseSection.ELIGIBILITY) {
    const activeTab = cfSliceData.eligiblityCourseData.activeEligiblityTab;
    if (activeTab === EligiblityTabs.ELIGIBLE) {
      return state.cfStateSlice.eligiblityCourseData?.eligibleCourseData
        ?.hasOpenIntakes;
    }
    return state.cfStateSlice.eligiblityCourseData?.nonEligibleCourseData
      ?.hasOpenIntakes;
  } else {
    return state.cfStateSlice.coursesData?.hasOpenIntakes;
  }
};

export const selectAggregations = (state: RootState) => {
  if (state.cfStateSlice.activeCourseSection === CourseSection.ELIGIBILITY) {
    return state.cfStateSlice.eligiblityCourseData.aggregations;
  } else {
    return state.cfStateSlice.coursesData.aggregations;
  }
};

export const selectFilterExpandedState = (state: RootState) =>
  state?.cfStateSlice?.filterState?.expanded;

export const selectActiveEligibilityTab = (state: RootState) => {
  return state.cfStateSlice.eligiblityCourseData.activeEligiblityTab;
};

export const selectUniversityData = (state: RootState) =>
  state.cfStateSlice.universityData;

export const selectDegreeIntakeMap = (state: RootState) => {
  return state.cfStateSlice.degreeIntakeMapping;
};

export const selectNoResultData = (state: RootState) => {
  return state?.cfStateSlice?.noResultsInfo;
};

export const selectStudentCfShortlistCount = (state: RootState) => {
  return state?.cfStateSlice?.studentInfo?.shortlistCount;
};

export const selectEuropeanCourses = (state: RootState) => {
  return state?.cfStateSlice?.coursesData?.europeanCourses;
};

export const selectEligibilityAggregation = (state: RootState) => {
  return {
    0: state?.cfStateSlice?.eligiblityCourseData?.eligibleCourseData
      ?.aggregations,
    1: state?.cfStateSlice?.eligiblityCourseData?.nonEligibleCourseData
      ?.aggregations,
  };
};

export const selectEligibilityCoursesTotalCount = (
  state: RootState,
  sectionType: EligiblityTabs,
) => {
  const courseData =
    sectionType === EligiblityTabs.ELIGIBLE
      ? state?.cfStateSlice?.eligiblityCourseData?.eligibleCourseData
      : state?.cfStateSlice?.eligiblityCourseData?.nonEligibleCourseData;
  const eligibleTotalCount =
    courseData?.totalCourses +
    (courseData?.specializationCourses?.length ?? 0) +
    (courseData?.subStreamCourses?.length ?? 0) +
    (courseData?.europeanCourses?.length ?? 0) +
    (courseData?.uspAdditionalCourses?.length ?? 0);
  return eligibleTotalCount;
};

export const selectActiveResultSection = (state: RootState) =>
  state?.cfStateSlice?.activeResultSection;

export const selectIsEligibilityFactorsCardVisible = (state: RootState) =>
  state?.cfStateSlice?.eligiblityCourseData?.isEligibilityFactorsCardVisible;

export const shortlistedCoursesForStudent = (store: RootState) =>
  store.cfStateSlice.studentInfo.shortlistedCourses;
export const selectSelectedCourses = (store: RootState) =>
  store.cfStateSlice.selectedCourses;
export const selectStudentInfo = (store: RootState) =>
  store.cfStateSlice.studentInfo;
export const selectSimilarCoursesForCourseId = (
  store: RootState,
  courseId?: number,
) => {
  if (courseId) {
    return store.cfStateSlice.similarCoursesforCourseId?.[courseId];
  }
};

export const {
  setActiveCourseSection,
  setCourseData,
  setEligiblityCourseData,
  appendCoursesToPage,
  updateStudentShortlistCount,
  setCourseSectionPage,
  setActiveEligiblityTab,
  setSubStreamCourses,
  setSpecialisationCourses,
  appendAggregations,
  setUniversityData,
  appendUniversitiesToPage,
  setSpecialisationUniversities,
  setSubStreamUniversities,
  setUniversityPage,
  setDegreeIntakeMap,
  setNoResultData,
  setEuropeanCourses,
  setUspAdditionalCourses,
  setActiveResultSection,
  setIsEligibilityFactorsCardVisible,
  updateStudent,
  addOrRemoveSelectedCourses,
  clearAllSelectedCourses,
  clearAllShortlistedCourses,
  updateSelectedCourses,
  updateSimilarCoursesForCourseId,
  setFilterExpandedState,
} = cfStateSlice.actions;

export default cfStateSlice.reducer;
