import {
  GetUserCountryGroupsType,
  GetUserInfoListResponseType,
} from '@/types/Services';

import {
  BaseResponse,
  GetAllDownloadsPresignedUrlResponseType,
  GetAllDownloadsResponse,
  GetDocumentMappingResponse,
  GetGeebeeSharedList,
  GetSharedListResponse,
} from '../types/service';

import { geebeeRootBaseApiWithTags } from './base/geebeeApi';

export const generalApi = geebeeRootBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getCountries: builder.query<BaseResponse<string[]>, void>({
      query: () => `users/countries`,
    }),
    getSharedList: builder.query<GetSharedListResponse, void>({
      query: () => `shared/lists`,
    }),
    getGeebeeSharedList: builder.query<GetGeebeeSharedList, void>({
      query: () => `/shared/filter-values`,
    }),
    getDocumentMapping: builder.query<GetDocumentMappingResponse, void>({
      query: () => `/v1/documents/mapping`,
    }),
    getDownloads: builder.query<GetAllDownloadsResponse, void>({
      query: () => `/request-download`,
    }),
    getDownloadAllPresignedUrl: builder.query<
      GetAllDownloadsPresignedUrlResponseType,
      { downloadRequestId: string }
    >({
      query: (downloadRequestId) =>
        `/request-download/${downloadRequestId}/download`,
    }),
    getUsersByRole: builder.query<GetUserInfoListResponseType, void>({
      query: () => `/shared/get-users-by-role`,
    }),
    getAllUsers: builder.query<GetUserInfoListResponseType, void>({
      query: () => `/shared/get-all-users`,
    }),
    getAssociatedUsers: builder.query<GetUserInfoListResponseType, void>({
      query: () => `/shared/get-associated-users`,
    }),
    getUserCountryGroups: builder.query<GetUserCountryGroupsType, void>({
      query: () => `/users-country/options`,
    }),
  }),
});

export const {
  useLazyGetCountriesQuery,
  useGetSharedListQuery,
  useGetDocumentMappingQuery,
  useGetGeebeeSharedListQuery,
  useGetDownloadsQuery,
  useLazyGetDownloadAllPresignedUrlQuery,
  useGetUsersByRoleQuery,
  useGetAllUsersQuery,
  useGetAssociatedUsersQuery,
  useLazyGetSharedListQuery,
  useGetUserCountryGroupsQuery,
} = generalApi;
