import * as React from 'react';
import { SVGProps } from 'react';

const SendIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width={24}
    height={26}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.37 11.994 1.907.85C.828.353-.37 1.344.11 2.459L2.71 9.662a2 2 0 0 0 1.542 1.291l12.523 2.156-12.523 2.155a2 2 0 0 0-1.542 1.292L.11 23.759c-.36 1.114.72 2.105 1.798 1.486L23.37 14.099c.84-.371.84-1.61 0-2.105Z"
      fill={props.fill || `#443EFF`}
    />
  </svg>
);

export default SendIcon;
