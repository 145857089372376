import { documentUploadBaseApi } from '@/services/base/documentUpload';
import {
  UploadProfilePictureRequestType,
  UploadProfilePictureResponseType,
} from '@/types/service';
import {
  CreateShortlistRequestResponseType,
  FileUploadResponse,
  UploadProofOfRequestApiRequestType,
  UploadProofOfRequestApiResponseType,
} from '@/types/Services';

import { transformResponse } from './util';

export const documentUploadApi = documentUploadBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    upload: builder.mutation<
      FileUploadResponse,
      { file: File; key: string; studentId: number }
    >({
      query: ({ file, key, studentId }) => {
        const queryObj: any = {
          url: `v1/documents/doc/${key}/${studentId}`,
          method: `POST`,
        };
        const formData = new FormData();
        formData.append(`file`, file);
        queryObj[`body`] = formData;
        return queryObj;
      },
      invalidatesTags: [],
    }),
    uploadAndSave: builder.mutation<
      FileUploadResponse,
      {
        file: File;
        key: string;
        applicationId?: number;
        fileLabel?: string;
        studentId: number;
        referenceLabel?: string;
        referenceLabelId?: number;
        id?: number;
      }
    >({
      query: ({
        file,
        key,
        applicationId,
        studentId,
        fileLabel,
        referenceLabel,
        referenceLabelId,
        id,
      }) => {
        const queryObj: any = {
          url: `v1/documents`,
          method: `POST`,
        };
        const formData = new FormData();
        formData.append(`file`, file, file.name.split(`,`).join(`_`));
        formData.append(`keyName`, key);
        if (applicationId) {
          formData.append(`applicationId`, String(applicationId));
        }
        if (fileLabel) {
          formData.append(`fileLabel`, fileLabel);
        }
        if (referenceLabel) {
          formData.append(`referenceLabel`, referenceLabel);
        }
        if (referenceLabelId) {
          formData.append(`referenceLabelId`, String(referenceLabelId));
        }
        if (id) {
          formData.append(`documentId`, String(id));
        }
        formData.append(`studentId`, String(studentId));
        queryObj[`body`] = formData;
        return queryObj;
      },
      invalidatesTags: [`documents`],
    }),
    addRemark: builder.mutation<
      boolean,
      { file?: File; comment?: string; applicationId: number }
    >({
      query: ({ file, comment, applicationId }) => {
        if (!file && !comment) return null;
        const queryObj: any = {
          url: `applications/remark`,
          method: `POST`,
        };
        const formData = new FormData();
        formData.append(`remark`, comment || ``);
        formData.append(`givenByTeam`, `CSP`);
        formData.append(`applicationId`, String(applicationId));
        if (file) {
          formData.append(`file`, file);
        }
        queryObj[`body`] = formData;
        return queryObj;
      },
    }),
    // need to think about automatic refetching using tags
    addStudentRemark: builder.mutation<
      boolean,
      { file?: File; remark?: string; studentId: number; fileName?: string }
    >({
      query: ({ file, remark, studentId }) => {
        if (!file && !remark) return null;
        const queryObj: any = {
          url: `v1/students/remark`,
          method: `POST`,
        };
        const formData = new FormData();
        formData.append(`remark`, remark || ``);
        formData.append(`givenByTeam`, `CSP`);
        formData.append(`studentId`, `${studentId}`);

        if (file) {
          formData.append(`file`, file);
        }
        queryObj[`body`] = formData;
        return queryObj;
      },
      // TODO:: need to check why automatic refetch not working
      invalidatesTags: [`Student`, `Remarks`, `documents`],
    }),

    uploadProofOfRequest: builder.mutation<
      UploadProofOfRequestApiResponseType,
      UploadProofOfRequestApiRequestType
    >({
      query: ({ studentId, file }) => {
        const formData = new FormData();
        //all the APIs for uploading a File have unknown as type from backend schema
        if (file) {
          formData.append(`file`, file as unknown as File);
        }

        return {
          url: `/shortlist/student/${studentId}/upload/proof-of-request`,
          method: `POST`,
          body: formData,
        };
      },
      transformResponse: transformResponse,
    }),
    uploadProfilePicture: builder.mutation<
      UploadProfilePictureResponseType,
      UploadProfilePictureRequestType
    >({
      query: ({ file, userId }) => {
        const formData = new FormData();

        formData.append(`file`, file as File);
        const queryObj: any = {
          url: `/users/${userId}/profile-pic`,
          body: formData,
          method: `POST`,
        };
        return queryObj;
      },
      transformResponse: transformResponse,
    }),
  }),
});

export const {
  useUploadMutation,
  useAddRemarkMutation,
  useUploadAndSaveMutation,
  useAddStudentRemarkMutation,
  useUploadProofOfRequestMutation,
  useUploadProfilePictureMutation,
} = documentUploadApi;
