import { useRef } from 'react';
import { Form } from 'react-final-form';
import {
  FFDropdown,
  FFTextInput,
  GBModalWrapper,
  GBThemeWrapper,
} from '@leapfinance/geebee-component-library';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { FormApi } from 'final-form';

import { selectList } from '@/app/features/sharedList/sharedListSlice';
import { useAppSelector } from '@/app/hooks';
import { MODAL_TYPES } from '@/components/modals/GlobalModal';
import useModal from '@/components/modals/useModal';
import { useGetAllSopOwnersQuery } from '@/services/sop';
import { getSharedListOptions } from '@/utils/helperFunctions';

export interface SOPMoreFiltersModalProps {
  filters: any;
  onApply: (filters: any) => void;
}

const SOPMoreFiltersModal = ({
  filters,
  onApply,
}: SOPMoreFiltersModalProps) => {
  const formRef = useRef<FormApi>();
  const { data } = useGetAllSopOwnersQuery();
  const sopOwnerMasterList = (data?.data?.owners ?? [])?.map((owner) => {
    return { label: owner?.name, value: owner?.id };
  });
  const sharedList = useAppSelector((state) => selectList(state));
  const countryOptions = getSharedListOptions(`countries`, sharedList ?? {});
  const { hideModal } = useModal();

  const onClose = () => {
    hideModal(MODAL_TYPES.SOP_MORE_FILTERS);
  };

  const handleSubmit = (values: any) => {
    onApply({
      ...filters,
      ...values,
      requestId: values?.requestId,
    });
    onClose();
  };

  const handleClearForm = () => {
    onApply({});
    onClose();
  };

  return (
    <GBThemeWrapper>
      <GBModalWrapper
        headerProps={{
          title: `SOP Filters`,
          onClose: onClose,
        }}
        footerProps={{
          primaryCta: {
            title: `Apply Filters`,
            buttonProps: {
              onClick: () => formRef?.current?.submit(),
            },
          },
          secondaryCta: {
            title: `Clear All`,
            buttonProps: {
              onClick: handleClearForm,
            },
          },
        }}
        variant="small"
      >
        <Form
          onSubmit={handleSubmit}
          initialValues={filters}
          render={({ form }) => {
            formRef.current = form;

            return (
              <div className="grid grid-cols-1 p-4 gap-2">
                <FFTextInput
                  fieldProps={{
                    name: `requestId`,
                  }}
                  componentProps={{
                    label: `Request ID`,
                    placeholder: `Enter Request ID`,
                    size: `small`,
                    className: `bg-white-0`,
                    type: `number`,
                    InputProps: {
                      startAdornment: <SearchRoundedIcon />,
                    },
                  }}
                />
                <FFDropdown
                  fieldProps={{ name: `applicationCountry` }}
                  componentProps={{
                    size: `small`,
                    textInputProps: {
                      label: `Country`,
                      placeholder: `Country`,
                      size: `small`,
                      className: `bg-white-0`,
                    },
                    disableClearable: false,
                    options: countryOptions ?? [],
                  }}
                />
                <FFDropdown
                  fieldProps={{ name: `assignedToId` }}
                  componentProps={{
                    size: `small`,
                    textInputProps: {
                      label: `SOP Owner`,
                      placeholder: `Select Owner`,
                      size: `small`,
                      className: `bg-white-0`,
                    },
                    disableClearable: false,
                    options: sopOwnerMasterList,
                  }}
                />
              </div>
            );
          }}
        />
      </GBModalWrapper>
    </GBThemeWrapper>
  );
};

export default SOPMoreFiltersModal;
