import React from 'react';
import { FieldProps, FieldRenderProps, useForm } from 'react-final-form';
import {
  FFStudentContactNumberField,
  isFalsy,
} from '@leapfinance/frontend-commons';
import { type FFStudentContactNumberFieldProps } from '@leapfinance/frontend-commons/components/geebee/FFStudentContactNumberField';
import { Typography } from '@mui/material';

import useCheckDuplicateStudent from '@/hooks/vas/useCheckDuplicateStudent';

import { isStudentExisting } from './helpers';
import StudentAlreadyExistWarning from './StudentAlreadyExistWarning';

export type FFVASContactNumberFieldProps = Omit<
  FFStudentContactNumberFieldProps,
  'onRedirectToExistingStudent' | 'handlePhoneNumberValidation'
> & {
  isStudentCreateModeOn?: boolean;
  handlePhoneNumberValidation?: (value: string) => void;
  onRedirectToExistingStudent?: () => void;
  isStudentSelected?: boolean;
} & {
  cspFieldProps?: FieldProps<
    any,
    FieldRenderProps<any, HTMLElement, any>,
    HTMLElement,
    any
  >;
};

function FFVASContactNumberField(props: FFVASContactNumberFieldProps) {
  const { getState, getFieldState } = useForm();
  const values = getState()?.values;
  const errors = getState()?.errors;
  const cspId = getFieldState(props?.cspFieldProps?.name ?? ``)?.value?.value;
  const countryCodeValue = (
    values?.[props?.countryCodeFieldProps?.name ?? ``] as any
  )?.value;
  const { handlePhoneNumberValidation, existingStudentData } =
    useCheckDuplicateStudent({
      cspId,
      countryCode: countryCodeValue,
      contactNumber: values?.[props?.phoneNumberFieldProps?.name ?? ``],
      // when no student selected then also show existing student warning
      skipDuplicateValidation: props.isStudentSelected,
    });

  return (
    <>
      <FFStudentContactNumberField
        {...props}
        formValues={values}
        existingStudentData={{
          data: {},
        }}
        handlePhoneNumberValidation={(value) => {
          if (props.isStudentCreateModeOn) {
            handlePhoneNumberValidation(
              value,
              (values?.countryCode as any)?.value,
              cspId,
            );
          }
        }}
        onRedirectToExistingStudent={() => {
          if (props.isStudentCreateModeOn) {
            window.open(
              `/student/${existingStudentData?.data?.studentId}?active=DETAILS`,
              `_blank`,
            );
          }
        }}
        combinedLabel={
          <Typography variant="subtitle2" className="text-gray-900 pb-2">
            Contact Number
          </Typography>
        }
      />
      {isStudentExisting({
        existingDuplicateStudent: existingStudentData?.data,
      }) &&
      isFalsy(errors?.[props?.phoneNumberFieldProps?.name ?? ``]) &&
      (props.isStudentCreateModeOn || !props.isStudentSelected) &&
      countryCodeValue === `+91` ? (
        <StudentAlreadyExistWarning />
      ) : null}
    </>
  );
}

export default FFVASContactNumberField;
