import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Form } from 'react-final-form';
import {
  Button,
  FFDropDown,
  FFEmailInputs,
  FFRadio,
  FFTextInput,
  GICReferralCard,
  isFalsy,
  useFinalFormYupFormHelpers,
} from '@leapfinance/frontend-commons';
import { isOptionType } from '@leapfinance/frontend-commons/components/geebee/inputs/DropDown';
import GBTheme from '@leapfinance/frontend-commons/components/geebee/theme';
import { type OPTION_TYPE } from '@leapfinance/frontend-commons/schema/common';
import { Typography } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import { type FormApi } from 'final-form';
import omit from 'lodash/omit';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';

import { selectUserDetails } from '@/app/features/user/userSlice';
import { useAppSelector } from '@/app/hooks';
import useCreateStudent from '@/hooks/vas/useCreateStudent';
import useUniversitiesOptions from '@/hooks/vas/useUniversitiesOptions';
import { StudentPageTabsEnum } from '@/pages/student/[studentId]';
import { useUploadStudentDocumentMutation } from '@/services/documents';
import {
  useCreateGICRequestMutation,
  useGetGICBankNamesQuery,
  useGetGICRequestReferralCodeQuery,
  useLazyGetStudentsGicRequiredDocumentsQuery,
  useUploadGicLoaDocumentMutation,
} from '@/services/vas';
import { Document } from '@/types/documentSection';
import { VASCreateGICRequestBodyType } from '@/types/vas/gic';
import { getIntakeOptionsInShortForm } from '@/utils/formHelpers';
import {
  GICFormSchema,
  VasStudentCreationRequestBodyType,
  VASStudentCreationSchema,
} from '@/validations/vas.schema';

import { transformOptionValues } from '../CF/Dashboard/helpers';

import FFVASStudentField from './FFVASStudentField';
import GICRequiredDocuments, { GicDocument } from './GICRequiredDocuments';
import {
  getAddNewStudentButtonStyles,
  getVasRequestFormTitle,
} from './helpers';
import VASDrawerHeader from './VASDrawerHeader';
import VASSuccessScreen from './VASSuccessScreen';
export const passportDocumentKeys = [
  `PASSPORT_BACK`,
  `PASSPORT_FRONT`,
  `CONSOLIDATED_PASSPORT`,
] as Document[`keyName`][];

export type GICFormProps = {
  studentId?: number;
  student?: string;
  cspId?: number;
  cspName?: string;
  initialValues?: Partial<
    Record<
      | keyof VASCreateGICRequestBodyType
      | keyof VasStudentCreationRequestBodyType,
      any
    >
  >;
  onClose: () => void;
};

function VASGICFormController({
  student,
  studentId,
  initialValues = {},
  cspId,
  cspName,
  onClose,
}: GICFormProps) {
  const router = useRouter();
  const user = useAppSelector((state) => selectUserDetails(state));
  const [screen, setScreen] = useState<number>(0);
  const [requiredDocuments, setRequiredDocuments] = useState<Document[]>([]);
  const [uploadedDocuments, setUploadedDocuments] = useState<GicDocument[]>([]);
  const [isSubmittingGicRequest, setisSubmittingGicRequest] = useState(false);
  const [getGicRequiredDocuments, setGicRequiredDocuments] =
    useLazyGetStudentsGicRequiredDocumentsQuery();
  const [uploadDocumentTrigger, { isLoading }] =
    useUploadStudentDocumentMutation();
  const formRef: React.MutableRefObject<FormApi<any, any> | null> =
    useRef(null);
  const [validate, formNames] = useFinalFormYupFormHelpers({
    schema: GICFormSchema.concat(VASStudentCreationSchema),
  });
  const [fetchUniversities] = useUniversitiesOptions({
    serviceCategory: `GIC`,
  });
  const { handleCreateNewStudent } = useCreateStudent();
  const [uploadLoaTrigger] = useUploadGicLoaDocumentMutation();
  const [triggerCreateGICRequest, { data, isLoading: isCreatingGICRequest }] =
    useCreateGICRequestMutation();
  const { data: referralCodeData } = useGetGICRequestReferralCodeQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const { data: bankNameData } = useGetGICBankNamesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const bankOptions =
    bankNameData?.data?.map((item) => ({
      label: item.bankName,
      value: item.bankName,
      imgSrc: item.bankLogoUrl ?? ``,
      description: item.description ?? [],
      imageClassName: `lfc-w-12 lfc-h-12`,
    })) ?? [];

  const { enqueueSnackbar } = useSnackbar();

  const uploadedDocumentsKeys = React.useMemo(
    () => uploadedDocuments.map((doc) => doc.keyName),
    [uploadedDocuments],
  );

  const initialValuesData = useMemo(() => {
    if (isFalsy(initialValues))
      return {
        createStudentMode: `no`,
      };

    return {
      student: studentId
        ? {
            label: student,
            value: studentId,
          }
        : null,
      [formNames.contactNumber]: initialValues.contactNumber,
      [formNames.countryCode]: initialValues.countryCode,
      [formNames.emailId]: initialValues.emailId,
      [formNames.recipientEmailIds]: initialValues.recipientEmailIds,
      [formNames.cspId]: {
        label: cspName,
        value: cspId,
      },
      createStudentMode: `no`,
    };
  }, [initialValues]);

  useEffect(() => {
    handleStudentChange(initialValuesData?.student as OPTION_TYPE);
  }, [initialValuesData]);

  const handleSubmit = async (values: any) => {
    setisSubmittingGicRequest(true);
    try {
      let newStudentDetails;
      if (values?.createStudentMode === `yes`) {
        newStudentDetails = await handleCreateNewStudent(values);
      }

      const studentIdValue =
        values?.createStudentMode === `yes`
          ? newStudentDetails?.value ?? -1
          : studentId ?? values?.student?.value ?? -1;

      if (!studentIdValue || studentIdValue === -1) {
        enqueueSnackbar(`Something went wrong`, { variant: `error` });
        console.error(`Error in raising gic request`);
        return;
      }

      const newDocuments = uploadedDocuments
        .filter((doc) => !(doc.file as Document)?.id)
        .filter((doc) => doc?.keyName !== (`LETTER_OF_ACCEPTANCE` as any));

      const loaDocument = uploadedDocuments.find(
        (doc) => doc.keyName === (`LETTER_OF_ACCEPTANCE` as any),
      );
      const documentsWithUrl = await Promise.allSettled<Document | undefined>(
        newDocuments.map(
          (doc, index) =>
            new Promise(async (resolve, reject) => {
              const res = await uploadDocumentTrigger({
                keyName: doc.keyName as Document[`keyName`],
                file: doc.file,
                studentId: studentIdValue,
              }).unwrap();
              if (res.success) {
                resolve(res.data);
              } else {
                reject({ doc, index });
              }
            }),
        ),
      );

      //upload LOA document to be seperate
      const uploadedLoaDocument = await uploadLoaTrigger({
        studentId: studentIdValue as number,
        file: loaDocument?.file as File,
      }).unwrap();

      const documentUploadFailed =
        documentsWithUrl.some((data) => data.status === `rejected`) ||
        !uploadedLoaDocument?.success ||
        isFalsy(uploadedLoaDocument?.data);

      if (documentUploadFailed) {
        enqueueSnackbar(`Error in uploading documents`, {
          variant: `error`,
        });
        console.error(`Error in uploading documents`);
        return;
      }

      const response = await triggerCreateGICRequest({
        studentId: studentIdValue,
        body: {
          ...transformOptionValues(omit(values, [`student`])),
          passportDocuments: documentsWithUrl.map((doc: any) => doc?.value),
          loaDocumentUrl: uploadedLoaDocument?.data as string,
        },
      }).unwrap();

      if (response.success) {
        enqueueSnackbar(`Raised gic request successfully`, {
          variant: `success`,
        });
        // redirect to vas dashboard
        // check if existing page is same as this then avoid
        if (
          !(
            router.pathname === `/student/[studentId]` &&
            router.query.studentId === studentIdValue &&
            router.query.active === `VAS`
          )
        ) {
          router.push(`/student/${studentIdValue}?active=VAS`);
        }
      } else {
        enqueueSnackbar(`Unable to raise gic request`, {
          variant: `error`,
        });
      }

      if (!isFalsy(initialValuesData?.student)) {
        onClose();
      }
    } catch (err) {
      enqueueSnackbar(`Unable to raise gic request`, {
        variant: `error`,
      });
    } finally {
      setisSubmittingGicRequest(false);
    }
  };

  const handleStudentChange = async (student?: OPTION_TYPE) => {
    if (!student || !student?.value) {
      setRequiredDocuments([]);
      return;
    }
    const documentsResponse = await getGicRequiredDocuments({
      studentId: student?.value as number,
    }).unwrap();
    if (documentsResponse?.success) {
      setRequiredDocuments(documentsResponse?.data ?? []);
    } else {
      setRequiredDocuments([]);
    }
  };

  const passportRelatedDocuments: GicDocument[] = React.useMemo(
    () =>
      requiredDocuments
        ?.filter((doc) => passportDocumentKeys.includes(doc.keyName))
        .map((doc) => ({ file: doc, keyName: doc.keyName })) ?? [],
    [requiredDocuments],
  );

  const loaRelatedDocuments: GicDocument[] = React.useMemo(
    () =>
      requiredDocuments
        ?.filter((doc) =>
          ([`LETTER_OF_ACCEPTANCE`] as any[]).includes(doc.keyName),
        )
        .map((doc) => ({ file: doc, keyName: doc.keyName })) ?? [],
    [requiredDocuments],
  );

  return (
    <ThemeProvider theme={GBTheme}>
      <div className="relative h-full">
        <div className="h-full overflow-hidden overflow-y-auto pb-24">
          <VASDrawerHeader
            title={getVasRequestFormTitle(`gic`)}
            onClose={onClose}
          />
          <Form<VASCreateGICRequestBodyType & VasStudentCreationRequestBodyType>
            initialValues={initialValuesData as any}
            validate={async (values) => {
              const validationresult = await validate?.(values as any);
              const passportDocumentsUploaded = !uploadedDocumentsKeys.some(
                (key) =>
                  passportDocumentKeys.includes(key as Document['keyName']),
              );
              const loaDocumentsUploaded = !uploadedDocumentsKeys.some((key) =>
                [`LETTER_OF_ACCEPTANCE`].includes(key as Document['keyName']),
              );
              const nonKeyNameDocuments = uploadedDocumentsKeys.some(
                (key) => !key,
              );
              return {
                ...validationresult,
                ...(passportDocumentsUploaded
                  ? {
                      passportDocument: [
                        `Student passport document is required`,
                      ],
                    }
                  : {}),
                ...(loaDocumentsUploaded
                  ? {
                      loaDocument: [
                        `Letter of Acceptance document is required`,
                      ],
                    }
                  : {}),
                ...(nonKeyNameDocuments
                  ? { nonKeyDocument: [`Select Document type`] }
                  : {}),
              };
            }}
            onSubmit={handleSubmit}
            render={({ form, initialValues, values, errors, handleSubmit }) => {
              const shouldDisableStudentField = !isFalsy(
                initialValues?.student?.value,
              );
              console.log(errors);
              formRef.current = form;

              if (data?.success && data?.data) {
                return (
                  <VASSuccessScreen
                    successScreenProps={{
                      title: `GIC request received!`,
                      subTitle: `We will send the GIC to the student’s phone number and email ID.`,
                    }}
                    trackRequestStatusCardProps={{
                      type: `gic`,
                      onClick: () => {
                        window.open(`/vas/dashboard`, `_blank`);
                      },
                    }}
                  />
                );
              }

              return (
                <>
                  <form className="px-4 py-6">
                    {screen === 0 ? (
                      <>
                        <div className="w-full px-4 pb-6">
                          <FFRadio
                            fieldProps={{
                              name: formNames.bankName,
                            }}
                            componentProps={{
                              type: `icon-tab`,
                              options: bankOptions,
                              row: false,
                              label: (
                                <Typography
                                  variant="subtitle2"
                                  className="text-gray-900"
                                >
                                  Select a bank to continue (Required)
                                </Typography>
                              ),
                              formControlLabelProps: {
                                sx: {
                                  width: `100%`,
                                  marginBottom: `1rem`,
                                },
                              },
                              radioGroupContainerClasses: `w-full grid grid-cols-12 gap-x-2 mr-0 pl-2`,
                              radioComponentContainerClasses: `col-span-12`,
                            }}
                          />
                        </div>
                        {referralCodeData?.success && referralCodeData?.data ? (
                          <GICReferralCard
                            referralCodeText={referralCodeData.data}
                          />
                        ) : null}
                      </>
                    ) : null}
                    {screen === 1 ? (
                      <>
                        <FFVASStudentField
                          onStudentChange={handleStudentChange}
                          dobFieldProps={{
                            name: formNames.dob,
                          }}
                          genderFieldProps={{
                            name: formNames.gender,
                          }}
                          maritalStatusFieldProps={{
                            name: formNames.maritalStatus,
                          }}
                          recipientEmailIdProps={{
                            name: formNames.recipientEmailIds,
                          }}
                          cspFieldProps={{
                            name: formNames.cspId,
                          }}
                          contactNumberFieldProps={{
                            countryCodeFieldProps: {
                              name: formNames.countryCode,
                            },
                            phoneNumberFieldProps: {
                              name: formNames.contactNumber,
                            },
                            countryCodeComponentProps: {
                              options: [],
                            },
                            formValues: values,
                          }}
                          createOrSearchStudentFieldProps={{
                            studentFieldProps: {
                              name: formNames.student,
                            },
                            firstNameFieldProps: {
                              name: formNames.firstName,
                            },
                            lastNameFieldProps: {
                              name: formNames.lastName,
                            },
                            studentcomponentProps: {
                              disabled: shouldDisableStudentField,
                              options: [],
                              fetchOptionsHandler: () => {},
                            },
                            addStudentLabelButtonProps: {
                              disabled: shouldDisableStudentField,
                              className: getAddNewStudentButtonStyles(
                                user,
                                shouldDisableStudentField,
                              ),
                            },
                            addStudentIconProps: {
                              className: `text-grey-400`,
                            },
                            onCreateStudentClick: (value) => {
                              setRequiredDocuments([]);
                            },
                          }}
                          emailFieldProps={{
                            name: formNames.emailId,
                          }}
                          serviceCategory="GIC"
                          studentId={
                            studentId ?? (values as any)?.student?.value
                          }
                        />
                        <div className="w-full pt-4">
                          <FFTextInput
                            fieldProps={{
                              name: formNames.emailId,
                            }}
                            componentProps={{
                              placeholder: `Please enter student’s email id.`,
                              label: (
                                <Typography
                                  variant="subtitle2"
                                  className="text-gray-900"
                                >
                                  Email Id
                                </Typography>
                              ),
                              // disabled: shouldDisableEmailField,
                            }}
                          />
                        </div>
                        <div className="w-full pt-4">
                          <FFDropDown
                            fieldProps={{
                              name: formNames.universityName,
                            }}
                            componentProps={{
                              options: [],
                              async: {
                                // @ts-ignore
                                loadOptions: fetchUniversities,
                              },
                              placeholder: `Please enter university`,
                              disableClearable: false,
                              label: (
                                <Typography
                                  variant="subtitle2"
                                  className="text-gray-900"
                                >
                                  Select a university
                                </Typography>
                              ),
                              creatable: true,
                            }}
                          />
                        </div>
                        <div className="w-full pt-4">
                          <FFDropDown
                            fieldProps={{
                              name: formNames.intake,
                            }}
                            componentProps={{
                              options: getIntakeOptionsInShortForm().slice(11),
                              label: (
                                <Typography
                                  variant="subtitle2"
                                  className="text-gray-900"
                                >
                                  Intake
                                </Typography>
                              ),
                              placeholder: `Please select Intake`,
                            }}
                          />
                        </div>

                        <div className="border rounded-lg mt-4">
                          <div className="px-4 py-2">
                            <Typography variant="subtitle1">
                              Student Documents
                            </Typography>
                          </div>
                          <div className="p-4 gap-4 flex flex-col border-t">
                            <GICRequiredDocuments
                              passportDocuments={passportRelatedDocuments ?? []}
                              loaDocuments={loaRelatedDocuments ?? []}
                              studentId={
                                !Number.isNaN(Number(studentId))
                                  ? Number(studentId)
                                  : undefined
                              }
                              uploadedDocuments={setUploadedDocuments}
                            />
                          </div>
                        </div>

                        <div className="w-full pt-4">
                          <FFEmailInputs
                            fieldProps={{
                              name: formNames.recipientEmailIds,
                            }}
                            componentProps={{
                              separateTopLabel: (
                                <Typography
                                  variant="subtitle2"
                                  className="text-gray-900 pb-2"
                                >
                                  Recipient Emails(Type and press enter to
                                  select value)
                                </Typography>
                              ),
                            }}
                          />
                        </div>

                        {referralCodeData?.success && referralCodeData?.data ? (
                          <GICReferralCard
                            referralCodeText={referralCodeData.data}
                          />
                        ) : null}
                      </>
                    ) : null}
                  </form>
                  <>
                    {data?.success && data?.data ? null : (
                      <div className="w-full absolute bottom-0 right-0 p-4 bg-white-0 shadow-t-lg">
                        <div
                          className={`flex ${
                            screen === 1
                              ? `items-center gap-x-2`
                              : `justify-end`
                          }`}
                        >
                          {screen === 1 ? (
                            <>
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  setScreen(0);
                                }}
                                className="w-1/2"
                              >
                                <Typography
                                  variant="subtitle2"
                                  className="normal-case text-primary-500"
                                >
                                  Back
                                </Typography>
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  form.submit();
                                }}
                                loading={isSubmittingGicRequest}
                                className="w-1/2"
                              >
                                <Typography
                                  variant="subtitle2"
                                  className="text-white normal-case"
                                >
                                  Raise Request
                                </Typography>
                              </Button>
                            </>
                          ) : null}
                          {screen === 0 ? (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setScreen(1);
                              }}
                              className="w-1/2"
                              disabled={!isFalsy(errors?.[formNames.bankName])}
                            >
                              <Typography
                                variant="subtitle2"
                                className="text-white normal-case"
                              >
                                Next
                              </Typography>
                            </Button>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </>
                </>
              );
            }}
          />
        </div>
      </div>
    </ThemeProvider>
  );
}

export default VASGICFormController;
