import React, { useEffect, useState } from 'react';
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import CloseIcon from '@mui/icons-material/Close';
// Icons
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
// Icons
import { Button, IconButton, Popover } from '@mui/material';
import {
  NovuProvider,
  useNotifications,
  useSocket,
  useUnseenCount,
} from '@novu/notification-center';
import { selectUserDetails } from 'app/features/user/userSlice';
import { useAppSelector } from 'app/hooks';
import { useRouter } from 'next/router';
import novuInstance from 'utils/novu/api.service';

import { DashboardTabTypesEnum } from '@/components/SummaryDashboard/UserPerformanceViewDashboard/constants';
import { selectDashboardActiveTab } from '@/features/generalSlice';
import { trackClick, trackPage } from '@/segment/eventTracker';
import { PageNames, WidgetFormat, WidgetName } from '@/types/segmentEvents';
import {
  BrowserNotificationAccessStatus,
  ComponentIdMapper,
  getBrowserNotificationTitle,
} from '@/utils/constants';
import CustomButton from 'components/CustomMUIComponents/CustomButton';
import {
  NotificationCategory,
  NotificationTab,
} from 'components/Notifications';

const Noti = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [unread, setUnread] = useState<any>();
  const [displayNotification, setDisplayNotification] = useState<any>(false);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [notificationPermissionStatus, setNotificationPermissionStatus] =
    useState(`default`);
  const [seenCount, setSeenCount] = useState<number | null>(null);

  const router = useRouter();
  const { unseenCount, setUnseenCount } = useUnseenCount();
  const { refetch, notifications } = useNotifications();
  const { socket } = useSocket();
  const userInfo = useAppSelector((state) => selectUserDetails(state));
  const dashboardActiveTab = useAppSelector((state) =>
    selectDashboardActiveTab(state),
  );

  useEffect(() => {
    checkNotificationAccessStatus();
    return () =>
      window?.addEventListener(`beforeunload`, function () {
        if (socket) {
          socket?.off(`unseen_count_changed`);
        }
      });
  }, []);

  useEffect(() => {
    if (unseenCount === 0) {
      setUnreadCount(0);
    }
    document &&
      document?.addEventListener(`visibilitychange`, updateLastSeenCount);
    return () =>
      document?.removeEventListener(`visibilitychange`, updateLastSeenCount);
  }, [unseenCount]);

  useEffect(() => {
    const unreadNotifications = notifications?.filter(
      (notification) => notification?.seen === false,
    );
    setUnread(unreadNotifications[0]);
  }, [notifications]);

  useEffect(() => {
    try {
      if (
        `Notification` in window &&
        unread &&
        displayNotification &&
        !anchorEl
      ) {
        const notification = new Notification(
          getBrowserNotificationTitle(unread?.templateIdentifier),
          {
            body: `Please check and take action`,
          },
        );
        notification.onclick = () => {
          trackClick({
            pageName: PageNames.SPEEDUP_SUMMARY_DASHBOARD,
            widgetName: WidgetName.NOTIFICATION_TOAST,
            widgetFormat: WidgetFormat.TOAST,
            contentName: getBrowserNotificationTitle(
              unread?.templateIdentifier,
            ),
            contentFormat: `Toast Notification`,
            notificationType: unread?.templateIdentifier,
          });
          window.open(
            `/view-application/${unread?.payload?.applicationId}`,
            `_blank`,
          );
        };
        notification.onshow = () => {
          trackPage({
            pageName: PageNames.SPEEDUP_SUMMARY_DASHBOARD,
            widgetName: WidgetName.NOTIFICATION_TOAST,
            widgetFormat: WidgetFormat.TOAST,
            notificationType: unread?.templateIdentifier,
          });
        };
        setUnread(null);
        setDisplayNotification(false);
      }
    } catch (error) {
      console.error(`Failed to trigger browser notification`, error);
    }
  }, [unread]);

  useEffect(() => {
    if (socket) {
      let previous = unseenCount;
      socket.on(`unseen_count_changed`, (data) => {
        setUnreadCount(data?.unseenCount - Number(seenCount));
        refetch();
        if (previous > data?.unseenCount) {
          previous = data?.unseenCount;
        }
        if (data?.unseenCount > previous && `Notification` in window) {
          setDisplayNotification(true);
        }
        setUnseenCount(data.unseenCount);
      });
    }

    return () => {
      if (socket) {
        socket.off(`unseen_count_changed`);
      }
    };
  }, [socket, seenCount]);

  const handleClick = () => {
    const element = document.getElementById(
      ComponentIdMapper.NOTIFICATION_DISPLAY,
    );
    setAnchorEl(element);
    setUnreadCount(0);
  };

  const handleClose = () => {
    setUnreadCount(0);
    setAnchorEl(null);
  };

  const triggerNotificationRequest = () => {
    try {
      trackPage({
        pageName: PageNames.SPEEDUP_SUMMARY_DASHBOARD,
        widgetName: WidgetName.NOTIFICATION_PERMISSION_TOAST,
        widgetFormat: WidgetFormat.TOAST,
      });
      if (
        `Notification` in window &&
        Notification.permission === BrowserNotificationAccessStatus.DENIED
      ) {
        alert(`Please reset settings in your browser to provide access`);
        return;
      }
      if (`Notification` in window) {
        Notification.requestPermission().then((permission) => {
          setNotificationPermissionStatus(permission);
          if (permission === BrowserNotificationAccessStatus.GRANTED) {
            trackClick({
              pageName: PageNames.SPEEDUP_SUMMARY_DASHBOARD,
              widgetName: WidgetName.NOTIFICATION_PERMISSION_TOAST,
              widgetFormat: WidgetFormat.TOAST,
              contentName: `Allow`,
              contentFormat: `Accept`,
            });
          }
          if (permission === BrowserNotificationAccessStatus.DENIED) {
            trackClick({
              pageName: PageNames.SPEEDUP_SUMMARY_DASHBOARD,
              widgetName: WidgetName.NOTIFICATION_PERMISSION_TOAST,
              widgetFormat: WidgetFormat.TOAST,
              contentName: `Block`,
              contentFormat: `Reject`,
            });
          }
        });
      }
    } catch (error) {
      console.error(`Failed to request notification access`, error);
    }
  };

  const checkNotificationAccessStatus = () => {
    try {
      setNotificationPermissionStatus(Notification?.permission);
    } catch (error) {
      console.error(`Failed to verify notification access`, error);
    }
  };

  const updateLastSeenCount = () => {
    if (document.visibilityState === `hidden`) {
      setSeenCount(unseenCount);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? `simple-popover` : undefined;
  return (
    <>
      {unreadCount > 0 &&
      router.asPath.includes(`dashboard/summary`) &&
      !anchorEl &&
      dashboardActiveTab === DashboardTabTypesEnum.MY_TASK ? (
        <div
          className="cursor-pointer underline absolute top-[28%] left-[48%] z-[500] bg-[#bebbff] rounded-full px-4 py-2 text-black-100 flex items-center"
          onClick={handleClick}
        >
          <p className="mr-2">
            {unreadCount} new update{unreadCount === 1 ? `` : `s`}
          </p>
          <CachedRoundedIcon color="inherit" />
        </div>
      ) : null}
      {notificationPermissionStatus !==
      BrowserNotificationAccessStatus.GRANTED ? (
        <Button
          variant="outlined"
          className="text-primary-500 border border-primary-500 font-sans h-fit my-auto"
          style={{ textTransform: `none` }}
          onClick={triggerNotificationRequest}
        >
          Allow Notifications
        </Button>
      ) : null}
      <IconButton
        onClick={handleClick}
        className="h-16 w-16 relative"
        id={ComponentIdMapper.NOTIFICATION_DISPLAY}
      >
        <NotificationsOutlinedIcon />
        {!!unseenCount && (
          <div className="absolute h-10 w-10  text-xs ">
            <p className="bg-red-600  h-4 w-4 p-3 rounded-full flex items-center justify-center text-white-0">
              {unseenCount > 99 ? `99+` : unseenCount}
            </p>
          </div>
        )}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: `bottom`,
          horizontal: `right`,
        }}
        sx={{
          top: `4%`,
          left: `-3%`,

          '.MuiPaper-root': {
            width: `500px !important`,
            overflowX: `hidden`,
            height: `540px`,
            overflowY: `hidden`,
          },
        }}
      >
        <div>
          <div className="flex items-center w-full justify-between px-4 pt-2">
            <p className="text-lg font-semibold">Notifications</p>
            <div className="flex">
              <CustomButton
                disabled={unseenCount === 0}
                text="Mark all as read"
                variant="text"
                className={
                  unseenCount === 0 ? `text-grey-500` : `text-primary-500`
                }
                onClick={() => {
                  novuInstance
                    .markAllAsRead(String(userInfo.userId))
                    .then(() => refetch());
                }}
              />
              <IconButton
                onClick={() => setAnchorEl(null)}
                className="rounded-full"
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div
            className="absolute  bottom-0 left-0 w-full bg-primary-500 text-white text-center cursor-pointer"
            onClick={() => {
              setAnchorEl(null);
              router.push(`/notifications/all`);
            }}
          >
            <p className="p-4 text-white-0">View All</p>
          </div>

          <NotificationTab compactView={true} height={`540px`} />
        </div>
      </Popover>
    </>
  );
};

const NotificationDropDown = () => {
  const userInfo = useAppSelector((state) => selectUserDetails(state));

  if (!userInfo) return null;
  return (
    <NovuProvider
      subscriberId={String(userInfo.userId)}
      applicationIdentifier={String(process.env.NOVU_IDENTIFIER)}
      backendUrl={process.env.NOVU_BACKEND_URL || undefined}
      socketUrl={process.env.NOVU_SOCKET_URL || undefined}
      stores={[
        { storeId: `All` },
        {
          storeId: NotificationCategory.document,
          query: { feedIdentifier: NotificationCategory.document },
        },
        {
          storeId: NotificationCategory.state,
          query: { feedIdentifier: NotificationCategory.state },
        },
        {
          storeId: NotificationCategory.remarks,
          query: { feedIdentifier: NotificationCategory.remarks },
        },
      ]}
    >
      <Noti />
    </NovuProvider>
  );
};

export default NotificationDropDown;
