import { multiDocsType, multiVisaDocsType } from "components/commons/ApplicationOptionsMapper";
import { Document } from "./context/interface";

export const multiDocsTypes = [
  multiDocsType.UNCONDITIONAL_OFFER_LETTER,
  multiDocsType.CONDITIONAL_OFFER_LETTER,
  multiDocsType.FINANCIAL_DOCUMENT,
  multiDocsType.ACADEMIC_DOCUMENTS,
  multiDocsType.MEDICAL_CERTIFICATE,
  multiDocsType.FEE_PAYMENT_RECEIPT,
  multiDocsType.TT,
];
export const multiVisaDocsTypes = [multiVisaDocsType.UNCONDITIONAL_OFFER_LETTER, multiVisaDocsType.FEE_PAYMENT_RECEIPT];

export const filterDocuments = (docs: Array<Document>, key: string) => {
  return docs.filter((doc: Document) => doc?.uploadedFrom?.toLowerCase() === key?.toLowerCase());
};
