import * as React from 'react';
import { SVGProps } from 'react';

const NoNotification = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={80}
    height={80}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={80} height={80} rx={40} fill="#EAF5FB" />
    <path
      d="M49.233 47.647c.135.136.267.334.384.555L33.942 32.53A6.993 6.993 0 0 1 40 28.999c3.86 0 7 3.141 7 7.001v7.5c0 1.092.581 2.496 1.353 3.267l.88.88ZM39 52a1 1 0 0 0 2 0v-1h-2v1Zm23.5-12c0 12.426-10.074 22.5-22.5 22.5S17.5 52.426 17.5 40 27.574 17.5 40 17.5 62.5 27.574 62.5 40ZM45 50a1 1 0 0 0-1-1H30.072c.128-.5.406-1.063.695-1.353l.88-.88C32.419 45.997 33 44.592 33 43.5V38a1 1 0 0 0-2 0v5.5c0 .558-.373 1.459-.767 1.853l-.88.88C28.58 47.004 28 48.409 28 49.5v.5a1 1 0 0 0 1 1h8v1c0 1.654 1.346 3 3 3s3-1.346 3-3v-1h1a1 1 0 0 0 1-1Zm9.707 3.293-2.833-2.833A.97.97 0 0 0 52 50v-.5c0-1.091-.581-2.496-1.353-3.267l-.88-.88C49.373 44.958 49 44.058 49 43.5V36c0-4.624-3.507-8.442-8-8.941v-1.06a1 1 0 1 0-2 0v1.075a8.994 8.994 0 0 0-6.519 3.993l-5.774-5.774a1 1 0 0 0-1.414 1.414l28 28a.996.996 0 0 0 1.414 0 1 1 0 0 0 0-1.414Z"
      fill="#2F9ED5"
    />
  </svg>
);

export default NoNotification;
