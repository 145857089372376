export const HEADER_HEIGHT = 70;
export enum ComponentIdEnum {
  STUDENT_COMMISSION_TAB = `student-commission-tab`,
  CALCULATION_COMMISSION_TAB = `calculation-commission-tab`,
  MY_DOWNLOADS = `commission-my-downloads`,
}
export enum ComponentsClassEnum {
  COMMISSION_TABS = `commission-tabs`,
}

export enum CustomEvents {
  COMMISSION_TAB_CHANGE = `commissionTabChange`,
}

/**
 * Replaces all occurrences of hyphens with slashes in a date string.
 *
 * @param {string} dateString - The date string to be fixed.
 * @returns {string} The fixed date string with hyphens replaced by slashes.
 */
export const fixDateForAllBrowsers = (dateString: string) => {
  return dateString?.replace(/-/g, `/`);
};

export enum CommentStatus {
  IDLE = `IDLE`,
  UPLOADING = `UPLOADING`,
  FAILED = `FAILED`,
}

export const formatRemarksDate = (dateString: any) => {
  const inputDate = new Date(dateString);
  const day = inputDate.getDate().toString().padStart(2, `0`);
  const month = (inputDate.getMonth() + 1).toString().padStart(2, `0`);
  const year = inputDate.getFullYear();
  const hours = inputDate.getHours().toString().padStart(2, `0`);
  const minutes = inputDate.getMinutes().toString().padStart(2, `0`);
  const seconds = inputDate.getSeconds().toString().padStart(2, `0`);
  const ampm = inputDate.getHours() >= 12 ? `PM` : `AM`;

  const formattedDate = `${day}/${month}/${year}, ${hours}:${minutes}:${seconds} ${ampm}`;
  return formattedDate;
};
