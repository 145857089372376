import React, { useMemo, useRef } from 'react';
import { Form } from 'react-final-form';
import {
  Button,
  FFDropDown,
  FFEmailInputs,
  FFTextInput,
  isFalsy,
  useFinalFormYupFormHelpers,
} from '@leapfinance/frontend-commons';
import { type OPTION_TYPE } from '@leapfinance/frontend-commons/schema/common';
import { Typography } from '@mui/material';
import { type FormApi } from 'final-form';
import omit from 'lodash/omit';
import { useSnackbar } from 'notistack';

import { selectUserDetails } from '@/app/features/user/userSlice';
import { useAppSelector } from '@/app/hooks';
import useCreateStudent from '@/hooks/vas/useCreateStudent';
import usePreferredCityOptions from '@/hooks/vas/usePreferredCityOptions';
import useUniversitiesOptions from '@/hooks/vas/useUniversitiesOptions';
import { useGetSourceCountriesQuery } from '@/services/courseFinder';
import { useCreateAccomodationRequestMutation } from '@/services/vas';
import { VASCreateAccomodationRequestBodyType } from '@/types/vas/accomodation';
import { getIntakeOptionsInShortForm } from '@/utils/formHelpers';
import {
  AccomodationFormSchema,
  VASStudentCreationSchema,
} from '@/validations/vas.schema';

import { transformOptionValues } from '../CF/Dashboard/helpers';

import FFVASStudentField from './FFVASStudentField';
import {
  getAddNewStudentButtonStyles,
  getVasRequestFormTitle,
} from './helpers';
import VASDrawerHeader from './VASDrawerHeader';
import VASSuccessScreen from './VASSuccessScreen';

export type AccomodationFormProps = {
  studentId?: number;
  student?: string;
  cspId?: number;
  cspName?: string;
  initialValues?: Partial<
    Record<keyof VASCreateAccomodationRequestBodyType, any>
  >;
  onClose: () => void;
};

function VASAccomodationFormController({
  student,
  studentId,
  initialValues = {},
  cspId,
  cspName,
  onClose,
}: AccomodationFormProps) {
  const user = useAppSelector((state) => selectUserDetails(state));
  const formRef: React.MutableRefObject<FormApi<any, any> | null> =
    useRef(null);
  const [validate, formNames] = useFinalFormYupFormHelpers({
    schema: AccomodationFormSchema.concat(VASStudentCreationSchema),
  });

  const [fetchCities] = usePreferredCityOptions();
  const [fetchUniversities] = useUniversitiesOptions({
    serviceCategory: `ACCOMMODATION`,
  });
  const { handleCreateNewStudent } = useCreateStudent();
  const { data: sourceCountryData } = useGetSourceCountriesQuery();
  const sourceCountries =
    sourceCountryData?.data?.map((country) => {
      return {
        label: country?.displayName ?? ``,
        value: country?.displayName ?? ``,
      };
    }) ?? [];
  const [
    triggerCreateAccomodationRequest,
    { data, isLoading: isCreatingAccoRequest },
  ] = useCreateAccomodationRequestMutation();
  const { enqueueSnackbar } = useSnackbar();
  const initialValuesData = useMemo(() => {
    if (isFalsy(initialValues))
      return {
        createStudentMode: `no`,
      };

    return {
      student: student
        ? {
            label: student,
            value: student,
          }
        : null,
      [formNames.contactNumber]: initialValues.contactNumber,
      [formNames.countryCode]: {
        label: initialValues.countryCode ?? ``,
        value: initialValues.countryCode ?? ``,
      },
      [formNames.emailId]: initialValues.emailId,
      [formNames.recipientEmailIds]: initialValues.recipientEmailIds,
      [formNames.cspId]: {
        label: cspName,
        value: cspId,
      },
      createStudentMode: `no`,
    };
  }, [initialValues]);

  const handleCountryChange = (country: OPTION_TYPE) => {
    formRef?.current?.change(formNames.destinationCity, undefined);
  };

  const handleSubmit = async (values: any) => {
    try {
      let newStudentDetails;
      if (values?.createStudentMode === `yes`) {
        newStudentDetails = await handleCreateNewStudent(values);
      }

      const response = await triggerCreateAccomodationRequest({
        studentId:
          values?.createStudentMode === `yes`
            ? newStudentDetails?.value ?? -1
            : studentId ?? values?.student?.value ?? -1,
        body: {
          ...transformOptionValues(omit(values, [`student`])),
        },
      }).unwrap();

      if (response.success) {
        enqueueSnackbar(`Raised accomodation request successfully`, {
          variant: `success`,
        });
      } else {
        enqueueSnackbar(`Unable to raise accomodation request`, {
          variant: `error`,
        });
      }
    } catch (err) {
      enqueueSnackbar(`Unable to raise accomodation request`, {
        variant: `error`,
      });
    } finally {
      if (!isFalsy(initialValuesData?.student)) {
        onClose();
      }
    }
  };

  return (
    <div className="relative h-full">
      <div className="h-full overflow-hidden overflow-y-auto pb-24">
        <VASDrawerHeader
          title={getVasRequestFormTitle(`accomodation`)}
          onClose={onClose}
        />
        <Form<
          VASCreateAccomodationRequestBodyType & {
            createStudentMode: 'yes' | 'no';
            firstName: string;
            lastName: string;
            student: {
              label: string;
              value: string | number;
            };
            cspId: {
              label: string;
              value: string | number;
            };
            maritalStatus: {
              label: string;
              value: string;
            };
            gender: {
              label: string;
              value: string;
            };
            dob: string;
          }
        >
          initialValues={initialValuesData as any}
          validate={validate as any}
          onSubmit={handleSubmit}
          render={({ form, initialValues, values, errors, handleSubmit }) => {
            const shouldDisableStudentField = !isFalsy(
              initialValues?.student?.value,
            );
            formRef.current = form;
            return data?.success && data?.data ? (
              <VASSuccessScreen
                successScreenProps={{
                  title: `Accommodation request received!`,
                }}
                trackRequestStatusCardProps={{
                  type: `accomodation`,
                  onClick: () => {
                    window.open(`/vas/dashboard`, `_blank`);
                  },
                }}
              />
            ) : (
              <form className="px-4 py-6">
                <FFVASStudentField
                  dobFieldProps={{
                    name: formNames.dob,
                  }}
                  genderFieldProps={{
                    name: formNames.gender,
                  }}
                  maritalStatusFieldProps={{
                    name: formNames.maritalStatus,
                  }}
                  recipientEmailIdProps={{
                    name: formNames.recipientEmailIds,
                  }}
                  cspFieldProps={{
                    name: formNames.cspId,
                  }}
                  contactNumberFieldProps={{
                    countryCodeFieldProps: {
                      name: formNames.countryCode,
                    },
                    phoneNumberFieldProps: {
                      name: formNames.contactNumber,
                    },
                    countryCodeComponentProps: {
                      // disabled: shouldDisableStudentField,
                      options: [],
                    },
                    phoneNumberComponentProps: {
                      // disabled: shouldDisableCountryCodeField,
                    },
                    formValues: values,
                  }}
                  createOrSearchStudentFieldProps={{
                    studentFieldProps: {
                      name: formNames.student,
                    },
                    firstNameFieldProps: {
                      name: formNames.firstName,
                    },
                    lastNameFieldProps: {
                      name: formNames.lastName,
                    },
                    studentcomponentProps: {
                      disabled: shouldDisableStudentField,
                      options: [],
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      fetchOptionsHandler: () => {},
                    },
                    addStudentLabelButtonProps: {
                      disabled: shouldDisableStudentField,
                      className: getAddNewStudentButtonStyles(
                        user,
                        shouldDisableStudentField,
                      ),
                    },
                    addStudentIconProps: {
                      className: `text-grey-400`,
                    },
                  }}
                  emailFieldProps={{
                    name: formNames.emailId,
                  }}
                  serviceCategory="ACCOMMODATION"
                  studentId={studentId ?? (values as any)?.student?.value}
                />
                <div className="w-full pt-4">
                  <FFTextInput
                    fieldProps={{
                      name: formNames.emailId,
                    }}
                    componentProps={{
                      placeholder: `Please enter student’s email id.`,
                      label: (
                        <Typography
                          variant="subtitle2"
                          className="text-gray-900"
                        >
                          Email Id
                        </Typography>
                      ),
                      // disabled: shouldDisableEmailField,
                    }}
                  />
                </div>
                <div className="w-full pt-4">
                  <FFDropDown
                    fieldProps={{
                      name: formNames.destinationCountry,
                    }}
                    componentProps={{
                      options: sourceCountries,
                      label: (
                        <Typography
                          variant="subtitle2"
                          className="text-gray-900"
                        >
                          Destination Country
                        </Typography>
                      ),
                      placeholder: `Please enter student's destination country`,
                      onChange: (_, value) =>
                        handleCountryChange(value as OPTION_TYPE),
                    }}
                  />
                </div>
                <div className="w-full pt-4">
                  <FFDropDown
                    fieldProps={{
                      name: formNames.universityName,
                    }}
                    componentProps={{
                      options: [],
                      async: {
                        // @ts-ignore
                        loadOptions: fetchUniversities,
                      },
                      placeholder: `Please enter university`,
                      disableClearable: false,
                      label: (
                        <Typography
                          variant="subtitle2"
                          className="text-gray-900"
                        >
                          Select a university
                        </Typography>
                      ),
                      creatable: true,
                    }}
                  />
                </div>
                <div className="w-full pt-4">
                  <FFDropDown
                    fieldProps={{
                      name: formNames.destinationCity,
                    }}
                    componentProps={{
                      disabled: isFalsy(
                        (values?.destinationCountry as any)?.value,
                      ),
                      options: [],
                      async: {
                        // @ts-ignore
                        loadOptions: (query, callback) => {
                          fetchCities(
                            query,
                            callback,
                            (values?.destinationCountry as any)?.label ?? ``,
                          );
                        },
                      },
                      placeholder: `Please enter preferred city`,
                      disableClearable: false,
                      label: (
                        <Typography
                          variant="subtitle2"
                          className="text-gray-900"
                        >
                          Destination City
                        </Typography>
                      ),
                      creatable: true,
                    }}
                  />
                </div>
                <div className="w-full pt-4">
                  <FFDropDown
                    fieldProps={{
                      name: formNames.intake,
                    }}
                    componentProps={{
                      options: getIntakeOptionsInShortForm().slice(11),
                      label: (
                        <Typography
                          variant="subtitle2"
                          className="text-gray-900"
                        >
                          Intake
                        </Typography>
                      ),
                      placeholder: `Please select Intake`,
                    }}
                  />
                </div>
                <div className="w-full pt-4">
                  <FFEmailInputs
                    fieldProps={{
                      name: formNames.recipientEmailIds,
                    }}
                    componentProps={{
                      separateTopLabel: (
                        <Typography
                          variant="subtitle2"
                          className="text-gray-900 pb-2"
                        >
                          Recipient Emails (Type and press enter to select
                          value)
                        </Typography>
                      ),
                    }}
                  />
                </div>
              </form>
            );
          }}
        />
      </div>
      {data?.success && data?.data ? null : (
        <div className="w-full absolute bottom-0 right-0 p-2 bg-white shadow-t-lg z-50">
          <div className="flex justify-center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                formRef.current?.submit();
              }}
              loading={isCreatingAccoRequest}
              className="w-1/3 py-2"
            >
              <Typography
                variant="subtitle2"
                className="text-white normal-case"
              >
                Raise Request
              </Typography>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default VASAccomodationFormController;
