import { useEffect, useState } from 'react';
import { isFalsy } from '@leapfinance/frontend-commons';
import { useRouter } from 'next/router';

import { useAppSelector } from '@/app/hooks';
import { useLazyGetRandomCourseQuery } from '@/components/CF/Dashboard/services/geebeeCf';
import { MODAL_TYPES } from '@/components/modals/GlobalModal';
import useModal from '@/components/modals/useModal';
import { selectRole } from '@/features/generalSlice';
import { Roles } from '@/types/global';
import { LocalStorageEnum } from '@/utils/constants';
export function useCourseReportModal(courseDetailsPage: boolean) {
  const { showModal, hideModal } = useModal();
  const [getRandomCourseTrigger] = useLazyGetRandomCourseQuery();
  const router = useRouter();

  const role: string | null = useAppSelector((state) => selectRole(state));

  const now = new Date();
  const today12 = new Date(now);
  today12.setHours(12, 0, 0, 0);

  const handleSetModalShown = () => {
    try {
      localStorage.setItem(
        LocalStorageEnum.LAST_COURSE_REPORT_MODAL_SHOWN_AT,
        now.toString(),
      );
    } catch (e) {
      console.log(`Error setting local storage item`, e);
    }
  };

  const handleOpenModal = (courseData: any) => {
    if (!courseDetailsPage) {
      showModal({
        modalType: MODAL_TYPES.REPORT_COURSE_GLOBAL_MODAL,
        modalProps: {
          open: true,
          courseData: courseData,
          onClose: () => {
            hideModal(MODAL_TYPES.REPORT_COURSE_GLOBAL_MODAL);
            handleSetModalShown();
          },
        },
      });
    }
  };

  const fetchRandomCourse = async () => {
    const data = await getRandomCourseTrigger().unwrap();
    const randomCourse = data?.data || {};
    if (!isFalsy(randomCourse)) {
      handleOpenModal(randomCourse);
    }
  };

  useEffect(() => {
    if (role && role === Roles.counsellor) {
      let lastShown = null;
      try {
        const lastShownTemp = localStorage.getItem(
          LocalStorageEnum.LAST_COURSE_REPORT_MODAL_SHOWN_AT,
        );
        if (lastShownTemp) {
          lastShown = new Date(lastShownTemp);
        }
      } catch (e) {
        console.log(`Error getting local storage item`, e);
      }

      if (
        (!lastShown && now > today12) ||
        (now > today12 && lastShown && lastShown < today12)
      ) {
        fetchRandomCourse();
      }
    }
  }, [role, router.asPath]);
}
