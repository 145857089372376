import * as React from 'react';
import { SVGProps } from 'react';

const StateChangeNotificationIcon = (
  props: SVGProps<SVGSVGElement> & { active?: boolean },
) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.887 2H6.113C3.845 2 2 3.925 2 6.292c0 2.377 1.864 4.291 4.088 4.291h1.848V7.221c0-1.506 1.174-2.731 2.617-2.731h.005c1.44.003 2.612 1.228 2.612 2.73v3.363h4.717c2.268 0 4.113-1.925 4.113-4.291C22 3.925 20.155 2 17.887 2Zm1.164 3.693L16.707 8.14a.569.569 0 0 1-.828 0l-1.172-1.223a.63.63 0 0 1 0-.865.569.569 0 0 1 .829 0l.757.79 1.93-2.012a.569.569 0 0 1 .828 0 .63.63 0 0 1 0 .864Z"
      fill={props.active ? `#91BB82` : `#b7b7b7`}
    />
    <path
      d="m15.77 12.304-3.772-.41V7.222c0-.832-.645-1.507-1.442-1.508-.8-.002-1.449.674-1.449 1.508v7.823h-.013L7.667 13.8a1.352 1.352 0 0 0-1.984.218 1.494 1.494 0 0 0 .203 2.01l2.895 2.521h8.53v-4.425c0-.925-.661-1.706-1.541-1.82ZM9.106 20.994a.6.6 0 0 0 .586.612h7.031a.599.599 0 0 0 .586-.612v-1.223H9.106v1.223Z"
      fill={props.active ? `#91BB82` : `#b7b7b7`}
    />
  </svg>
);

export default StateChangeNotificationIcon;
