import React, { useRef, useState } from 'react';
import DocViewer, {
  DocViewerRef,
  DocViewerRenderers,
} from '@cyntler/react-doc-viewer';
import { GBTypography } from '@leapfinance/geebee-component-library';
import { Add } from '@mui/icons-material';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, IconButton } from '@mui/material';

import useDocumentActions from '@/hooks/useDocumentActions';
function DocumentViewer(props: { file: File; fileUrl?: string }) {
  const docViewerRef = useRef<DocViewerRef>(null);
  const { handlePresignedUrlDownload } = useDocumentActions();
  const [zoomLevel, setZoomLevel] = useState(1.1);
  return (
    <>
      {props?.file?.type !== `application/pdf` ? (
        <div className="h-full text-center">
          <GBTypography variant="sh2">
            Could not preview the document
          </GBTypography>
          <div
            onClick={() => {
              props?.fileUrl &&
                handlePresignedUrlDownload({
                  url: props?.fileUrl,
                });
            }}
            className="w-fit cursor-pointer mx-auto"
          >
            <GBTypography
              variant="cta5"
              className="underline text-gbTokens-primary-500"
            >
              Click here to download
            </GBTypography>
          </div>
        </div>
      ) : (
        <DocViewer
          ref={docViewerRef}
          documents={[{ uri: window?.URL?.createObjectURL(props?.file) }]}
          pluginRenderers={DocViewerRenderers}
          config={{
            header: {
              disableHeader: true,
              disableFileName: true,
            },
            csvDelimiter: `,`, // "," as default,
            pdfZoom: {
              defaultZoom: zoomLevel, // 1 as default,
              zoomJump: 0.2, // 0.1 as default,
            },
            pdfVerticalScrollByDefault: true, // false as default
          }}
        />
      )}
      <div className="sticky bottom-2 flex items-center justify-center gap-2 w-full">
        <IconButton
          className="bg-white-0 p-1 shadow-md rounded-sm"
          onClick={() => setZoomLevel((l) => l + 0.5)}
        >
          <Add />
        </IconButton>

        <IconButton
          className="bg-white-0 p-1 shadow-md rounded-sm"
          onClick={() => setZoomLevel((l) => l - 0.5)}
        >
          <RemoveIcon />
        </IconButton>
      </div>
    </>
  );
}

export default DocumentViewer;
