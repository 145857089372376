import { call, put, takeLatest } from "redux-saga/effects";
import { userManagementApi } from "./UserManagementApi";
import { userManagementActions } from "./UserManagementActions";
import { AnyAction } from "redux";

function* fetchUsersData(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(
      userManagementApi.fetchUsersDataRequest,
      action.payload
    );
    if (res.success) {
      yield put(userManagementActions.fetchUsersDataSuccess(res.data));
    } else {
      yield put(userManagementActions.fetchUsersDataFail(res.message));
    }
  } catch (e) {
    console.error(e);
    yield put(
      userManagementActions.fetchUsersDataFail(
        "Something went wrong. Please contact the tech team"
      )
    );
  }
}
function* fetchCountries(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(
      userManagementApi.fetchCountriesRequest,
      action.payload
    );
    if (res?.success) {
      yield put(userManagementActions.fetchCountriesSuccess(res.data));
    } else {
      yield put(userManagementActions.fetchCountriesFail(res.message));
    }
  } catch (e) {
    console.error(e);
    yield put(
      userManagementActions.fetchCountriesFail(
        "Something went wrong. Please contact the tech team"
      )
    );
  }
}
function* createUser(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(userManagementApi.createUserRequest, action.payload);
    if (res?.success) {
      yield put(userManagementActions.createUserSuccess(res.data));
    } else {
      yield put(userManagementActions.createUserFail(res.message));
    }
  } catch (e) {
    console.error(e);
    yield put(
      userManagementActions.createUserFail(
        "Something went wrong. Please contact the tech team"
      )
    );
  }
}
function* editUser(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(userManagementApi.editUserRequest, action.payload);
    if (res?.success) {
      yield put(userManagementActions.editUserSuccess(res.data));
    } else {
      yield put(userManagementActions.editUserFail(res.message));
    }
  } catch (e) {
    console.error(e);
    yield put(
      userManagementActions.editUserFail(
        "Something went wrong. Please contact the tech team"
      )
    );
  }
}
function* deactivateUser(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(userManagementApi.deactivateUserRequest, action.payload);
    if (res?.success) {
      yield put(userManagementActions.deactivateUserSuccess(res.data));
    } else {
      yield put(userManagementActions.deactivateUserFail(res.message));
    }
  } catch (e) {
    console.error(e);
    yield put(
      userManagementActions.deactivateUserFail(
        "Something went wrong. Please contact the tech team"
      )
    );
  }
}
function* fetchEachUserData(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(
      userManagementApi.fetchEachUserDataRequest,
      action.payload
    );
    if (res?.success) {
      yield put(userManagementActions.fetchEachUserDataSuccess(res.data));
    } else {
      yield put(userManagementActions.fetchEachUserDataFail(res.message));
    }
  } catch (e) {
    console.error(e);
    yield put(
      userManagementActions.fetchEachUserDataFail(
        "Something went wrong. Please contact the tech team"
      )
    );
  }
}
function* addLeaves(action: AnyAction): Generator<any, any, any> {
  const {eachUserId} = action.payload
  try {
    const res = yield call(
      userManagementApi.addLeavesRequest,
      action.payload
    );
    if (res?.success) {
      yield put(userManagementActions.addLeavesSuccess(res.data));
    } else {
      yield put(userManagementActions.addLeavesFail(res.message));
    }
  } catch (e) {
    console.error(e);
    yield put(
      userManagementActions.addLeavesFail(
        "Something went wrong. Please contact the tech team"
      )
    );
  }
}
function* removeLeaves(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(
      userManagementApi.removeLeavesRequest,
      action.payload
    );
    if (res?.success) {
      yield put(userManagementActions.removeLeavesSuccess(res.data));
    } else {
      yield put(userManagementActions.removeLeavesFail(res.message));
    }
  } catch (e) {
    console.error(e);
    yield put(
      userManagementActions.removeLeavesFail(
        "Something went wrong. Please contact the tech team"
      )
    );
  }
}

function* userManagementSaga() {
  yield takeLatest("FETCH_USERS_DATA", fetchUsersData);
  yield takeLatest("FETCH_COUNTRIES", fetchCountries);
  yield takeLatest("CREATE_USER", createUser);
  yield takeLatest("EDIT_USER", editUser);
  yield takeLatest("FETCH_EACH_USER_DATA", fetchEachUserData);
  yield takeLatest("DEACTIVATE_USER", deactivateUser);
  yield takeLatest("ADD_LEAVES",addLeaves);
  yield takeLatest("REMOVE_LEAVES",removeLeaves);
}

export default userManagementSaga;
