import { geebeeRootBaseApiWithTags } from 'services/base/geebeeApi';

import {
  AddGteConditionRequestType,
  AddGteConditionResponseType,
  GetGteApplicationDocumentsListRequestType,
  GetGteApplicationDocumentsListResponseType,
  GetGteEligibleKeysRequestType,
  GetGteEligibleKeysResponseType,
  StartGteRequestType,
  StartGTEResponseType,
  UpdateGteConditionRequestType,
  UpdateGteConditionResponseType,
} from '@/types/Services';

export const gteServices = geebeeRootBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    startGteProcess: builder.mutation<
      StartGTEResponseType,
      StartGteRequestType
    >({
      query: ({ applicationId }) => ({
        url: `/gte/start/${applicationId}`,
        method: `POST`,
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as StartGTEResponseType;
        } else {
          return {
            data,
            success: true,
            message: null,
          } as unknown as StartGTEResponseType;
        }
      },
    }),

    fetchApplicationLevelGteDocuments: builder.query<
      GetGteApplicationDocumentsListResponseType,
      GetGteApplicationDocumentsListRequestType
    >({
      query: ({ applicationId }) => ({
        url: `/gte/documents/${applicationId}`,
        method: `GET`,
      }),
      providesTags: [`GteApplicationDocuments`],
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetGteApplicationDocumentsListResponseType;
        } else {
          return {
            data,
            message: null,
            success: true,
          } as unknown as GetGteApplicationDocumentsListResponseType;
        }
      },
    }),
    updateGteConditionStatus: builder.mutation<
      UpdateGteConditionResponseType,
      UpdateGteConditionRequestType
    >({
      query: (body) => ({
        url: `/gte/documents/change-condition`,
        method: `POST`,
        body,
      }),
      invalidatesTags: [`GteApplicationDocuments`],
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as UpdateGteConditionResponseType;
        } else {
          return {
            data,
            message: null,
            success: true,
          } as unknown as UpdateGteConditionResponseType;
        }
      },
    }),
    addGteConditions: builder.mutation<
      AddGteConditionResponseType,
      AddGteConditionRequestType
    >({
      query: (body) => ({
        url: `/gte/add-conditions`,
        method: `POST`,
        body,
      }),
      invalidatesTags: [`GteApplicationDocuments`],
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as AddGteConditionResponseType;
        } else {
          return {
            data,
            message: null,
            success: true,
          } as unknown as AddGteConditionResponseType;
        }
      },
    }),
    getGteEligibleKeys: builder.query<
      GetGteEligibleKeysResponseType,
      GetGteEligibleKeysRequestType
    >({
      query: ({ applicationId }) => ({
        url: `/gte/eligible-keys/${applicationId}`,
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetGteEligibleKeysResponseType;
        } else {
          return {
            data,
            message: null,
            success: true,
          } as unknown as GetGteEligibleKeysResponseType;
        }
      },
    }),
  }),
});

export const {
  useStartGteProcessMutation,
  useFetchApplicationLevelGteDocumentsQuery,
  useLazyFetchApplicationLevelGteDocumentsQuery,
  useUpdateGteConditionStatusMutation,
  useAddGteConditionsMutation,
  useGetGteEligibleKeysQuery,
} = gteServices;
