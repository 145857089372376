import React from 'react';
import PlaceIcon from '@mui/icons-material/Place';
import SchoolIcon from '@mui/icons-material/School';
import { Tooltip, Typography } from '@mui/material';

import AddedToShortlistTypo from '@/components/CF/Dashboard/CfCoursesDashboard/CourseCardWrapper/AddedToShortlistTypo';

type Props = {
  course?: {
    courseName?: string;
    universityName?: string;
    cityName?: string;
    countryName?: string;
    shortlisted?: boolean;
  };
};

function SelectedCourseItem({ course }: Props) {
  return (
    <div className="flex flex-col">
      <div>
        <Typography variant="subtitle1">{course?.courseName}</Typography>
        <div className="grid grid-cols-2 items-center pt-2">
          <div className="flex  items-center ">
            <SchoolIcon className="text-grey-600 mr-1" fontSize="small" />
            <Tooltip title={course?.universityName ?? ``}>
              <Typography variant="subtitle2" className="line-clamp-1">
                {course?.universityName}
              </Typography>
            </Tooltip>
          </div>
          <div className="flex justify-start items-center ">
            <PlaceIcon className="text-grey-600 mr-1" fontSize="small" />
            <Tooltip title={course?.universityName ?? ``}>
              <Typography variant="subtitle2" className="line-clamp-1">
                {course?.cityName && (
                  <>
                    <span>{course?.cityName}</span>
                  </>
                )}
                {course?.countryName && (
                  <span>,&nbsp;{course?.countryName}</span>
                )}
              </Typography>
            </Tooltip>
          </div>
        </div>
        {course?.shortlisted && <AddedToShortlistTypo />}
      </div>
    </div>
  );
}

export default SelectedCourseItem;
