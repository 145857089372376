import { useEffect, useRef, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { Button, isFalsy } from '@leapfinance/frontend-commons';
import {
  isOptionType,
  isOptionTypeArray,
} from '@leapfinance/frontend-commons/components/geebee/inputs/DropDown';
import GeebeeTheme from '@leapfinance/frontend-commons/components/geebee/theme';
import { OPTION_TYPE } from '@leapfinance/frontend-commons/schema/common';
import { GBTypography } from '@leapfinance/geebee-component-library';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import {
  Chip,
  Divider,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { ThemeProvider } from '@mui/system';

import { MODAL_TYPES } from '@/components/modals/GlobalModal';
import useModal from '@/components/modals/useModal';
import { TabPanelProps } from '@/components/Student/StudentMoreFilter/interfaces';
import MoreFilters from '@/components/UnisetuDashboard/Filters/MoreFilters';

const TabsLayout = (count: number) => [
  {
    tabTitle: `More Filters ${count ? `(${count})` : ``}`,
    tabPanel: <MoreFilters />,
  },
];

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className="px-8 py-4 w-full overflow-y-scroll h-full bg-white-0"
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

type FilterType = {
  title: string;
  key: string;
  value: string;
  label: string;
};

export interface IUnisetuDashboardMoreFilter {
  initialValues: Record<string, any>;
  onSubmit: (values: Record<string, any>) => void;
  onReset: () => void;
}

const UnisetuDashboardMoreFilters = (props: IUnisetuDashboardMoreFilter) => {
  const { hideModal } = useModal();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [appliedFilters, setAppliedFilters] = useState<FilterType[]>([]);
  const [appliedFiltersMap, setAppliedFiltersMap] = useState<
    Record<string, FilterType[]>
  >({});
  const { initialValues, onSubmit, onReset } = props;
  const formRef = useRef<any>(null);

  const handleTabsChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: number,
  ) => {
    setCurrentTab(value);
  };

  const filterKeysToShow: string[] = [
    `counsellorIds`,
    `zonalManagerIds`,
    `dashboardPerformanceCategory`,
    `countryGroups`,
  ];

  const filterKeyLabelMapping: Record<string, string> = {
    counsellorIds: `Counsellors`,
    zonalManagerIds: `Zonal Managers`,
    dashboardPerformanceCategory: `Dashboard Performance Category`,
    countryGroups: `Country Groups`,
  };

  const handleFormSpy = (values: any) => {
    const filterValues = values?.values;
    const filters = filterKeysToShow.reduce((acc, key) => {
      const data = filterValues?.[key];
      if (isOptionType(data)) {
        acc.push({
          title: data?.label,
          key,
          value: data?.value,
          label: filterKeyLabelMapping[key],
        });
      } else if (isOptionTypeArray(data)) {
        const values = data.map((option) => ({
          title: option.label,
          key,
          value: option?.value,
          label: filterKeyLabelMapping[key],
        }));
        acc.push(...values);
      }
      return acc;
    }, [] as any);

    setTimeout(() => {
      setAppliedFilters(filters);
    }, 0);
  };

  const filtersCountMapping: Record<string, number> = {
    counsellorIds: 0,
    zonalManagerIds: 0,
    countryGroups: 0,
  };

  appliedFilters?.forEach((filter: OPTION_TYPE & { key: string }) => {
    filtersCountMapping[filter?.key] += 1;
  });

  useEffect(() => {
    const filtersMap: Record<string, FilterType[]> = {};
    appliedFilters?.forEach((filter) => {
      const labelMapping = filter?.label;
      if (isFalsy(filtersMap[labelMapping])) {
        filtersMap[labelMapping] = [filter];
      } else {
        filtersMap[labelMapping]?.push(filter);
      }
    });
    setAppliedFiltersMap(filtersMap);
  }, [appliedFilters]);

  const moreFilterCount = Object?.keys(filtersCountMapping)
    ?.map((filter) => {
      return filtersCountMapping?.[filter];
    })
    ?.filter((filter) => !!filter)?.length;

  const removeFilter = ({ key, value }: { key?: any; value: any }) => {
    if (!key) return;
    const filterValues = formRef?.current?.getState()?.values;

    const filter = filterValues?.[key];
    if (Array.isArray(filter)) {
      if (isOptionTypeArray(filter)) {
        formRef?.current?.change(
          key,
          filter.filter((data) => data.value !== value),
        );
      } else {
        formRef?.current?.change(
          key,
          filter.filter((data) => data !== value),
        );
      }
    } else if (isOptionType(filter) && filter.value === value) {
      formRef?.current?.change(key, undefined);
    }
  };

  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="bg-white-0 rounded-lg w-[70%] h-[50%] py-4">
        <ThemeProvider theme={GeebeeTheme}>
          <div className="flex justify-between items-center px-6">
            <div className="flex items-center gap-x-4">
              <IconButton
                className="m-0 p-0"
                onClick={() => {
                  hideModal(MODAL_TYPES.UNISETU_DASHBOARD_MORE_FILTER);
                }}
              >
                <ChevronLeftRoundedIcon className="text-[#101010]" />
              </IconButton>
              <Typography variant="h3" className="text-[#101010]">
                All Filters
              </Typography>
            </div>
            <div className="flex items-center gap-x-3">
              <Button
                variant="text"
                onClick={() => {
                  hideModal(MODAL_TYPES.UNISETU_DASHBOARD_MORE_FILTER);
                  onReset();
                }}
              >
                <Typography variant="body2" className="text-primary-700">
                  Reset Filter
                </Typography>
              </Button>
              <Button
                onClick={() => {
                  hideModal(MODAL_TYPES.UNISETU_DASHBOARD_MORE_FILTER);
                  onSubmit(formRef?.current?.getState()?.values);
                }}
              >
                <Typography className="font-bold" variant="body1">
                  Apply
                </Typography>
              </Button>
            </div>
          </div>
          {!isFalsy(appliedFilters) ? (
            <div className="flex items-center flex-wrap gap-4 flex-1 border border-grey-300 px-6 py-3 my-4 max-h-[20%] overflow-scroll">
              <Typography variant="subtitle1" className="text-grey-900">
                Applied Filters :
              </Typography>
              {Object.keys(appliedFiltersMap)?.map((filterMap) => {
                const label = filterMap;
                const filterValues = appliedFiltersMap[filterMap];
                return (
                  <>
                    <GBTypography variant="sh2Semibold">{label}: </GBTypography>
                    {filterValues.map(
                      (
                        filter: OPTION_TYPE & { title: string },
                        index: number,
                      ) => (
                        <Chip
                          className="!p-0 !h-auto py-0.5 bg-grey-200"
                          key={`${filter.title}-${filter?.value}-${index}`}
                          label={
                            <Typography variant="subtitle2">
                              {filter.title}
                            </Typography>
                          }
                          onDelete={
                            typeof filter?.key !== `undefined`
                              ? () =>
                                  removeFilter({
                                    key: filter.key,
                                    value: filter.value,
                                  })
                              : undefined
                          }
                        />
                      ),
                    )}
                    <Divider orientation="vertical" className="h-4 w-4" />
                  </>
                );
              })}
            </div>
          ) : (
            <Divider className="border-grey-300 my-4" />
          )}
          <div className="flex">
            <Tabs
              value={currentTab}
              orientation="vertical"
              onChange={handleTabsChange}
              aria-label="Dashboard More Filters"
              TabIndicatorProps={{
                style: {
                  backgroundColor: `#E2E2FF`,
                },
              }}
              className="bg-grey-100 w-[40%]"
              sx={{
                '& .Mui-selected': {
                  backgroundColor: `#E3E2FF`,
                },
              }}
            >
              {TabsLayout(moreFilterCount)?.map((tabDetails, index) => {
                return (
                  <Tab
                    key={`${tabDetails}-${index}`}
                    className="items-start"
                    label={
                      <Typography
                        variant="subtitle1"
                        className="text-grey-800 normal-case"
                      >
                        {tabDetails?.tabTitle}
                      </Typography>
                    }
                    {...a11yProps(index)}
                  />
                );
              })}
            </Tabs>
            <Form
              onSubmit={() => console.log(`Submitting`)}
              initialValues={initialValues}
              render={({ form }) => {
                formRef.current = form;
                return (
                  <>
                    <FormSpy
                      subscription={{ values: true }}
                      onChange={handleFormSpy}
                    />
                    {` `}
                    {TabsLayout(moreFilterCount)?.map((tabDetails, index) => {
                      return (
                        <TabPanel
                          value={currentTab}
                          index={index}
                          key={`component-display-${index}`}
                        >
                          {currentTab === index ? tabDetails?.tabPanel : null}
                        </TabPanel>
                      );
                    })}
                  </>
                );
              }}
            />
          </div>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default UnisetuDashboardMoreFilters;
