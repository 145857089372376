import * as React from 'react';
import { SVGProps } from 'react';

const EnglishExamDocIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_235_8530)">
      <path
        d="M23.4678 17.1251C19.3667 17.1251 16.0303 20.4615 16.0303 24.5626C16.0303 28.6636 19.3667 32.0001 23.4678 32.0001C27.6033 32.0001 30.9678 28.6636 30.9678 24.5626C30.9678 20.4615 27.6033 17.1251 23.4678 17.1251ZM25.7505 23.6627L23.2505 26.6627C23.0712 26.8778 22.8066 27.0001 22.5303 27.0001C22.4944 27.0001 22.4583 26.998 22.4221 26.9938C22.1079 26.9573 21.8332 26.7648 21.6917 26.4818L20.6917 24.4818C20.4601 24.0187 20.6479 23.4556 21.111 23.2241C21.574 22.9924 22.1372 23.1802 22.3688 23.6433L22.7277 24.3612L24.31 22.4624C24.6415 22.0647 25.2327 22.011 25.6304 22.3423C26.0282 22.6738 26.082 23.2649 25.7505 23.6627Z"
        fill="#7672F7"
      />
      <path
        d="M23.0637 5.34087L19.2598 1.40719C19.258 1.40531 19.2561 1.40337 19.2543 1.4015C18.3785 0.510874 17.1598 6.10352e-05 15.9109 6.10352e-05H3.84277C2.29196 6.10352e-05 1.03027 1.26175 1.03027 2.81256V27.1251C1.03027 28.6759 2.29196 29.9376 3.84277 29.9376H15.8671C14.7895 28.4181 14.1553 26.5631 14.1553 24.5626C14.1553 19.4276 18.3328 15.2501 23.4678 15.2501C23.7841 15.2501 24.0968 15.2659 24.4053 15.2965V8.62381C24.4053 7.38825 23.9288 6.22256 23.0637 5.34087ZM10.9678 20.9376H5.96777C5.45002 20.9376 5.03027 20.5178 5.03027 20.0001C5.03027 19.4823 5.45002 19.0626 5.96777 19.0626H10.9678C11.4855 19.0626 11.9053 19.4823 11.9053 20.0001C11.9053 20.5178 11.4855 20.9376 10.9678 20.9376ZM10.9678 15.9376H5.96777C5.45002 15.9376 5.03027 15.5178 5.03027 15.0001C5.03027 14.4823 5.45002 14.0626 5.96777 14.0626H10.9678C11.4855 14.0626 11.9053 14.4823 11.9053 15.0001C11.9053 15.5178 11.4855 15.9376 10.9678 15.9376ZM10.9678 10.9376H5.96777C5.45002 10.9376 5.03027 10.5178 5.03027 10.0001C5.03027 9.48231 5.45002 9.06256 5.96777 9.06256H10.9678C11.4855 9.06256 11.9053 9.48231 11.9053 10.0001C11.9053 10.5178 11.4855 10.9376 10.9678 10.9376ZM16.9678 15.9376H15.9678C15.45 15.9376 15.0303 15.5178 15.0303 15.0001C15.0303 14.4823 15.45 14.0626 15.9678 14.0626H16.9678C17.4855 14.0626 17.9053 14.4823 17.9053 15.0001C17.9053 15.5178 17.4855 15.9376 16.9678 15.9376ZM16.9678 10.9376H15.9678C15.45 10.9376 15.0303 10.5178 15.0303 10.0001C15.0303 9.48231 15.45 9.06256 15.9678 9.06256H16.9678C17.4855 9.06256 17.9053 9.48231 17.9053 10.0001C17.9053 10.5178 17.4855 10.9376 16.9678 10.9376Z"
        fill="#7672F7"
      />
    </g>
    <defs>
      <clipPath id="clip0_235_8530">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default EnglishExamDocIcon;
