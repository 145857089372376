import { type TestContext } from 'yup';
import { type AnyObject } from 'yup/lib/types';

import { EnglishExam, ExamType } from '@/types/student';

const IELTS_FIELDS = {
  IELTS_TOTAL: `IELTS_TOTAL`,
  IELTS_BAND_MINIMUM: `IELTS_BAND_MINIMUM`,
  IELTS: `IELTS`,
};

const TOEFL_FIELDS = {
  TOEFL_TOTAL: `TOEFL_TOTAL`,
  TOEFL_BAND_MINIMUM: `TOEFL_BAND_MINIMUM`,
  TOEFL: `TOEFL`,
};

const PTE_FIELDS = {
  PTE_BAND_MINIMUM: `PTE_BAND_MINIMUM`,
  PTE_TOTAL: `PTE_TOTAL`,
  PTE: `PTE`,
};

const DUOLINGO_FIELDS = {
  DUOLINGO_TOTAL: `DUOLINGO_TOTAL`,
  DUOLINGO_BAND_MINIMUM: `DUOLINGO_BAND_MINIMUM`,
  DUOLINGO: `DUOLINGO`,
};

const validIeltsKeys = Object.values(IELTS_FIELDS);
const validTOEFLKeys = Object.values(TOEFL_FIELDS);
const validPTEKeys = Object.values(PTE_FIELDS);
const validDuolingoKeys = Object.values(DUOLINGO_FIELDS);

const englishTestSubScoreValidation = (
  value: any | undefined,
  examType: ExamType,
) => {
  if (validTOEFLKeys.includes(examType)) {
    if (typeof value !== `number`) return false;
    if (Number(value) > 0 && Number(value) <= 30) return true;
    return false;
  }

  return undefined;
};

const englishTestSubScoreValidationMessage = (examType: ExamType) => {
  if (validTOEFLKeys.includes(examType)) return `Enter value between 0 and 30`;
  return undefined;
};

function wholeNumber(this: any, message?: string) {
  return this.test(
    `wholeNumber`,
    ``,
    function (this: TestContext<AnyObject>, value: number) {
      if (typeof value === `undefined` || value === null) return true;
      //check for posetive integer
      const wholeNumber = Number.isInteger(value) && value >= 0;
      if (!wholeNumber) {
        return this.createError({
          message: message ?? `${this.path} must be a whole number`,
        });
      } else {
        return true;
      }
    },
  );
}

function decimal(this: any, message?: string) {
  return this.test(
    `decimal`,
    ``,
    function (this: TestContext<AnyObject>, value: number) {
      if (typeof value === `undefined`) return true;
      //check for posetive integer
      const isDecimal = value >= 0;
      if (!isDecimal) {
        return this.createError({
          message: message ?? `${this.path} must be a decimal`,
        });
      } else {
        return true;
      }
    },
  );
}

function englishTestScore(
  this: any,
  examKey: string,
  isValid: (
    value: any | undefined,
    examType: EnglishExam,
  ) => boolean | undefined,
  message?: (examType: EnglishExam) => string | undefined,
) {
  return this.test(
    `englishTestScore`,
    ``,
    function (this: TestContext<AnyObject>, value: number) {
      if (typeof value !== `number`) return true;

      const examType: EnglishExam =
        this.parent?.[examKey]?.value ?? this.parent?.[examKey];
      if (!examType) return true;

      if (validIeltsKeys.includes(examType)) {
        const validGrade =
          isValid?.(value, examType) ??
          (Number(value) > 0 && Number(value) <= 9);
        if (!validGrade)
          return this.createError({
            message: message?.(examType) ?? `Please enter between 0 to 9`,
          });
      } else if (validDuolingoKeys.includes(examType)) {
        const validGrade =
          isValid?.(value, examType) ??
          (Number(value) >= 10 && Number(value) <= 160);
        if (!validGrade)
          return this.createError({
            message: message?.(examType) ?? `Please enter between 10 to 160`,
          });
      } else if (validPTEKeys.includes(examType)) {
        const validGrade =
          isValid?.(value, examType) ??
          (Number(value) >= 10 && Number(value) <= 90);
        if (!validGrade)
          return this.createError({
            message: message?.(examType) ?? `Please enter between 10 to 90`,
          });
      } else if (validTOEFLKeys.includes(examType)) {
        const validGrade =
          isValid?.(value, examType) ??
          (Number(value) >= 0 && Number(value) <= 120);
        if (!validGrade)
          return this.createError({
            message: message?.(examType) ?? `Please enter between 0 to 120`,
          });
      }
      return true;
    },
  );
}

function decimalPoints(this: any, points: number, message?: string) {
  return this.test(
    `decimal-points`,
    ``,
    function (this: TestContext<AnyObject>, value: number) {
      if (typeof value === `undefined`) return true;
      const decimalSeperated = String(value).split(`.`);

      if (
        decimalSeperated &&
        Array.isArray(decimalSeperated) &&
        decimalSeperated.length == 2
      ) {
        const decimalPoints = decimalSeperated?.[1];
        if (decimalPoints && String(decimalPoints).length > points) {
          return this.createError({
            message: message ?? `${this.path} only ${points} are allowed`,
          });
        }
      } else {
        return true;
      }

      return true;
    },
  );
}

export {
  wholeNumber,
  decimal,
  englishTestScore,
  decimalPoints,
  englishTestSubScoreValidation,
  englishTestSubScoreValidationMessage,
  IELTS_FIELDS,
  TOEFL_FIELDS,
  PTE_FIELDS,
  DUOLINGO_FIELDS,
};
