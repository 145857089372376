import * as React from 'react';
import { SVGProps } from 'react';

const PlusOutlinedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 3.33331V12.6666"
      stroke="#443EFF"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33325 8H12.6666"
      stroke="#443EFF"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PlusOutlinedIcon;
