import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-final-form';
import { DropDown, isFalsy } from '@leapfinance/frontend-commons';
import { type OPTION_TYPE } from '@leapfinance/frontend-commons/schema/common';
import { DeleteOutline } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import difference from 'lodash/difference';
import { useSnackbar } from 'notistack';

import useDocumentActions from '@/hooks/useDocumentActions';
import useFileUpload from '@/hooks/useFileUpload';
import { Document } from '@/types/documentSection';
import { startCaseToLower } from '@/utils/common';

import GicDocumentChipWrapper from './DocumentChipWrapper';
import DocumentUploadCard from './DocumentUploadCard';
export interface GicDocument {
  keyName?: Document[`keyName`];
  file: File | Document;
  isOtherUploadedDocument?: boolean;
}

interface IGICRequiredDocumentsProps {
  passportDocuments: GicDocument[];
  loaDocuments: GicDocument[];
  studentId?: number;
  uploadedDocuments?: (documents: GicDocument[]) => void;
}

export const gicAcceptedFormats = [`jpg`, `png`, `pdf`, `jpeg`];
const passportDocumentKeys = [
  `PASSPORT_BACK`,
  `PASSPORT_FRONT`,
  `CONSOLIDATED_PASSPORT`,
] as Document[`keyName`][];

export default function GICRequiredDocuments(
  props: IGICRequiredDocumentsProps,
) {
  const { getState } = useForm();
  const hasSubmitted = Object.values(getState()?.touched ?? {}).every(
    (value) => value === true,
  );
  const passportErrorMessage = hasSubmitted
    ? getState()?.errors?.passportDocument?.[0]
    : undefined;
  const loaErrorMessage = hasSubmitted
    ? getState()?.errors?.loaDocument?.[0]
    : undefined;

  const keyNameErrorMessage = hasSubmitted
    ? getState()?.errors?.nonKeyDocument?.[0]
    : undefined;

  const [passportDocuments, setPassportDocuments] = useState<GicDocument[]>([]);
  const [loaDocuments, setLoaDocuments] = useState<GicDocument[]>([]);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const {
    handleDeleteDocument,
    handlePresignedUrlDownload,
    handleDownloadDocument,
  } = useDocumentActions();
  const { validateFiles } = useFileUpload({
    accept: gicAcceptedFormats.map((key) => `.${key}`).join(`,`),
  });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setPassportDocuments(props.passportDocuments);
  }, [props.passportDocuments]);

  useEffect(() => {
    setLoaDocuments(props.loaDocuments);
  }, [props.loaDocuments]);

  useEffect(() => {
    props?.uploadedDocuments?.([...passportDocuments, ...loaDocuments]);
  }, [passportDocuments, loaDocuments]);

  const remainingPassportDocumentKeys = difference(
    passportDocumentKeys,
    passportDocuments.map((doc) => doc.keyName),
  ).filter((keyName) => !isFalsy(keyName)) as Document[`keyName`][];

  const remainingPassportDocOptions = remainingPassportDocumentKeys.map(
    (key) => {
      return {
        label: startCaseToLower(key),
        value: key,
      };
    },
  );

  const documentFileObject = (doc: any): doc is Document => {
    return typeof doc?.keyName !== `undefined`;
  };

  const handlePassportDocumentDelete = (index: number, document?: Document) => {
    if (document?.id && document?.keyName) {
      handleDeleteDocument({
        id: document.id as any,
        keyName: document.keyName,
        onDeleteDocumentSuccess: () => removePassportDocument(index),
      });
    } else {
      removePassportDocument(index);
    }
  };

  const handlePassportDocumentKeyChange = (
    index: number,
    keyName?: Document[`keyName`],
  ) => {
    if (!keyName) return;
    const documents = [...passportDocuments];
    if (documents?.[index]) {
      documents[index] = { ...documents[index], keyName };
    }

    setPassportDocuments(documents);
  };

  const removePassportDocument = (index: number) => {
    const documents = [...passportDocuments];
    documents.splice(index, 1);
    setPassportDocuments(documents);
  };

  const handleView = (data: any) => {
    handlePresignedUrlDownload({
      url: data?.url || ``,
    });
  };

  const handleFiles = (files: FileList | null) => {
    const file = files?.[0];
    if (!file) return;
    const validationResult = validateFiles({
      files: [file],
      acceptedFormats: gicAcceptedFormats,
    });

    if (validationResult.isValid) {
      setPassportDocuments([
        ...passportDocuments,
        { file, isOtherUploadedDocument: true },
      ]);
    } else {
      enqueueSnackbar(validationResult.errorMessage, { variant: `error` });
    }
  };

  const handlePassportUpload = () => {
    inputRef?.current?.click?.();
  };

  return (
    <>
      {isFalsy(passportDocuments) || passportDocuments?.length == 0 ? (
        <>
          <DocumentUploadCard
            errorMessage={passportErrorMessage}
            cardTitle="  Student Passport"
            cardDescription=" Please Upload A scanned copy of front and last
    page of the Passport of the student"
            keyName="CONSOLIDATED_PASSPORT"
            studentId={props?.studentId}
            onDocumentUpload={(doc) => {
              if (doc) {
                setPassportDocuments([...passportDocuments, doc]);
              }
            }}
          />
        </>
      ) : (
        <>
          {passportDocuments.map((doc, index) => {
            if (!doc.isOtherUploadedDocument && doc.keyName) {
              //  Document file object
              return (
                <GicDocumentChipWrapper
                  key={doc.keyName}
                  title={
                    <Typography variant="subtitle2">
                      {startCaseToLower(doc.keyName)}
                    </Typography>
                  }
                  onDelete={(doc) =>
                    handlePassportDocumentDelete(index, doc as any)
                  }
                  documentMeta={doc.file as Document}
                />
              );
            } else if (
              doc.isOtherUploadedDocument &&
              !documentFileObject(doc.file)
            ) {
              return (
                <div key={`gic-passport` + (doc.file as File).lastModified}>
                  <div
                    className={`py-[10px] px-4 flex items-center gap-4 border rounded-lg   w-full justify-between ${
                      !isFalsy(keyNameErrorMessage) && !doc?.keyName
                        ? `border-error-400`
                        : `border-primary-400`
                    }`}
                  >
                    <Typography variant="subtitle2" className="line-clamp-2">
                      {doc.file.name}
                    </Typography>
                    <div className="min-w-[40%] flex items-center">
                      <DropDown
                        options={remainingPassportDocOptions}
                        placeholder="Select key"
                        className="w-full"
                        onChange={(_, value) =>
                          handlePassportDocumentKeyChange(
                            index,
                            (value as OPTION_TYPE)
                              ?.value as Document[`keyName`],
                          )
                        }
                      />
                      <span>
                        <IconButton
                          onClick={() => removePassportDocument(index)}
                        >
                          <DeleteOutline />
                        </IconButton>
                      </span>
                    </div>
                  </div>
                  {!isFalsy(keyNameErrorMessage) && !doc.keyName && (
                    <Typography variant="caption1" className="text-error-500">
                      {keyNameErrorMessage}
                    </Typography>
                  )}
                </div>
              );
            }
          })}
          {!isFalsy(remainingPassportDocumentKeys) ? (
            <div className="flex justify-between">
              <Typography variant="subtitle2">
                Want to upload other passport documents ?
              </Typography>
              <span onClick={handlePassportUpload}>
                <input
                  ref={inputRef}
                  hidden
                  className="hidden"
                  type={`file`}
                  accept={gicAcceptedFormats.map((key) => `.${key}`).join(`,`)}
                  onChange={(event) => handleFiles(event.target.files)}
                />
                <Typography
                  variant="subtitle1"
                  className="underline text-grey-900 cursor-pointer"
                >
                  Upload
                </Typography>
              </span>
            </div>
          ) : (
            <></>
          )}
        </>
      )}

      {isFalsy(loaDocuments) || loaDocuments?.length == 0 ? (
        <DocumentUploadCard
          errorMessage={loaErrorMessage}
          cardTitle="Letter of Acceptance"
          cardDescription="Please upload letter of acceptance or any other
          proof of student’s offer acceptance"
          keyName={`LETTER_OF_ACCEPTANCE` as any}
          studentId={props?.studentId}
          onDocumentUpload={(doc) => {
            if (doc) {
              setLoaDocuments([...loaDocuments, doc]);
            }
          }}
        />
      ) : (
        <>
          {loaDocuments.map((doc) => {
            if (!doc.isOtherUploadedDocument && doc.keyName) {
              return (
                <GicDocumentChipWrapper
                  key={doc.keyName}
                  title={
                    <Typography variant="subtitle2">
                      {startCaseToLower(doc.keyName)}
                    </Typography>
                  }
                  onView={(doc) => {
                    if (typeof doc !== `undefined`) {
                      handleView(doc as any);
                    }
                  }}
                  onDownload={(doc) => {
                    if (typeof doc !== `undefined`) {
                      handleDownloadDocument(doc as any);
                    }
                  }}
                />
              );
            } else {
              return <></>;
            }
          })}
        </>
      )}
    </>
  );
}
