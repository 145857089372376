export interface IuseFileUploadProps {
  accept?: string;
  multiple?: boolean;
}

type ValidateFiles = {
  files: File[] | null;
  /**
   * by default it will only accept pdf files
   */
  acceptedFormats?: string[];
  /**
   * 35000000bytes : 35MB default value
   */
  maxSizeInBytes?: number;
};

type ValidationResult = {
  isValid: boolean;
  errorMessage?: string;
};

export default function useFileUpload(props?: IuseFileUploadProps) {
  /**
   * Opens a file selection modal and invokes the provided callback with the selected files.
   *
   * @param {function} handleFile - A callback function to handle the selected files.
   * @param {FileList | null} files - The selected files (if any).
   * @returns {void}
   */

  const openFileModal = (handleFile: (files?: FileList | null) => void) => {
    /**
     * @type {HTMLInputElement}
     */
    const input = document.createElement(`input`);
    input.type = `file`;
    input.accept = props?.accept ?? ``;
    if (props?.multiple) {
      input.multiple = true;
    }
    input.click();
    input.onchange = function (event) {
      const files = (event.target as HTMLInputElement)?.files;
      handleFile(files);
    };
  };

  const validateFiles = ({
    files,
    acceptedFormats = [`pdf`],
    maxSizeInBytes = 35000000,
  }: ValidateFiles): ValidationResult => {
    if (!files || files.length === 0) {
      return { isValid: false, errorMessage: `No files selected.` };
    }
    //[TODO]: should we parse content to get the file type ?
    for (const file of files) {
      // Check file format
      const fileFormat = file.name.split(`.`).pop()?.toLowerCase() || ``;
      if (!acceptedFormats.includes(fileFormat)) {
        return {
          isValid: false,
          errorMessage: `Invalid file format: ${file.name}`,
        };
      }

      // Check file size
      if (file.size > maxSizeInBytes) {
        return {
          isValid: false,
          errorMessage: `File size exceeds the maximum allowed size: ${file.name}`,
        };
      }
    }

    return { isValid: true };
  };

  return { openFileModal, validateFiles };
}
