/* eslint-disable @next/next/no-page-custom-font */
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@mui/material';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import { CaptureConsole } from '@sentry/integrations';
import * as Sentry from '@sentry/nextjs';
import App, { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { SnackbarProvider } from 'notistack';
import { Store } from 'redux';
import { wrapper } from 'redux/store';
import { END } from 'redux-saga';
import { theme } from 'utils/theme';

import { selectList } from '@/app/features/sharedList/sharedListSlice';
import { useAppSelector } from '@/app/hooks';
import JoyrideComponent from '@/components/Joyride/JoyrideComponent';
import { SnackbarUtilsConfigurator } from '@/utils/snackbar';
import ApplicationProvider from 'components/Application/context/Application';
import Layout from 'components/Layout';
import GlobalModal from 'components/modals/GlobalModal';

import ConfirmDialog from '../components/ConfirmDialog';

import 'public/static/GlobalStyleSheet.scss';
import 'tailwindcss/tailwind.css';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/output.css';
import '../styles/globals.css';
import '@leapfinance/frontend-commons/tailwind-compiled.css';

if (process.env.SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === `production`,
    dsn: process.env.SENTRY_DSN,
    sampleRate: 0.2,
    tracesSampleRate: 0.2,
    autoSessionTracking: true,
    integrations: [new CaptureConsole({ levels: [`error`] }) as any],
    async beforeSend(event, hint) {
      // Because beforeSend and beforeBreadcrumb are async, user can fetch some data
      // return a promise, or whatever he wants
      // Our CustomError defined in errors.js has `someMethodAttachedToOurCustomError`
      // which can mimick something like a network request to grab more detailed error info or something.
      // hint is original exception that was triggered, so we check for our CustomError name
      const error = hint?.originalException as Error;
      if (error && error?.message === `websocket error`) {
        return null;
      }
      return event;
    },
  });
}

declare module 'next' {
  export interface NextPageContext {
    /**
     * Provided by next-redux-wrapper: The redux store
     */
    store: Store;
  }
}
const GeebeeApp = ({ Component, pageProps }: AppProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isErrorModelOpen, setIsErrorModelOpen] = useState<boolean>(false);
  const [currentPageRoute, setCurrentPageRoute] = useState<string>();

  const router = useRouter();

  useEffect(() => {
    setCurrentPageRoute(router?.pathname?.toString());
  }, [router]);

  const progressDialog = (value: boolean) => {
    setIsModalOpen(value);
  };

  const showErrorMessage = (value: boolean) => {
    setIsErrorModelOpen(value);
  };

  const modifiedPageProps = { ...pageProps, progressDialog, showErrorMessage };

  const authenticationExemptedRoutes = [`/redirect/[url]`];

  if (
    currentPageRoute &&
    authenticationExemptedRoutes?.includes(currentPageRoute)
  ) {
    return (
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <ApplicationProvider>
            <>
              <Head>
                <meta name="robots" content="noindex, nofollow" />
                <link rel="icon" href="/GBFavicon.svg" />
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link
                  rel="preconnect"
                  href="https://fonts.gstatic.com"
                  crossOrigin="true"
                />
                <link
                  href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap"
                  rel="stylesheet"
                />
              </Head>
              <Component {...modifiedPageProps} />
            </>
          </ApplicationProvider>
        </SnackbarProvider>
      </ThemeProvider>
    );
  }

  return router.pathname === `/healthcheck` ? (
    <Component {...modifiedPageProps} />
  ) : (
    <>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <ApplicationProvider>
            <>
              <Head>
                <meta name="robots" content="noindex, nofollow" />
                <link rel="icon" href="/GBFavicon.svg" />
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link
                  rel="preconnect"
                  href="https://fonts.gstatic.com"
                  crossOrigin="true"
                />
                <link
                  href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap"
                  rel="stylesheet"
                />

                {/* eslint-disable-next-line @next/next/no-page-custom-font */}
                <link
                  href="https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
                  rel="stylesheet"
                />
              </Head>
              {String(process.env.NEXT_PUBLIC_CLARITY_TRACKING_KEY) !== `` && (
                <Script
                  id="csp-tracker-clarity"
                  strategy="afterInteractive"
                  dangerouslySetInnerHTML={{
                    __html: `(function(c,l,a,r,i,t,y){
                        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                        })(window, document, "clarity", "script", "${process.env.NEXT_PUBLIC_CLARITY_TRACKING_KEY}");`,
                  }}
                />
              )}
              {isErrorModelOpen && (
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className="flex justify-center items-center"
                  open={isModalOpen}
                  onClose={() => {
                    setIsErrorModelOpen(false);
                  }}
                  closeAfterTransition
                >
                  <Fade in={true}>
                    <div className="relative py-4 px-4 rounded bg-white max-w-md w-11/12 md:w-full text-center outline-none">
                      <p className="text-xl pb-8">Please Wait.....</p>
                    </div>
                  </Fade>
                </Modal>
              )}
              {isModalOpen && (
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className="flex justify-center items-center"
                  open={isModalOpen}
                  onClose={() => {
                    setIsModalOpen(false);
                  }}
                  closeAfterTransition
                >
                  <Fade in={true}>
                    <div className="relative py-4 px-4 rounded bg-white max-w-md w-11/12 md:w-full text-center outline-none">
                      <p className="text-xl pb-8">Please Wait.....</p>
                    </div>
                  </Fade>
                </Modal>
              )}
              {authenticationExemptedRoutes?.includes(
                router?.pathname?.toString(),
              ) ? (
                <Component {...modifiedPageProps} />
              ) : (
                <Layout {...modifiedPageProps}>
                  <Component {...modifiedPageProps} />
                </Layout>
              )}
            </>
            <ToastContainer
              position="top-right"
              autoClose={7500}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnHover
            />
            <ConfirmDialog />
            <GlobalModal />
            <SnackbarUtilsConfigurator />
            <JoyrideComponent />
          </ApplicationProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </>
  );
};

export default wrapper.withRedux(GeebeeApp);
