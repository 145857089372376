import { FetchArgs } from '@reduxjs/toolkit/dist/query';

import { documentUploadBaseApi } from '@/services/base/documentUpload';
import { geebeeRootBaseApiWithTags } from '@/services/base/geebeeApi';
import {
  AddSopNotesRequestType,
  AddSopNotesResponseType,
  DeleteSopDocumentRequestType,
  DeleteSopDocumentResponseType,
  GetAllSopOwnersResponseType,
  GetAllSopsRequestType,
  GetAllSopsResponseType,
  GetSopDetailsRequestType,
  GetSopDetailsResponseType,
  GetSopRequestDetailsRequestType,
  GetSopRequestDetailsResponseType,
  UpdateSopRequestDetailsRequestType,
  UpdateSopRequestDetailsResponseType,
  UploadSopDocumentsRequestType,
  UploadSopDocumentsResponseType,
} from '@/types/Services';
import { transformResponse } from '@/utils/common';

export const sopServicesApi = geebeeRootBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getSopDetailsFromApplicationId: builder.query<
      GetSopDetailsResponseType,
      GetSopDetailsRequestType
    >({
      query: (payload) =>
        `/sop/app/${payload?.requestType}/${payload?.applicationId}`,
      providesTags: [`GetSopDetailsFromAppId`],
      transformResponse: transformResponse,
    }),
    getAllSopRequests: builder.mutation<
      GetAllSopsResponseType,
      GetAllSopsRequestType
    >({
      query: (payload) => {
        return {
          url: `/sop/all`,
          method: `POST`,
          body: payload,
        };
      },
      transformResponse,
    }),
    getSopDetailsFromRequestId: builder.query<
      GetSopRequestDetailsResponseType,
      GetSopRequestDetailsRequestType
    >({
      query: (payload) => `/sop/${payload?.sopRequestId}`,
      transformResponse,
      providesTags: [`GetSopDetailsFromRequestId`],
    }),
    getAllSopOwners: builder.query<GetAllSopOwnersResponseType, void>({
      query: () => `/sop-owner/all`,
      transformResponse,
    }),
    updateSopRequestDetails: builder.mutation<
      UpdateSopRequestDetailsResponseType,
      UpdateSopRequestDetailsRequestType
    >({
      query: (payload) => {
        return {
          url: `/sop/${payload?.sopRequestId}`,
          method: `PUT`,
          body: payload?.updateSopRequestDto,
        };
      },
      transformResponse,
    }),
    deleteSopDocument: builder.mutation<
      DeleteSopDocumentResponseType,
      DeleteSopDocumentRequestType
    >({
      query: (payload) => {
        return {
          url: `/sop/document/${payload?.documentId}`,
          method: `DELETE`,
        };
      },
      transformResponse,
      invalidatesTags: [`GetSopDetailsFromAppId`, `GetSopDetailsFromRequestId`],
    }),
    addSopNotes: builder.mutation<
      AddSopNotesResponseType,
      AddSopNotesRequestType
    >({
      query: (payload) => {
        return {
          method: `POST`,
          url: `/sop/${payload?.sopRequestId}/notes`,
          body: payload,
        };
      },
      invalidatesTags: [`GetSopDetailsFromRequestId`],
      transformResponse,
    }),
  }),
});

export const sopDocumentApi = documentUploadBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadSopDocument: builder.mutation<
      UploadSopDocumentsResponseType,
      UploadSopDocumentsRequestType
    >({
      query: (payload) => {
        const formData = new FormData();
        if (payload?.file) {
          formData.append(`file`, payload?.file as File);
        }
        return {
          url: `/sop/${payload?.applicationId}/upload/${payload?.documentType}`,
          body: formData,
          method: `POST`,
        } as FetchArgs;
      },
      transformResponse,
    }),
  }),
});

export const {
  useGetAllSopRequestsMutation,
  useGetSopDetailsFromApplicationIdQuery,
  useGetSopDetailsFromRequestIdQuery,
  useGetAllSopOwnersQuery,
  useUpdateSopRequestDetailsMutation,
  useDeleteSopDocumentMutation,
  useAddSopNotesMutation,
} = sopServicesApi;

export const { useUploadSopDocumentMutation } = sopDocumentApi;
