import { useEffect } from 'react';

import { isFalsy } from '@/components/Commission/helper';
import { useLazyCheckDuplicateQuery } from '@/services/student';

type Props = {
  contactNumber?: string;
  countryCode?: string;
  skipDuplicateValidation?: boolean;
  cspId?: any;
};

function useCheckDuplicateStudent({
  cspId,
  contactNumber,
  countryCode,
  skipDuplicateValidation,
}: Props) {
  const [triggerDuplicateStudentCheck, { data: existingStudentData }] =
    useLazyCheckDuplicateQuery();

  const handlePhoneNumberValidation = (
    value: string,
    countryCode?: number,
    cspId?: any,
    checkForAllCountryCodes?: boolean,
  ) => {
    if (
      (!checkForAllCountryCodes && (countryCode as any) !== `+91`) ||
      isNaN(Number(value)) ||
      isNaN(Number(countryCode)) ||
      value?.length !== 10
    ) {
      return;
    }
    triggerDuplicateStudentCheck({
      mobileNumber: `${countryCode}${value}`,
      cspId: cspId ?? 0,
    });
  };

  useEffect(() => {
    if (skipDuplicateValidation) {
      return;
    }
    if (!isFalsy(countryCode) && !isFalsy(contactNumber)) {
      handlePhoneNumberValidation(
        contactNumber ?? ``,
        (countryCode ?? ``) as any,
        cspId,
      );
    }
  }, [contactNumber, countryCode, skipDuplicateValidation, cspId]);

  return {
    existingStudentData,
    handlePhoneNumberValidation,
  };
}

export default useCheckDuplicateStudent;
