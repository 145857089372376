import { isFalsy } from '@leapfinance/frontend-commons';
import { differenceInYears, isFuture } from 'date-fns';

import { Shape } from '@/types/common';
import {
  VASCreateAccomodationRequestBodyType,
  VASUpdateAccomodationRequestBodyType,
} from '@/types/vas/accomodation';
import { VASCreateFlywirePaymentLinkRequestBodyType } from '@/types/vas/flywire';
import {
  VASCreateGICRequestBodyType,
  VASUpdateGICRequestBodyType,
} from '@/types/vas/gic';
import {
  VASCreateLoansRequestBodyType,
  VASUpdateLoansRequestBodyType,
} from '@/types/vas/loan';
import yup from '@/utils/yup/yupInstance';

const today = new Date();

const trimValues = (value: string) => {
  return value.trim();
};

const optionSchema = yup.object().shape({
  label: yup.string(),
  value: yup.string(),
});

function optionSchemaTest(this: any, item: any) {
  const isItemNotSelected = !item?.label || !item?.value;
  if (isItemNotSelected) {
    return this.createError({
      path: ``,
      message: `Required`,
    });
  }

  return true;
}

const GICBankPasswordSchema = yup
  .string()
  .matches(/^[^\s]+$/, `Password cannot contain spaces`);

const GICBankUserNameSchema = yup
  .string()
  .min(5, `Should be at least 5 characters`)
  .max(100, `Should be at most 100 characters`);

const urlSchema = yup.string().url(`Invalid URL format`);

function contactSchemaTest(this: any, item: any) {
  if (
    (!isFalsy(this.parent?.countryCode?.value) &&
      this.parent?.countryCode?.value !== `+91`) ||
    // TODO:: this for request tracker forms - need a better solutoin
    (!isFalsy(this.parent?.countrycode?.value) &&
      this.parent?.countrycode?.value !== `+91`)
  ) {
    if (isFalsy(item)) {
      return this.createError({
        path: ``,
        message: `Contact Number is required`,
      });
    }
    return true;
  }

  if (isFalsy(item)) {
    return this.createError({
      path: ``,
      message: `Contact Number is required`,
    });
  }

  if ((item || ``).charAt(0) === `0`) {
    return this.createError({
      path: ``,
      message: `Contact number cannot start with 0`,
    });
  }

  if ((item || ``).length !== 10 || !/^\d+$/.test(item || ``)) {
    return this.createError({
      path: ``,
      message: `Contact number must be exactly 10 digits`,
    });
  }
  return true;
}

const contactSchema = yup
  .string()
  .test(
    `valid-contact-number`,
    `Invalid phone number`,
    contactSchemaTest as any,
  );

const emailSchema = yup
  .string()
  .email(`Invalid email format`)
  .max(255, `Email must be at most 255 characters`);

const studentNameSchema = yup
  .string()
  .max(50, `Max 50 characters only`)
  .transform(trimValues);

const CountryTextFieldSchema = yup
  .string()
  .matches(
    /^[a-zA-Z ]*$/,
    `Destination country must contain only alphabetical characters and spaces`,
  )
  .max(56, `Destination country must be at most 56 characters`);

const CityTextFieldSchema = yup
  .string()
  .matches(
    /^[a-zA-Z]+(?: [a-zA-Z]+)*$/,
    `Preferred city must contain only alphabetical characters and spaces`,
  )
  .max(85, `Preferred city must be at most 85 characters`);

const StudentCreationFormSchema = yup.object().shape({
  firstName: studentNameSchema.required(`Required`),
  lastName: studentNameSchema.required(`Required`),
});

const DobSchema = yup
  .date()
  .test(
    `age-validation`,
    `Age can't be less than 10 years`,
    function (dob: any) {
      if (this.parent.createStudentMode === `no`) return true;

      if (typeof dob === `undefined` || dob === null) return true;

      if (isFuture(dob)) {
        return false; // Invalid date or future date
      }

      const age = differenceInYears(today, dob);
      return age >= 10;
    },
  );

export type VasStudentCreationRequestBodyType = {
  createStudentMode: 'yes' | 'no';
  firstName: string;
  lastName: string;
  student: {
    label: string;
    value: string | number;
  };
  cspId: {
    label: string;
    value: string | number;
  };
  maritalStatus: {
    label: string;
    value: string;
  };
  gender: {
    label: string;
    value: string;
  };
  dob: string;
};

const VASStudentCreationSchema = yup
  .object()
  .shape<Shape<VasStudentCreationRequestBodyType>>({
    createStudentMode: yup.string().oneOf([`yes`, `no`]).required(`Required`),
    firstName: yup.string().when(`createStudentMode`, {
      is: (createStudentMode: 'yes' | 'no') => createStudentMode === `yes`,
      then: studentNameSchema.required(`Required`),
      otherwise: studentNameSchema.optional().nullable(),
    }),
    lastName: yup.string().when(`createStudentMode`, {
      is: (createStudentMode: 'yes' | 'no') => createStudentMode === `yes`,
      then: studentNameSchema.required(`Required`),
      otherwise: studentNameSchema.optional().nullable(),
    }),
    student: optionSchema.when(`createStudentMode`, {
      is: (createStudentMode: 'yes' | 'no') => createStudentMode === `yes`,
      then: optionSchema.nullable().optional(),
      otherwise: optionSchema
        .nullable()
        .test(`has-label-value`, ``, optionSchemaTest),
    }),
    cspId: optionSchema
      .nullable()
      .test(`has-label-value`, ``, optionSchemaTest),
    maritalStatus: optionSchema.when(`createStudentMode`, {
      is: (createStudentMode: 'yes' | 'no') => createStudentMode === `yes`,
      then: optionSchema
        .nullable()
        .test(`has-label-value`, ``, optionSchemaTest),
      otherwise: optionSchema.nullable().optional(),
    }),
    gender: optionSchema.when(`createStudentMode`, {
      is: (createStudentMode: 'yes' | 'no') => createStudentMode === `yes`,
      then: optionSchema
        .nullable()
        .test(`has-label-value`, ``, optionSchemaTest),
      otherwise: optionSchema.nullable().optional(),
    }),
    dob: yup.date().when(`createStudentMode`, {
      is: (createStudentMode: 'yes' | 'no') => createStudentMode === `yes`,
      then: DobSchema.required(`Date of birth is required`),
      otherwise: DobSchema.nullable().optional(),
    }),
  });

const AccomodationFormSchema = yup.object().shape<
  Shape<
    VASCreateAccomodationRequestBodyType & {
      intake: {
        label: string;
        value: string;
      };
    }
  >
>({
  intake: optionSchema.nullable().test(`has-label-value`, ``, optionSchemaTest),
  contactNumber: contactSchema.nullable(),
  emailId: emailSchema.nullable().required(`Email is Required`),
  countryCode: optionSchema
    .nullable()
    .test(`has-label-value`, ``, optionSchemaTest),
  universityName: optionSchema
    .nullable()
    .test(`has-label-value`, ``, optionSchemaTest),
  destinationCountry: optionSchema
    .nullable()
    .test(`has-label-value`, ``, optionSchemaTest),
  destinationCity: optionSchema
    .nullable()
    .test(`has-label-value`, ``, optionSchemaTest),
  recipientEmailIds: yup.array(yup.string()),
});

const AccomodationRequestTrackerFormSchema = yup.object().shape<
  Shape<
    VASUpdateAccomodationRequestBodyType & {
      intake: {
        label: string;
        value: string;
      };
    }
  >
>({
  contactNumber: contactSchema.nullable(),
  emailId: emailSchema.nullable().required(`Email is Required`),
  countrycode: optionSchema
    .nullable()
    .test(`has-label-value`, ``, optionSchemaTest),
  universityName: optionSchema
    .nullable()
    .test(`has-label-value`, ``, optionSchemaTest),
  destinationCountry: optionSchema
    .nullable()
    .test(`has-label-value`, ``, optionSchemaTest),
  destinationCity: optionSchema
    .nullable()
    .test(`has-label-value`, ``, optionSchemaTest),
  status: optionSchema.nullable().test(`has-label-value`, ``, optionSchemaTest),
  intake: optionSchema.nullable().test(`has-label-value`, ``, optionSchemaTest),
  cannotBeFulfilledReason: yup.string().when(`status`, {
    is: (item: any) => item?.value === `CANNOT_BE_FULFILLED`,
    then: yup
      .string()
      .max(255, `Must be at most 255 characters`)
      .nullable()
      .required(`Required`),
    otherwise: yup
      .string()
      .max(255, `Must be at most 255 characters`)
      .nullable()
      .optional(),
  }),
  onHoldReason: yup.string().when(`status`, {
    is: (item: any) => item?.value === `ON_HOLD`,
    then: yup
      .string()
      .max(255, `Must be at most 255 characters`)
      .nullable()
      .required(`Required`),
    otherwise: yup
      .string()
      .max(255, `Must be at most 255 characters`)
      .nullable()
      .optional(),
  }),
});

const GICFormSchema = yup.object().shape<Shape<VASCreateGICRequestBodyType>>({
  countryCode: optionSchema
    .nullable()
    .test(`has-label-value`, ``, optionSchemaTest),
  contactNumber: contactSchema.nullable(),
  emailId: emailSchema.nullable().required(`Email is Required`),
  intake: optionSchema.nullable().test(`has-label-value`, ``, optionSchemaTest),
  universityName: optionSchema
    .nullable()
    .test(`has-label-value`, ``, optionSchemaTest),
  bankName: yup.string().required(`Required`),
  recipientEmailIds: yup.array(yup.string()),
});

const GICRequestTrackerFormSchema = yup.object().shape<
  Shape<
    VASUpdateGICRequestBodyType & {
      intake: {
        label: string;
        value: string;
      };
      gicCertificate: string;
    }
  >
>({
  countrycode: optionSchema.test(`has-label-value`, ``, optionSchemaTest),
  contactNumber: contactSchema.nullable(),
  emailId: emailSchema.required(`Email is Required`),
  universityName: optionSchema
    .nullable()
    .test(`has-label-value`, ``, optionSchemaTest),
  intake: optionSchema.nullable().test(`has-label-value`, ``, optionSchemaTest),
  userName: GICBankUserNameSchema.nullable().optional(),
  password: GICBankPasswordSchema.nullable().optional(),
  bankUrl: urlSchema.nullable().optional(),
  status: optionSchema.nullable().test(`has-label-value`, ``, optionSchemaTest),
  bankName: optionSchema
    .nullable()
    .test(`has-label-value`, ``, optionSchemaTest),
  fundingInstructionDocumentUrl: yup.string().nullable().optional(),
  cannotBeFulfilledReason: yup.string().when(`status`, {
    is: (item: any) => item?.value === `CANNOT_BE_FULFILLED`,
    then: yup
      .string()
      .max(255, `Must be at most 255 characters`)
      .nullable()
      .required(`Required`),
    otherwise: yup
      .string()
      .max(255, `Must be at most 255 characters`)
      .nullable()
      .optional(),
  }),
  onHoldReason: yup.string().when(`status`, {
    is: (item: any) => item?.value === `ON_HOLD`,
    then: yup
      .string()
      .max(255, `Must be at most 255 characters`)
      .nullable()
      .required(`Required`),
    otherwise: yup
      .string()
      .max(255, `Must be at most 255 characters`)
      .nullable()
      .optional(),
  }),
  gicCertificateDocumentUrl: yup.string().when(`status`, {
    is: (item: any) => item?.value === `REQUEST_FULFILLED`,
    then: yup.string().nullable().required(`GIC Certificate is Required`),
    otherwise: yup.string().nullable().optional(),
  }),
});

const FlywireFormSchema = yup.object().shape<
  Shape<
    VASCreateFlywirePaymentLinkRequestBodyType &
      VasStudentCreationRequestBodyType & {
        applicationExists: boolean;
        permissionRequired: any;
      }
  >
>({
  ...VASStudentCreationSchema.fields,
  emailId: emailSchema.nullable().required(`Email is Required`),
  countryCode: optionSchema
    .nullable()
    .test(`has-label-value`, ``, optionSchemaTest),
  contactNumber: contactSchema.nullable(),
  applicationId: yup.string().when(`student`, {
    is: (student: any) => student?.value !== undefined,
    then: yup.string().when(`applicationExists`, {
      is: (exists: any) => {
        return exists === true;
      },
      then: yup.string().required(`Required`),
      otherwise: yup.string().nullable().optional(),
    }),
    otherwise: yup.string().nullable().optional(),
  }),
  universityName: optionSchema.when(`createStudentMode`, {
    is: (createStudentMode: 'yes' | 'no') => createStudentMode === `yes`,
    then: optionSchema.nullable().optional(),
    otherwise: optionSchema
      .nullable()
      .test(`has-label-value`, ``, optionSchemaTest),
  }),
  permissionRequired: yup
    .array()
    .of(optionSchema)
    .when(`createStudentMode`, {
      is: (createStudentMode: 'yes' | 'no') => createStudentMode === `yes`,
      then: yup.array().of(optionSchema).optional(),
      otherwise: yup.array().of(optionSchema).min(1, `Required`),
    }),
});

const LoansFormSchema = yup.object().shape<
  Shape<
    VASCreateLoansRequestBodyType & {
      permissionRequired: any;
    }
  >
>({
  contactNumber: contactSchema.nullable(),
  emailId: emailSchema.nullable().required(`Email is Required`),
  countryCode: optionSchema
    .nullable()
    .test(`has-label-value`, ``, optionSchemaTest),
  universityName: optionSchema
    .nullable()
    .test(`has-label-value`, ``, optionSchemaTest),
  destinationCountry: optionSchema
    .nullable()
    .test(`has-label-value`, ``, optionSchemaTest),
  type: optionSchema.nullable().test(`has-label-value`, ``, optionSchemaTest),
  permissionRequired: yup.array().of(optionSchema).min(1, `Required`),
});

const LoanRequestTrackerFormSchema = yup
  .object()
  .shape<Shape<VASUpdateLoansRequestBodyType>>({
    contactNumber: contactSchema.nullable(),
    emailId: emailSchema.nullable().required(`Email is Required`),
    countrycode: optionSchema
      .nullable()
      .test(`has-label-value`, ``, optionSchemaTest),
    universityName: optionSchema
      .nullable()
      .test(`has-label-value`, ``, optionSchemaTest),
    destinationCountry: optionSchema
      .nullable()
      .test(`has-label-value`, ``, optionSchemaTest),
    type: optionSchema.nullable().test(`has-label-value`, ``, optionSchemaTest),
    status: optionSchema
      .nullable()
      .test(`has-label-value`, ``, optionSchemaTest),
    rejectReason: yup.string().when(`status`, {
      is: (item: any) => item?.value === `REJECTED`,
      then: yup
        .string()
        .max(255, `Must be at most 255 characters`)
        .nullable()
        .required(`Required`),
      otherwise: yup
        .string()
        .max(255, `Must be at most 255 characters`)
        .nullable()
        .optional(),
    }),
    onHoldReason: yup.string().when(`status`, {
      is: (item: any) => item?.value === `ON_HOLD`,
      then: yup
        .string()
        .max(255, `Must be at most 255 characters`)
        .nullable()
        .required(`Required`),
      otherwise: yup
        .string()
        .max(255, `Must be at most 255 characters`)
        .nullable()
        .optional(),
    }),
  });

export {
  contactSchema,
  emailSchema,
  studentNameSchema,
  AccomodationFormSchema,
  optionSchema,
  optionSchemaTest,
  AccomodationRequestTrackerFormSchema,
  StudentCreationFormSchema,
  VASStudentCreationSchema,
  GICFormSchema,
  GICRequestTrackerFormSchema,
  FlywireFormSchema,
  LoansFormSchema,
  LoanRequestTrackerFormSchema,
};
