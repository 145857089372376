import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

type SetStateWithCallback<T> = (
  state: T | ((prevState: T) => T),
  callback?: () => void,
) => void;

function useStateWithCallback<T>(
  initialState: T,
): [T, SetStateWithCallback<T>] {
  const [state, setState] = useState(initialState);
  const callbackRef = useRef<(() => void) | null | undefined>(null);

  useEffect(() => {
    if (callbackRef.current !== null || callbackRef.current !== undefined) {
      callbackRef.current?.();
      callbackRef.current = null;
    }
  }, [state]);

  const setStateWithCallback: SetStateWithCallback<T> = (
    newState: T | ((prevState: T) => T),
    callback?: () => void,
  ) => {
    callbackRef.current = callback;
    setState(newState);
  };

  return [state, setStateWithCallback];
}

export default useStateWithCallback;
