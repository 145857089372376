import { PageNames } from '../types/segmentEvents';

const getPageName = () => {
  const pathName = window.location.pathname;
  if (pathName === `/dashboard/summary`)
    return PageNames.SPEEDUP_SUMMARY_DASHBOARD;
  else if (pathName === `/course-finder`) return PageNames.COURSE_FINDER;
  else if (pathName?.split(`/`)?.[1] === `/knowledge-library`)
    return PageNames.KNOWLEDGE_LIBRARY;
};

export { getPageName };
