import { HttpClient } from "./api.client";
import { toast } from "react-toastify";

class ApiService {
  private httpClient: HttpClient;

  private isAuthenticated = false;

  constructor(private backendUrl?: string) {
    this.httpClient = new HttpClient(backendUrl);
  }

  private setAuthorizationToken(token: string) {
    this.httpClient.setAuthorizationToken(token);

    this.isAuthenticated = true;
  }

  private disposeAuthorizationToken() {
    this.httpClient.disposeAuthorizationToken();

    this.isAuthenticated = false;
  }

  private async initializeSession(appId: string, subscriberId: string, hmacHash = null) {
    return await this.httpClient.post(`/widgets/session/initialize`, {
      applicationIdentifier: appId,
      subscriberId: subscriberId,
      hmacHash,
    });
  }

  async markAllAsRead(subscriberId: string) {
    try {
      const applicationIdentifier = process.env.NOVU_IDENTIFIER;
      const data = await this.initializeSession(String(applicationIdentifier), subscriberId); // this can be cached

      const {
        profile: { _id },
        token,
      } = data;

      this.httpClient.setAuthorizationToken(token);

      return await this.httpClient.post(`/widgets/messages/seen`, {
        subscriberId: _id,
      });
    } catch (error) {
      toast.error(`Cannot mark notifications as read`);
    }
  }
}

const novuInstance = new ApiService(process.env.NOVU_BACKEND_URL);
export default novuInstance;
