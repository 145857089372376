import { AnyAction } from "redux";
const initialState: any = {
  usersData: [],
  countryData: [],
  detailType: null,
  createUserMessage: null,
  editUserMessage: null,
  userManagementPageNo: 1,
  eachUserData: [],
  eachUserId: null,
  addedLeavesMessage: null,
  removedLeavesMessage: null,
  usersDataMessage: null,
  countriesDataMessage: null,
  eachUserDataMessage:null,
};

const userManagementReducer = (
  state: any = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case "FETCH_USERS_DATA":
      return {
        ...state,
        usersDataMessage: null,
      };
    case "FETCH_USERS_DATA_SUCCESS":
      return {
        ...state,
        usersData: action.payload,
      };
    case "FETCH_USERS_DATA_FAIL":
      return {
        ...state,
        usersDataMessage: "Fail",
      };
    case "FETCH_COUNTRIES":
      return {
        ...state,
        countriesDataMessage: null,
      };
    case "FETCH_COUNTRIES_SUCCESS":
      let list: [] = [];
      list = action.payload?.map((item: string) => {
        return {
          label: item,
          value: item,
        };
      });
      return {
        ...state,
        countryData: list,
      };
    case "FETCH_COUNTRIES_FAIL":
      return {
        ...state,
        countriesDataMessage: "Fail",
      };
    case "SET_DETAIL_TYPE":
      return {
        ...state,
        detailType: action.payload,
      };
    case "CREATE_USER":
      return {
        ...state,
        createUserMessage: null,
      };
    case "CREATE_USER_SUCCESS":
      return {
        ...state,
        createUserMessage: "Success",
      };
    case "CREATE_USER_FAIL":
      return {
        ...state,
        createUserMessage: "Fail",
      };
    case "EDIT_USER":
      return {
        ...state,
        editUserMessage: null,
      };
    case "EDIT_USER_SUCCESS":
      return {
        ...state,
        editUserMessage: "Success",
      };
    case "EDIT_USER_FAIL":
      return {
        ...state,
        editUserMessage: "Fail",
      };
    case "FETCH_EACH_USER_DATA":
      return {
        ...state,
        eachUserDataMessage: null,
      };
    case "FETCH_EACH_USER_DATA_SUCCESS":
      return {
        ...state,
        eachUserData: action.payload,
        eachUserDataMessage: "Success",
      };
    case "FETCH_EACH_USER_DATA_FAIL":
      return {
        ...state,
        eachUserDataMessage: "Fail",
      };
    case "SET_USER_MANAGEMENT_PAGE_NO":
      return {
        ...state,
        userManagementPageNo: action.payload,
      };
    case "SET_CREATE_USER_MESSAGE":
      return {
        ...state,
        createUserMessage: action.payload,
      };
    case "SET_EDIT_USER_MESSAGE":
      return {
        ...state,
        editUserMessage: action.payload,
      };
    case "SET_EACH_USER_ID":
      return {
        ...state,
        eachUserId: action.payload,
      };
    case "DEACTIVATE_USER":
      return {
        ...state,
        deactivateUserMessage: null,
      };
    case "DEACTIVATE_USER_SUCCESS":
      return {
        ...state,
        deactivateUserMessage: "Success",
      };
    case "DEACTIVATE_USER_FAIL":
      return {
        ...state,
        deactivateUserMessage: "Fail",
      };
    case "SET_DEACTIVATE_USER_MESSAGE":
      return {
        ...state,
        deactivateUserMessage: action.payload,
      };
    case "ADD_LEAVES":
      return {
        ...state,
        addedLeavesMessage: null,
      };
    case "ADD_LEAVES_SUCCESS":
      return {
        ...state,
        addedLeavesMessage: "Success",
      };
    case "ADD_LEAVES_FAIL":
      return {
        ...state,
        addedLeavesMessage: "Fail",
      };
    case "REMOVE_LEAVES":
      return {
        ...state,
        removedLeavesMessage: null,
      };
    case "REMOVE_LEAVES_SUCCESS":
      return {
        ...state,
        removedLeavesMessage: "Success",
      };
    case "REMOVE_LEAVES_FAIL":
      return {
        ...state,
        removedLeavesMessage: "Fail",
      };
    case "SET_ADDED_LEAVES_MESSAGE":
      return {
        ...state,
        addedLeavesMessage: action.payload,
      };
    case "SET_REMOVED_LEAVES_MESSAGE":
      return {
        ...state,
        removedLeavesMessage: action.payload,
      };
    case "SET_EACH_USER_DATA_MESSAGE":
      return {
        ...state,
        eachUserDataMessage: action.payload,
      };
    default:
      return state;
  }
};

export default userManagementReducer;
