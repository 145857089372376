import { type TestContext } from 'yup';
import { type AnyObject } from 'yup/lib/types';

function wholeNumber(this: any, message?: string) {
  return this.test(
    `wholeNumber`,
    ``,
    function (this: TestContext<AnyObject>, value: number) {
      if (typeof value === `undefined` || value === null) return true;
      //check for posetive integer
      const wholeNumber = Number.isInteger(value) && value >= 0;
      if (!wholeNumber) {
        return this.createError({
          message: message ?? `${this.path} must be a whole number`,
        });
      } else {
        return true;
      }
    },
  );
}

function decimal(this: any, message?: string) {
  return this.test(
    `decimal`,
    ``,
    function (this: TestContext<AnyObject>, value: number) {
      if (typeof value === `undefined`) return true;
      //check for posetive integer
      const isDecimal = value >= 0;
      if (!isDecimal) {
        return this.createError({
          message: message ?? `${this.path} must be a decimal`,
        });
      } else {
        return true;
      }
    },
  );
}

function decimalPoints(this: any, points: number, message?: string) {
  return this.test(
    `decimal-points`,
    ``,
    function (this: TestContext<AnyObject>, value: number) {
      if (typeof value === `undefined`) return true;

      const decimalSeperated = String(value).split(`.`);

      if (
        decimalSeperated &&
        Array.isArray(decimalSeperated) &&
        decimalSeperated.length == 2
      ) {
        const decimalPoints = decimalSeperated?.[1];
        if (decimalPoints && String(decimalPoints).length > points) {
          return this.createError({
            message: message ?? `${this.path} only ${points} are allowed`,
          });
        }
      } else {
        return true;
      }

      return true;
    },
  );
}

export { wholeNumber, decimal, decimalPoints };
