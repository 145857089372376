import * as yup from 'yup';
import { AnyObject } from 'yup/lib/types';
/**
 *@param this Validates the grade entered by the user based on the grade system selected.
 *@this any
 *@returns boolean or throws an error if the grade is invalid.
 */
function validGradeCheck(this: any, gradeKey: string) {
  return this.test(
    `validGradeCheck`,
    ``,
    function (this: yup.TestContext<AnyObject>, value: any) {
      try {
        const gradeSystem = this.parent?.gradeSystem?.value;
        if (!gradeSystem) return true;

        const grade = this.parent?.[gradeKey];
        if (gradeSystem === `CGPA`) {
          const validGrade = Number(grade) >= 0 && Number(grade) <= 10;
          if (!validGrade)
            return this.createError({
              message: `Enter grade between 0 and 10`,
            });
        } else if (gradeSystem === `PERCENTAGE`) {
          const validGrade = Number(grade) >= 0 && Number(grade) <= 100;
          if (!validGrade)
            return this.createError({
              message: `Enter percentage between 0 and 100`,
            });
        } else if (gradeSystem === `ZERO_TO_FOUR`) {
          const validGrade = Number(grade) >= 0 && Number(grade) <= 4;
          if (!validGrade)
            return this.createError({
              message: `Enter percentage between 0 and 4`,
            });
        }
        return true;
      } catch (error) {
        console.log(error);
        return true;
      }
    },
  );
}

function isAlphanumeric(this: any, message: string) {
  return this.test(
    `is-alphanumeric`,
    message,
    function (value: string | undefined) {
      if (!value) {
        return true; // Empty values are considered valid
      }

      // Use a regular expression to check for alphanumeric characters
      const alphanumericRegex = /^[0-9a-zA-Z]+$/;
      return alphanumericRegex.test(value);
    },
  );
}

export { validGradeCheck, isAlphanumeric };
