import { NotificationCategory } from './notification.enum';

interface BaseTemplate {
  userFirstName: string;
  userLastName: string;
  universityName: string;
  studentFirstName: string;
  studentLastName: string;
}

interface StateTemplateInterface extends BaseTemplate {
  oldstate: string;
  newstate: string;
  notificationCategory:
    | 'SHORTLIST_REQUEST_STATUS'
    | 'PAYMENT_STATUS'
    | 'VISA_STATUS'
    | 'PRE_VISA_STATUS'
    | 'DEFERRAL_STATUS'
    | 'CONDITION_MET'
    | 'STATE';
}

interface DocumentTemplateInterface extends BaseTemplate {
  filename: string;
  filelabel: string;
}

interface RemarksTemplateInterface extends BaseTemplate {
  remarkText: string;
  notificationCategory:
    | 'SHORTLIST_REQUEST_PENDING'
    | 'SHORTLIST_REQUEST_PROCESSED'
    | 'SHORTLIST_REQUEST_DELETED'
    | 'REMARKS';
  shortListCoursesCount: string;
  shortListRequestRemark: string;
}

export const stateTemplate = ({
  userFirstName,
  userLastName,
  oldstate,
  newstate,
  universityName,
  studentFirstName,
  studentLastName,
  notificationCategory,
}: StateTemplateInterface) => {
  let titleSuffix = `state`;
  if (notificationCategory === `CONDITION_MET`) {
    titleSuffix = `conditional offer status`;
  } else if (notificationCategory === `PAYMENT_STATUS`) {
    titleSuffix = `payment status`;
  } else if (notificationCategory === `DEFERRAL_STATUS`) {
    titleSuffix = `deferral status`;
  } else if (notificationCategory === `PRE_VISA_STATUS`) {
    titleSuffix = `Pre-Visa status`;
  } else if (notificationCategory === `SHORTLIST_REQUEST_STATUS`) {
    titleSuffix = `shortlist request status`;
  } else if (notificationCategory === `VISA_STATUS`) {
    titleSuffix = `visa status`;
  }
  return {
    title: `${
      userFirstName.length > 15
        ? userFirstName.slice(0, 15) + `...`
        : userFirstName
    } ${(userLastName || ` `)[0]} changed the ${titleSuffix}`,
    body: `${oldstate ? `From: ${oldstate}` : ``} ${
      newstate ? `to ${newstate}` : ``
    }`,
    footNote: `${studentFirstName || ``} ${studentLastName || ``}, ${
      universityName || ``
    }`,
  };
};

export const documentTemplate = ({
  userFirstName,
  userLastName,
  filelabel,
  filename,
  universityName,
  studentFirstName,
  studentLastName,
}: DocumentTemplateInterface) => {
  return {
    title: `${
      userFirstName.length > 15
        ? userFirstName.slice(0, 15) + `...`
        : userFirstName
    } ${(userLastName || ` `)[0]} uploaded a new document`,
    body: `File: ${filelabel}`,
    footNote: `${studentFirstName || ``} ${studentLastName || ``}, ${
      universityName || ``
    }`,
  };
};

export const remarkTemplate = ({
  userFirstName,
  userLastName,
  remarkText,
  universityName,
  studentFirstName,
  studentLastName,
  notificationCategory,
  shortListCoursesCount,
  shortListRequestRemark,
}: RemarksTemplateInterface) => {
  // TODO:: need to transform this based on notificationCategory
  const isShortlistRequestCategoryNotification = [
    `SHORTLIST_REQUEST_PENDING`,
    `SHORTLIST_REQUEST_PROCESSED`,
    `SHORTLIST_REQUEST_DELETED`,
  ].includes(notificationCategory);

  let titleMessage = ``;

  if (notificationCategory === `SHORTLIST_REQUEST_DELETED`) {
    titleMessage = ` deleted a shortlist`;
  } else if (
    [`SHORTLIST_REQUEST_PENDING`, `SHORTLIST_REQUEST_PROCESSED`].includes(
      notificationCategory,
    )
  ) {
    titleMessage = ` requested a new shortlist`;
  } else {
    titleMessage = `added a new remark`;
  }

  return {
    title: `${
      userFirstName.length > 15
        ? userFirstName.slice(0, 15) + `...`
        : userFirstName
    } ${(userLastName || ` `)[0]} ${titleMessage}`,
    body: isShortlistRequestCategoryNotification
      ? `Shortlist: ${shortListRequestRemark || ``}`
      : `Remark: ${remarkText || ``}`,
    footNote: `${studentFirstName || ``} ${studentLastName || ``}, ${
      universityName || ``
    }`,
  };
};

export const notificationTemplateMapper = (
  category: NotificationCategory,
  properties: any,
): {
  title: string;
  body: string;
  footNote: string;
} => {
  switch (category) {
    case NotificationCategory.state:
      return stateTemplate(properties);
    case NotificationCategory.remarks:
      return remarkTemplate(properties);
    case NotificationCategory.document:
      return documentTemplate(properties);

    default:
      return { title: ``, body: ``, footNote: `` };
      break;
  }
};
