import axios from 'axios';

export const updateVisaDataRequest = async (payload: any) => {
  let res;
  try {
    res = await axios.post(`/api/geebeeRoute?path=visa/gb`, payload);
    return res?.data;
  } catch (err) {
    throw new Error(`Something went wrong. Please contact the tech team`);
  }
};

export const deleteVisaDataRequest = async (payload: any) => {
  let res;
  try {
    res = await axios.delete(
      `/api/geebeeRoute?path=csp/visa/student/${payload.studentId}/application/${payload.applicationId}`,
    );
    if (res?.data?.success) {
      return res?.data?.data;
    } else {
      return false;
    }
  } catch (err) {
    console.log(`error deleting visa data`);
    return false;
  }
};

export const visaManagementApi = {
  updateVisaDataRequest,
  deleteVisaDataRequest,
};
