import { Tab, Tabs, Tooltip } from "@mui/material";
import React from "react";
import { NotificationCategory } from "./notification.enum";
import NotificationList from "./NotificationList";
import { notificationIcon } from "./NotificationTemplate";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  height: string | undefined;
  padding: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="overflow-y-scroll"
      style={{ height: props.height ? props.height : `auto`, paddingBottom: props.padding }}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const tabsData = [
  {
    label: `All`,
  },
  {
    storeId: NotificationCategory.state,
    label: `State`,
  },
  {
    storeId: NotificationCategory.document,
    label: `Documents`,
  },
  {
    storeId: NotificationCategory.remarks,
    label: `Remarks`,
  },
];

interface NotificationTabProps {
  compactView?: boolean;
  height?: string;
}
const NotificationTab = (props: NotificationTabProps) => {
  const [value, setValue] = React.useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="Geebee tabs"
        TabIndicatorProps={{
          style: {
            backgroundColor: `#3F47F5`,
            height: `4px`,
            borderRadius: `8px 8px 0px 0px`,
          },
        }}
        sx={{
          position: `sticky`,
          minHeight: `auto`,
          ".MuiButtonBase-root": {
            minHeight: `fit-content !important`,
            padding: `16px !important`,
          },
          ".MuiTabs-scroller": {
            height: `fit-content !important`,
          },
          ".MuiTab-root": {
            minWidth: `72px !important`,
          },
        }}
      >
        {tabsData.map(({ label, storeId }, index) => (
          <Tab
            label={
              <Tooltip title={String(label)} arrow>
                <span>{!storeId ? label : props.compactView ? `` : label}</span>
              </Tooltip>
            }
            icon={
              props.compactView && storeId ? (
                <Tooltip title={String(label)} arrow>
                  <span>{notificationIcon[storeId](value === index)}</span>
                </Tooltip>
              ) : (
                <></>
              )
            }
            key={`${label}-${index}`}
            {...a11yProps(index)}
            className={`text-sm font-sans`}
            sx={{
              marginLeft: `1rem`,
              ".MuiTab-root": {
                minWidth: `auto !important`,
              },
            }}
          />
        ))}
      </Tabs>
      {tabsData.map(({ storeId, label }, index) => (
        <TabPanel
          key={`${index}-${label}`}
          index={index}
          value={value}
          height={props.height}
          padding={props.compactView ? `9rem` : `4rem`}
        >
          <NotificationList
            storeId={storeId}
            containerId={`simple-tabpanel-${index}`}
            compactView={props.compactView}
            enableScrollTopTop={true}
          />
        </TabPanel>
      ))}
    </div>
  );
};

export default NotificationTab;
