import addMonths from 'date-fns/addMonths';
import format from 'date-fns/format';
import { GroupedIntakes } from 'types/formUtilities';
import { AnySchema } from 'yup';

import { RemoveIndex } from '@/types/common';
import {
  FilterData,
  FilterMapping,
} from 'components/Student/StudentMoreFilter/interfaces';
export const monthArr = [
  `January`,
  `February`,
  `March`,
  `April`,
  `May`,
  `June`,
  `July`,
  `August`,
  `September`,
  `October`,
  `November`,
  `December`,
];
export const shortFormMonths = [
  `Jan`,
  `Feb`,
  `March`,
  `April`,
  `May`,
  `June`,
  `July`,
  `Aug`,
  `Sept`,
  `Oct`,
  `Nov`,
  `Dec`,
];

type Option = {
  label: string;
  value: string | number;
};

const getIntakeOptionsInShortForm = (intakeStartYear?: number): Option[] => {
  const currentYear = Number(format(new Date(), `yyyy`));
  const startYear = currentYear - (intakeStartYear ?? 2);
  const intakeOptions: Option[] = [];
  for (let year = startYear; year < currentYear + 3; year++) {
    const options = [];
    for (
      let labelMonth = 0, valueMonth = 0;
      labelMonth < shortFormMonths.length && valueMonth < monthArr.length;
      labelMonth += 1, valueMonth += 1
    ) {
      options.push({
        label: `${shortFormMonths[labelMonth]} ${year
          .toString()
          .substring(year.toString().length - 2, year.toString().length)}`,
        value: `${monthArr[valueMonth].toUpperCase()}_${year}`,
      });
    }
    intakeOptions.push(...[...options]);
  }
  return intakeOptions;
};

interface IgetIntakeOptions {
  fromCurrentMonth?: boolean;
}

export const getIntakeOptionsV2 = (props?: IgetIntakeOptions): Option[] => {
  const currentYear = Number(format(new Date(), `yyyy`));
  const intakeOptions: Option[] = [];
  for (let year = currentYear - 2; year < currentYear + 5; year++) {
    const options = [];
    for (const month of monthArr) {
      options.push({
        label: `${month} ${year}`,
        value: `${month.toUpperCase()}_${year}`,
      });
    }
    intakeOptions.push(...[...options]);
  }
  if (props && props.fromCurrentMonth) {
    const currentMonth = Number(new Date().getMonth());
    return intakeOptions.slice(24 + currentMonth);
  }
  return intakeOptions;
};

const getIntakeOptions = (): Option[] => {
  const currentYear = Number(format(new Date(), `yyyy`));
  const intakeOptions: Option[] = [];
  for (let year = currentYear - 2; year < currentYear + 3; year++) {
    const options = [];
    for (const month of monthArr) {
      options.push({
        label: `${month} ${year}`,
        value: `${month.toUpperCase()}_${year}`,
      });
    }
    intakeOptions.push(...[...options]);
  }
  return intakeOptions;
};

const getGroupedIntakes = (): {
  intakes: Option[];
  groupedIntakes: GroupedIntakes[];
} => {
  let startYear: number = Number(format(new Date(), `yyyy`)) - 1;
  const intakes: Option[] = getIntakeOptions().slice(11);
  const groupedIntakes: GroupedIntakes[] = [];
  let div = 0;
  for (let i = 1; i < intakes.length; i += 12) {
    const group: any = {};
    group[`title`] = `${startYear} Intakes`;
    group[`intakeGroups`] = [
      {
        title: `Autumn ${startYear} (Dec ${startYear - 1} to Mar ${startYear})`,
        months: intakes.slice(div, (div += 4)),
      },
      {
        title: `Summer ${startYear} (Apr to July ${startYear})`,
        months: intakes.slice(div, (div += 4)),
      },
      {
        title: `Fall ${startYear} (Aug ${startYear} to Nov ${startYear})`,
        months: intakes.slice(div, (div += 4)),
      },
    ];
    startYear++;
    groupedIntakes.push(group);
  }

  return { intakes, groupedIntakes };
};

type MakeKeysRequired<T> = {
  [P in keyof T]-?: T[P];
};

const getFormFieldNames = <T>(
  schema: AnySchema<T>,
): MakeKeysRequired<RemoveIndex<{ [K in keyof T]: string }>> => {
  const fields = (schema as any).fields;
  const names: MakeKeysRequired<RemoveIndex<{ [K in keyof T]: string }>> =
    Object.keys(fields).reduce((acc, field) => {
      acc[field] = field;
      return acc;
    }, {} as any);
  return names;
};

const getValueFieldMapping = (
  data: { label: string; value: any }[],
): { [key: string]: string } => {
  const fieldLabels: { [key: string]: string } = {};
  data.forEach(({ label, value }) => {
    fieldLabels[String(value)] = label;
  });
  return fieldLabels;
};

const getFilterObject = (
  currentFilters: FilterData,
  labelMapping: Record<string, string | undefined>,
  labelKeyDataMapping: { [key: string]: { label: string; value: any }[] },
): FilterMapping => {
  const filterObj = {} as FilterMapping;
  Object.keys(currentFilters).forEach((key) => {
    if (labelMapping[key]) {
      const values: { label: string; value: any }[] = [];
      if (labelKeyDataMapping[key]) {
        const fieldLabels: { [key: string]: string } = getValueFieldMapping(
          labelKeyDataMapping[key],
        );

        currentFilters[key].forEach((value: any) =>
          values.push({
            label: fieldLabels[String(value)],
            value,
          }),
        );
      } else if (Array.isArray(currentFilters[key])) {
        currentFilters[key].forEach((value: { label: string; value: any }) =>
          values.push({
            label: String(value),
            value,
          }),
        );
      } else {
        values.push({
          label: String(currentFilters[key]),
          value: currentFilters[key],
        });
      }

      filterObj[key] = {
        title: labelMapping[key] || key,
        values,
        keyName: key,
      };
    }
  });

  return filterObj;
};

function getFutureIntakeOptionsFromNow(monthsCount: number) {
  const intakes: Option[] = [];
  const date = addMonths(new Date(), 0);
  const endDate = addMonths(new Date(), monthsCount);
  const currentIntakeMonthValue = `${monthArr[
    date.getMonth()
  ].toUpperCase()}_${format(date, `yyyy`)}`;
  const endIntakeMonthValue = `${monthArr[
    endDate.getMonth()
  ].toUpperCase()}_${format(endDate, `yyyy`)}`;

  const relevantGroupedIntakesStartIndex =
    getGroupedIntakes().groupedIntakes.findIndex((item: GroupedIntakes) => {
      return (
        item.intakeGroups.findIndex((item) =>
          item.months
            .map((item) => item.value)
            .includes(currentIntakeMonthValue),
        ) !== -1
      );
    });

  const relevantGroupedIntakesEndIndex =
    getGroupedIntakes().groupedIntakes.findIndex((item: GroupedIntakes) => {
      return (
        item.intakeGroups.findIndex((item) =>
          item.months.map((item) => item.value).includes(endIntakeMonthValue),
        ) !== -1
      );
    });
  const relevantGroupedIntakes = getGroupedIntakes()
    .groupedIntakes.slice(
      relevantGroupedIntakesStartIndex,
      relevantGroupedIntakesEndIndex,
    )
    .map((item) => item.intakeGroups)
    .flat()
    .map((item) => {
      return {
        label: item.title,
        value: item.title,
        children: item.months.map((item) => {
          return {
            ...item,
            children: null,
          };
        }),
      };
    });

  // data structure - [{label - intake group, value - intake group, children - { label - month val, value- month val, children:null}}]
  // Intake => Dec -> March

  return relevantGroupedIntakes;
}

const mapLabelValue: (
  object: Record<string, unknown>[],
  labelKey: string,
  valueKey: string,
) => { label: string; value: string }[] = (object, labelKey, valueKey) => {
  return object.map((eachItem) => ({
    label: (eachItem[labelKey] as string) || ``,
    value: (eachItem[valueKey] as string) || ``,
  }));
};

export {
  getIntakeOptions,
  getFormFieldNames,
  getValueFieldMapping,
  getFilterObject,
  getGroupedIntakes,
  getIntakeOptionsInShortForm,
  getFutureIntakeOptionsFromNow,
  mapLabelValue,
};
