import React from 'react';
import { Typography } from '@mui/material';

import VASWarningIcon from '../Icons/vas/VASWarningIcon';

function StudentAlreadyExistWarning() {
  return (
    <div className="bg-error-100 rounded p-2 flex items-center gap-x-2 my-4">
      <VASWarningIcon className="w-4 h-4" />
      <Typography variant="body2" className="text-grey-900">
        Looks like the student already exists in our system.
      </Typography>
    </div>
  );
}

export default StudentAlreadyExistWarning;
