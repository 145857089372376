import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const commissionBaseApi = createApi({
  reducerPath: `commissionBaseApi`,
  baseQuery: fetchBaseQuery({ baseUrl: `/api/commissionRoute?path=` }),
  endpoints: () => ({}),
});

export const commissionBaseApiWithTags = commissionBaseApi.enhanceEndpoints({
  addTagTypes: [
    `Billing`,
    `CommissionStatus`,
    `CommissionRate`,
    `CommissionDescription`,
    `BillingCycles`,
    `BillingDetails`,
    `InvoiceDetails`,
    `BillingCycle`,
    `ApplicableIntakes`,
    `PossibleBillingNames`,
    `BillingCycleRules`,
  ],
});
