import { add, addMonths, format, subHours } from 'date-fns';
import {
  StageLevelApiDataStructure,
  StageLevelSummaryCard,
} from 'types/summaryDashboard';
import {
  DATE_TIME_FIELD_MAP,
  STAGE_LEVEL_SUMMARY_CARD_STYLING_CONFIG,
} from 'utils/constants';
import {
  getIntakeOptions,
  getIntakeOptionsInShortForm,
  monthArr,
  shortFormMonths,
} from 'utils/formHelpers';

function transformStageLevelSummaryApiResponse(
  data: StageLevelApiDataStructure[],
): StageLevelSummaryCard[] {
  return data.map((item: StageLevelApiDataStructure) => {
    return {
      title: {
        titleText: item.label,
        tooltipText:
          STAGE_LEVEL_SUMMARY_CARD_STYLING_CONFIG[item.label]?.tooltipText,
        titleTextColor:
          STAGE_LEVEL_SUMMARY_CARD_STYLING_CONFIG[item.label]?.titleTextColor,
        tooltipFillColor:
          STAGE_LEVEL_SUMMARY_CARD_STYLING_CONFIG[item.label]?.tooltipFillColor,
        titleBackgroundColor:
          STAGE_LEVEL_SUMMARY_CARD_STYLING_CONFIG[item.label]
            ?.titleBackgroundColor,
      },
      stageLevelSummaries: item.stages,
    };
  });
}

function formatDate(date: Date): string {
  return `${date
    .toLocaleDateString()
    .split(`/`)
    .reverse()
    .join(`-`)} ${date.toLocaleTimeString()}`;
}

function getDateOptions(config: {
  fieldName: string;
  label: string;
  from: string;
  to: string;
}) {
  const withinHours = [24, 12, 6, 3, 1, 7 * 24, 30 * 24];

  const dateOptions = withinHours.map((item: number) => {
    return {
      label: `Within ${
        Math.round(item / 24) > 1
          ? `${Math.round(item / 24)} days`
          : `${item} hours`
      }`,
      value: {
        [config.from]: subHours(new Date(), item).toISOString(),
        [config.to]: new Date().toISOString(),
      },
    };
  });

  return [
    ...dateOptions,
    {
      label: `Select date range`,
      value: `${config.fieldName}-openDateRangeModal`,
    },
  ];
}

function createDateAPIPayload({
  formValues,
  type,
}: {
  formValues: any;
  type: 'receivedAt';
}) {
  return {
    [DATE_TIME_FIELD_MAP[type].from]:
      formValues[type]?.value === `${type}-openDateRangeModal`
        ? formValues[DATE_TIME_FIELD_MAP[type].from]?.toISOString()
        : formValues[type]?.value[DATE_TIME_FIELD_MAP[type].from],
    [DATE_TIME_FIELD_MAP[type].to]:
      formValues[type]?.value === `${type}-openDateRangeModal`
        ? formValues[DATE_TIME_FIELD_MAP[type].to]?.toISOString()
        : formValues[type]?.value[DATE_TIME_FIELD_MAP[type].to],
  };
}

function resetLocalStorageFilterData(key: string) {
  localStorage.removeItem(key);
}

function getFutureIntakesFromNow(nextMonths: number) {
  const nextDate = addMonths(new Date(), 1);

  const nextMonthYearValue = `${monthArr[
    nextDate.getMonth()
  ].toUpperCase()}_${nextDate.getFullYear()}`;

  const nextMonthYearValueIndex = getIntakeOptionsInShortForm().findIndex(
    (item: any) => item.value === nextMonthYearValue,
  );

  return getIntakeOptionsInShortForm().slice(
    nextMonthYearValueIndex,
    nextMonthYearValueIndex + nextMonths,
  );
}

export {
  transformStageLevelSummaryApiResponse,
  formatDate,
  getDateOptions,
  resetLocalStorageFilterData,
  getFutureIntakesFromNow,
  createDateAPIPayload,
};
