import { AnyAction } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';

import { visaManagementActions } from './VisaManagementActions';
import { visaManagementApi } from './VisaManagementApi';

function* updateVisaData(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(
      visaManagementApi.updateVisaDataRequest,
      action.payload,
    );
    if (res.success) {
      yield put(visaManagementActions.updateVisaDataSuccess(res.data));
    } else {
      yield put(visaManagementActions.updateVisaDataFailure(res.message));
    }
  } catch (e) {
    console.error(e);
    yield put(
      visaManagementActions.updateVisaDataFailure(
        `Something went wrong. Please contact the tech team`,
      ),
    );
  }
}
function* visaManagementSaga() {
  yield takeLatest(`UPDATE_VISA_DATA`, updateVisaData);
}

export default visaManagementSaga;
