import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const applicationQcApi = createApi({
  reducerPath: `applicationQcApi`,
  baseQuery: fetchBaseQuery({
    baseUrl: `/api/geebeeRoute?path=/application-qc`,
  }),
  endpoints: () => ({}),
});

export const applicationQcApiWithTags = applicationQcApi.enhanceEndpoints({
  addTagTypes: [
    `OverallEligibility`,
    `EligibilityRequirement`,
    `DocumentChecklist`,
  ],
});
