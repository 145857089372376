import { FetchArgs } from '@reduxjs/toolkit/dist/query';

import { documentUploadBaseApi } from '@/services/base/documentUpload';
import { geebeeRootBaseApiWithTags } from '@/services/base/geebeeApi';
import { definitions } from '@/types/schema';
import {
  BaseResponse,
  BooleanTypeResponse,
  ExportApplicationExcelRequestType,
  ExportApplicationExcelResponseType,
  ExportOverallPerformanceExcelRequestType,
  ExportOverallPerformanceExcelResponseType,
  ExportStudentExcelRequestType,
  ExportStudentExcelResponseType,
  GetNewsLetterRequestBodyType,
  GetNewsLetterResponseType,
  GetPendingApplicationsTasksReponseType,
  GetPendingApplicationsTasksRequestType,
  GetPendingStudentsTasksRequestType,
  GetPendingStudentsTasksResponseType,
  GetPinItemsRequestType,
  GetPinItemsResponseType,
  GetProjectionTableResponseType,
  GetProjectionTableTotalRowRequestType,
  GetProjectionTableTotalRowResponseType,
  GetSummaryDashboardRequestType,
  GetUnisetuDashboardResponseType,
  MarkProductTourCompleteRequestType,
  MarkProductTourCompleteResponseType,
  PinDashboardItemRequestBodyType,
  SnoozeApplicationTaskRequestType,
  SnoozeApplicationTaskResponseType,
  SnoozeStudentTaskRequestType,
  SnoozeStudentTaskResponseType,
  UnPinDashboardItemRequestBodyType,
} from '@/types/Services';
import { transformResponse } from '@/utils/common';

export const summaryDashboardV2Api = geebeeRootBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getDashboardSummaryData: builder.mutation<
      GetUnisetuDashboardResponseType,
      GetSummaryDashboardRequestType
    >({
      query: (payload) => ({
        url: `/dashboard/summary`,
        method: `POST`,
        body: payload.dto,
      }),
      transformResponse: transformResponse,
    }),
    getDashboardPerformanceData: builder.mutation<
      GetUnisetuDashboardResponseType,
      GetSummaryDashboardRequestType
    >({
      query: (payload) => ({
        url: `/dashboard/performance`,
        method: `POST`,
        body: payload.dto,
      }),
      transformResponse: transformResponse,
    }),
    getPendingApplicationTasks: builder.mutation<
      GetPendingApplicationsTasksReponseType,
      GetPendingApplicationsTasksRequestType
    >({
      query: (body) =>
        ({
          url: `/dashboard/pending-tasks/application`,
          method: `POST`,
          body,
        } as FetchArgs),
      transformResponse: transformResponse,
    }),

    getPendingStudentTasks: builder.mutation<
      GetPendingStudentsTasksResponseType,
      GetPendingStudentsTasksRequestType
    >({
      query: (body) =>
        ({
          url: `/dashboard/pending-tasks/student/`,
          method: `POST`,
          body,
        } as FetchArgs),

      transformResponse: transformResponse,
    }),

    snoozeApplicationTask: builder.mutation<
      SnoozeApplicationTaskResponseType,
      SnoozeApplicationTaskRequestType
    >({
      query: ({ applicationId, minutes }) => {
        return {
          url: `applications/${applicationId}/snooze-pending-task/minutes/${minutes}`,
          method: `PUT`,
        } as FetchArgs;
      },
      transformResponse: transformResponse,
      //   invalidatesTags: [`ApplicationPendingTasks`],
    }),

    snoozeStudentTask: builder.mutation<
      SnoozeStudentTaskResponseType,
      SnoozeStudentTaskRequestType
    >({
      query: ({ studentId, minutes }) => {
        return {
          url: `students/${studentId}/snooze-pending-task/minutes/${minutes}`,
          method: `PUT`,
        } as FetchArgs;
      },
      transformResponse: transformResponse,
      //   invalidatesTags: [`StudentPendingTasks`],
    }),
    pinDashboardItem: builder.mutation<
      BooleanTypeResponse,
      PinDashboardItemRequestBodyType
    >({
      query: ({ itemType, itemName }) => ({
        url: `/pin-item/item-type/${itemType}/item-name/${itemName}`,
        method: `POST`,
      }),
      transformResponse: transformResponse,
    }),
    unPinDashboardItem: builder.mutation<
      BooleanTypeResponse,
      UnPinDashboardItemRequestBodyType
    >({
      query: ({ itemType, itemName }) => ({
        url: `/pin-item/item-type/${itemType}/item-name/${itemName}`,
        method: `DELETE`,
      }),
      transformResponse: transformResponse,
    }),
    getNewsLetter: builder.mutation<
      GetNewsLetterResponseType,
      GetNewsLetterRequestBodyType
    >({
      query: ({ sheetType, ...rest }) => ({
        url: `/v1/excel-sheet/${sheetType}`,
        method: `POST`,
        body: rest,
      }),
      transformResponse: transformResponse,
    }),
    getPinItems: builder.mutation<
      GetPinItemsResponseType,
      GetPinItemsRequestType
    >({
      query: ({ itemType }) => ({
        url: `/pin-item/item-type/${itemType}`,
        method: `POST`,
      }),
      transformResponse: transformResponse,
    }),
    markProductTourComplete: builder.mutation<
      MarkProductTourCompleteResponseType,
      MarkProductTourCompleteRequestType
    >({
      query: ({ productTourType }) => ({
        url: `dashboard/mark-product-tour-as-completed/${productTourType}`,
        method: `PUT`,
      }),
      transformResponse: transformResponse,
    }),
    requestPerformanceDashboardReportDownload: builder.mutation<
      ExportOverallPerformanceExcelResponseType,
      ExportOverallPerformanceExcelRequestType
    >({
      query: (body) => ({
        url: `/dashboard/performance/export`,
        method: `POST`,
        body,
      }),
      transformResponse: transformResponse,
    }),
    requestStudentReportDownload: builder.mutation<
      ExportStudentExcelResponseType,
      ExportStudentExcelRequestType
    >({
      query: (body) => ({
        url: `/students/export/excel`,
        method: `POST`,
        body,
      }),
      transformResponse: transformResponse,
    }),
    requestApplicationReportDownload: builder.mutation<
      ExportApplicationExcelResponseType,
      ExportApplicationExcelRequestType
    >({
      query: (body) => ({
        url: `/applications/export/excel`,
        method: `POST`,
        body,
      }),
      transformResponse: transformResponse,
    }),
    requestProjectionTableDownload: builder.mutation<
      ExportApplicationExcelResponseType,
      ExportApplicationExcelRequestType
    >({
      query: (body) => ({
        url: `csps/performance/export/excel`,
        method: `POST`,
        body,
      }),
      transformResponse: transformResponse,
    }),
    exportFileWithType: builder.query<
      definitions['ResponseWrapper«string»'],
      { fileType: string }
    >({
      query: ({ fileType }) => `/documents/url/${fileType}`,
      transformResponse: transformResponse,
    }),
    requestCspProjectionData: builder.mutation<
      GetProjectionTableResponseType,
      GetProjectionTableTotalRowRequestType
    >({
      query: (body) => ({
        url: `/csps/performance/student-intake-target`,
        method: `POST`,
        body: body,
      }),
      transformResponse: transformResponse,
    }),
    requestProjectionTotalData: builder.mutation<
      GetProjectionTableTotalRowResponseType,
      GetProjectionTableTotalRowRequestType
    >({
      query: (body) => ({
        url: `csps/performance/student-intake-target-summary`,
        method: `POST`,
        body: body,
      }),
      transformResponse: transformResponse,
    }),
  }),
});

export const summaryDashboardDocumentApi =
  documentUploadBaseApi.injectEndpoints({
    endpoints: (builder) => ({
      uploadPerformanceTargetUpload: builder.mutation<
        BaseResponse<any>,
        {
          file: File;
        }
      >({
        query: ({ file }) => {
          const formData = new FormData();
          formData.append(`file`, file);
          return {
            url: `bulk-update/csv/CSP_PERFORMANCE_TARGET`,
            body: formData,
            method: `POST`,
          } as FetchArgs;
        },
        transformResponse: transformResponse,
      }),
    }),
  });

export const {
  useGetDashboardSummaryDataMutation,
  useGetDashboardPerformanceDataMutation,
  useGetPendingApplicationTasksMutation,
  useGetPendingStudentTasksMutation,
  usePinDashboardItemMutation,
  useUnPinDashboardItemMutation,
  useSnoozeApplicationTaskMutation,
  useSnoozeStudentTaskMutation,
  useGetNewsLetterMutation,
  useGetPinItemsMutation,
  useMarkProductTourCompleteMutation,
  useRequestPerformanceDashboardReportDownloadMutation,
  useRequestStudentReportDownloadMutation,
  useRequestApplicationReportDownloadMutation,
  useLazyExportFileWithTypeQuery,
  useRequestCspProjectionDataMutation,
  useRequestProjectionTableDownloadMutation,
  useRequestProjectionTotalDataMutation,
} = summaryDashboardV2Api;

export const { useUploadPerformanceTargetUploadMutation } =
  summaryDashboardDocumentApi;
