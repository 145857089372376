import axios, { AxiosRequestConfig } from 'axios';
import { saveAs } from 'file-saver';
export const ERROR_MESSAGE = `Something went wrong. Please contact the tech team`;
import { toast } from 'react-toastify';
export const filterApplicationsData = async (payload: any) => {
  try {
    const res = await axios.post(
      `/api/geebeeRoute?path=applications/search`,
      payload,
    );
    if (res?.data?.success) {
      return res.data;
    }
  } catch (err) {
    return `Something went wrong. Please contact the tech team`;
  }
};
export const eachStudentApplicationData = async (id: any) => {
  try {
    const res = await axios.get(`/api/geebeeRoute?path=applications/${id}`);
    return res;
  } catch (error: any) {
    throw error;
  }
};
export const updateApplicationData = async (datas: any) => {
  const { id } = datas;
  try {
    const res = await axios.put(
      `/api/geebeeRoute?path=applications/${id}`,
      datas,
    );
    if (res?.data?.success) {
      return res.data;
    }
  } catch (err) {
    return `Something went wrong. Please contact the tech team`;
  }
};
export const addRemarks = async (data: any) => {
  try {
    const res = await axios.post(`/api/upload?path=applications/remark`, data, {
      headers: {
        'Content-Type': `multipart/form-data`,
      },
    });
    if (res?.data?.success) {
      return res.data;
    }
  } catch (err) {
    return `Something went wrong. Please contact the tech team`;
  }
};
export const getRemarks = async (id: any) => {
  try {
    const res = await axios.get(
      `/api/geebeeRoute?path=applications/${id}/remarks`,
    );
    if (res?.data?.success) {
      return res.data;
    }
  } catch (err) {
    return `Something went wrong. Please contact the tech team`;
  }
};
export const uploadDocument = async (data: any) => {
  const {
    file,
    d,
    leadId,
    title,
    eachAppData,
    referenceLabel,
    referenceLabelId,
  } = data;

  try {
    const formData = new FormData();
    formData.append(`file`, file, file.name.split(`,`).join(`_`));
    if (referenceLabel) {
      formData.append(`referenceLabel`, referenceLabel);
    }
    if (referenceLabelId) {
      formData.append(`referenceLabelId`, referenceLabelId);
    }
    let url = ``;
    url = `/api/upload?path=documents/doc/${title}/0/${eachAppData?.student?.id}/${eachAppData?.id}`;
    const res = await axios.post(url, formData, {
      headers: {
        'Content-Type': `multipart/form-data`,
      },
    });

    if (res?.data?.success) {
      return res.data.data;
    } else {
      return res.data;
    }
  } catch (err) {
    return ERROR_MESSAGE;
  }
};
export const fetchUploadDocument = async (data: any) => {
  const { keyName, studentId, applicationId } = data;
  try {
    const res = await axios.get(
      `/api/geebeeRoute?path=documents/status/${studentId}/${applicationId}`,
    );
    if (res?.data?.success) {
      return res.data;
    }
  } catch (err) {
    return `Something went wrong. Please contact the tech team`;
  }
};

export const fetchOpsManagerList = async (payload: {
  applicationId: number | null;
}) => {
  if (!payload.applicationId) return null;
  try {
    const res = await axios.get(
      `/api/geebeeRoute?path=users/change-assigned-to-dropdown/${payload.applicationId}`,
    );
    if (res?.data?.success) {
      return res.data.data;
    } else {
      throw new Error(
        res.data.message || `Something went wrong.Please contact tech team`,
      );
    }
  } catch (err: any) {
    throw err;
  }
};

export const fetchQCList = async () => {
  try {
    const res = await axios.get(`/api/geebeeRoute?path=users/qc-team`);
    if (res?.data?.success) {
      return res.data.data;
    } else {
      throw new Error(
        res.data.message || `Something went wrong.Please contact tech team`,
      );
    }
  } catch (err: any) {
    throw err;
  }
};

export const fetchCountryList = async () => {
  try {
    const res = await axios.get(`/api/geebeeRoute?path=users-country/options`);
    if (res?.data?.success) {
      return res.data;
    }
  } catch (err) {
    return `Something went wrong. Please contact the tech team`;
  }
};

export const viewDocument = async (id: any) => {
  try {
    const res = await axios.get(
      `/api/geebeeRoute?path=counsellor/viewdoc/${id}`,
    );
    if (res?.data?.success) {
      return res.data.data;
    }
  } catch (err) {
    return `Something went wrong. Please contact the tech team`;
  }
};
export const deleteDocument = async (data: any) => {
  try {
    const res = await axios.delete(
      `/api/geebeeRoute?path=documents/${data?.id}/${data?.keyName}`,
    );
    if (res?.data?.success) {
      return res?.data;
    }
  } catch (err) {
    return `Something went wrong. Please contact the tech team`;
  }
};
export const downloadDocument = async (data: any) => {
  const downloadUrl = `documents/download/${data?.keyName}/${data?.id}`;
  try {
    const config: AxiosRequestConfig = {
      method: `GET`,
      url: `/api/download?path=${downloadUrl}`,
      responseType: `blob`,
    };
    const res = await axios(config);
    if (res && res.data && res.data.size > 0) {
      const fileName = res.headers[`content-disposition`].split(`filename=`)[1];
      saveAs(res.data, fileName);
    }
  } catch (err) {
    return `Something went wrong. Please contact the tech team`;
  }
};
export const downloadAllDocs = async (data: any) => {
  const downloadUrl = `documents/download/all/${data?.student?.id}/${data?.id}/${data.uploadedFrom}`;
  try {
    const config: AxiosRequestConfig = {
      method: `GET`,
      url: `/api/download?path=${downloadUrl}`,
      responseType: `blob`,
    };
    const res = await axios(config);
    if (res && res.data) {
      toast.success(
        `Download Request sent. Please click on My Downloads button`,
      );
    } else {
      toast.error(`Unable to send download all request`);
    }
  } catch (err) {
    return `Something went wrong. Please contact the tech team`;
  }
};

export const statusDocument = async (datas: any) => {
  const { status, id, leadId, values } = datas;

  try {
    const res = await axios.post(
      `/api/geebeeRoute?path=counsellor/doc/status/${status}/${id}/${leadId}`,
      values,
    );
    if (res?.data?.success) {
      return res.data.data;
    }
  } catch (err) {
    return `Something went wrong. Please contact the tech team`;
  }
};

export const downloadRemarkDocument = async (id: string) => {
  try {
    const url = `/api/download?path=remarks/${id}/download-attachment`;

    const config: AxiosRequestConfig = {
      method: `GET`,
      url: `${url}`,
      responseType: `blob`,
    };
    const res = await axios(config);
    if (res && res.data && res.data.size > 0) {
      const fileName = res.headers[`content-disposition`].split(`filename=`)[1];
      saveAs(res.data, fileName);
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const fetchDocumentKeys = async () => {
  try {
    const res = await axios.get(`/api/geebeeRoute?path=documents/keys`);
    if (res?.data?.success) {
      let docsList: [] = [];
      docsList = res.data.data.map((item: any) => {
        return {
          label: item.split(`_`).join(` `),
          value: item,
        };
      });
      return docsList;
    } else {
      return null;
    }
  } catch (err) {
    throw new Error(`Something went wrong. Please contact the tech team`);
  }
};
export const getStudentsSuggestions = async (
  student: string,
  pageNumber: number,
) => {
  try {
    const res = await axios.post(
      `/api/geebeeRoute?path=applications/search/student`,
      {
        pageNumber,
        pageSize: 20,
        name: student,
      },
    );
    if (res?.data?.success) {
      return res.data.data;
    } else {
      throw new Error(
        res?.data?.message ||
          `Something went wrong. Please contact the tech team`,
      );
    }
  } catch (error) {
    throw error;
  }
};

export const getUniversitySuggestions = async (
  university: string,
  pageNumber: number,
) => {
  try {
    const res = await axios.post(
      `/api/geebeeRoute?path=applications/search/university-name`,
      {
        pageNumber,
        pageSize: 20,
        name: university,
      },
    );
    if (res?.data?.success) {
      return res.data.data;
    } else {
      throw new Error(
        res?.data?.message ||
          `Something went wrong. Please contact the tech team`,
      );
    }
  } catch (error) {
    throw error;
  }
};

export const getCMSUniversitySuggestions = async (
  cmsUniversity: string,
  pageNumber: number,
) => {
  try {
    const url = `/api/geebeeRoute?path=cms-university/search/cms-university-name?pageNumber=${pageNumber}&pageSize=${20}&cmsUniversityName=${cmsUniversity}`;
    const res = await axios.get(url);
    if (res?.data?.success) {
      return res.data.data;
    } else {
      throw new Error(
        res?.data?.message ||
          `Something went wrong. Please contact the tech team`,
      );
    }
  } catch (error) {
    throw error;
  }
};

export const getCMSUniversityFilterSuggestions = async (
  cmsUniversity: string,
  pageNumber: number,
) => {
  try {
    const url = `/api/geebeeRoute?path=applications/search/cms-university-name?pageNumber=${pageNumber}&pageSize=${20}&cmsUniversityName=${cmsUniversity}`;
    const res = await axios.get(url);
    if (res?.data?.success) {
      return res.data.data;
    } else {
      throw new Error(
        res?.data?.message ||
          `Something went wrong. Please contact the tech team`,
      );
    }
  } catch (error) {
    throw error;
  }
};

export const getUserName = async () => {
  try {
    const res = await axios.get(`/api/geebeeRoute?path=users/profile`);
    if (res.data?.success) {
      return res.data?.data || ``;
    } else {
      throw new Error(
        res.data?.message || `Something went wrong. Please contact tech team`,
      );
    }
  } catch (error: any) {
    throw error;
  }
};

export const getAllCsps = async () => {
  try {
    const res = await axios.get(`/api/geebeeRoute?path=v1/csps`);
    if (res.data?.success) {
      return res.data?.data || ``;
    } else {
      throw new Error(
        res.data?.message || `Something went wrong. Please contact tech team`,
      );
    }
  } catch (error: any) {
    throw error;
  }
};
export const handleRemoveConditionalReasons = async (data: any) => {
  const { applicationId, payload } = data;
  try {
    const res = await axios.post(
      `/api/geebeeRoute?path=applications/${applicationId}/remove-conditions`,
      payload,
    );
    if (res.data?.success) {
      return true;
    } else {
      throw new Error(
        res.data?.message || `Something went wrong. Please contact tech team`,
      );
    }
  } catch (error: any) {
    throw error;
  }
};
