import React from 'react';

import { MODAL_TYPES } from '../modals/GlobalModal';
import useModal from '../modals/useModal';

import AddContactForm from './AddContactForm';

function useAddJerryContact() {
  const { showModal } = useModal();

  const handleShowAddModal = () => {
    showModal({
      modalType: MODAL_TYPES.NO_RESULTS,
      modalProps: {
        body: (
          <div className="h-full w-full flex items-center justify-center">
            <AddContactForm />
          </div>
        ),
      },
    });
  };

  return { handleShowAddModal };
}

export default useAddJerryContact;
