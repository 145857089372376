import { FetchArgs } from '@reduxjs/toolkit/dist/query';

import {
  CommissionInvoiceRequestType,
  CommissionInvoiceResponseType,
  CreateBillingCycleRequestType,
  CreateBillingCycleResponseType,
  DownloadRequestPathRequestType,
  DownloadRequestPathResponseType,
  GetAppicationCommissionPartsRequestType,
  GetAppicationCommissionPartsResponseType,
  GetApplicableIntakesRequestType,
  GetApplicableIntakesResponseType,
  GetBillingCycleCommissionDescriptionRequestType,
  GetBillingCycleCommissionDescriptionResponse,
  GetBillingCycleCommissionRateRequestType,
  GetBillingCycleCommissionRateResponseType,
  GetBillingCycleCommissionRulesResponseType,
  GetBillingCycleRequestType,
  GetBillingCycleResponseType,
  GetBillingCyclesRequestType,
  GetBillingCyclesResponseType,
  GetCommissionBulkPartsListResponseType,
  GetCommissionPartsListRequestType,
  GetCommissionSharedList,
  GetCommissionStatusQueryResponse,
  GetDownloadRequestsPathRequestType,
  GetDownloadRequestsPathResponseType,
  GetPossibleBillingNamesRequestType,
  GetPossibleBillingNamesResponseType,
  GetUniqueBillingNamesRequestType,
  GetUniqueBillingNamesResponseType,
  UpdateApplicationPartsBulkRequestType,
  UpdateBillingCycleCommissionDescriptionRequestType,
  UpdateBillingCycleCommissionDescriptionResponse,
  UpdateBillingCycleCommissionRateResponse,
  UpdateBillingCycleCommissionRulesRequestType,
  UpdateBillingCycleCommissionRulesResponseType,
  UpdateBillingCycleRequestType,
  UpdateBillingCycleResponseType,
  UpdateCommissionInvoiceRequestType,
  UpdateCommissionInvoiceResponseType,
  UpdateCommissionMutationResponseType,
  UpdateCommissionStatusMutationRequestType,
} from '@/types/commission';
import { components } from '@/types/commissionSchema';
import { BaseResponse } from '@/types/Services';

import { UpdateBillingCycleCommissionRateRequestType } from '../../../types/commission';

import { commissionBaseApiWithTags } from './base';

export const transformResponse = <Req extends BaseResponse<Req>, Res>(
  data: Req,
) => {
  if (!data.success) {
    return {
      data: null,
      message: data.message,
      apiError: true,
    } as unknown as Res;
  } else {
    return data as unknown as Res;
  }
};
export const commissionApi = commissionBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    // later replace response, request types with schema based types
    getApplicationCommissionStatus: builder.query<
      GetCommissionStatusQueryResponse,
      { applicationId: string }
    >({
      query: ({ applicationId }) => `application-commission/${applicationId}`,
      providesTags: [`CommissionStatus`],
      transformResponse: transformResponse,
    }),
    updateApplicationCommissionStatus: builder.mutation<
      UpdateCommissionMutationResponseType,
      { body: UpdateCommissionStatusMutationRequestType; applicationId: string }
    >({
      query: ({ applicationId, body }) => ({
        url: `application-commission/${applicationId}`,
        method: `POST`,
        body,
      }),
      invalidatesTags: [`CommissionStatus`],
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as any;
        } else {
          return { data } as unknown as any;
        }
      },
    }),
    getCommissionSharedList: builder.query<GetCommissionSharedList, void>({
      query: () => `shared/lists`,
      transformResponse: transformResponse,
    }),
    getBillingCycles: builder.mutation<
      GetBillingCyclesResponseType,
      GetBillingCyclesRequestType
    >({
      query: (body) => {
        const request: FetchArgs = {
          url: `/billing-cycle/search`,
          method: `POST`,
          body,
        };

        return request;
      },
      transformResponse: transformResponse,
    }),
    createBillingCycle: builder.mutation<
      CreateBillingCycleResponseType,
      CreateBillingCycleRequestType
    >({
      query: (body) => {
        const request: FetchArgs = {
          url: `/billing-cycle`,
          method: `POST`,
          body,
        };

        return request;
      },
      invalidatesTags: [`ApplicableIntakes`, `PossibleBillingNames`],
      transformResponse: transformResponse,
    }),
    getBillingCycle: builder.query<
      GetBillingCycleResponseType,
      GetBillingCycleRequestType
    >({
      query: ({ billingCycleId }) => {
        const request: FetchArgs = {
          url: `/billing-cycle/${billingCycleId}`,
        };
        return request;
      },
      providesTags: [`BillingCycle`],
      transformResponse: transformResponse,
    }),
    updateBillingCycle: builder.mutation<
      UpdateBillingCycleResponseType,
      UpdateBillingCycleRequestType
    >({
      query: ({ billingCycleId, ...body }) => {
        const request: FetchArgs = {
          url: `/billing-cycle/${billingCycleId}`,
          method: `PUT`,
          body,
        };
        return request;
      },
      invalidatesTags: [
        `BillingCycle`,
        `CommissionRate`,
        `InvoiceDetails`,
        `CommissionDescription`,
        `BillingCycleRules`,
        `ApplicableIntakes`,
      ],
      transformResponse: transformResponse,
    }),
    getPossibleBillingNames: builder.query<
      GetPossibleBillingNamesResponseType,
      GetPossibleBillingNamesRequestType
    >({
      query: ({ cmsUniversityId }) => {
        const request: FetchArgs = {
          url: `/billing-cycle/names/${cmsUniversityId}`,
        };
        return request;
      },
      providesTags: [`PossibleBillingNames`],
      transformResponse: transformResponse,
    }),
    getApplicableIntakes: builder.query<
      GetApplicableIntakesResponseType,
      GetApplicableIntakesRequestType
    >({
      query: ({ cmsUniversityId }) => {
        const request: FetchArgs = {
          url: `/billing-cycle/applicable-intakes/${cmsUniversityId}`,
        };
        return request;
      },
      providesTags: [`ApplicableIntakes`],
      transformResponse: transformResponse,
    }),
    getInvoiveDetails: builder.query<
      CommissionInvoiceResponseType,
      CommissionInvoiceRequestType
    >({
      query: ({ billingCycleId }) => {
        const request: FetchArgs = {
          url: `/commission-invoice/${billingCycleId}`,
        };
        return request;
      },
      providesTags: [`InvoiceDetails`],
      transformResponse: transformResponse,
    }),
    updateInvoiveDetails: builder.mutation<
      UpdateCommissionInvoiceResponseType,
      UpdateCommissionInvoiceRequestType
    >({
      query: ({ billingCycleId, ...body }) => {
        const request: FetchArgs = {
          url: `/commission-invoice/${billingCycleId}`,
          method: `POST`,
          body,
        };
        return request;
      },
      invalidatesTags: [`InvoiceDetails`],
      transformResponse: transformResponse,
    }),
    updateBillingCycleCommissionRate: builder.mutation<
      UpdateBillingCycleCommissionRateResponse,
      UpdateBillingCycleCommissionRateRequestType
    >({
      query: ({ billingCycleId, ...body }) => ({
        url: `/billing-cycle/${billingCycleId}/commission-rate`,
        method: `PUT`,
        body,
      }),
      invalidatesTags: [`CommissionRate`],
      transformResponse: transformResponse,
    }),
    getBillingCycleCommissionRate: builder.query<
      GetBillingCycleCommissionRateResponseType,
      GetBillingCycleCommissionRateRequestType
    >({
      query: ({ billingCycleId }) =>
        `/billing-cycle/${billingCycleId}/commission-rate`,
      providesTags: [`CommissionRate`],
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetBillingCycleCommissionRateResponseType;
        } else {
          return {
            data,
          } as unknown as GetBillingCycleCommissionRateResponseType;
        }
      },
    }),
    updateBillingCycleCommissionDescription: builder.mutation<
      UpdateBillingCycleCommissionDescriptionResponse,
      UpdateBillingCycleCommissionDescriptionRequestType
    >({
      query: ({ billingCycleId, description }) => ({
        url: `/billing-cycle/${billingCycleId}/description`,
        method: `PUT`,
        body: { description },
      }),
      invalidatesTags: [`CommissionDescription`],
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as UpdateBillingCycleCommissionDescriptionResponse;
        } else {
          return {
            data,
          } as unknown as UpdateBillingCycleCommissionDescriptionResponse;
        }
      },
    }),
    getBillingCycleCommissionDescription: builder.query<
      GetBillingCycleCommissionDescriptionResponse,
      GetBillingCycleCommissionDescriptionRequestType
    >({
      query: ({ billingCycleId }) =>
        `billing-cycle/${billingCycleId}/description`,
      providesTags: [`CommissionDescription`],
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetBillingCycleCommissionDescriptionResponse;
        } else {
          return {
            data,
          } as unknown as GetBillingCycleCommissionDescriptionResponse;
        }
      },
    }),
    getBillingCycleCommissionRules: builder.query<
      GetBillingCycleCommissionRulesResponseType,
      { billingCycleId: string }
    >({
      query: ({ billingCycleId }) => `billing-cycle/${billingCycleId}/rules`,
      providesTags: [`BillingCycleRules`],
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetBillingCycleCommissionRulesResponseType;
        } else {
          return {
            data,
          } as unknown as GetBillingCycleCommissionRulesResponseType;
        }
      },
    }),
    updateBillingCycleCommissionRules: builder.mutation<
      UpdateBillingCycleCommissionRulesResponseType,
      {
        billingCycleId: string;
        body: UpdateBillingCycleCommissionRulesRequestType;
      }
    >({
      query: ({ billingCycleId, body }) => ({
        url: `/billing-cycle/${billingCycleId}/rules`,
        method: `POST`,
        body,
      }),
      invalidatesTags: [`BillingCycleRules`],
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as UpdateBillingCycleCommissionRulesResponseType;
        } else {
          return {
            data,
          } as unknown as UpdateBillingCycleCommissionRulesResponseType;
        }
      },
    }),
    getUniqueBillingNames: builder.mutation<
      GetUniqueBillingNamesResponseType,
      GetUniqueBillingNamesRequestType
    >({
      query: (body) => ({
        url: `/billing-cycle/unique-names`,
        method: `POST`,
        body,
      }),
      transformResponse: transformResponse,
    }),

    getApplicationCommissionParts: builder.mutation<
      GetAppicationCommissionPartsResponseType,
      GetAppicationCommissionPartsRequestType
    >({
      query: (body) => ({
        url: `/application-commission-parts/search`,
        method: `POST`,
        body,
      }),
      transformResponse: transformResponse,
    }),

    getCommissionBulkPartsList: builder.mutation<
      GetCommissionBulkPartsListResponseType,
      GetCommissionPartsListRequestType
    >({
      query: (body) => ({
        url: `/application-commission-parts/bulk/list-values`,
        method: `POST`,
        body,
      }),
      transformResponse: transformResponse,
    }),

    updateApplicationPartsbulk: builder.mutation<
      GetCommissionBulkPartsListResponseType,
      UpdateApplicationPartsBulkRequestType
    >({
      query: (body) => ({
        url: `/application-commission-parts/bulk`,
        method: `PUT`,
        body,
      }),
      transformResponse: transformResponse,
    }),

    getDuplicateStudentsList: builder.mutation<
      components['schemas']['ResponseWrapperDuplicateSearchResponseDto'],
      components['schemas']['ApplicationCommissionSearchRequestDto']
    >({
      query: (body) => ({
        url: `/application-commission/search/duplicates`,
        method: `POST`,
        body,
      }),
      transformResponse: transformResponse,
    }),

    createDownloadRequest: builder.mutation<
      DownloadRequestPathResponseType,
      DownloadRequestPathRequestType
    >({
      query: (body) => {
        return {
          url: `/application-commission-parts/export/v2`,
          method: `POST`,
          body,
        } as FetchArgs;
      },
      transformResponse,
    }),
    getDownloadRequests: builder.query<
      GetDownloadRequestsPathResponseType,
      GetDownloadRequestsPathRequestType
    >({
      query: () => `/request-download`,
      transformResponse: transformResponse,
    }),
  }),
});

export const {
  useGetApplicationCommissionStatusQuery,
  useGetCommissionSharedListQuery,
  useUpdateApplicationCommissionStatusMutation,
  useLazyGetCommissionSharedListQuery,
  useGetBillingCyclesMutation,
  useCreateBillingCycleMutation,
  useGetBillingCycleQuery,
  useUpdateBillingCycleMutation,
  useGetApplicableIntakesQuery,
  useGetPossibleBillingNamesQuery,
  useGetInvoiveDetailsQuery,
  useUpdateInvoiveDetailsMutation,
  useUpdateBillingCycleCommissionRateMutation,
  useUpdateBillingCycleCommissionDescriptionMutation,
  useGetBillingCycleCommissionDescriptionQuery,
  useGetBillingCycleCommissionRateQuery,
  useUpdateBillingCycleCommissionRulesMutation,
  useGetBillingCycleCommissionRulesQuery,
  useGetUniqueBillingNamesMutation,
  useGetApplicationCommissionPartsMutation,
  useGetCommissionBulkPartsListMutation,
  useUpdateApplicationPartsbulkMutation,
  useGetDuplicateStudentsListMutation,
  useCreateDownloadRequestMutation,
  useGetDownloadRequestsQuery,
} = commissionApi;
