import React from 'react';
import { Close } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';

type Props = {
  title: string;
  onClose: () => void;
};

function VASDrawerHeader({ title, onClose }: Props) {
  return (
    <div
      className={`flex items-center justify-between bg-primary-50 p-4 sticky top-0 z-100`}
    >
      <Typography variant="h3" className="text-grey-900">
        {title}
      </Typography>
      <IconButton className="self-end" onClick={onClose}>
        <Close />
      </IconButton>
    </div>
  );
}

export default VASDrawerHeader;
