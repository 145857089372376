import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserConfig } from 'types/user';

import type { RootState } from '../../store';

const initialState = {} as { userConfig: UserConfig };
//[Todo]: Move to context or window obj
export const userSlice = createSlice({
  name: `user `,
  initialState,
  reducers: {
    updateConfig: (state, action: PayloadAction<UserConfig>) => {
      state.userConfig = action.payload;
    },
  },
});

export const { updateConfig } = userSlice.actions;

export const selectUserDetails = (state: RootState) => state.user.userConfig;

export default userSlice.reducer;
