import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  CommissionCsvBulkUploadRequestType,
  CommissionCsvBulkUploadResponseType,
} from '@/types/commission';

import { transformResponse } from './geebeeCommission';

const geebeeCommissionBaseUploadApi = createApi({
  reducerPath: `geebeCommisionDocumentUpload`,
  baseQuery: fetchBaseQuery({ baseUrl: `/api/commission-upload?path=` }),
  endpoints: () => ({}),
});

export const geebeeCommissionBaseUploadApiWithTags =
  geebeeCommissionBaseUploadApi.enhanceEndpoints({
    addTagTypes: [],
  });

export const commisionFileUploadApi =
  geebeeCommissionBaseUploadApiWithTags.injectEndpoints({
    endpoints: (builder) => ({
      uploadApplicationCommissionParts: builder.mutation<
        CommissionCsvBulkUploadResponseType,
        CommissionCsvBulkUploadRequestType
      >({
        query: (body) => ({
          url: `application-commission-parts/csv`,
          method: `POST`,
          body,
        }),
        transformResponse: transformResponse,
      }),
    }),
  });

export const { useUploadApplicationCommissionPartsMutation } =
  commisionFileUploadApi;
