import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import { generalApi } from 'services/generalApi';
import { definitions } from 'types/schema';
import { GetGeebeeSharedList } from 'types/service';

import { OPTION_TYPE } from '@/types/common';
import { GetEligibilityParameterListResponseType } from '@/types/Services';

export type SharedLists = definitions['SpeedUpFilterDto'];

const initialState = {} as SharedLists;

export const geebeeSharedListSlice = createSlice({
  name: `sharedLists`,
  initialState,
  reducers: {
    updateList: (_state, action: PayloadAction<SharedLists>) => {
      return action.payload;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      generalApi.endpoints.getGeebeeSharedList.matchFulfilled,
      (_state, { payload }) => {
        return payload.data;
      },
    );
  },
});

export const { updateList } = geebeeSharedListSlice.actions;

export const selectGeebeeList = (state: RootState) => {
  return state.geebeeSharedList || [];
};

export type DataCorrectionParameterType = Exclude<
  Exclude<
    GetEligibilityParameterListResponseType[`data`],
    undefined
  >[number][`eligibilityParameters`],
  undefined
>[number][`fieldType`];

export const selectParamterListForDataCorrection = (
  state: RootState,
  type?: DataCorrectionParameterType,
) => {
  const list = state.geebeeSharedList?.dataCorrectionFieldOptions;
  if (!type) return list;
  return list?.filter(
    (op) =>
      (op as OPTION_TYPE & { category: DataCorrectionParameterType })
        ?.category === type,
  );
};

export default geebeeSharedListSlice.reducer;
