import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';

import { commissionApi } from '@/components/Commission/services/geebeeCommission';
import { GetCommissionSharedList } from '@/types/commission';

export type CommissionSharedLists = GetCommissionSharedList['data'];

const initialState = {} as CommissionSharedLists;

export const commissionSharedListSlice = createSlice({
  name: `commissionSharedList`,
  initialState,
  reducers: {
    updateList: (_state, action: PayloadAction<CommissionSharedLists>) => {
      return action.payload;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      commissionApi.endpoints.getCommissionSharedList.matchFulfilled,
      (_state, { payload }) => {
        return payload.data;
      },
    );
  },
});

export const { updateList } = commissionSharedListSlice.actions;

export const selectCommissionSharedList = (state: RootState) => {
  return state.commissionSharedList;
};

export default commissionSharedListSlice.reducer;
