import React from 'react';
import GeebeeTheme from '@leapfinance/frontend-commons/components/geebee/theme';
import { ThemeProvider } from '@mui/system';

import { AccomodationFormProps } from '@/components/vas/VASAccomodationFormController';
import { FlywireFormProps } from '@/components/vas/VASFlywireFormController';
import VASFormControllerSwitch from '@/components/vas/VASFormControllerSwitch';
import { GICFormProps } from '@/components/vas/VASGICFormController';
import { LoansFormProps } from '@/components/vas/VASLoansFormController';
import { VASTypes } from '@/types/vas';

import { MODAL_TYPES } from '../GlobalModal';
import useModal from '../useModal';

export type VASDrawerProps = {
  accomodationFormProps: Omit<AccomodationFormProps, 'onClose'> & {
    onClose?: () => void;
  };
  gicFormProps: Omit<GICFormProps, 'onClose'> & {
    onClose?: () => void;
  };
  flywireFormProps: Omit<FlywireFormProps, 'onClose'> & {
    onClose?: () => void;
  };
  loansFormProps: Omit<LoansFormProps, 'onClose'> & {
    onClose?: () => void;
  };
  type: VASTypes;
  onClose: () => void;
};

function VASDrawer({
  accomodationFormProps,
  gicFormProps,
  flywireFormProps,
  loansFormProps,
  type,
}: VASDrawerProps) {
  const { hideModal } = useModal();
  return (
    <ThemeProvider theme={GeebeeTheme}>
      <div className="w-[40rem] h-full">
        {type && (
          <VASFormControllerSwitch
            onClose={() => {
              hideModal(MODAL_TYPES.VAS_DRAWER);
            }}
            flywireFormProps={flywireFormProps}
            accomodationFormProps={accomodationFormProps}
            gicFormProps={gicFormProps}
            loansFormProps={loansFormProps}
            type={type}
          />
        )}
      </div>
    </ThemeProvider>
  );
}

export default VASDrawer;
