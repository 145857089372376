import React, { useRef } from 'react';
import { Button } from '@leapfinance/frontend-commons';
import { gbTheme, GBTypography } from '@leapfinance/geebee-component-library';
import Close from '@mui/icons-material/Close';
import { Box, Divider, IconButton, ThemeProvider } from '@mui/material';
import { FormApi } from 'final-form';

import { useUpdateApplicationUsingOfferLetterMutation } from '@/services/applicationApi';

import useModal from '../modals/useModal';

import DocumentViewer from './DocumentViewer';
import OfferLetterForm, { type OfferLetterFormType } from './OfferLetterForm';

interface IOfferLetterValidateProps {
  file: File;
  fileUrl?: string;
  initialData?: OfferLetterFormType;
  applicationId: number;
  documentId: number;
  onSuccess?: () => void;
}

function OfferLetterValidate(props: IOfferLetterValidateProps) {
  const { hideModal } = useModal();
  const formRef = useRef<FormApi<OfferLetterFormType>>(null);
  const [_triggerUpdate, { isLoading }] =
    useUpdateApplicationUsingOfferLetterMutation({
      fixedCacheKey: `offer-letter`,
    });
  return (
    <ThemeProvider theme={gbTheme}>
      <div className="flex flex-col h-[80%] w-[75%] bg-white-0 rounded-md">
        <div className="flex justify-between items-center px-4 py-3">
          <GBTypography variant="sh2">Additional Offer Detail</GBTypography>
          <IconButton onClick={() => hideModal()}>
            <Close />
          </IconButton>
        </div>
        <Divider />
        <div className="grid grid-cols-2 gap-2 overflow-hidden px-4 py-3">
          <Box
            className=" bg-[#1C1C1C7A] p-3 h-full relative overflow-y-auto"
            sx={{ '#pdf-controls': { display: `none` } }}
          >
            <DocumentViewer file={props?.file} fileUrl={props?.fileUrl} />
          </Box>
          <div className="overflow-hidden overflow-y-auto p-2 h-full">
            <OfferLetterForm
              formRef={formRef}
              initialData={props?.initialData}
              applicationId={props?.applicationId}
              documentId={props?.documentId}
              onSuccess={() => window?.location?.reload()}
            />
          </div>
        </div>
        <div className="flex px-4 py-3 gap-2">
          <Button fullWidth variant="outlined" onClick={() => hideModal()}>
            Cancel
          </Button>
          <Button
            fullWidth
            onClick={() => formRef?.current?.submit()}
            loading={isLoading}
          >
            Save
          </Button>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default OfferLetterValidate;
