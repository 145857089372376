import React from 'react';
import CreateOrSearchStudentField, {
  type CreateOrSearchStudentFieldProps,
} from '@leapfinance/frontend-commons/components/geebee/CreateOrSearchStudent';

import { selectUserDetails } from '@/app/features/user/userSlice';
import { useAppSelector } from '@/app/hooks';
import useStudentOptions from '@/hooks/vas/useStudentOptions';
import { ADD_STUDENT_USER_TYPES } from '@/utils/constants';

export type FFVASCreateOrSearchStudentFieldProps =
  CreateOrSearchStudentFieldProps & {
    isStudentCreateModeOn?: boolean;
    handleStudentChange?: (_: any, student: any) => void;
  };

function FFVASCreateOrSearchStudentField(
  props: FFVASCreateOrSearchStudentFieldProps,
) {
  const [fetchStudents] = useStudentOptions();
  const user = useAppSelector((state) => selectUserDetails(state));

  return (
    <CreateOrSearchStudentField
      {...props}
      studentcomponentProps={{
        ...props.studentcomponentProps,
        options: [],
        // async: {
        //   // @ts-ignore
        //   loadOptions: fetchStudents,
        // },
        fetchOptionsHandler: fetchStudents,
        textInputProps: {
          placeholder: `Please enter a student name`,
        },
        disableClearable: false,
        creatable: ADD_STUDENT_USER_TYPES?.includes(user?.userType)
          ? true
          : false,
        creatableText: (inputValue) => `Add Student - ${inputValue}`,
        onChange: props.handleStudentChange,
      }}
      studentCreateModeDefaultValue={props.isStudentCreateModeOn}
    />
  );
}

export default FFVASCreateOrSearchStudentField;
