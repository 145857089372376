import { FetchArgs } from '@reduxjs/toolkit/query';

import { definitions } from '@/types/schema';
import {
  GetAllPendingTaskRequestType,
  GetAllPendingTaskResponseType,
} from '@/types/service';
import {
  AssignTaskInBulkRequestType,
  AssignTaskInBulkResponseType,
  BulkChangeStatusForEmailTaskRequestType,
  BulkChangeStatusForEmailTaskResponseType,
  BulkEscalateEmailTaskRequestType,
  BulkEscalateEmailTaskResponseType,
  ForwardEmailIdsListResponse,
  ForwardUniversityEmailRequestType,
  ForwardUniversityEmailResponseType,
  GetEscalatedUsersForTaskRequestBody,
  GetEscalatesUsersForTaskResponse,
  GetTaskAuditLogsResponseType,
  GetTaskDetailsResponse,
  TaskAddCommentRequestBody,
  TaskAllTeamUsersResponse,
  TaskCommentsResponse,
  TaskDetailsRequestBody,
  TaskDetailsResponse,
  TaskStatsRequestBody,
  TaskStatsResponse,
  UpdateTaskDetailsRequestBody,
} from '@/types/Services';
import { transformResponse } from '@/utils/common';

import { tasksBaseApiWithTags } from './base/tasksRoute';

export const tasksApi = tasksBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getAllTeamUsers: builder.query<TaskAllTeamUsersResponse, void>({
      query: () => `/team-users`,
      //[Todo]: Throw error if success false and create a HOC or wrapper for transform response
      transformResponse: ({ success, data, message }: any) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as any;
        } else {
          return { data } as unknown as any;
        }
      },
    }),
    getAllTaskStats: builder.mutation<TaskStatsResponse, TaskStatsRequestBody>({
      query: (body) => ({
        url: `/stats`,
        method: `POST`,
        body,
      }),
      //[Todo]: Throw error if success false and create a HOC or wrapper for transform response
      transformResponse: ({ success, data, message }: any) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as any;
        } else {
          return { data } as TaskStatsResponse as any;
        }
      },
    }),
    getAllTasks: builder.mutation<TaskDetailsResponse, TaskDetailsRequestBody>({
      query: (body) => ({
        url: `/view-all`,
        method: `POST`,
        body,
      }),
      //[Todo]: Throw error if success false and create a HOC or wrapper for transform response
      transformResponse: transformResponse,
    }),
    getTaskComments: builder.query<TaskCommentsResponse, { id: number }>({
      query: ({ id }) => `/${id}/comments`,
      transformResponse: ({ success, data, message }: any) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as any;
        } else {
          return { data } as TaskCommentsResponse as any;
        }
      },
      providesTags: [`TaskComments`],
    }),
    addTaskComment: builder.mutation<
      definitions['ResponseWrapper«boolean»'],
      TaskAddCommentRequestBody & { id: number }
    >({
      query: ({ id, ...body }) => ({
        url: `/${id}/comments`,
        method: `POST`,
        body,
      }),
      transformResponse: ({ success, data, message }: any) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as any;
        } else {
          return { data } as TaskDetailsResponse as any;
        }
      },
      invalidatesTags: [`TaskComments`],
    }),
    getTaskDetails: builder.query<GetTaskDetailsResponse, { id: number }>({
      query: ({ id }) => `/${id}`,
      //[Todo]: Throw error if success false and create a HOC or wrapper for transform response
      transformResponse: ({ success, data, message }: any) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as any;
        } else {
          return { data } as GetTaskDetailsResponse as any;
        }
      },
      providesTags: [`TaskDetails`],
    }),
    updateTaskDetails: builder.mutation<
      definitions['ResponseWrapper«boolean»'],
      UpdateTaskDetailsRequestBody & { id: number }
    >({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: `PATCH`,
        body,
      }),
      invalidatesTags: [`TaskComments`, `TaskDetails`, `TaskAuditLogs`],
    }),
    getEscalatedUserForTask: builder.mutation<
      GetEscalatesUsersForTaskResponse,
      GetEscalatedUsersForTaskRequestBody
    >({
      query: (body) => ({
        url: `/escalate-users`,
        method: `POST`,
        body,
      }),
      transformResponse: ({ success, data, message }: any) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as any;
        } else {
          return { data } as GetEscalatesUsersForTaskResponse as any;
        }
      },
    }),
    getPendingTasks: builder.mutation<
      GetAllPendingTaskResponseType,
      GetAllPendingTaskRequestType
    >({
      query: (body) => ({ url: `/pending`, method: `POST`, body }),
      //[Todo]: Throw error if success false and create a HOC or wrapper for transform response
      transformResponse: transformResponse,
    }),
    getTaskAuditLogs: builder.query<
      GetTaskAuditLogsResponseType,
      {
        taskId: number;
      }
    >({
      query: ({ taskId }) => `/${taskId}/audit-log`,
      providesTags: [`TaskAuditLogs`],
      transformResponse: transformResponse,
    }),
    forwardUniversityEmail: builder.mutation<
      ForwardUniversityEmailResponseType,
      ForwardUniversityEmailRequestType
    >({
      query: ({ taskId, ...body }) => ({
        url: `/${taskId}/forward-university-email`,
        method: `POST`,
        body,
      }),
      invalidatesTags: [`TaskAuditLogs`],
    }),
    getFromEmailIds: builder.query<ForwardEmailIdsListResponse, any>({
      query: () => `/university-email-from-email-ids`,
      transformResponse: transformResponse,
    }),

    assignTaksInBulk: builder.mutation<
      AssignTaskInBulkResponseType,
      AssignTaskInBulkRequestType
    >({
      query: (body) => {
        return {
          url: `/assign-tasks-in-bulk`,
          body,
          method: `POST`,
        } as FetchArgs;
      },
      transformResponse,
    }),

    bulkEscalateEmailTask: builder.mutation<
      BulkEscalateEmailTaskResponseType,
      BulkEscalateEmailTaskRequestType
    >({
      query: (body) => {
        return {
          url: `/escalate-tasks-in-bulk`,
          body,
          method: `POST`,
        } as FetchArgs;
      },
      transformResponse,
    }),
    bulkChangeStatusEmailTask: builder.mutation<
      BulkChangeStatusForEmailTaskResponseType,
      BulkChangeStatusForEmailTaskRequestType
    >({
      query: (body) => {
        return {
          url: `/change-task-status-in-bulk`,
          body,
          method: `POST`,
        } as FetchArgs;
      },
      transformResponse,
    }),
  }),
});

export const {
  useGetAllTeamUsersQuery,
  useGetAllTaskStatsMutation,
  useGetAllTasksMutation,
  useGetTaskCommentsQuery,
  useAddTaskCommentMutation,
  useGetTaskDetailsQuery,
  useLazyGetTaskDetailsQuery,
  useUpdateTaskDetailsMutation,
  useGetEscalatedUserForTaskMutation,
  useGetPendingTasksMutation,
  useGetTaskAuditLogsQuery,
  useForwardUniversityEmailMutation,
  useGetFromEmailIdsQuery,
  useAssignTaksInBulkMutation,
  useBulkEscalateEmailTaskMutation,
  useBulkChangeStatusEmailTaskMutation,
} = tasksApi;
