import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DashboardTabTypesEnum } from '@/components/SummaryDashboard/UserPerformanceViewDashboard/constants';

import { RootState } from '../app/store';
import { Roles } from '../utils/userManagementPermissions';

const initialState: {
  countries: string[] | null;
  role: string | null;
  settingRole: boolean;
  dashboardActiveTab: DashboardTabTypesEnum;
  resetCounsellorCspPerformanceDashboardFilter: boolean;
} = {
  countries: null,
  role: null,
  settingRole: true,
  dashboardActiveTab: DashboardTabTypesEnum.MY_TASK,
  resetCounsellorCspPerformanceDashboardFilter: false,
};

export const generalSlice = createSlice({
  name: `general`,
  initialState,
  reducers: {
    updateCountries: (state, action: PayloadAction<string[]>) => {
      state.countries = action.payload;
    },
    updateRole: (state, action: PayloadAction<Roles>) => {
      state.role = action.payload;
      state.settingRole = false;
    },
    updateDashboardActiveTab: (
      state,
      action: PayloadAction<DashboardTabTypesEnum>,
    ) => {
      state.dashboardActiveTab = action.payload;
    },
    updateResetCounsellorCspPerformanceFilter: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.resetCounsellorCspPerformanceDashboardFilter = action.payload;
    },
  },
});

export const {
  updateRole,
  updateDashboardActiveTab,
  updateResetCounsellorCspPerformanceFilter,
} = generalSlice.actions;

export const selectCountries = (state: RootState) => state.general.countries;
export const selectRole = (state: RootState) => state.general.role;
export const selectRoleStatus = (state: RootState) => state.general.settingRole;
export const selectDashboardActiveTab = (state: RootState) =>
  state.general.dashboardActiveTab;
export const selectResetCounsellorCspPerformanceFilter = (state: RootState) =>
  state.general.resetCounsellorCspPerformanceDashboardFilter;

export default generalSlice.reducer;
