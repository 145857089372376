import { store } from '../app/store';
import {
  confirmDialog as confirmDialogAction,
  ConfirmModalStateType,
} from '../features/confirmDialogSlice';

export const confirmDialog = ({
  message,
  title,
  onSubmit,
  onCancel,
  options,
}: ConfirmModalStateType) => {
  store.dispatch(
    confirmDialogAction({ message, title, onSubmit, onCancel, options }),
  );
};
