const ArrowDownward = () => {
  return (
    <svg
      width="16"
      height="42"
      viewBox="0 0 16 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M9 1C9 0.447715 8.55228 0 8 0C7.44772 0 7 0.447715 7 1L9 1ZM7.29289 41.7071C7.68342 42.0976 8.31658 42.0976 8.70711 41.7071L15.0711 35.3431C15.4616 34.9526 15.4616 34.3195 15.0711 33.9289C14.6805 33.5384 14.0474 33.5384 13.6569 33.9289L8 39.5858L2.34315 33.9289C1.95262 33.5384 1.31946 33.5384 0.928932 33.9289C0.538408 34.3195 0.538408 34.9526 0.928932 35.3431L7.29289 41.7071ZM7 1L7 41H9L9 1L7 1Z"
        fill="#686868"
      />
    </svg>
  );
};

export default ArrowDownward;
