import React from 'react';
import { Stack } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';

import {
  DocumentNotificationIcon,
  RemarkNotificationIcon,
  StateChangeNotificationIcon,
  TaskNotificationIcon,
} from 'components/Icons';

import { NotificationCategory, NotificationVariant } from './notification.enum';

export const notificationIcon = {
  [NotificationCategory.task]: (active?: boolean) => (
    <TaskNotificationIcon active={active} />
  ),
  [NotificationCategory.remarks]: (active?: boolean) => (
    <RemarkNotificationIcon active={active} />
  ),
  [NotificationCategory.document]: (active?: boolean) => (
    <DocumentNotificationIcon active={active} />
  ),
  [NotificationCategory.state]: (active?: boolean) => (
    <StateChangeNotificationIcon active={active} />
  ),
  [`SHORTLIST`]: (active?: boolean) => (
    <RemarkNotificationIcon active={active} />
  ),
};

export const notificationBackground = {
  [NotificationCategory.task]: `#FFF5F6`,
  [NotificationCategory.remarks]: `#F5FFFE`,
  [NotificationCategory.document]: `#FFFAF5`,
  [NotificationCategory.state]: `#F8FFF5`,
  [`SHORTLIST`]: `#F5FFFE`,
};

export interface NotificationTemplateProps {
  category: NotificationCategory;
  seen?: boolean;
  variant: NotificationVariant;
  title: string;
  body: string;
  footNote: string;
  id: string;
  onNotificationClick: (id: string, redirect?: boolean) => void;
  createdAt: string;
}

const NotificationTemplate = (props: NotificationTemplateProps) => {
  return (
    <div
      className={`flex items-start justify-start border-b-[1px] cursor-pointer overflow-x-hidden p-4 hover:bg-[#FAFAFA] ${
        !props.seen ? ` border-l-4 border-l-primary-400` : ``
      }`}
      style={{
        backgroundColor: props.seen ? `#F5F5F5` : `white`,
      }}
      onClick={() => props.onNotificationClick(props.id, true)}
    >
      <div className="px-2">
        {props.variant === NotificationVariant.contained ? (
          <div
            className="flex items-center justify-center p-4"
            style={{ backgroundColor: notificationBackground[props.category] }}
          >
            {notificationIcon[props.category](true)}
          </div>
        ) : (
          <div className="p-2">{notificationIcon[props.category](true)}</div>
        )}
      </div>

      <div className="ml-2 flex-1 overflow-hidden">
        <Stack gap={`4px`}>
          <p className="text-sm w-[100%] text-grey-600 overflow-hidden whitespace-nowrap overflow-ellipsis max-w-[85%]">
            {props.title}
          </p>
          <p className="text-md  text-grey-800 font-medium overflow-hidden whitespace-nowrap overflow-ellipsis max-w-[85%]">
            {props.body}
          </p>
          <p className="text-sm text-grey-800  overflow-hidden whitespace-nowrap overflow-ellipsis max-w-[85%]">
            {props.footNote}
          </p>
          <div className="flex items-start justify-start ">
            <span className="text-[10px] text-grey-700   ">
              {props.category}
            </span>
            <span className="text-[10px] text-grey-700    mx-2 flex items-center justify-center">
              .
            </span>
            <span className="text-[10px] text-grey-700    ">
              {formatDistanceToNow(new Date(props.createdAt), {
                addSuffix: true,
              })}
            </span>
            {!props.seen && (
              <span
                className="text-[12px] text-primary-400 ml-auto hover:bg-primary-100 px-1 rounded-md"
                onClick={(e) => {
                  e.stopPropagation();
                  props.onNotificationClick(props.id);
                }}
              >
                Mark as read
              </span>
            )}
          </div>
        </Stack>
      </div>
    </div>
  );
};

export default NotificationTemplate;
