const intakeGroups = [
  {
    startMonth: `JANUARY`,
    endMonth: `MARCH`,
    startMonthLabel: `January`,
    endMonthLabel: `March`,
    includedMonths: [`JANUARY`, `FEBRUARY`, `MARCH`],
    index: 0,
  },
  {
    startMonth: `APRIL`,
    endMonth: `JUNE`,
    startMonthLabel: `April`,
    endMonthLabel: `June`,
    includedMonths: [`APRIL`, `MAY`, `JUNE`],
    index: 1,
  },
  {
    startMonth: `JULY`,
    endMonth: `DECEMBER`,
    startMonthLabel: `July`,
    endMonthLabel: `December`,
    includedMonths: [
      `JULY`,
      `AUGUST`,
      `SEPTEMBER`,
      `OCTOBER`,
      `NOVEMBER`,
      `DECEMBER`,
    ],
    index: 2,
  },
];

const indexMonthMapper: Record<number, string> = {
  1: `JANUARY`,
  2: `FEBRUARY`,
  3: `MARCH`,
  4: `APRIL`,
  5: `MAY`,
  6: `JUNE`,
  7: `JULY`,
  8: `AUGUST`,
  9: `SEPTEMBER`,
  10: `OCTOBER`,
  11: `NOVEMBER`,
  12: `DECEMBER`,
};

const getIntakeGroupIndexOfCurrentMonth = () => {
  const currentDate = new Date();
  const currentMonthIndex = currentDate?.getMonth() + 1;
  const currentMonthLabel = indexMonthMapper[currentMonthIndex];
  const intakeGroupIndexOfCurrentMonth = intakeGroups?.filter((group) => {
    if (group?.includedMonths?.includes(currentMonthLabel)) {
      return group;
    }
  })?.[0]?.index;
  return intakeGroupIndexOfCurrentMonth;
};

const getIntakeGroupsForDashboardFilters = (groups: number[]) => {
  // gets the intake groups of the previous and future years based on the current intake group
  const transformedIntakesForPreviousAndFutureYearIntakes = groups?.map(
    (value) => {
      const first = value + intakeGroups?.length;
      const reminder = first % intakeGroups?.length;
      return reminder;
    },
  );

  const combinedIntakeGroups =
    transformedIntakesForPreviousAndFutureYearIntakes?.map((value) => {
      return intakeGroups[value];
    });
  return combinedIntakeGroups;
};

const getIntakeYearsForDashboardFilters = (groups: number[]) => {
  const currentDate = new Date();
  const currentGroupIndex = groups?.[2];
  // gets the years for each of the intake groups
  // If the future intake group falls in the next year, it'll be currentYear+1, and currentYear-1 for previous year
  const transformedYearsForPreviousAndFutureYearIntakes = groups?.map(
    (value) => {
      if (value < 0) {
        return currentDate?.getFullYear() - 1;
      }
      if (value > currentGroupIndex && value > intakeGroups?.length - 1) {
        return currentDate?.getFullYear() + 1;
      }

      return currentDate?.getFullYear();
    },
  );
  return transformedYearsForPreviousAndFutureYearIntakes;
};

const getIntakeGroupOfCurrentMonth = () => {
  const intakeGroupIndexOfCurrentMonth = getIntakeGroupIndexOfCurrentMonth();

  // takes the previous 2 groups, current group, and the future 3 intake groups
  const groups = [
    intakeGroupIndexOfCurrentMonth - 2,
    intakeGroupIndexOfCurrentMonth - 1,
    intakeGroupIndexOfCurrentMonth,
    intakeGroupIndexOfCurrentMonth + 1,
    intakeGroupIndexOfCurrentMonth + 2,
    intakeGroupIndexOfCurrentMonth + 3,
  ];
  // extracts the group index of the current month
  const intakeYears = getIntakeYearsForDashboardFilters(groups);
  const transformedIntakeGroups = getIntakeGroupsForDashboardFilters(groups);

  // builds the option type array for the intake dropdown
  // label - Month Name and year name
  // value - list of months and years that fall under that intake group
  // projection value - intake range for the projection table filters
  const intakeGroupOptionTypeArray = transformedIntakeGroups?.map(
    (value, index) => {
      const intakeYearLastTwoDigits = intakeYears[index]
        ?.toString()
        .substring(2);
      const intakeStartMonthLabelFirstThreeLetters =
        value?.startMonthLabel.substring(0, 3);
      const intakeEndMonthLabelFirstThreeLetters =
        value?.endMonthLabel.substring(0, 3);
      const intakeYear = intakeYears[index]?.toString();
      const intakeStartMonthLabel = value?.startMonth;
      const intakeEndMonthLabel = value?.endMonth;
      return {
        label: `${intakeStartMonthLabelFirstThreeLetters}'${intakeYearLastTwoDigits} - ${intakeEndMonthLabelFirstThreeLetters}'${intakeYearLastTwoDigits}`,
        value: value?.includedMonths?.map((month) => {
          return `${month}_${intakeYear}`;
        }),
        projectionIntakeValue: `${intakeStartMonthLabel}${intakeYearLastTwoDigits}_${intakeEndMonthLabel}${intakeYearLastTwoDigits}`,
      };
    },
  );
  return intakeGroupOptionTypeArray;
};
export const mockIntakes = getIntakeGroupOfCurrentMonth();
