import * as React from 'react';
import { SVGProps } from 'react';

const CommissionWarningFilledIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_602_2314)">
      <mask
        id="mask0_602_2314"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="24"
      >
        <path d="M0.5 0H24.5V24H0.5V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_602_2314)">
        <path
          d="M2.80722 22.7368C1.64385 22.7368 1.13101 21.8918 1.66659 20.8592L11.5249 1.87706C12.0605 0.844427 12.9384 0.844427 13.4746 1.87706L23.3329 20.8592C23.8697 21.8918 23.3563 22.7368 22.1923 22.7368H2.80722Z"
          fill="#8B2C2C"
        />
        <path
          d="M11.5774 8.92375C11.5774 8.3532 11.9747 8 12.5001 8C13.0131 8 13.4232 8.36678 13.4232 8.92375V14.1551C13.4232 14.7121 13.0131 15.0789 12.5001 15.0789C11.9747 15.0789 11.5774 14.7252 11.5774 14.1551V8.92375ZM11.5001 16.94C11.5006 16.659 11.6061 16.3897 11.7935 16.1911C11.981 15.9924 12.2351 15.8807 12.5001 15.8804C12.7652 15.8807 13.0193 15.9924 13.2067 16.1911C13.3942 16.3897 13.4997 16.659 13.5001 16.94C13.4998 17.221 13.3943 17.4904 13.2069 17.6891C13.0194 17.8879 12.7652 17.9997 12.5001 18C12.235 17.9997 11.9808 17.8879 11.7934 17.6891C11.6059 17.4904 11.5005 17.221 11.5001 16.94Z"
          fill="#F5E2E2"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_602_2314">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default CommissionWarningFilledIcon;
