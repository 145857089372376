import React from 'react';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import {
  isFalsy,
  useFinalFormYupFormHelpers,
} from '@leapfinance/frontend-commons';
import { Button } from '@leapfinance/frontend-commons';
import {
  FFDropdown,
  FFMobileInput,
  FFTextInput,
  FFTextInputDropDown,
} from '@leapfinance/geebee-component-library';
import { Close, DeleteOutlineOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { FormApi } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { definitions } from 'types/schema';

import { selectList } from '@/app/features/sharedList/sharedListSlice';
import { useAppSelector } from '@/app/hooks';
import { useUpdateApplicationUsingOfferLetterMutation } from '@/services/applicationApi';
import { OPTION_TYPE, Shape } from '@/types/common';
import { startCaseToLower } from '@/utils/common';
import { getSharedListOptions } from '@/utils/helperFunctions';
import yup from '@/yup/yupInstance';

import { transformOptionValues } from '../CF/Dashboard/helpers';

export type OfferLetterFormType = definitions['OfferLetterInfoDto'];

const offerLetterSchema = yup.object().shape<Shape<OfferLetterFormType>>({
  admitDate: yup.mixed().required(`Required`).nullable(),
  studentReferenceId: yup.string().nullable(),
  studentName: yup.string().nullable(),
  courseName: yup.string().nullable(),
  year: yup.string().nullable(),
  mode: yup.string().nullable(),
  university: yup.string().nullable(),
  semester: yup.string().nullable(),
  offerType: yup.mixed().required(`Required`).nullable(),
  startDate: yup.mixed().nullable(),
  endDate: yup.mixed().nullable(),
  lastDateFeePayment: yup.mixed().required(`Required`).nullable(),
  lastDateMeetingConditions: yup.mixed().nullable(),
  lastDateOfferAcceptance: yup.mixed().nullable(),
  remainingPayment: yup.mixed().nullable(),
  priorToEnrolling: yup.string().nullable(),
  importantInformation: yup.string().nullable(),
  scholarship: yup.mixed().nullable(),
  annualTuitionFee: yup.mixed().nullable(),
  firstYearTuitionFee: yup.mixed().nullable(),
  paymentInformation: yup.string().nullable(),
  conditions: yup.array().of(yup.mixed()).nullable(),
  netFee: yup.mixed().nullable(),
  program: yup.string().nullable(),
});

interface IOfferLetterFormProps {
  initialData?: OfferLetterFormType;
  formRef: React.MutableRefObject<FormApi<OfferLetterFormType> | null>;
  applicationId: number;
  documentId: number;
  onSuccess?: () => void;
}

export const FeesDropDown = (props: {
  name: string;
  currencyOptions: any[];
  label: string;
}) => (
  <FFTextInputDropDown
    fieldProps={{
      name: props.name,
      parse: (value) => {
        return {
          currency: value?.dropdownValue,
          amount: value?.textInputValue,
        };
      },
      format: (value) => {
        if (!value) return null;
        return {
          dropdownValue: value?.currency?.value
            ? value?.currency
            : { label: value?.currency, value: value?.currency },
          textInputValue: value?.amount,
        };
      },
    }}
    componentProps={{
      dropdownProps: {
        options: props.currencyOptions,

        getOptionLabel: (option) => {
          return typeof option === `string` ? option : option?.label;
        },
        isOptionEqualToValue: (option, value) => {
          if (typeof option === `string` || typeof value === `string`)
            return true;
          return option?.label === value?.label;
        },
        renderOption: (props, option) => {
          if (typeof option === `string`) return null;
          return <li {...props}>{option?.label}</li>;
        },
        textInputProps: { placeholder: `Currency` },
      },
      placeholder: `Enter amount`,
      label: props?.label,
    }}
  />
);

function OfferLetterForm(props: IOfferLetterFormProps) {
  const [validate, formNames] = useFinalFormYupFormHelpers({
    schema: offerLetterSchema,
  });
  const sharedList = useAppSelector((state) => selectList(state));
  const currency = getSharedListOptions(`currency`, sharedList);

  const [triggerUpdate] = useUpdateApplicationUsingOfferLetterMutation({
    fixedCacheKey: `offer-letter`,
  });

  const conditionalAdmitReasonsListOptions =
    sharedList?.conditionalAdmitConditions?.map(
      (data: { labelValue?: any; labelKey?: any }) => data.labelKey,
    ) || [];

  if (typeof window === `undefined`) return null;

  const handleSubmit = (values: any) => {
    const data = { ...props?.initialData, ...transformOptionValues(values) };
    const nonFalsyValues: any = {};
    Object.keys(data).forEach((key) => {
      if (key && !isFalsy(data?.[key])) {
        nonFalsyValues[key] = data?.[key];
      }
    });
    triggerUpdate({
      id: props?.applicationId,
      documentId: props?.documentId,
      dto: nonFalsyValues,
    })
      .unwrap()
      .then((data) => {
        if (data?.success) {
          props?.onSuccess?.();
        }
      });
  };

  return (
    <div className="bg-white-0">
      <Form<OfferLetterFormType>
        mutators={{ ...arrayMutators }}
        onSubmit={handleSubmit}
        validate={validate as any}
        initialValues={{
          ...props?.initialData,
          conditions: isFalsy(props?.initialData?.conditions)
            ? [{ condition: null as any, conditionDescription: `` }]
            : props?.initialData?.conditions,
          offerType: isFalsy(props?.initialData?.offerType)
            ? (null as any)
            : {
                label: startCaseToLower(props?.initialData?.offerType ?? ``),
                value: props?.initialData?.offerType,
              },
        }}
        render={({ form, values }) => {
          props.formRef.current = form;
          return (
            <div className="grid grid-cols-2 gap-4 p-4">
              <FFTextInput
                fieldProps={{ name: formNames.studentReferenceId }}
                componentProps={{
                  label: `Ref/Application No`,
                  placeholder: `Enter Ref/Application No`,
                }}
              />

              <FFTextInput
                fieldProps={{ name: formNames.studentName }}
                componentProps={{
                  label: `Student Name`,
                  disabled: !isFalsy(
                    (form?.getState()?.initialValues as any)?.[
                      formNames.studentName as any
                    ],
                  ),
                  placeholder: `Enter student name`,
                }}
              />
              <FFTextInput
                fieldProps={{ name: formNames.courseName }}
                componentProps={{
                  label: `Course`,
                  disabled: !isFalsy(
                    (form?.getState()?.initialValues as any)?.[
                      formNames.courseName as any
                    ],
                  ),
                  placeholder: `Enter course`,
                }}
              />
              <FFTextInput
                fieldProps={{ name: formNames.year }}
                componentProps={{
                  label: `Year`,
                  disabled: !isFalsy(
                    (form?.getState()?.initialValues as any)?.[
                      formNames.year as any
                    ],
                  ),
                  placeholder: `Enter year`,
                }}
              />
              <FFTextInput
                fieldProps={{ name: formNames.mode }}
                componentProps={{
                  label: `Mode`,
                  disabled: !isFalsy(
                    (form?.getState()?.initialValues as any)?.[
                      formNames.mode as any
                    ],
                  ),
                  placeholder: `Enter mode`,
                }}
              />
              <FFTextInput
                fieldProps={{ name: formNames.university }}
                componentProps={{
                  label: `University`,
                  disabled: !isFalsy(
                    (form?.getState()?.initialValues as any)?.[
                      formNames.university as any
                    ],
                  ),
                  placeholder: `Enter university`,
                }}
              />
              <FFTextInput
                fieldProps={{ name: formNames.program }}
                componentProps={{
                  label: `Program`,
                  disabled: !isFalsy(
                    (form?.getState()?.initialValues as any)?.[
                      formNames.program as any
                    ],
                  ),
                  placeholder: `Enter program`,
                }}
              />
              <FFTextInput
                fieldProps={{ name: formNames.semester }}
                componentProps={{
                  label: `Semester`,
                  disabled: !isFalsy(
                    (form?.getState()?.initialValues as any)?.[
                      formNames.semester as any
                    ],
                  ),
                  placeholder: `Enter semester`,
                }}
              />
              <FFDropdown
                fieldProps={{ name: formNames.offerType }}
                componentProps={{
                  options: [
                    {
                      label: `Conditional Admit Received`,
                      value: `CONDITIONAL_ADMIT_RECEIVED`,
                    },
                    {
                      label: `Unconditional Admit Received`,
                      value: `UNCONDITIONAL_ADMIT_RECEIVED`,
                    },
                  ],
                  getOptionLabel: (option) => (option as OPTION_TYPE).label,
                  textInputProps: {
                    label: `Offer Type`,
                    placeholder: `Select offer type`,
                    required: true,
                  },
                  disableClearable: true,
                }}
              />
              <FFTextInput
                fieldProps={{ name: formNames.admitDate }}
                componentProps={{
                  label: `Admit Date`,
                  type: `date`,
                  required: true,
                }}
              />
              <FFTextInput
                fieldProps={{ name: formNames.startDate }}
                componentProps={{ label: `Start Date`, type: `date` }}
              />
              <FFTextInput
                fieldProps={{ name: formNames.endDate }}
                componentProps={{ label: `End Date`, type: `date` }}
              />
              {(values?.offerType as unknown as OPTION_TYPE)?.value ===
                `CONDITIONAL_ADMIT_RECEIVED` && (
                <div className="col-span-2">
                  <FieldArray name="conditions">
                    {({ fields }) => {
                      return (
                        <div className="flex flex-col gap-4">
                          {fields.map((name, index) => (
                            <div key={index} className="flex gap-4">
                              <FFDropdown
                                fieldProps={{
                                  name: `${name}condition`,
                                  validate: (value) => !value && `Required`,
                                }}
                                componentProps={{
                                  options: conditionalAdmitReasonsListOptions,
                                  textInputProps: {
                                    label: `condition ${index + 1}`,
                                    required: index === 0,
                                  },
                                  fullWidth: true,
                                  getOptionLabel: (option) =>
                                    startCaseToLower(option as string),
                                  disableClearable: true,
                                  placeholder: `Select condition`,
                                  getOptionDisabled: (option) => {
                                    return !!values?.conditions
                                      ?.map((c) => c?.condition)
                                      ?.filter((o) => !isFalsy(o))
                                      ?.includes(option);
                                  },
                                }}
                              />
                              <FFTextInput
                                fieldProps={{
                                  name: `${name}conditionDescription`,
                                  validate: (value) => !value && `Required`,
                                }}
                                componentProps={{
                                  label: `Description`,
                                  placeholder: `Enter Condition description`,
                                  // multiline: true,
                                  // rows: 3,
                                  required: index === 0,
                                }}
                              />
                              {index > 0 && (
                                <IconButton
                                  onClick={() => fields.remove(index)}
                                  className="h-fit self-center rounded-full p-1"
                                >
                                  <DeleteOutlineOutlined className="text-gbTokens-grey-A600" />
                                </IconButton>
                              )}
                            </div>
                          ))}
                          <Button
                            variant="outlined"
                            onClick={() => fields.push({})}
                            className="self-center"
                          >
                            Add Condition
                          </Button>
                        </div>
                      );
                    }}
                  </FieldArray>
                </div>
              )}
              <FeesDropDown
                name={formNames.firstYearTuitionFee}
                currencyOptions={currency}
                label="First year tuition fees"
              />
              <FeesDropDown
                name={formNames.annualTuitionFee}
                currencyOptions={currency}
                label="Annual tuition fees"
              />
              <FeesDropDown
                name={formNames.netFee}
                currencyOptions={currency}
                label="Net Fees"
              />

              <FeesDropDown
                name={formNames.scholarship}
                currencyOptions={currency}
                label="Scholarship Fees"
              />

              <FeesDropDown
                name={formNames.remainingPayment}
                currencyOptions={currency}
                label="Required Deposit"
              />
              <FFTextInput
                fieldProps={{ name: formNames.paymentInformation }}
                componentProps={{
                  label: `Payment Information`,
                  placeholder: `Enter payment information`,
                }}
              />

              <FFTextInput
                fieldProps={{ name: formNames.lastDateFeePayment }}
                componentProps={{
                  label: `Last Date for Fee payment`,
                  type: `date`,
                  required: true,
                }}
              />
              <FFTextInput
                fieldProps={{
                  name: formNames.lastDateOfferAcceptance,
                  validate: (value) => {
                    return (
                      form.getState()?.values
                        ?.offerType as unknown as OPTION_TYPE
                    )?.value === `UNCONDITIONAL_ADMIT_RECEIVED` && !value
                      ? `Required`
                      : false;
                  },
                }}
                componentProps={{
                  label: `Last Date for Offer Acceptance`,
                  type: `date`,
                  required:
                    (values?.offerType as unknown as OPTION_TYPE)?.value ===
                    `UNCONDITIONAL_ADMIT_RECEIVED`,
                }}
              />
              <FFTextInput
                fieldProps={{ name: formNames.lastDateMeetingConditions }}
                componentProps={{
                  label: `Last Date for Meeting Conditions`,
                  type: `date`,
                }}
              />
              <FFTextInput
                fieldProps={{ name: formNames.priorToEnrolling }}
                componentProps={{
                  label: `Prior to enrolling`,
                  containerClassName: `col-span-2`,
                  multiline: true,
                  rows: 3,
                }}
              />
              <FFTextInput
                fieldProps={{ name: formNames.importantInformation }}
                componentProps={{
                  label: `Important Information`,
                  containerClassName: `col-span-2`,
                  multiline: true,
                  rows: 3,
                }}
              />
            </div>
          );
        }}
      />
    </div>
  );
}

export default OfferLetterForm;
