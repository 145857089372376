import axios from "axios";

export const fetchUsersDataRequest = async (payload: any) => {
  let page = 0;
  if (payload && payload > 0) {
    page = payload - 1;
  }
  let dataPayload = {
    pageNumber: page,
    pageSize: 10,
  };
  let res;
  try {
    res = await axios.post(`/api/geebeeRoute?path=users/search`, dataPayload);
    if (res?.data?.success) {
      return res?.data;
    } else {
      throw new Error(res?.data?.message);
    }
  } catch (err) {
    throw new Error("Something went wrong. Please contact the tech team");
  }
};
export const fetchCountriesRequest = async (payload: any) => {
  let res;
  try {
    res = await axios.get(`/api/geebeeRoute?path=users/countries`);
    if (res?.data?.success) {
      return res?.data;
    } else {
      throw new Error(res?.data?.message);
    }
  } catch (err) {
    throw new Error("Something went wrong. Please contact the tech team");
  }
};
export const createUserRequest = async (payload: any) => {
  let res;
  try {
    res = await axios.post(`/api/geebeeRoute?path=users`, payload);
    if (res?.data?.success) {
      return res?.data;
    } else {
      throw new Error(res?.data?.message);
    }
  } catch (err) {
    throw new Error("Something went wrong. Please contact the tech team");
  }
};
export const editUserRequest = async (payload: any) => {
  const { eachUserId, values } = payload;
  let res;
  try {
    res = await axios.put(`/api/geebeeRoute?path=users/${eachUserId}`, values);
    if (res?.data?.success) {
      return res?.data;
    } else {
      throw new Error(res?.data?.message);
    }
  } catch (err) {
    throw new Error("Something went wrong. Please contact the tech team");
  }
};
export const deactivateUserRequest = async (eachUserId: any) => {
  let res;
  try {
    res = await axios.delete(
      `/api/geebeeRoute?path=users/${eachUserId}/deactivate`
    );
    if (res?.data?.success) {
      return res?.data;
    } else {
      throw new Error(res?.data?.message);
    }
  } catch (err) {
    throw new Error("Something went wrong. Please contact the tech team");
  }
};
export const fetchEachUserDataRequest = async (userId: any) => {
  let res;
  try {
    res = await axios.get(`/api/geebeeRoute?path=users/${userId}`);
    if (res?.data?.success) {
      return res?.data;
    } else {
      throw new Error(res?.data?.message);
    }
  } catch (err) {
    throw new Error("Something went wrong. Please contact the tech team");
  }
};
export const addLeavesRequest = async (payload: any) => {
  const { eachUserId, leavePayload } = payload;
  let res;
  try {
    res = await axios.post(
      `/api/geebeeRoute?path=users/${eachUserId}/leave`,
      leavePayload
    );
    if (res?.data?.success) {
      return res?.data;
    } else {
      throw new Error(res?.data?.message);
    }
  } catch (err) {
    throw new Error("Something went wrong. Please contact the tech team");
  }
};
export const removeLeavesRequest = async (eachUserId: any) => {
  let res;
  try {
    res = await axios.delete(
      `/api/geebeeRoute?path=users/${eachUserId}/leave`
    );
    if (res?.data?.success) {
      return res?.data;
    } else {
      throw new Error(res?.data?.message);
    }
  } catch (err) {
    throw new Error("Something went wrong. Please contact the tech team");
  }
};

export const userManagementApi = {
  fetchUsersDataRequest,
  fetchCountriesRequest,
  createUserRequest,
  editUserRequest,
  fetchEachUserDataRequest,
  deactivateUserRequest,
  addLeavesRequest,
  removeLeavesRequest,
};
