import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import { Button, isFalsy } from '@leapfinance/frontend-commons';
import GeebeeTheme from '@leapfinance/frontend-commons/components/geebee/theme';
import {
  FFAsyncDropdown,
  GBTypography,
} from '@leapfinance/geebee-component-library';
import { CloseRounded } from '@mui/icons-material';
import {
  Divider,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import { ThemeProvider } from '@mui/system';

import { CFEventsType, CfEventUtil } from '@/components/CF/CFEventsUtil';
import useGetStudentOptions from '@/components/CF/Dashboard/hooks/useGetStudentOptions';
import {
  useBulkAddToShortlistMutation,
  useGetCFCourseDetailsByIdQuery,
} from '@/components/CF/Dashboard/services/geebeeCf';
import useModal from '@/components/modals/useModal';
import SelectedCourseItem from '@/pages/course-details/SelectedCourseItem';
import { useLazyGetShortlistDataQuery } from '@/services/courseFinder';
import { SegmentEventSource } from '@/types/segmentEvents';

export type AddCourseToShortlistProps = {
  student?: {
    label: string;
    value: any;
  };
  courseDetails: {
    courseName?: string;
    universityName?: string;
    cityName?: string;
    countryName?: string;
    courseId: number;
  };
};

function AddCourseToShortlist({
  student,
  courseDetails,
}: AddCourseToShortlistProps) {
  const [triggerGetShortlist, { data, isLoading: studentShortlistLoading }] =
    useLazyGetShortlistDataQuery();
  const [debouncedGetStudents] = useGetStudentOptions();
  // to make data consistent with CF
  const { data: courseData } = useGetCFCourseDetailsByIdQuery(
    {
      id: courseDetails?.courseId,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    if (!isFalsy(student)) {
      triggerGetShortlist({
        studentId: student?.value ?? -1,
      });
    }
  }, [student]);

  const getSelectedCourseDetails = () => {
    const courses =
      (data?.data?.shortlist ?? []).map(
        (shortlistItem) => shortlistItem.courses,
      ) ?? [];
    const finalCoursesList = courses?.flat() ?? [];

    return (
      finalCoursesList.find(
        (item) => item?.courseId === courseDetails?.courseId,
      ) ?? {}
    );
  };

  const getCourseDetailsWithShortlistedFlag = (values: any) => {
    const courseWithShortlistedFlag = getSelectedCourseDetails();

    if (
      !isFalsy(courseWithShortlistedFlag) &&
      values?.createStudentMode !== `yes` &&
      !isFalsy(values?.student)
    ) {
      return {
        ...courseWithShortlistedFlag,
        shortlisted: true,
      };
    }

    return {
      ...courseDetails,
      shortlisted: false,
    };
  };

  const { hideModal } = useModal();

  const [
    addToShortlistTrigger,
    { isLoading: isLoadingResults, data: shortlistAddedData },
  ] = useBulkAddToShortlistMutation();

  const addToShortlist = async (values: any) => {
    CfEventUtil.triggerCfEvent(CFEventsType.CD_ADD_SHORTLIST_MODAL, {
      restInfo: {
        courseId: courseDetails?.courseId,
        courseName: courseDetails?.courseName as string,
        source: SegmentEventSource.CF,
      },
    });

    addToShortlistTrigger({
      studentId: values?.student?.value as number,
      courses: [courseData?.data],
    }).unwrap();
  };

  const handleStudentChange = (_: any, student: any) => {
    if (!isFalsy(student?.value)) {
      triggerGetShortlist({
        studentId: student.value,
      });
    }
  };

  const handleRedirectToShortlist = (values: any) => {
    window.open(
      `/student/` + values?.student?.value + `?active=SHORTLIST`,
      `_blank`,
    );
  };

  if (typeof window === `undefined`) return null;

  return (
    <ThemeProvider theme={GeebeeTheme}>
      <div className="flex items-center justify-center w-full h-full">
        <div className="bg-white text-black  z-10 relative max-h-[500px] w-[500px]">
          <div
            className="sticky top-0 z-[999] bg-white border-b flex flex-col justify-center w-full"
            id="selected-course-select-student"
          >
            <div className="w-full flex flex-row justify-between items-center px-4 py-1">
              <Typography variant="subtitle1">
                Add this course to shortlist
              </Typography>
              <IconButton
                onClick={() => {
                  hideModal();
                }}
              >
                <CloseRounded />
              </IconButton>
            </div>
          </div>
          <Divider />
          <Form
            onSubmit={addToShortlist}
            initialValues={{ student: student }}
            render={({ handleSubmit, values }) => {
              const addToShortlistButtonDisabled =
                isLoadingResults || !values?.student;

              return (
                <>
                  {shortlistAddedData?.success ? (
                    <div className="flex flex-col justify-center items-center py-6 gap-y-6 px-6">
                      <img
                        height={100}
                        width={100}
                        src="https://ik.imagekit.io/onsnhxjshmp/geebee-emails/VAS/Success%20(2)_AdfdATl7j.gif?updatedAt=1703778274029"
                        alt="success"
                      />
                      <Typography variant="subtitle2" className="text-grey-900">
                        Course Successfully Added to{` `}
                        {values?.student?.label?.split(`-`)?.[0] ??
                          values?.student?.label}
                        `s
                        {` `}
                        Shortlist.
                      </Typography>
                    </div>
                  ) : (
                    <>
                      <div>
                        <div className="px-4 pt-6">
                          <FFAsyncDropdown
                            fieldProps={{
                              name: `student`,
                            }}
                            componentProps={{
                              textInputProps: {
                                label: (
                                  <GBTypography
                                    variant="sh2Semibold"
                                    className="text-black"
                                  >
                                    Select student{` `}
                                    <span className="text-gbTokens-error-500">
                                      *
                                    </span>
                                  </GBTypography>
                                ),
                                required: true,
                                placeholder: `Please select a student`,
                              },
                              options: [],
                              fetchOptionsHandler: debouncedGetStudents,
                              disableClearable: false,
                              onChange: handleStudentChange,
                            }}
                          />
                        </div>
                      </div>

                      <div className="flex flex-col px-4 pt-6 w-full">
                        {isLoadingResults || studentShortlistLoading ? (
                          <div
                            key={`selected-course`}
                            className="grid grid-cols-[10%_80%_10%] mb-4 items-start"
                          >
                            <Skeleton
                              variant="rectangular"
                              className="h-4 w-4 m-[9px]"
                            />
                            <div>
                              <Skeleton variant="text" />
                              <Skeleton variant="text" className="w-[40%]" />
                              <div className="flex justify-start items-center gap-2">
                                <Skeleton variant="text" className="w-[50%]" />
                                <Skeleton variant="text" className="w-[50%]" />
                              </div>
                            </div>
                            <Skeleton
                              variant="rectangular"
                              className="h-7 w-5 m-2"
                            />
                          </div>
                        ) : (
                          <SelectedCourseItem
                            course={getCourseDetailsWithShortlistedFlag(values)}
                          />
                        )}
                      </div>
                    </>
                  )}
                  <div className="[&>button]:shadow-lg flex items-center justify-center gap-4 px-4 py-6 sticky bottom-0 bg-white">
                    {shortlistAddedData?.success ||
                    getCourseDetailsWithShortlistedFlag(values)?.shortlisted ? (
                      <>
                        <Button
                          loading={isLoadingResults}
                          disableElevation={false}
                          className="w-full drop-shadow-lg"
                          onClick={() => {
                            CfEventUtil.triggerCfEvent(
                              CFEventsType.CD_VIEW_SHORTLIST_IN_MODAL,
                              {
                                restInfo: {
                                  courseId: courseDetails?.courseId,
                                  courseName:
                                    courseDetails?.courseName as string,
                                  source: SegmentEventSource.CF,
                                },
                              },
                            );
                            handleRedirectToShortlist(values);
                          }}
                        >
                          View Shortlisted Course
                        </Button>
                      </>
                    ) : (
                      <>
                        {addToShortlistButtonDisabled ? (
                          <Tooltip
                            title="Please select a student"
                            arrow
                            placement="top"
                          >
                            <div className="w-full drop-shadow-lg">
                              <Button
                                loading={isLoadingResults}
                                disableElevation={false}
                                disabled={addToShortlistButtonDisabled}
                                className="read-only-cta w-full drop-shadow-lg"
                                onClick={handleSubmit}
                              >
                                Add to Shortlist
                              </Button>
                            </div>
                          </Tooltip>
                        ) : (
                          <Button
                            loading={isLoadingResults}
                            disableElevation={false}
                            disabled={addToShortlistButtonDisabled}
                            className="read-only-cta w-full drop-shadow-lg"
                            onClick={handleSubmit}
                          >
                            Add to Shortlist
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                </>
              );
            }}
          />
        </div>
      </div>
    </ThemeProvider>
  );
}

export default AddCourseToShortlist;
