import { createTheme } from '@mui/material';

export const theme = createTheme({
  typography: {
    fontFamily: [`sans-serif`].join(`,`),
  },
  palette: {
    secondary: {
      main: `#443EFF`,
    },
    primary: {
      main: `#443EFF`,
    },
  },
});
