import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import { generalApi } from 'services/generalApi';
import { definitions } from 'types/schema';

const initialState = {
  allUsers: [],
  zonalManagers: [],
  counsellors: [],
  assingedTos: [],
  userAssociatedCountryGroup: [],
  userAssociatedCounsellors: [],
  userAssociatedZms: [],
  visaCounsellors: [],
} as definitions['SpeedupUserFilterDto'];

export const userInfoListSlice = createSlice({
  name: `userInfoLists`,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addMatcher(
      generalApi.endpoints.getAllUsers.matchFulfilled,
      (state, { payload }) => {
        state.allUsers = payload?.data?.allUsers ?? [];
      },
    );
    builder.addMatcher(
      generalApi.endpoints.getUsersByRole.matchFulfilled,
      (state, { payload }) => {
        state.zonalManagers = payload?.data?.zonalManagers ?? [];
        state.counsellors = payload?.data?.counsellors ?? [];
        state.assingedTos = payload?.data?.assingedTos ?? [];
        state.visaCounsellors = payload?.data?.visaCounsellors ?? [];
      },
    );
    builder.addMatcher(
      generalApi.endpoints.getAssociatedUsers.matchFulfilled,
      (state, { payload }) => {
        state.userAssociatedCounsellors =
          payload?.data?.userAssociatedCounsellors ?? [];
        state.userAssociatedCountryGroup =
          payload?.data?.userAssociatedCountryGroup ?? [];
        state.userAssociatedZms = payload?.data?.userAssociatedZms ?? [];
      },
    );
  },
});

export const selectUserInfoList = (state: RootState) => {
  return state?.userInfoList ?? {};
};

export default userInfoListSlice.reducer;
