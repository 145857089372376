import { FetchArgs } from '@reduxjs/toolkit/query';

import { documentUploadBaseApi } from '@/services/base/documentUpload';
import { geebeeRootBaseApiWithTags } from '@/services/base/geebeeApi';
import {
  AddUpdateIssuesRequestType,
  AddUpdateIssuesResponseType,
  GetEligibilityParameterListRequestType,
  GetEligibilityParameterListResponseType,
  GetParameterCurrentValueRequestType,
  GetParameterCurrentValueResponseType,
  GetRequiredInformationRequestType,
  GetRequiredInformationResponseType,
  GetUniversitiesRequestType,
  GetUniversititesResponseType,
  GetUniversityCoursesRequestType,
  GetUniversityCoursesResponseType,
  GetUniversityReportedIssuesRequestType,
  GetUniversityReportedIssuesResponseType,
  GetUniversityRequestType,
  GetUniversityResponseType,
  UpdateEligibilityRequestType,
  UpdateEligibilityResponseType,
  UpdateIssuesRequestType,
  UpdateIssuesResponseType,
  UpdateRequiredInformationRequestType,
  UpdateRequiredInformationResponseType,
  UploadSampleDocumentRequestType,
  UploadSampleDocumentResponseType,
} from '@/types/Services';
import { transformResponse } from '@/utils/common';

export const universityApi = geebeeRootBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getUniversityDetails: builder.query<
      GetUniversityResponseType,
      GetUniversityRequestType
    >({
      query: ({ id }) => `/cf/university/${id}`,
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetUniversityResponseType;
        } else {
          return {
            success,
            data,
            message,
          } as unknown as GetUniversityResponseType;
        }
      },
      providesTags: [`UniversityPage`],
    }),
    getUniversityCourses: builder.mutation<
      GetUniversityCoursesResponseType,
      GetUniversityCoursesRequestType
    >({
      query: (body) => ({
        url: `/cf/university/courses`,
        method: `POST`,
        body,
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetUniversityCoursesResponseType;
        } else {
          return {
            success,
            data,
            message,
          } as unknown as GetUniversityCoursesResponseType;
        }
      },
    }),

    getUniversities: builder.mutation<
      GetUniversititesResponseType,
      GetUniversitiesRequestType
    >({
      query: (body) => ({
        url: `/cf/universities`,
        method: `POST`,
        body,
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetUniversititesResponseType;
        } else {
          return {
            success,
            data,
            message,
          } as unknown as GetUniversititesResponseType;
        }
      },
    }),

    addUpdateReportedIssues: builder.mutation<
      AddUpdateIssuesResponseType,
      AddUpdateIssuesRequestType
    >({
      query: ({ universityId, dto }) => ({
        url: `/university-data-correction/report-issue/${universityId}`,
        method: `POST`,
        body: dto,
      }),
      invalidatesTags: [`UniversityPage`],
      transformResponse: transformResponse,
    }),

    getEligibilityParameterList: builder.query<
      GetEligibilityParameterListResponseType,
      GetEligibilityParameterListRequestType
    >({
      query: ({ universityId }) =>
        `/university-data-correction/${universityId}/eligibility-parameters`,
      transformResponse: transformResponse,
      keepUnusedDataFor: 0,
      providesTags: [`DataCorrectionGetEligibilityParameter`],
    }),

    getRequiredInformationList: builder.query<
      GetRequiredInformationResponseType,
      GetRequiredInformationRequestType
    >({
      query: ({ universityId }) =>
        `/university-data-correction/${universityId}/required-information`,
      transformResponse: transformResponse,
      providesTags: [`DataCorrectionGetRequiredParameter`],
      keepUnusedDataFor: 0,
    }),

    updateUniversityEligibilityParameters: builder.mutation<
      UpdateEligibilityResponseType,
      UpdateEligibilityRequestType
    >({
      query: ({ universityId, degreeId, dto }) => ({
        url: `/university-data-correction/${universityId}/${degreeId}/update-eligibility-requirements`,
        method: `POST`,
        body: dto,
      }),
      transformResponse: transformResponse,
      invalidatesTags: [`DataCorrectionGetEligibilityParameter`],
    }),

    updateRequiredInformation: builder.mutation<
      UpdateRequiredInformationResponseType,
      UpdateRequiredInformationRequestType
    >({
      query: ({ universityId, degreeId, dto }) => ({
        url: `/university-data-correction/${universityId}/${degreeId}/update-required-information`,
        method: `POST`,
        body: dto,
      }),
      invalidatesTags: [`DataCorrectionGetRequiredParameter`],
      transformResponse: transformResponse,
    }),

    getParameterCurrentValue: builder.query<
      GetParameterCurrentValueResponseType,
      GetParameterCurrentValueRequestType
    >({
      query: ({ universityId, degreeId, parameterName, parameterType }) =>
        `/university-data-correction/${universityId}/${degreeId}/${parameterType}/${parameterName}`,
      transformResponse: transformResponse,
      keepUnusedDataFor: 0,
    }),

    getReportedIssuesList: builder.query<
      GetUniversityReportedIssuesResponseType,
      GetUniversityReportedIssuesRequestType
    >({
      query: ({ universityId }) =>
        `/university-data-correction/${universityId}/reported-issues`,
      transformResponse: transformResponse,
      keepUnusedDataFor: 0,
    }),

    updateReportedIssues: builder.mutation<
      UpdateIssuesResponseType,
      UpdateIssuesRequestType
    >({
      query: ({ universityId, dto }) => ({
        url: `/university-data-correction/${universityId}/update-university-data`,
        method: `POST`,
        body: dto,
      }),
      transformResponse: transformResponse,
    }),
  }),
});

export const universityDocumentApi = documentUploadBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadSampleDocument: builder.mutation<
      UploadSampleDocumentResponseType,
      UploadSampleDocumentRequestType
    >({
      query: ({ universityId, degreeId, file, keyName }) => {
        const formData = new FormData();
        formData.append(`file`, file as File);
        const payload: FetchArgs = {
          url: `/documents/sample-document/${universityId}/degree/${degreeId}/${keyName}`,
          method: `POST`,
          body: formData,
        };
        return payload;
      },
      transformResponse: transformResponse,
    }),
  }),
});

export const { useUploadSampleDocumentMutation } = universityDocumentApi;
export const {
  useGetUniversityDetailsQuery,
  useGetUniversityCoursesMutation,
  useGetUniversitiesMutation,
  useAddUpdateReportedIssuesMutation,
  useGetEligibilityParameterListQuery,
  useGetRequiredInformationListQuery,
  useUpdateUniversityEligibilityParametersMutation,
  useUpdateRequiredInformationMutation,
  useGetParameterCurrentValueQuery,
  useLazyGetParameterCurrentValueQuery,
  useGetReportedIssuesListQuery,
  useUpdateReportedIssuesMutation,
} = universityApi;
