import * as React from 'react';
import { SVGProps } from 'react';

const RemarkNotificationIcon = (
  props: SVGProps<SVGSVGElement> & { active?: boolean },
) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18 4h-1v8c0 .55-.45 1-1 1H4v1c0 1.1.9 2 2 2h10l4 4V6c0-1.1-.9-2-2-2Zm-3 5V2c0-1.1-.9-2-2-2H2C.9 0 0 .9 0 2v13l4-4h9c1.1 0 2-.9 2-2Z"
      fill={props.active ? `#56AADE` : `#b7b7b7`}
    />
  </svg>
);

export default RemarkNotificationIcon;
