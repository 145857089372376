import React from 'react';

import { VASTypes } from '@/types/vas';

import VASAccomodationFormController, {
  AccomodationFormProps,
} from './VASAccomodationFormController';
import VASFlywireFormController, {
  FlywireFormProps,
} from './VASFlywireFormController';
import VASGICFormController, { GICFormProps } from './VASGICFormController';
import VASLoansFormController, {
  LoansFormProps,
} from './VASLoansFormController';

type Props = {
  accomodationFormProps: Omit<AccomodationFormProps, 'onClose'> & {
    onClose?: () => void;
  };
  gicFormProps: Omit<GICFormProps, 'onClose'> & {
    onClose?: () => void;
  };
  flywireFormProps: Omit<FlywireFormProps, 'onClose'> & {
    onClose?: () => void;
  };
  loansFormProps: Omit<LoansFormProps, 'onClose'> & {
    onClose?: () => void;
  };
  type: VASTypes;
  onClose: () => void;
};

// TODO:: dynamic import for form controllers
function VASFormControllerSwitch({
  type,
  accomodationFormProps,
  gicFormProps,
  flywireFormProps,
  loansFormProps,
  onClose,
}: Props) {
  switch (type) {
    case `accomodation`:
      return (
        <VASAccomodationFormController
          {...accomodationFormProps}
          onClose={onClose}
        />
      );
    case `gic`:
      return <VASGICFormController {...gicFormProps} onClose={onClose} />;
    case `flywire`:
      return (
        <VASFlywireFormController {...flywireFormProps} onClose={onClose} />
      );
    case `loan`:
      return <VASLoansFormController {...loansFormProps} onClose={onClose} />;
    default:
      return <></>;
  }
}

export default VASFormControllerSwitch;
