import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

import { OptionType } from '@/lib/constant';
import {
  useGetUniversitySuggestionsMutation,
  useGetUniversityVendorSuggestionsMutation,
} from '@/services/applicationApi';
import { useFetchCspCountriesQuery } from '@/services/student';
import { ServiceCategoryTypes } from '@/types/vas';

type Props = {
  serviceCategory: ServiceCategoryTypes;
};

const cfIdCountryMapping: Record<string, number | undefined> = {};

const GIC_COUNTRY = `Canada`;

function useUniversitiesOptions({ serviceCategory }: Props) {
  const [triggerCollegeSearch] = useGetUniversitySuggestionsMutation();
  const [triggerCollegeVendorsSearch] =
    useGetUniversityVendorSuggestionsMutation();
  const [countryList, setCountryList] = useState<OptionType[]>([]);

  const { data } = useFetchCspCountriesQuery(undefined, {
    skip: serviceCategory === `ACCOMMODATION`,
  });

  useEffect(() => {
    const countriesList = data?.data?.countries;
    if (countriesList && !countryList.length) {
      const updatedList = countriesList.map((item: any) => {
        if (item.labelKey) {
          cfIdCountryMapping[String(item.labelKey)] =
            item.cfCountryId ?? undefined;
        }
        return {
          label: item.labelValue,
          value: item.labelKey,
          isDisabled: !item?.isAuthorized,
        };
      });
      updatedList.sort((a: any, b: any) => {
        return Number(a?.isDisabled) - Number(b?.isDisabled);
      });

      setCountryList(updatedList);
    }
  }, [data]);

  const fetchUniversities = async (inputValue: string, callback: any) => {
    const options = [];

    const data = await triggerCollegeSearch({
      universityName: inputValue,
      ...(serviceCategory === `GIC` && {
        countryIds: cfIdCountryMapping[GIC_COUNTRY]
          ? [cfIdCountryMapping[GIC_COUNTRY] as number]
          : [],
      }),
      ...(serviceCategory === `FLYWIRE` && {
        paymentVendor: `FLYWIRE`,
      }),
    }).unwrap();
    const list = data?.data?.universities || [];
    //   {
    //     "universityId": null,
    //     "universityName": "Adelphi University",
    //     "stateId": null,
    //     "gbOfferRate": null,
    //     "gbTieUp": null,
    //     "cmsInstitutesMapped": null,
    //     "vendorUniversityId": "FLYWIRE:ADI",
    //     "vendorUnivCurrency": "USD",
    //     "metadata": [
    //         {
    //             "id": "amount",
    //             "value": 0,
    //             "title": "Amount"
    //         }
    //     ],
    //     "paymentDestinationId": "FLYWIRE:ADI"
    // }
    for (let i = 0; i < list.length; i++) {
      options.push({
        value: list[i]?.universityName,
        label: list[i]?.universityName,
        gbOfferRate: list[i]?.gbOfferRate,
        stateId: list[i]?.stateId,
        gbTieUp: list[i]?.gbTieUp,
        vendorUnivCurrency: (list[i] as any)?.vendorUnivCurrency,
        vendorUniversityId: (list[i] as any)?.vendorUniversityId,
        vendorUnivCountryCode: (list[i] as any)?.vendorUnivCountryCode,
        vendorUnivCountry: (list[i] as any)?.vendorUnivCountry,
        metadata: (list[i] as any)?.metadata,
      });
    }
    callback(options);
  };

  const fetchUniversityVendorOptions = async (
    inputValue: string,
    callback: any,
  ) => {
    const options = [];

    const data = await triggerCollegeVendorsSearch({
      universityName: inputValue,
      ...(serviceCategory === `GIC` && {
        countryIds: cfIdCountryMapping[GIC_COUNTRY]
          ? [cfIdCountryMapping[GIC_COUNTRY] as number]
          : [],
      }),
      ...(serviceCategory === `FLYWIRE` && {
        paymentVendor: `FLYWIRE`,
      }),
    }).unwrap();
    const list = data?.data?.universities || [];
    //   {
    //     "universityId": null,
    //     "universityName": "Adelphi University",
    //     "stateId": null,
    //     "gbOfferRate": null,
    //     "gbTieUp": null,
    //     "cmsInstitutesMapped": null,
    //     "vendorUniversityId": "FLYWIRE:ADI",
    //     "vendorUnivCurrency": "USD",
    //     "metadata": [
    //         {
    //             "id": "amount",
    //             "value": 0,
    //             "title": "Amount"
    //         }
    //     ],
    //     "paymentDestinationId": "FLYWIRE:ADI"
    // }
    for (let i = 0; i < list.length; i++) {
      options.push({
        value: list[i]?.universityName,
        label: list[i]?.universityName,
        gbOfferRate: list[i]?.gbOfferRate,
        stateId: list[i]?.stateId,
        gbTieUp: list[i]?.gbTieUp,
        vendorUnivCurrency: (list[i] as any)?.vendorUnivCurrency,
        vendorUniversityId: (list[i] as any)?.vendorUniversityId,
        vendorUnivCountryCode: (list[i] as any)?.vendorUnivCountryCode,
        vendorUnivCountry: (list[i] as any)?.vendorUnivCountry,
        metadata: (list[i] as any)?.metadata,
      });
    }
    callback(options);
  };

  return [
    debounce(fetchUniversities, 500),
    debounce(fetchUniversityVendorOptions, 500),
  ];
}

export default useUniversitiesOptions;
