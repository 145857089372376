import { Button } from '@leapfinance/frontend-commons';
import GeebeeTheme from '@leapfinance/frontend-commons/components/geebee/theme';
import { Close } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { ThemeProvider } from '@mui/system';
import { nanoid } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { close } from '../../features/confirmDialogSlice';
import { CommissionWarningIcon } from '../Icons';

import CustomButton from './../CustomMUIComponents/CustomButton';
// sample usage for reference
// confirmDialog({
//     type: CONFIRM_MODAL_TYPES.COMMISSION_WARNING,
//     title: `Are you sure you want to take this action?`,
//     message: `Commission`,
//     meta: {
//       intakes: Array.from({ length: 12 }, (_, monthIndex) => {
//         const date = new Date(2023, monthIndex, 1);
//         const monthName = date.toLocaleString('default', { month: 'short' });
//         const year = date.getFullYear();
//         return `${monthName} ${year}`;
//       }),
//       warningParameters: [
//         'Total number of receivable parts',
//         'Rate',
//       ],
//     },
//     onSubmit: () => {
//       console.log('commission');
//     },
//   }),

const CommissionWarningModal = () => {
  const { onSubmit, onCancel, options, meta } = useAppSelector(
    (state) => state.confirmDialog,
  );
  const { intakes, warningParameters } = meta || {};
  const dispatch = useAppDispatch();

  return (
    <ThemeProvider theme={GeebeeTheme}>
      <Dialog open={Boolean(onSubmit)} maxWidth="sm">
        <div className="px-6 py-4">
          <div className="flex w-full gap-2 pb-4">
            <CommissionWarningIcon />
            <Typography variant="subtitle1" className="text-grey-900">
              Are you sure you want to take this action?
            </Typography>
          </div>
          <DialogContent className={`flex flex-col bg-error-100 rounded p-3`}>
            {intakes?.length > 0 && (
              <Typography variant="subtitle2" className="text-error-700 pb-2">
                {`Following details of all the students in intake ${intakes?.join(
                  `, `,
                )} will be updated`}
              </Typography>
            )}
            <ul className="list-disc pl-6 text-[10px] text-error-700">
              {warningParameters?.map((item: any) => (
                <li key={nanoid()}>
                  <Typography variant="body2" className="text-error-700">
                    {item}
                  </Typography>
                </li>
              ))}
            </ul>
          </DialogContent>
          <DialogActions
            className={`flex w-full pt-4  items-center justify-center`}
          >
            <>
              <Button
                className="flex-1 p-2 "
                size="large"
                variant={`outlined`}
                onClick={() => {
                  if (onCancel) {
                    onCancel();
                  }
                  dispatch(close());
                }}
              >
                {options?.cancelButtontext || `Cancel`}
              </Button>
              <Button
                className="flex-1 p-2"
                size="large"
                onClick={() => {
                  if (onSubmit) {
                    onSubmit();
                  }
                  dispatch(close());
                }}
              >
                {options?.proceedButtonText || `Confirm`}
              </Button>
            </>
          </DialogActions>
        </div>
      </Dialog>
    </ThemeProvider>
  );
};

export default CommissionWarningModal;
